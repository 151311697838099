import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import CreateGroup from './createGroup';
import UpdateGroup from './updateGroup';
import { DimmableLoader, ErrorMessage } from '../../../components/Styled/Misc';
import { Modal } from '../../../components/Styled/Misc/Modal';
import { CloseButton, Button } from '../../../components/Styled/Buttons';

const ButtonContainer = styled.div`
margin-left: auto`;

const StyledButton = styled.button`
border: none;
text-decoration: none;
background: inherit;
cursor: pointer;
font-size: 14px
`;

const H2 = styled.h2`
  ${({ color }) => `color: ${color}`}`;

const Editor = () => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [list, setList] = useState(null);
  const [res, setRes] = useState(null);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [updateList, switchUpdateList] = useState(false);
  const [openUpdatePage, setOpenUpdatePage] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/permissiongroup/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setList(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart, updateList]);

  const deleteGroup = useCallback((id) => {
    const loader = async () => {
      const r = await api.delete(`/api/permissiongroup/${id}`, auth);
      if (r.status === 204) {
        switchUpdateList(!updateList);
        return 'Видалено успішно';
      }
      if (r.status === 400) {
        const error = await r.json();

        error.error_data.map((e) => {
          if (e.messages) {
            throw new Error(`${r.status}: ${e.messages}`);
          }
          return error.error_data;
        });

        return error.error_data;
      }
      if (!r.ok) throw new Error(`${r.status}: ${r.statusText}`);

      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRes(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart, updateList]);

  const handleOpenedUpdatePage = useCallback((id) => {
    setActiveId(id);
    setOpenUpdatePage(true);
  }, []);

  return (
    <>
      <DimmableLoader loading={loading}>
        {err && (
          <ErrorMessage text={err} />
        )}

        {res && (
          <H2 dismissible color="green">
            {res}
          </H2>
        )}
        {list
        && (
          <>
            <Modal open={showCreateGroup} onHide={() => setShowCreateGroup(false)} size="xl">
              <>
                <ButtonContainer>
                  <CloseButton onClick={() => setShowCreateGroup(false)} />
                </ButtonContainer>
                <CreateGroup
                  onClose={() => setShowCreateGroup(false)}
                  setUpdateList={switchUpdateList}
                />
              </>
            </Modal>

            <Modal open={openUpdatePage} onHide={() => setOpenUpdatePage(false)} size="xl">
              <>
                <ButtonContainer>
                  <CloseButton onClick={() => setOpenUpdatePage(false)} />
                </ButtonContainer>
                <UpdateGroup
                  onClose={() => setOpenUpdatePage(false)}
                  activeId={activeId}
                  setUpdateList={switchUpdateList}
                />
              </>
            </Modal>

            <H2 color="#00e7ff">Список груп:</H2>
            <table>
              <tbody>
                {list.results.map((el) => (
                  <tr key={el.id}>
                    <td style={{ width: '20%' }}>
                      <StyledButton type="button" onClick={() => handleOpenedUpdatePage(el.id)}>{el.name}</StyledButton>
                    </td>
                    <td>
                      <StyledButton type="button" onClick={() => handleOpenedUpdatePage(el.id)}>{el.description}</StyledButton>
                    </td>
                    <td style={{ width: '5px' }}>
                      <Button variant="outline-danger" onClick={() => deleteGroup(el.id)} content="Видалити" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <Button onClick={() => setShowCreateGroup(true)} content="Створити" />
            </div>
          </>
        )}
      </DimmableLoader>
    </>
  );
};

export default Editor;
