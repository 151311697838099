import styled from 'styled-components';

const StyledGridCell = styled.div`
  border: 1px solid #DDE2E9;
  padding: 10px 9px;
  display: grid;
  align-items: center;
  word-break: break-all;
  &:hover{
    background: linear-gradient(0deg, #66BC7A, #66BC7A), #008F21
  };
`;

export const StyledTableCell = styled(StyledGridCell).attrs((props) => ({
  style: {
    gridArea: `${props.rowId}/${props.colName}/${props.rowId}/${props.colName}`,
    background: props.active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
  },
}))``;

export const StyledCellNoData = styled(StyledGridCell).attrs((props) => ({
  style: {
    gridArea: props.gridArea,
  },
}))`
  justify-content: center;
  padding: 5px 0;
`;

export const StyledDiv = styled.div`
  margin-left: ${(props) => `${props.level}em`};
  cursor:pointer;
`;

export const StyledCell = styled.div`
  padding: 0.2em;
`;

export const StyledString = styled(StyledCell)`
`;

export const StyledNumber = styled(StyledCell)`
  text-align: right;
`;
