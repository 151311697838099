import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SelectAllIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledSelectAll = styled(StyledButton)`
    border: 1px solid #a2b4e8;
    height: 2.5em;
    align-self: flex-end;
    &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: #d0f7d4fc;
    }
`;

export const SelectAllButton = ({ content, onClick, disabled }) => (
  <StyledSelectAll onClick={onClick} disabled={disabled} title={content}>
    <StyledImg style={{ width: '20px' }} src={SelectAllIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledSelectAll>
);

SelectAllButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SelectAllButton.defaultProps = {
  onClick: () => null,
  content: 'Обрати всі',
  disabled: false,
};

export default SelectAllButton;
