/* eslint-disable camelcase */
import styled from 'styled-components';

export const GridTemplate = styled.div`
  display: grid;
  grid-template-areas:
    "kdb day_sum accum_sum";
`;

export const DivElement = styled.div`
  border: 1px solid #DDE2E9; 
  position: relative;
  ${({ not_import }) => not_import ? 'background: #ffc6c6' : ''}
  &:hover{
    background: linear-gradient(0deg, #66BC7A, #66BC7A), #008F21
  };
`;

export const DivHeader = styled.div`
  border: 1px solid #DDE2E9; 
  grid-area: ${({ area }) => area};
  position: sticky;
  display: flex;
  align-items: center;
  text-align: center;
  top: 0;
  height: 2.3em;
  z-index: 1;
  padding: .5em 9px;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  cursor: pointer;
  font-family: Roboto,sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: black;
`;
