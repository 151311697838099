import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DocGrid, GridItem } from '../../../../components/Styled/grids/editorGrid';
import { Label } from '../../../../components/Styled/elements';
import { FileIcon } from '../../../../components/Styled/Buttons/icons';

export const StepLabel = styled(Label)`
  // color: green;
  color: #488de4;
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 1.2em;
`;

export const InfoLabel = styled.h3`
  color: green;
  text-align: center;
`;

export const GItem = styled(GridItem)`
  overflow: auto;
  display: flex;
`;


const StyledStepItem = styled(GridItem)`
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 10px;
  margin: 10px 0;
  background: white;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
  // width: 100%;
  // display: flex;
  // flex-direction: column;
`;

export const StepItem = ({ label, children }) => (
  <StyledStepItem>
    <StepLabel>{label}</StepLabel>
    {React.Children.toArray(children)}
  </StyledStepItem>
);

StepItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export const PeriodDiv = styled.div`
  // display: block;
  // justify-content: space-around;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  
  @media (max-width: 600px) {
  display: block;
  grid-template-rows: auto auto;
  grid-template-columns: none;
  }
`;

export const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "info"
    "org"
    "master"
    ;
  grid-template-rows: 30px 50px auto 1fr;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;  
`;

export const DropZone = styled.div`
  text-align: center;
  margin: 10px;
  padding: 10px;
  background: #ffc6c6;
  // border: 4px dashed red;
  border-radius: 10px;
`;

export const BoxTittle = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Form = styled.form`
  height: 100%; 
`;

export const Buttons = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  // padding-bottom: 1em; 
`;

const StyledBoxFile = styled.div`
   display: flex;
   align-items: center;
   margin-top: 5px;
   margin-right: 5px;
   font-size: 13px;
   background: white;
   border: 1px solid #a2b4e8;
   border-radius: 0.3rem;
   padding: 4px;
   cursor: pointer;
`;

const StyledBoxFileImg = styled.img`
`;

export const BoxFile = ({ fileName }) => (
  <StyledBoxFile>
    <StyledBoxFileImg src={FileIcon} alt={fileName} />
    {fileName}
  </StyledBoxFile>
);

BoxFile.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export const FileDiv = styled.div`
`;

export const ResultContainer = styled.div`
  margin-left: 10px;
  border: 1px solid #DDE2E9;
  border-radius: 0.3em;
  padding: 10px;
  width: 100%;
`;

export const ResultHeader = styled.div`
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
  color: #605b5b;
  margin: 0.3em 0;
`;

export const ResultSubHeader = styled.div`
  font-weight: 700;
  margin: 0.7em 0;
  color: green;
`;

export const ResultItem = styled.div`
  display: flex;
  align-items: center;
`;
