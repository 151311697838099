import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import Field from './field';
import itemTypes from '../itemTypes';
import { HeaderText } from '../../../../components/Styled/Forms';
import Panel from '../panel';
import { ClosedItemIcon, OpenedItemIcon } from '../../../../components/Styled/Buttons/icons';

const StyledBoxFile = styled.div`
   display: flex;
   align-items: center;
   margin-right: 5px;
   font-size: 13px;
   background: white;
   padding: 4px;
   cursor: pointer;
   margin-left: ${(props) => `calc(15px * ${props.lvl})`}
`;

const StyledBoxFileImg = styled.img`
`;

const AvailableFieldList = ({
  onClick,
  activeField,
  label,
  onDblClick,
  onDragEnd,
  visibleColumns,
  setOpenedColumns,
  openedColumns,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [_collected, ref] = useDrop({
    accept: [
      itemTypes.group, itemTypes.selection, itemTypes.order, itemTypes.filter, itemTypes.column,
    ],
    drop: (item) => onDragEnd(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // CanDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  return (
    <div>
      <HeaderText>{label}</HeaderText>
      <Panel ref={ref}>
        {visibleColumns && visibleColumns.map((f) => (
          <StyledBoxFile key={f.name} lvl={f.lvl}>
            <StyledBoxFileImg
              src={openedColumns.includes(f.name) ? OpenedItemIcon : ClosedItemIcon}
              alt={f.name}
              onClick={() => setOpenedColumns(f.name)}
            />
            <Field
              key={f.name}
              label={f.label}
              name={f.name}
              onClick={onClick}
              active={activeField === f.name}
              onDblClick={onDblClick}
            />
          </StyledBoxFile>
        ))}
      </Panel>
    </div>
  );
};


export const availablePropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
}));

AvailableFieldList.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeField: PropTypes.string,
  label: PropTypes.string.isRequired,
  onDblClick: PropTypes.func,
  onDragEnd: PropTypes.func.isRequired,
  visibleColumns: availablePropType.isRequired,
  setOpenedColumns: PropTypes.func.isRequired,
  openedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AvailableFieldList.defaultProps = {
  activeField: null,
  onDblClick: () => null,
};
export default AvailableFieldList;
