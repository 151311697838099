import React, {
  useMemo, memo, useRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddButton,
  RemoveSelectionButton,
} from '../../../../components/Styled/Buttons';
import { CommandPanel } from '../../../../components/Styled/Misc';
import { tablePropType } from '../hooks';
import TableItem from './item';
import TableProps from './props';
import { EditorContainer } from '../../../../components/Styled/Forms';
import { TwoColGrid } from '../styles';

const TablesPage = ({
  tables, activeTable, onRenameTable, onChangeTable,
  onAddTable, onRemoveTable, onSetActiveTable, showModal,
  setShowModal, setKeyValue, keyValue,
}) => {
  const [height, setHeight] = useState(0);
  const displayTables = useMemo(
    () => tables.map((t, key) => ({
      ...t,
      key,
    })), [tables],
  );

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const entries = useMemo(() => Object.entries(tables.filter((t) => t.name === activeTable)
    .reduce((R, t) => t.text, [])), [activeTable, tables]);

  return (
    <div>
      <EditorContainer>
        <TwoColGrid ref={containerHeaderRef} height={height}>
          <div>
            <CommandPanel>
              <AddButton
                title="Додати таблицю"
                content=""
                onClick={() => onAddTable(Math.random()
                  .toString(36))}
              />
              <RemoveSelectionButton
                title="Видалити таблицю"
                content=""
                onClick={() => onRemoveTable(activeTable)}
                disabled={activeTable === 'main' || !activeTable}
              />
            </CommandPanel>
            <div>
              {displayTables.map((table) => (
                <TableItem
                  key={table.key}
                  active={table.name === activeTable}
                  onClick={() => onSetActiveTable(table.name)}
                  table={table}
                  onRenameTable={onRenameTable}
                />
              ))}
            </div>
          </div>
          <div>
            <TableProps
              onChange={(v, k) => onChangeTable(activeTable, v, k)}
              entries={entries}
              showModal={showModal}
              setShowModal={setShowModal}
              keyValue={keyValue}
              setKeyValue={setKeyValue}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>
  );
};

TablesPage.propTypes = {
  tables: PropTypes.arrayOf(tablePropType).isRequired,
  activeTable: PropTypes.string,
  onRenameTable: PropTypes.func.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onAddTable: PropTypes.func.isRequired,
  onRemoveTable: PropTypes.func.isRequired,
  onSetActiveTable: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  keyValue: PropTypes.string,
  setKeyValue: PropTypes.func.isRequired,
};

TablesPage.defaultProps = {
  activeTable: null,
  keyValue: null,
};

export default memo(TablesPage);
