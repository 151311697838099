import styled from 'styled-components';
import { DefaultButton } from './defaultButton';
import { ButtonStyled } from '../../../containers/itemField/styles';

export const StyledDropDownButton = styled(ButtonStyled)`
  padding: 8px 9px;
`;

export const StyledButton = styled(DefaultButton)`
  // transform: scale(1.1);
  background: white;
  border: 1px solid #a2b4e8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  color: #5e93d0;
  border-radius: .3rem;
  outline: none;
  padding: 5px 8px;
  cursor: pointer;
  // margin-left: 12px;
  margin-right: 10px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  //font-size: 0;
  ${({ disabled }) => disabled && 'filter: grayscale(1) opacity(.275);'}
  transition: all 0.3s ease-out ;
  &:hover{
    ${({ disabled }) => !disabled && 'transform: scale(1.05);'}
  }
`;

export const StyledColorButton = styled(StyledButton)`
  background: linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9;
`;

export const StyledImg = styled.img`
  cursor: pointer;
`;

export const StyledText = styled.div`
 display: initial;
 margin-left: 5px;
 
 @media (max-width: 1395px) {
  display: none;
  }
`;
