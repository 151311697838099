import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import DKSUDates from './DKSUDates';
import DKSUData from './DKSUData';
import { AppContext } from '../../../providers/authProvider';
import { LogicaContext } from '../../../providers/authLogicaProvider';
import {
  getDateStr, LOGIKA_URL_DATA, dateToUpload, DATATYPES,
} from './consts';
import { checkTask } from '../../../api/checktask';
import { Modal } from '../../../components/Styled/Misc/Modal';
import { CloseButton } from '../../../components/Styled/Buttons';
import { DimmableLoader } from '../../../components/Styled/Misc';
import LoginInLogica from '../loginInLogica/editor';
import LogicaPing from '../Ping';

const Button = styled.button`
width: ${(props) => props.width || '300px'};
height: 40px;
color: white;
background-color: ${(props) => props['background-color'] || '#6f91ff'};
border-radius: 5px;
cursor: pointer;
&:hover{
background-color: ${(props) => props['hover-background-color'] || '#1e53ff'};
}
margin-right: ${(props) => props['margin-right']};
`;

const H2 = styled.h2`
color: ${(props) => props.color};
`;

const Editor = () => {
  const [dataType, setDataType] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [res, setRes] = useState(null);
  const [show, setShow] = useState(false);
  const [modalLogicaLogin, setModalLogicaLogin] = useState(false);
  const [datesRes, setDatesRes] = useState(null);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);

  const { auth } = useContext(AppContext);
  const token = auth().access;
  const { ticket, setTicket } = useContext(LogicaContext);

  useEffect(() => {
    if (!ticket || err === '401 ' || err === 'Помилка сервера - 401') {
      setTicket(null);
      setShow(false);
      setModalLogicaLogin(true);
      setErr(null);
      return;
    }
    setModalLogicaLogin(false);
  }, [err, setTicket, ticket]);

  const onStart = useCallback(() => {
    setLoading(true);
    setErr(null);
    setRes(null);
    setDatesRes(null);
  }, []);

  const onEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback((e) => {
    setLoading(false);
    setErr(e);
  }, []);

  const onDone = useCallback((r) => {
    setLoading(false);
    setRes(r);
  }, []);

  const getDKSUData = useCallback(() => {
    const loader = async () => {
      const datetimes = dateToUpload(date);
      const params = {
        datetimes,
        attachedBudgets: false,
        ticket,
        year: year || new Date().getFullYear(),
      };
      if (dataType !== 'Всі') {
        params.datatype = dataType;
      }
      const r = await fetch(`${LOGIKA_URL_DATA}`, {
        method: 'POST',
        headers:
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        body: JSON.stringify(params),
      });

      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      // eslint-disable-next-line camelcase
      const { task_id } = await r.json();
      // eslint-disable-next-line camelcase
      return task_id;
    };

    onStart();
    loader()
      .then((d) => checkTask(d, auth, (r) => {
        if (r.result.errors) { onError(r.result.errors[0]); } else { onDone('Дані завантажено успішно!'); }
        if (r.status !== 'PENDING') { onEnd(); }
      },
      (e) => {
        onError(e ? e.message : 'Скасовано');
      }))
      .catch((e) => {
        onError(e.message);
      });
  }, [auth, dataType, date, onDone, onEnd, onError, onStart, ticket, token, year]);

  const getDKSUDates = useCallback(() => {
    const loader = async () => {
      const response = await fetch(`${LOGIKA_URL_DATA}?ticket=${ticket}&year=${year}`, {
        method: 'GET',
        headers:
          {
            Authorization: `Bearer ${token}`,
          },
      });

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    };

    onStart();
    loader()
      .then((data) => {
        setDatesRes(data);
        onEnd();
      },
      (e) => {
        onError(e ? e.message : 'Скасовано');
      })
      .catch((e) => {
        onError(e.message);
        onEnd();
      });
  }, [onEnd, onError, onStart, ticket, token, year]);

  const getCurrentDKSUData = useCallback(() => {
    const loader = async () => {
      const params = {
        ticket, year: year || new Date().getFullYear(),
      };
      const r = await fetch(`${LOGIKA_URL_DATA}`, {
        method: 'POST',
        headers:
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        body: JSON.stringify(params),
      });

      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      // eslint-disable-next-line camelcase
      const { task_id } = await r.json();
      // eslint-disable-next-line camelcase
      return task_id;
    };

    onStart();
    loader()
      .then((d) => checkTask(d, auth, (r) => {
        if (r.result.errors) { onError(r.result.errors[0]); } else { onDone('Дані завантажено успішно!'); }
        if (r.status !== 'PENDING') { onEnd(); }
      },
      (e) => {
        onError(e ? e.message : 'Скасовано');
      }))
      .catch((e) => {
        onError(e.message);
      });
  }, [auth, onDone, onEnd, onError, onStart, ticket, token, year]);

  const datesByType = () => {
    if (dataType === 'Всі') {
      const arr = DATATYPES.filter((t) => t !== dataType)
        .map((type) => datesRes[type].map((data) => ({
          value: getDateStr(data.datefile),
          display_name: getDateStr(data.datefile),
        }))).flat().reduce((ar, val) => ar
          .find((el) => JSON.stringify(el) === (JSON.stringify(val))) ? ar : [...ar, val], []);
      return arr;
    }
    return datesRes && dataType && datesRes[dataType]
      .map((data) => ({
        value: getDateStr(data.datefile),
        display_name: getDateStr(data.datefile),
      }));
  };

  const lastDownload = datesRes && dataType && (getDateStr(datesRes.current_date[dataType]) || '-');

  return (
    <>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      <DimmableLoader loading={loading}>
        <div>
          <Button onClick={getCurrentDKSUData} margin-right="10px" width="150px" disabled={!logicaPingSuccess}>Оновити все</Button>
          <Button onClick={() => setShow(true)} background-color="#2196a0" hover-background-color="#00cedf" disabled={!logicaPingSuccess}>Оновити з додатковими налаштуваннями</Button>
        </div>

        {res && !show && <H2 color="green">{res}</H2>}
        {err && !show && <H2 color="red">{err}</H2>}

        <Modal open={modalLogicaLogin}>
          <>
            <h3>Авторизація в Логіці</h3>
            <LoginInLogica />
            <div style={{ width: '300px' }}>
              <CloseButton onClick={() => { setModalLogicaLogin(false); }} />
            </div>
          </>
        </Modal>

        <Modal open={show}>
          <>
            <DimmableLoader loading={loading}>

              <div style={{ width: '300px' }}>
                {' '}
                <CloseButton onClick={() => setShow(false)} />
              </div>
              {res && <H2 color="green">{res}</H2>}
              {err && <H2 color="red">{err}</H2>}
              <DKSUDates
                year={year}
                setYear={setYear}
                getDKSUDates={getDKSUDates}
                datesRes={datesRes}
                logicaPingSuccess={logicaPingSuccess}
              />
              {datesRes
                && (
                  <DKSUData
                    dataType={dataType}
                    setDataType={setDataType}
                    date={date}
                    setDate={setDate}
                    getDKSUData={getDKSUData}
                    datesByType={datesByType}
                    lastDownload={lastDownload}
                    logicaPingSuccess={logicaPingSuccess}
                  />
                )}

            </DimmableLoader>
          </>
        </Modal>
      </DimmableLoader>
    </>
  );
};
export default Editor;
