import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  ResultContainer, ResultHeader, ResultSubHeader, ResultItem,
} from './styles';
import { DocumentIcon } from '../../../../components/Styled/Buttons/icons';

const StyledLink = styled(Link).attrs({
  target: '_blank',
})`
  color: #488de4;
  font-size: 0.9em;
  &:hover{
    color: #1a4aac;
  };
  &:visited{
    color: #3f51b4;
  };
`;

const ShowResults = ({ result }) => (
  <ResultContainer>
    <ResultHeader>
      {result.length
        ? "До бази даних автоматично додані наступні об'єкти"
        : "До бази даних не було додано жодного об'єкту"}
    </ResultHeader>
    {result.map((res) => (
      <Fragment key={res.uri}>
        <ResultSubHeader key={res.uri}>
          {res.label}
        </ResultSubHeader>
        {res.items.map((item) => (
          <ResultItem key={item.id}>
            <img width="20px" src={DocumentIcon} alt="doc" />
            <StyledLink to={`${res.frontend}${item.id}`}>
              {`${item.repr}`}
            </StyledLink>
          </ResultItem>
        ))}
      </Fragment>
    ))}
  </ResultContainer>
);


ShowResults.propTypes = {
  result: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    uri: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
    })),
    frontend: PropTypes.string,
  })),
};

ShowResults.defaultProps = {
  result: [],
};

export default ShowResults;
