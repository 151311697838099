import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DraftIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledDraftButton = styled(StyledButton)`
  height: 2.5em;
  &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: #d0f7d4fc;
  }
`;

export const DraftButton = ({
  content, onClick, disabled, addText,
}) => (
  <StyledDraftButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={DraftIcon} alt={content} />
    {addText
    && <StyledText>{content}</StyledText>}
  </StyledDraftButton>
);

DraftButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  addText: PropTypes.bool,
};

DraftButton.defaultProps = {
  onClick: () => null,
  content: 'Зберегти чернетку',
  disabled: false,
  addText: false,
};

export default DraftButton;
