import { useEffect } from 'react';
import api from '../../../api/req';
import { REPORTS_API_URL, SCHEMAS_API_URL } from './consts';

/**
 * HOOK useLoadReport Загрудает данные оотчета - преднастроенный варинат
 * @param reportId { number } - report id
 * @param auth { function } - auth
 * @param onLoadStart { function } start load handler
 * @param onLoadEnd { function } end load handler
 * @param onSuccess { function } load success handler
 * @param onError { function } error load handler
 */
export const useLoadReport = (
  reportId, auth, onLoadStart, onLoadEnd, onSuccess, onError,
) => useEffect(
  () => {
    const loader = async () => {
      const r = await api.get(`${REPORTS_API_URL}${reportId}/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    if (reportId) {
      onLoadStart();
      loader()
        .then((rData) => {
          onSuccess(rData);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    }
  },
  [auth, onError, onLoadEnd, onLoadStart, onSuccess, reportId],
);

/**
 * HOOK useLoadShema Загрудает данные схемы
 * @param reportData {{schema:  {id: number}}}
 * @param auth { function }
 * @param onLoadStart { function }
 * @param onLoadEnd { function }
 * @param onSuccess { function }
 * @param onError { function }
 */
export const useLoadShema = (
  reportData, auth, onLoadStart, onLoadEnd, onSuccess, onError,
) => useEffect(
  () => {
    const loader = async (schemaId) => {
      const r = await api.get(`${SCHEMAS_API_URL}${schemaId}/`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    if (reportData.schema) {
      onLoadStart();
      loader(reportData.schema.id)
        .then((s) => {
          onSuccess(s);
        })
        .catch((e) => onError(e.message))
        .finally(() => onLoadEnd());
    }
  },
  [auth, onError, onLoadEnd, onLoadStart, onSuccess, reportData],
);
