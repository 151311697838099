import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PeriodButton, OKButton, CloseButton } from '../../../components/Styled/Buttons';
import { CommandPanel } from '../../../components/Styled/Misc';
import { withLabel } from '../withLabel';
import DateInput from './dateInput';

const LabeledDateInput = withLabel(DateInput);
const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin-bottom: 10px;
  
  @media (max-width: 600px) {
  grid-template-rows: auto auto;
  grid-template-columns: none;
  }
`;
const StyledContainer = styled.div`
  position: relative;
`;
const ModalContainer = styled.div`
  width: 300px;
  position: absolute;
  background: white;
  margin-top: 10px;
  top: 100%;
  right: 0;
  z-index: 2;
  overflow: visible;
  border: 1px solid #DDE2E9;
  border-radius: 4px;
  padding: 10px;
  background: linear-gradient(0deg,rgba(255,255,255,0.8),rgba(255,255,255,0.8)),#DDE2E9;
  box-shadow: 2px 3px 22px -1px rgba(191,206,219,1);
`;
const CloseBut = styled(CloseButton)`
  width: 30%;
  justify-content: center;
  margin-right: 0;
`;
const OKBut = styled(OKButton)`
  width: 30%;
  justify-content: center;
  margin-right: 0;
`;
const CommandPanelCont = styled(CommandPanel)`
  display: flex;
  justify-content: space-between;
`;

class ModalPeriodButton extends PureComponent {
  static propTypes = {
    period: PropTypes.shape({
      bDate: PropTypes.string,
      eDate: PropTypes.string,
    }),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    period: {
      bDate: null,
      eDate: null,
    },
    onChange: () => null,
  };

  static getDerivedStateFromProps(props, state) {
    const { period } = props;
    if (state.mPeriod !== period) {
      return {
        period,
        mPeriod: period,
      };
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      period: null,
      mPeriod: null,
    };
  }

  getPeriodHeader = () => {
    const { period } = this.props;
    const BDate = new Date(period.bDate);
    const EDate = new Date(period.eDate);
    const opt = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    if (!period.bDate && !period.eDate) return 'За все життя';
    if (period.bDate && !period.eDate) return `З ${BDate.toLocaleString('uk', opt)} до кінця часів`;
    if (!period.bDate && period.eDate) return `Від Різдва Христового до ${EDate.toLocaleString('uk', opt)}`;
    return `${BDate.toLocaleString('uk', opt)} - ${EDate.toLocaleString('uk', opt)}`;
  };

  onOk = (e) => {
    const { period } = this.state;
    const { onChange } = this.props;
    this.setState({ modalOpened: false });
    onChange(e, period);
  };

  render() {
    const { modalOpened, period, mPeriod } = this.state;
    return (
      <StyledContainer>
        <PeriodButton
          content={this.getPeriodHeader()}
          onClick={() => this.setState({ modalOpened: !modalOpened })}
        />
        {modalOpened && (
          <>
            <ModalContainer>
              <DataContainer>
                <LabeledDateInput
                  label="Дата початку"
                  value={period.bDate}
                  onChange={(e, v) => this.setState({ period: { ...period, bDate: v } })}
                />
                <LabeledDateInput
                  label="Дата закінчення"
                  value={period.eDate}
                  onChange={(e, v) => this.setState({ period: { ...period, eDate: v } })}
                />
              </DataContainer>
              <CommandPanelCont>
                <OKBut onClick={this.onOk} />
                <CloseBut
                  onClick={() => this.setState({ modalOpened: false, period: mPeriod })}
                />
              </CommandPanelCont>
            </ModalContainer>
          </>

        )}
      </StyledContainer>
    );
  }
}

export default ModalPeriodButton;
