import styled, { keyframes } from 'styled-components';
import { DefaultButton } from '../../components/Styled/Buttons/defaultButton';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  ${({ active }) => active
    ? 'display: flex' : 'display: nonne'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: #02020287;
`;

export const ContainerText = styled.div`
  // z-index: 6;
  padding: 20px;
  width: 40em;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 30px -4px rgb(55,55,55);
  border: 1px solid #DDE2E9;
  border-radius: 4px;
  margin: 10px 0;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-bottom: 25px;
`;
export const Button = styled.button`
  border-radius: 4px;
  border:none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  padding: 8.5px 10px;
  font-size: 1em;
  color: white;
  margin-left: 5px;
  background: linear-gradient(180deg,rgba(255,255,255,0.1) 0%,#179b17 100%),#15b615;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  filter: ${({ disabled }) => (disabled ? 'grayscale(1) opacity(.6)' : 'unset')};
  &:hover{
    background: #0e8d0e;
  };
`;
export const ContainerVarButton = styled.div`
  text-align: right;
  margin-top: 30px;
`;
export const Header = styled.h2`
  margin-bottom: 15px;
  margin-left: 15px;
  color: #2085e6;
  text-align: center;
`;
export const Text = styled.p`
  margin-left: 15px;
  font-size: 17px;
  line-height: 1.3;
  color: #636363;
  text-indent: 1.5em;
`;
export const ButtonRepeat = styled(Button)`
  background: linear-gradient(180deg,rgba(255,255,255,0.1) 0%,#3567A1 100%),#4281C9;
    :hover{
      background: linear-gradient(180deg,rgba(255,255,255,0.3) 0%,rgb(58,113,177) 100%),rgb(61,124,196);
    };
`;
const rotate = keyframes`
  0% {
    transform: rotate3d(0, 1, 0, 30deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, -30deg);
  }
`;
export const Img = styled.img`
  transition: all 4s ease;
  animation: ${rotate} 1s linear infinite;
`;
export const StyledMessageButton = styled(DefaultButton)`
  // position: relative;
  margin-right: 7px;
  padding: 0;
  border: none;
  background: none
    // &:hover{
    //   filter: grayscale(0.4);
    // };
`;
export const NumMes = styled.div`
  color: white;
  // background: red;
  font-weight: 700;
  padding: 1px 4px;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  margin-left: 12px;
   &:hover{
      filter: grayscale(0.4);
    };
`;
