import React, {
  useState, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import {
  faPrint, faSave, faPlus,
  faFileExcel, faFilePdf, faFileCode,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CloseButton, GenerateButton, SettingsButton, Button,
} from '../../components/Styled/Buttons';
import useReportEditor from './hooks';
import { HeaderText, DescriptionText } from '../../components/Styled/Forms';
import ReportSettingsEditor from './ReportSettingsEditor';
import Results from './results';
import {
  ErrorMessage, CommandPanel, DimmableLoader, Modal,
} from '../../components/Styled/Misc';
import Tabs from '../../components/Tab';
import ParamsField from './reportParams';
import SaveReport from './saveReport';
import ContextMenu from './results/contextMenu';
import DetailsShower from './detailsShower';

const StyledParamContainer = styled.div`
  display: flex;
  &>*+*{
    padding-left: 1em;
  }
  &>*{
    flex: 1 1 auto;
  }
`;

const StyledButton = styled(Button)`
z-index: 9;
right: calc(50% - 140px);
top: 36%;
position: absolute
`;

const SFontAwesomeIcon = styled(FontAwesomeIcon)`
width: 20px !important;
height: 20px`;

const SHOW_MODES = {
  results: 'RESULTS',
  settings: 'SETTINGS',
  saveNew: 'SAVENEW',
  save: 'SAVE',
};

const StdReportEditor = ({ reportId }) => {
  const {
    reportData, schema,
    availableGroups, groups, groupsHandlers,
    availableColumns, columns, columnsHandlers,
    availableSelections, selections, selectionsHandlers,
    availableOrders, orders, ordersHandlers,
    availableFilters, filters, filtersHandlers,
    params, availableParams, paramsHandlers,
    onGenerateReport, results, loading,
    onGenerateReportXLSX, onGenerateReportHTML, onGenerateReportPDF,
    onSaveReport, onAbortReport,
    error, onGetSettings,
  } = useReportEditor(reportId);
  const [showMode, setShowMode] = useState(SHOW_MODES.results);
  const [updated, setUpdated] = useState(false);
  // const [isLandScape, setLandScape] = useState(false);
  const [typeOfReport, setTypeOfReport] = useState('report');

  const resultRef = useRef(null);

  const containerHeaderRef = useRef(null);
  const height = containerHeaderRef.current
    ? containerHeaderRef.current.getBoundingClientRect().height : 0;

  const print = () => {
    if (results && resultRef.current) {
      const el = resultRef.current.cloneNode(true);
      const printDiv = document.getElementById('print-area');
      printDiv.appendChild(el);
      window.print();
      printDiv.removeChild(el);
    }
    return true;
  };

  const [contextMenu, setContextMenu] = useState({
    opened: false,
    x: 0,
    y: 0,
    detail: null,
    allowedDetails: [],
  });
  const onContextMenu = useCallback(
    (e, contextGroups, contextData) => {
      const knowFields = new Set([
        ...Object.keys(contextGroups.row),
        ...Object.keys(contextGroups.column),
      ]);
      const currentField = [...knowFields]
        .filter((kf) => kf in schema.src.meta_fields
          && schema.src.meta_fields[kf].key in contextData)
        .reduce((R, r) => ({
          ...schema.src.meta_fields[r],
          name: r,
        }), null);
      if (!currentField) return false;
      const usedGroups = new Set([
        ...groups.reduce((R, sg) => [...R, ...(sg.items.map((g) => g.name))], []),
        ...columns.reduce((R, sc) => [...R, ...(sc.items.map((c) => c.name))], []),
      ]);
      const allowedGroups = availableGroups.filter((a) => !usedGroups.has(a.name));

      setContextMenu({
        opened: true,
        x: e.clientX,
        y: e.clientY,
        detail: {
          currentItem: {
            backend: currentField.resource,
            key: currentField.key,
            name: currentField.name,
            id: contextData[currentField.key],
            repr: contextData.repr,
          },
        },
        allowedDetails: allowedGroups,
      });
      e.preventDefault();
      return false;
    },
    [schema, groups, columns, availableGroups],
  );

  const [detail, setDetail] = useState({
    settings: {},
    opened: false,
  });

  const onDetail = useCallback(
    (e, group, currentItem) => {
      const oldSettings = onGetSettings();
      const newFIlters = [
        ...oldSettings.filters,
        [currentItem.name, '=', {
          id: currentItem.id,
          repr: currentItem.repr,
        }, true],
      ];
      const newGroups = [...oldSettings.groups, { [group.name]: { hierarchy: false } }];
      setDetail({
        settings: {
          ...oldSettings,
          groups: newGroups,
          filters: newFIlters,
        },
        opened: true,
      });
      setContextMenu(({ ...r }) => ({
        ...r,
        opened: false,
      }));
    },
    [onGetSettings],
  );

  const handleGenerateReport = useCallback(() => {
    if (typeOfReport === 'Excel') {
      return onGenerateReportXLSX.onAbortReport();
    }
    if (typeOfReport === 'HTML') {
      return onGenerateReportHTML.onAbortReport();
    }
    if (typeOfReport === 'PDF') {
      return onGenerateReportPDF.onAbortReport();
    }
    return onAbortReport();
  }, [
    onAbortReport,
    onGenerateReportHTML,
    onGenerateReportPDF,
    onGenerateReportXLSX,
    typeOfReport,
  ]);

  return (
    <>
      {error && (
        <ErrorMessage text={error} />
      )}
      <DndProvider backend={Backend}>
        <div ref={containerHeaderRef}>
          <HeaderText>{reportData.name}</HeaderText>
          <DescriptionText>
            {reportData.description}
          </DescriptionText>
          <StyledParamContainer>
            {availableParams && availableParams.map((param) => (
              <div key={param.name} id={param.name}>
                <ParamsField
                  param={param}
                  currentParam={params[param.name]}
                  changeParam={paramsHandlers.changeParams}
                />
              </div>
            ))}
          </StyledParamContainer>
          <CommandPanel>
            <GenerateButton
              onClick={() => {
                setTypeOfReport('report');
                onGenerateReport();
                setShowMode(SHOW_MODES.results);
                setUpdated(true);
              }}
              content="Сформувати звіт"
            />
            <SettingsButton
              onClick={() => setShowMode(
                (sm) => sm === SHOW_MODES.settings ? SHOW_MODES.results : SHOW_MODES.settings,
              )}
            />
            <Button
              onClick={() => print()}
              title="Друк"
              // disabled={!results || showMode !== SHOW_MODES.results}
            >
              <SFontAwesomeIcon icon={faPrint} />
            </Button>
            <Button
              onClick={() => {
                setTypeOfReport('Excel');
                onGenerateReportXLSX.onGenerateReport();
              }}
              // disabled={!results || showMode !== SHOW_MODES.results}
              title="Зберегти в Excel"
            >
              <SFontAwesomeIcon icon={faFileExcel} color="#5e735e" />
            </Button>
            <Button
              onClick={() => {
                setTypeOfReport('HTML');
                onGenerateReportHTML.onGenerateReport();
              }}
              // disabled={!results || showMode !== SHOW_MODES.results}
              title="Зберегти в HTML"
            >
              <SFontAwesomeIcon icon={faFileCode} color="#bf814b" />
            </Button>
            <Button
              onClick={() => {
                setTypeOfReport('PDF');
                onGenerateReportPDF.onGenerateReport();
              }}
              // disabled={!results || showMode !== SHOW_MODES.results}
              title="Зберегти в PDF"
            >
              <SFontAwesomeIcon icon={faFilePdf} color="red" />
            </Button>

            {/* <Button */}
            {/*  onClick={() => { */}
            {/*    setLandScape(!isLandScape); */}
            {/*  }} */}
            {/*  disabled={!results || showMode !== SHOW_MODES.results} */}
            {/*  title={isLandScape ? 'Альбомна' : 'Портретна'} */}
            {/* > */}
            {/*  <SFontAwesomeIcon icon={isLandScape ? faImage : faFileImage} /> */}
            {/* </Button> */}
            <Button
              onClick={() => {
                setShowMode(SHOW_MODES.save);
              }}
              title="Зберегти звіт"
            >
              <SFontAwesomeIcon icon={faSave} />
            </Button>
            <Button
              onClick={() => {
                setShowMode(SHOW_MODES.saveNew);
              }}
              title="Створити новий звіт"
            >
              <SFontAwesomeIcon icon={faPlus} />
            </Button>

          </CommandPanel>
        </div>
        {showMode === SHOW_MODES.save && (
          <div>
            <HeaderText>Зберегти звіт</HeaderText>
            <SaveReport
              onHide={() => setShowMode(SHOW_MODES.results)}
              reportData={reportData}
              onSave={onSaveReport}
            />
          </div>
        )}
        {showMode === SHOW_MODES.saveNew && (
          <div>
            <HeaderText>Створити новий звіт</HeaderText>
            <SaveReport
              onHide={() => setShowMode(SHOW_MODES.results)}
              isNew
              reportData={reportData}
              onSave={onSaveReport}
            />
          </div>
        )}
        <div style={{ border: 'none' }}>
          {showMode === SHOW_MODES.settings && (
            <Tabs panes={[
              {
                menuItem: 'Групування',
                render: () => (
                  <ReportSettingsEditor.GroupEditor
                    availableGroups={availableGroups}
                    groups={groups}
                    groupsHandlers={groupsHandlers}
                  />
                ),
              },
              {
                menuItem: 'Групування колонок',
                render: () => (
                  <ReportSettingsEditor.ColumnEditor
                    availableColumns={availableColumns}
                    columns={columns}
                    columnsHandlers={columnsHandlers}
                  />
                ),
              },
              {
                menuItem: 'Обрані поля',
                render: () => (
                  <ReportSettingsEditor.SelectionEditor
                    availableSelections={availableSelections}
                    selections={selections}
                    selectionHandlers={selectionsHandlers}
                  />

                ),
              },
              {
                menuItem: 'Сортування',
                render: () => (
                  <ReportSettingsEditor.OrdersEditor
                    availableOrders={availableOrders}
                    orders={orders}
                    ordersHandlers={ordersHandlers}
                  />
                ),
              },
              {
                menuItem: 'Фільтрування',
                render: () => (
                  <ReportSettingsEditor.FiltersEditor
                    availableFilters={availableFilters}
                    filters={filters}
                    filtersHandlers={filtersHandlers}
                  />
                ),
              },
            ]}
            />
          )}
        </div>
      </DndProvider>
      <div style={{
        border: '1px solid var(--borderTab)',
        height: `calc(100vh - ${height}px)`,
        overflow: 'auto',
      }}
      >
        {loading && updated && (
          <StyledButton
            variant="outline-danger"
            onClick={handleGenerateReport}
          >
            Скасувати
          </StyledButton>
        )}
        <DimmableLoader loading={loading} fullscreen={false}>
          {results && showMode === SHOW_MODES.results ? (
            <>
              <Results
                result={results}
                ref={resultRef}
                caption={results.header || reportData.name}
                onContextMenu={onContextMenu}
              />
              {contextMenu.opened && (
                <ContextMenu
                  top={contextMenu.y}
                  left={contextMenu.x}
                  allowedColumns={[]}
                  onCloseMenu={() => setContextMenu(({ ...r }) => ({
                    ...r,
                    opened: false,
                  }))}
                  detail={contextMenu.detail}
                  allowedDetails={contextMenu.allowedDetails}
                  onDetailProcessing={onDetail}
                />

              )}
            </>

          ) : <div style={{ height: '100px' }} />}
        </DimmableLoader>
      </div>
      <Modal open={detail.opened}>
        <>
          <DetailsShower reportId={reportId} schema={schema} settings={detail.settings} />
          <CloseButton
            onClick={() => setDetail(
              ({ ...r }) => ({
                ...r,
                opened: false,
              }),
            )}
          />
        </>
      </Modal>
    </>
  );
};


StdReportEditor.propTypes = {
  reportId: PropTypes.string,
};

StdReportEditor.defaultProps = {
  // results: null,
  reportId: null,
};

export default StdReportEditor;
