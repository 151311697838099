import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { dateToString } from '../../../api/utils';
import { DivStyled, InputStyled } from '../../../components/Styled/Input';
import { ClickerWrapper } from '../../../components/Styled/Misc';
import { SelectButton, PortalDiv } from './styles';
import DatePicker from './datePicker';

const StyledSelectButoon = Styled(SelectButton).attrs({
  tabIndex: -1,
})``;

class DateInput extends Component {
  static propTypes = {
    // eslint-disable-next-line
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    noBorder: PropTypes.bool,
    noBackground: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    onChange: () => console.log('This control id ReadOnly because prop "onChange" is not defined'),
    disabled: false,
    readOnly: false,
    noBorder: false,
    noBackground: false,
  };

  // eslint-disable-next-line
  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (state.pvalue !== props.value) {
      return ({
        value: props.value && !Number.isNaN(Date.parse(value)) ? new Date(value) : new Date(),
        pvalue: props.value,
      });
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      // eslint-disable-next-line
      pvalue: null,
      portalOpened: false,
      cursorPosition: 0,
    };
    this.divRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.inputRef.current) {
      const { cursorPosition } = this.state;
      this.inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }

  changeHandler = (e, value) => {
    const { onChange } = this.props;
    onChange(e, dateToString(value));
    this.setState({ portalOpened: false });
  };

  getValue = () => {
    const { value } = this.state;
    if (!value) return '';
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return value.toLocaleString('uk', options);
  };

  keyPressHandler = (e) => {
    const { value } = this.state;
    const cursorPosition = this.inputRef.current.selectionStart;
    const valid = (e.charCode >= 48 && e.charCode <= 59)
      || (e.charCode === 46);
    if (valid) {
      const cValue = this.getValue();
      if (e.charCode === 46) {
        const newPosition = cValue.indexOf('.', cursorPosition);
        if (newPosition > -1) {
          this.setState({ cursorPosition: newPosition + 1 });
        }
      } else {
        const InsertPosition = cValue.substr(cursorPosition, 1) === '.' ? cursorPosition + 1 : cursorPosition;
        const newValue = cValue.substring(0, InsertPosition)
          + String.fromCharCode(e.charCode)
          + cValue.substring(InsertPosition + 1, cValue.length);
        const newDate = value ? new Date(value.valueOf()) : new Date();
        if (InsertPosition < 2) {
          newDate.setDate(newValue.substr(0, 2));
          if (value && newDate.getMonth() !== value.getMonth()) {
            return false;
          }
        } else if (InsertPosition < 6) {
          newDate.setMonth(newValue.substr(3, 2) - 1);
          if (value && newDate.getFullYear() !== value.getFullYear()) {
            if (String.fromCharCode(e.charCode) === '0') {
              newDate.setMonth(0);
              newDate.setFullYear(value.getFullYear());
            } else if (String.fromCharCode(e.charCode) === '1') {
              newDate.setMonth(9);
              newDate.setFullYear(value.getFullYear());
            } else {
              return false;
            }
          }
        } else {
          newDate.setFullYear(newValue.substr(6, 4));
        }
        this.setState({ cursorPosition: InsertPosition + 1 });
        this.changeHandler(e, newDate);
      }
    }
    e.preventDefault();
    return false;
  };

  render() {
    const {
      disabled, noBackground, noBorder, readOnly,
    } = this.props;
    const { portalOpened, value } = this.state;
    return (
      <DivStyled ref={this.divRef} noBackground={noBackground} noBorder={noBorder} style={{ position: 'relative' }}>
        <InputStyled
          value={this.getValue()}
          onChange={() => null}
          disabled={disabled}
          onKeyPress={this.keyPressHandler}
          ref={this.inputRef}
          readOnly={readOnly}
          onKeyDown={(e) => e.keyCode === 27 && portalOpened
            && this.setState({ portalOpened: false })}
        />
        {!readOnly && (
          <StyledSelectButoon
            onClick={() => this.setState({ portalOpened: true })}
            disabled={disabled}
            onKeyDown={(e) => e.keyCode === 27 && portalOpened
              && this.setState({ portalOpened: false })}
          />
        )}
        {portalOpened && (
          <>
            {createPortal((
              <ClickerWrapper onClick={() => this.setState({ portalOpened: false })} />
            ), document.body)}
            <PortalDiv top={this.divRef.current.getBoundingClientRect().height}>
              <DatePicker
                value={value}
                onChange={this.changeHandler}
              />
            </PortalDiv>
          </>
        )}
      </DivStyled>
    );
  }
}

export default DateInput;
