import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import { SetPasswordButton } from '../../../components/Styled/Buttons';
import Group from './group';

const ThisDocGrid = styled(DocGrid)`
    grid-template-areas:  
    "username first_name last_name"
    "email org group"
    "checkboxes checkboxes checkboxes"
    "button button button"
    "password password2 password2";
    
    @media (max-width: 940px) {
        display: block;
    }      
`;

const editorRenderer = (getControl, { data, reload, dataSetter }) => (
  <ThisDocGrid>
    <GridItem area="username">
      {getControl('username') }
    </GridItem>
    <GridItem area="first_name">
      {getControl('first_name')}
    </GridItem>
    <GridItem area="last_name">
      {getControl('last_name')}
    </GridItem>
    <GridItem area="email">
      {getControl('email')}
    </GridItem>
    <GridItem area="checkboxes">
      {/* {getControl('is_staff')} */}
      {getControl('is_superuser')}
    </GridItem>
    <GridItem area="org">
      {getControl('org', { backend: '/api/catalog/orgs/' })}
    </GridItem>
    {data
    && (
      <GridItem area="group">
        <Group onChange={dataSetter} data={data} />
      </GridItem>
    )}
    <GridItem area="button">
      <SetPasswordButton
        disabled={!data.id}
        userid={data.id}
        active={data.is_active}
        content="Встановити пароль"
        onSuccess={() => reload()}
      />
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/core/user/', CommandPanels.catCommandPanel);
