import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HeaderText, StyledForm, EditorContainer } from '../../components/Styled/Forms';
import {
  Item, ErrorMessage, DimmableLoader, Question, QuestionActions, SearchInput,
} from '../../components/Styled/Misc';
import { ReportsIcon, DeleteIcon } from '../../components/Styled/Buttons/icons';
import { Button } from '../../components/Styled/Buttons';
import api from '../../api/req';
import { mapStateAuth, withAuthConsumer } from '../../providers/authProvider';

const RContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 1em;
  
  @media (max-width: 1200px) {
  display: block;
  }
`;

const DescContainer = styled.div`
  display: flex;
  flex-order: colummn;
  align-items: end;
  & > *{
    flex: 1 1 auto;
  }
  & > :last-child{
    flex: 0 0 auto;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-order: colummn;
  & > *{
    flex: 1 1 auto;
  }
  & > :last-child{
    flex: 0 0 auto;
  }
  
  @media (max-width: 720px) {
  display: block
  }
`;

const ListContainer = styled.div`
  overflow: auto;
  flex: auto;
`;
const ButtonDel = styled(Button)`
  margin: 0 0 0 0.7em;
`;

class ReportLister extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isErrored: false,
      errMessage: '',
      errHeader: '',
      reportList: [],
      searchText: '',
      searchedReportList: [],
    };
  }

  componentDidMount() {
    this.reload();
  }

  // eslint-disable-next-line
  setSearchedReportList = (reportList, searchText) => searchText
    ? reportList.filter((r) => `${r.title}${r.description}`.toUpperCase().indexOf(searchText.toUpperCase()) !== -1)
    : reportList;

  reload = () => {
    this.setState({ isLoading: true });
    const { authF } = this.props;
    const { searchText } = this.state;
    api.get$('/api/reports/report/', authF).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          this.setState({
            isLoading: false,
            isErrored: false,
            errMessage: '',
            errHeader: '',
            reportList: d,
            searchedReportList: this.setSearchedReportList(d, searchText),
          });
        });
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errMessage: r.status === 403 ? 'У Вас немає прав доступу до цього розділу.' : `${r.status} ${r.statusText}`,
          errHeader: r.status === 403 ? 'Помилка прав доступу' : 'Вам все вдалося, але виник несподіваний результат',
          reportList: [],
          searchedReportList: [],
        });
      }
    });
  };

  deleteReport = (reportId) => {
    this.setState({ isLoading: true });
    const { authF } = this.props;
    api.delete$(`/api/reports/report/${reportId}/`, authF).then((r) => {
      if (r.ok) {
        this.reload();
      } else {
        this.setState({
          isLoading: false,
          isErrored: true,
          errMessage: `${r.status} ${r.statusText}`,
          reportList: [],
          searchedReportList: [],
        });
      }
    });
  };

  onSearchHandler = (e, searchText) => {
    const { reportList } = this.state;
    this.setState({
      searchText,
      searchedReportList: this.setSearchedReportList(reportList, searchText),
    });
  };

  render() {
    const {
      errMessage, isErrored, isLoading, searchedReportList, errHeader,
    } = this.state;
    return (
      <ListContainer className="fullscreenContainer">
        <DimmableLoader loading={isLoading}>
          <HeaderContainer>
            <HeaderText>Звітність</HeaderText>
            <SearchInput onSearch={this.onSearchHandler} />
          </HeaderContainer>

          <StyledForm>
            {isErrored && (
              <ErrorMessage text={errMessage} header={errHeader} />
            )}
            <EditorContainer>
              <RContainer>
                {searchedReportList.length ? searchedReportList.map((r) => (
                  <Item
                    key={r.id}
                    header={(
                      <Link to={`/grep/${r.id}`}>
                        {r.name}
                      </Link>
                    )}
                    content={(
                      <DescContainer>
                        <div>
                          {r.description}
                        </div>
                        {(!('editable' in r) || r.editable) && (
                          <Question
                            actions={Object.values(QuestionActions.YesNoAction)}
                            actionHandler={(e, v) => {
                              if (v === QuestionActions.YesNoAction.yes.value) {
                                this.deleteReport(r.id);
                              }
                            }}
                            questionText={`Ви дійсно бажаєте видалити звіт ${r.title}?`}
                          >
                            <ButtonDel onClick={() => this.deleteReport(r.id)}>
                              <img src={DeleteIcon} alt="Видалити" />
                            </ButtonDel>
                          </Question>
                        )}
                      </DescContainer>
                    )}
                    icon={(
                      <img src={ReportsIcon} alt="Звіт" />
                    )}
                  />
                )) : (
                  <ErrorMessage
                    header="Немає звітів"
                    text="За заданими умовами пошуку немає жодного звіту"
                  />
                )}
              </RContainer>
            </EditorContainer>
          </StyledForm>
        </DimmableLoader>
      </ListContainer>
    );
  }
}


export default withAuthConsumer(mapStateAuth)(ReportLister);
