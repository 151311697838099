import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import SearchInput from '../../../components/Styled/Misc/Search';
import Kdbs from './kdbs';
import api from '../../../api/req';
import { AppContext, withAuthConsumer } from '../../../providers/authProvider';
import { DimmableLoader } from '../../../components/Styled/Misc';
import { SaveButton } from '../../../components/Styled/Buttons';

const Editor = ({ currentUser }) => {
  const [kdbs, setKdbs] = useState([]);
  const [searchedKdbsList, setSearchedKdbsList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [errorText, setErrorText] = useState(null);
  const [loading, setLoading] = useState(false);

  const { auth } = useContext(AppContext);

  const handleSetSearchedKdbsList = useCallback((kdbsList, text) => text
    ? kdbsList.filter((r) => `${r.repr}`.toUpperCase().indexOf(text.toUpperCase()) !== -1)
    : kdbsList, []);

  const queryParser = (response, f) => {
    if (response.ok) {
      response.json().then((data) => f(data));
      setErrorText(null);
      setLoading(false);
    } else {
      setErrorText(`${response.status} ${response.statusText}`);
      setLoading(false);
    }
  };

  const reloadKDBs = useCallback((disKdbs) => {
    api.get$('/api/catalog/kdbs/', auth)
      .then((r) => queryParser(r, (d) => {
        if (disKdbs.length === 0) {
          setKdbs(d);
          setSearchedKdbsList(handleSetSearchedKdbsList(d, searchText));
          return;
        }
        const res = d.map((kdb) => disKdbs
          .filter((i) => i.org.id === currentUser.org.id)
          .map((el) => el.kdb_id)
          .includes(kdb.id) ? { ...kdb, checked: true } : kdb);
        setKdbs(res);
        setSearchedKdbsList(handleSetSearchedKdbsList(res, searchText));
      }));
  }, [auth, currentUser.org.id, handleSetSearchedKdbsList, searchText]);

  const reloadDisabledKDBs = useCallback(() => {
    setLoading(true);
    api.get$('/api/catalog/disabledkdbbyorg/', auth)
      .then((r) => queryParser(r, (d) => reloadKDBs(d)));
  }, [auth, reloadKDBs]);

  useEffect(() => { reloadDisabledKDBs(); }, [reloadDisabledKDBs]);

  const handleCheckAllKDBs = useCallback((type) => {
    if (type === 'all') {
      setSearchedKdbsList(searchedKdbsList.map((kdb) => ({ ...kdb, checked: true })));
      setKdbs(kdbs.map((kdb) => ({ ...kdb, checked: true })));
    } else {
      setSearchedKdbsList(searchedKdbsList.map((kdb) => ({ ...kdb, checked: false })));
      setKdbs(kdbs.map((kdb) => ({ ...kdb, checked: false })));
    }
  }, [kdbs, searchedKdbsList]);

  const onSearchHandler = useCallback((e, text) => {
    setSearchText(text);
    setSearchedKdbsList(handleSetSearchedKdbsList(kdbs, text));
  }, [handleSetSearchedKdbsList, kdbs]);

  const updateDisabledKdb = useCallback(() => {
    const options = {
      kdb_list: kdbs.filter((i) => i.checked).map((kdb) => kdb.id),
    };
    if (currentUser.is_superuser) {
      options.org_id = currentUser.org.id;
    }
    setLoading(true);
    api.post('/api/catalog/disabledkdbbyorg/', auth, options)
      .then((r) => queryParser(r, (d) => d));
  }, [auth, currentUser, kdbs]);

  return (
    <DimmableLoader loading={loading}>
      <div style={{ overflow: 'auto', marginBottom: '10px' }}>
        <div style={{
          display: 'flex', height: '40px', alignItems: 'center', position: 'sticky', top: 0, zIndex: '1', backgroundColor: 'white',
        }}
        >
          <FontAwesomeIcon
            icon={faCheckSquare}
            title="Обрати всі"
            size="lg"
            color="white"
            style={{ backgroundColor: '#4281c9', margin: '0 5px', cursor: 'pointer' }}
            onClick={() => handleCheckAllKDBs('all')}
          />
          <FontAwesomeIcon
            icon={faSquare}
            title="Скинути всі відмітки"
            color="white"
            style={{ backgroundColor: '#4281c9', marginRight: '15px', cursor: 'pointer' }}
            size="lg"
            onClick={() => handleCheckAllKDBs('nothing')}
          />
          <SearchInput onSearch={onSearchHandler} />
          <div style={{ marginLeft: '10px' }}>
            <SaveButton onClick={updateDisabledKdb} />
          </div>
        </div>

        {errorText && (
          <h2 style={{ color: 'red', backgroundColor: 'white', margin: '0' }}>{errorText}</h2>
        )}

        <Kdbs
          kdbs={kdbs}
          searchedKdbsList={searchedKdbsList}
          gridArea="kdbs"
          onChangeKdbs={(e, v) => setKdbs(v)}
          onChangeSearchedKdbsList={(e, v) => setSearchedKdbsList(v)}
        />
      </div>
    </DimmableLoader>
  );
};

Editor.propTypes = {
  currentUser: PropTypes.shape({
    is_superuser: PropTypes.bool,
    org: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired,
};
const mapState = (state) => ({
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  withRouter,
);

export default enhance(Editor);
