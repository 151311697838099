import React, { PureComponent, createRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import { getFrontEndByBackend } from '../../../const/meta';
import {
  ContextMenuContainer, ContextMenuItem, ContextMenuSubItem,
} from './styles';

const StyledLink = Styled(Link).attrs({
  target: '_blank',
})`
  color: #488de4;
  &:hover{
    color: #1a4aac;
  };
  &:visited{
    color: #3f51b4;
  };
`;


class ContextMenu extends PureComponent {
  static propTypes = {
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    onCloseMenu: PropTypes.func.isRequired,
    detail: PropTypes.shape({
      currentItem: PropTypes.shape({
        backend: PropTypes.string,
        id: PropTypes.number,
        repr: PropTypes.string,
      }).isRequired,
      expandColumns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.strung,
      })).isRequired,
      knowGroups: PropTypes.shape().isRequired,
    }).isRequired,
    onDetailProcessing: PropTypes.func,
    allowedColumns: PropTypes.arrayOf(PropTypes.shape({
      query_label: PropTypes.string,
    })).isRequired,
  };

  static defaultProps = {
    onDetailProcessing: null,
  };

  constructor(props) {
    super(props);
    this.menuRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.clicker);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.clicker);
  }

  clicker = (e) => {
    const { onCloseMenu } = this.props;
    if (!e.path.includes(this.menuRef.current)) {
      onCloseMenu(e);
    }
  };

  render() {
    const {
      left,
      top,
      onCloseMenu,
      detail,
      onDetailProcessing,
      allowedColumns,
    } = this.props;
    return createPortal(
      <ContextMenuContainer left={left} top={top} ref={this.menuRef}>
        <ContextMenuItem>
          <StyledLink
            to={`${getFrontEndByBackend(detail.currentItem.backend)}${detail.currentItem.id}`}
            onClick={onCloseMenu}
          >
            {`Відкрити ${detail.currentItem.repr}`}
          </StyledLink>
        </ContextMenuItem>
        <ContextMenuItem>
          Розшифрувати по:
        </ContextMenuItem>
        {onDetailProcessing && detail.expandColumns.map((c) => (
          <ContextMenuSubItem
            key={c.key}
            onClick={(e) => {
              onCloseMenu(e);
              onDetailProcessing(e, {
                groups: [
                  ...allowedColumns.filter((a) => a.query_label === c.query_label),
                ],
                filters: [
                  ...allowedColumns
                    .filter((a) => a.query_label in detail.knowGroups)
                    .map((a) => ({
                      ...a,
                      ct: '__exact',
                      not: false,
                      value: a.type.indexOf('api/') > -1 ? { id: detail.knowGroups[a.query_label] } : detail.knowGroups[a.query_label],
                    })),
                ],
              });
            }}
          >
            {c.label}
          </ContextMenuSubItem>

        ))}
      </ContextMenuContainer>,
      document.body,
    );
  }
}

export default ContextMenu;
