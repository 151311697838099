import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from './icons';
import { StyledButton, StyledImg } from './styledCommandPanelButton';

export const CloseButton = ({
  content, onClick, disabled, className,
}) => (
  <StyledButton onClick={onClick} disabled={disabled} title={content} className={className}>
    <StyledImg src={CloseIcon} alt={content} />
    <span>Закрити</span>
  </StyledButton>
);

CloseButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

CloseButton.defaultProps = {
  onClick: () => null,
  content: 'Закрити',
  disabled: false,
  className: null,
};

export default CloseButton;
