import React from 'react';
import PropTypes from 'prop-types';
import TableControls from '../../../BaseEditor/tableControls';
import { fieldPropType } from '../hooks';
import { ListItem } from '../styles';

const FieldItem = ({
  field, active, onRenameField, onClick,
}) => (

  <ListItem
    active={active}
    style={{ marginTop: '4px' }}
    onClick={onClick}
  >
    <TableControls.StringInput
      controlId={`input-field-${field.itemKey}`}
      value={field.name}
      onChange={(e, v) => onRenameField(field.name, v)}
    />
  </ListItem>
);

FieldItem.propTypes = {
  field: fieldPropType.isRequired,
  active: PropTypes.bool,
  onRenameField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

FieldItem.defaultProps = {
  active: false,
};

export default FieldItem;
