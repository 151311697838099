import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "name";
  
  @media (max-width: 600px) {
  display: block;
  }
`;

const editorRenderer = (getControl) => (
  <ThisDocGrid>
    <GridItem area="name">
      {getControl('name')}
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/catalog/landcategories/', CommandPanels.catCommandPanel);
