import React, {
  useEffect, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import {
  ContextMenuContainer,
  ContextMenuItem,
  ContextMenuSubItem,
} from '../../BaseReport/resultShower/styles';
import { getFrontEndByBackend } from '../../../const/meta';

const StyledLink = Styled(Link)
  .attrs({
    target: '_blank',
  })`
  color: #488de4;
  &:hover{
    color: #1a4aac;
  };
  &:visited{
    color: #3f51b4;
  };
`;

const ContextMenu = ({
  left, top, onCloseMenu, detail, onDetailProcessing, allowedDetails,
}) => {
  const menuRef = useRef(null);
  useEffect(
    () => {
      const clicker = (e) => {
        if (!e.path.includes(menuRef.current)) {
          onCloseMenu(e);
        }
      };
      document.addEventListener('click', clicker);

      return function cleanup() {
        document.removeEventListener('click', clicker);
      };
    },
  );

  return createPortal(
    <ContextMenuContainer left={left} top={top} ref={menuRef}>
      <ContextMenuItem>
        <StyledLink
          to={`${getFrontEndByBackend(detail.currentItem.backend)}${detail.currentItem.id}`}
          onClick={onCloseMenu}
        >
          {`Відкрити ${detail.currentItem.repr}`}
        </StyledLink>
      </ContextMenuItem>
      <ContextMenuItem>
        Розшифрувати по:
      </ContextMenuItem>
      {onDetailProcessing && allowedDetails.map((c) => (
        <ContextMenuSubItem
          key={c.key}
          onClick={(e) => onDetailProcessing(e, c, detail.currentItem)}
        >
          {c.label}
        </ContextMenuSubItem>
      ))}
    </ContextMenuContainer>,
    document.body,
  );
};

ContextMenu.propTypes = {
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  detail: PropTypes.shape({
    currentItem: PropTypes.shape({
      backend: PropTypes.string,
      id: PropTypes.number,
      repr: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onDetailProcessing: PropTypes.func,
  allowedDetails: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default ContextMenu;
