import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { DivFooter } from './styles';

const StyledDivFooter = Styled(DivFooter)`
  justify-content: flex-end;
`;

const FooterComponent = ({ data, rowNumber }) => (
  <>
    <StyledDivFooter area={`${rowNumber + 2} / date`}>
      Разом
    </StyledDivFooter>
    <StyledDivFooter area={`${rowNumber + 2} / order_amount`}>
      {data.reduce((R, row) => R + Number(row.order_amount), 0).toFixed(2)}
    </StyledDivFooter>
    <DivFooter area={`${rowNumber + 2} / payment_notes`} />
  </>
);

FooterComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    order_amount: PropTypes.string,
  })),
  rowNumber: PropTypes.number,
};

FooterComponent.defaultProps = {
  data: [],
  rowNumber: 0,
};
export default FooterComponent;
