/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Div = styled.div`
  position: relative;
`;

const HintDiv = styled.div`
  position: absolute;
  left: 80px;
  bottom: 6px;
  border: 1px solid #d4d4d5;
  border-radius: .28571429rem;
  font-weight: 500;
  padding: 3px 15px;
  background: antiquewhite;
  opacity: 0.9;
  color: firebrick;
  font-size: small;
  ::after {
    left: calc(50% - 6px);
    bottom: -6px;
    background: antiquewhite;
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;  
    transform: rotate(45deg);
    z-index: 2;
    box-shadow: 1px 1px 0 0 #bababc;
  }
`;

export const withErrors = (WrappedComponent) => {
  const ComponentWithErrors = ({ errors, ...props }) => (
    <>
      {errors ? (
        <Div>
          <HintDiv>
            <b>Помилка:</b>
            <ul>{errors.map((er, k) => <li key={k}>{er}</li>)}</ul>
          </HintDiv>
        </Div>
      ) : null }
      <WrappedComponent errored={!!errors} {...props} />
    </>
  );

  ComponentWithErrors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  ComponentWithErrors.defaultProps = {
    errors: null,
  };

  return ComponentWithErrors;
};

export default withErrors;
