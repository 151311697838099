import React, { PureComponent } from 'react';
import ReportEditor from '../../BaseReport';
import { EditorControls } from '../../BaseEditor';
import { dataTypes, reports } from '../../../const/meta';
import { ControlsConrainer, StyledControl, ReportContainer } from '../styles';

const md = reports.comparePeriods;

const redefineColumns = {
  account: { type: '/api/catalog/accounts/', columns: ['code', 'name'] },
  budget: { type: '/api/catalog/budgets/', columns: ['name'] },
  date: { type: dataTypes.date },
  kdb: { type: '/api/catalog/kdbs/', columns: ['code', 'name'] },
  org: { type: '/api/catalog/orgs/', columns: ['name'] },
  payer: { type: '/api/catalog/payers/', columns: ['code', 'name'] },
  payer__industry: {
    type: '/api/catalog/industries/', columns: ['name'], query_label: 'payer__industry', label: 'Галузь', key: 'payer__industry',
  },
  payer__region: {
    type: '/api/catalog/regions/',
    columns: ['name'],
    query_label: 'payer__region',
    label: 'Регіональна ознака (старостинство)',
    key: 'payer__region',
  },
  _subscription_document: { type: '/api/document/subscriptions/', columns: ['date'] },
  order_amount: { type: 'DECIMAL' },
  revenue_amount: { type: 'DECIMAL' },
  prev_order_amount: { type: 'DECIMAL' },
  curr_order_amount: { type: 'DECIMAL' },
  abs_diff_order_amount: { type: 'DECIMAL' },
  rel_diff_order_amount: { type: 'DECIMAL' },
  prev_revenue_amount: { type: 'DECIMAL' },
  curr_revenue_amount: { type: 'DECIMAL' },
  abs_diff_revenue_amount: { type: 'DECIMAL' },
  rel_diff_revenue_amount: { type: 'DECIMAL' },
};

const addColummns = ['payer__industry', 'payer__region'];

const resColumns = [
  { label: 'Сума попереднього періоду, грн', key: 'prev_revenue_amount' },
  { label: 'Сума поточного періоду, грн', key: 'curr_revenue_amount' },
  { label: 'Відхилення, грн', key: 'abs_diff_revenue_amount' },
  { label: 'Відхилення, %', key: 'rel_diff_revenue_amount' },
// abs_diff_revenue_amount: -704.8
// curr_revenue_amount: 704.8
// prev_revenue_amount: 0
// rel_diff_revenue_amount: -100
];

const resColumnsItems = resColumns.map((r) => r.key);

const valuesRepresentator = (field, value) => {
  if (value && resColumnsItems.includes(field)) {
    return value.toLocaleString('uk', { minimumFractionDigits: 2 });
  } if (field === '_subscription_document') {
    return `Казначейська виписка #${value.id__id} від ${value.date}`;
  } if (field === 'payer') {
    return value ? `${value.code} ${value.name}` : '';
  } if (field === 'date') {
    const v = new Date(value.repr);
    return v.toLocaleDateString();
  }
  return value && value.repr;
};

class ComparePeriodsReport extends PureComponent {
  constructor(props) {
    super(props);
    const cDate = new Date();
    this.state = {
      bdate: `${cDate.getFullYear()}-01-01`,
      edate: `${cDate.getFullYear()}-12-31`,
      bdate2: `${cDate.getFullYear() - 1}-01-01`,
      edate2: `${cDate.getFullYear() - 1}-12-31`,
    };
  }

  bdateChangeHandler = (e, v) => {
    const cDate = new Date(v);
    this.setState({
      bdate: v,
      bdate2: `${cDate.getFullYear() - 1}-${cDate.getMonth() + 1}-${cDate.getDate()}`,
    });
  };

  edateChangeHandler = (e, v) => {
    const cDate = new Date(v);
    this.setState({
      edate: v,
      edate2: `${cDate.getFullYear() - 1}-${cDate.getMonth() + 1}-${cDate.getDate()}`,
    });
  };

  render() {
    const {
      bdate, edate, bdate2, edate2,
    } = this.state;
    return (
      <ReportContainer>
        <ControlsConrainer>
          <StyledControl>
            <EditorControls.DateInput
              value={bdate}
              onChange={this.bdateChangeHandler}
              label="Початок періоду"
            />
          </StyledControl>
          <StyledControl>
            <EditorControls.DateInput
              value={edate}
              onChange={this.edateChangeHandler}
              label="Кінець періоду"
            />
          </StyledControl>
        </ControlsConrainer>
        <ReportEditor
          valuesRepresentator={valuesRepresentator}
          params={{
            first_period_start: bdate,
            first_period_end: edate,
            second_period_start: bdate2,
            second_period_end: edate2,
          }}
          overrideColumns={redefineColumns}
          resourceColumns={resColumns}
          addColummns={addColummns}
          meta={md}
        />
      </ReportContainer>
    );
  }
}

export default ComparePeriodsReport;
