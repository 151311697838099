import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ExpandIcon, RollupIcon } from './icons';
import { StyledButton, StyledImg } from './styledCommandPanelButton';

const Button = styled(StyledButton)`
  margin-left: auto;
`;

export const ExpandButton = ({
  content, onClick, disabled, className, expanded,
}) => {
  const text = content || (!expanded ? 'Розгорнути' : 'Згорнути');
  return (
    <Button onClick={onClick} disabled={disabled} title={text} className={className}>
      {expanded ? (
        <StyledImg src={RollupIcon} alt={text} />
      ) : (
        <StyledImg src={ExpandIcon} alt={text} />
      )}
    </Button>
  );
};

ExpandButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  expanded: PropTypes.bool,
};

ExpandButton.defaultProps = {
  onClick: () => null,
  content: null,
  expanded: false,
  disabled: false,
  className: '',
};

export default ExpandButton;
