import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputStyled } from '../../../components/Styled/Input/index';
import ImageShower from './imageShower';

const StyledFileInput = styled(InputStyled).attrs({
  type: 'file',
  multiple: true,
})`
`;

class FileInput extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.shape({ file: PropTypes.string }),
      PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    alt: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    width: null,
    height: null,
    alt: '',
  };

  render() {
    const {
      onChange, width, height, alt, value,
    } = this.props;
    return (
      <div>
        <ImageShower
          value={value}
          height={height}
          width={width}
          alt={alt}
        />
        <StyledFileInput onChange={onChange} />
      </div>
    );
  }
}

export default FileInput;
