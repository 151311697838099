import { useMemo } from 'react';

/**
 *
 * @param {{
 * meta: {
 *  options: {
 *    group: [],
 *    column: [],
 *    fields: [],
 *    filter: [],
 *    order: [],
 *  },
 *  schema: {},
 * },
 * data: {
 *    header: [[]],
 *    body: [[]],
 *    footer: [{}],
 *    columns_cnt: int,
 *    },
 * }} result
 */
// eslint-disable-next-line import/prefer-default-export
export const useReportResult = (result) => {
  const header = useMemo(() => result.data.header, [result.data.header]);
  const body = useMemo(() => result.data.body, [result.data.body]);
  const footer = useMemo(() => result.data.footer, [result.data.footer]);
  return {
    header, body, footer,
  };
};
