import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const PotralDiv = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,.7);
  overflow: hidden;
  
  @media (max-width: 900px) {
  position: fixed;
  }
`;

const PortalContainer = styled.div`
  width: fit-content;
  padding: 1em 1.5em;
  border-radius: 0.2em;
  opacity: 1;
  background: white;
  margin: 10vh auto;
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 900px) {
  overflow: auto;
  margin: 5vh auto;
  }
`;

export const Modal = ({ open, children, node }) => {
  if (!open) { return null; }
  return createPortal(
    <PotralDiv>
      <PortalContainer>
        {React.Children.only(children)}
      </PortalContainer>
    </PotralDiv>,
    node,
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

Modal.defaultProps = {
  open: false,
  node: document.body,
};

export default Modal;
