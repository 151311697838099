import React, {
  useState, useEffect, useCallback, useRef, useContext,
} from 'react';
import styled from 'styled-components';
import { LogicaContext } from '../../../providers/authLogicaProvider';
import Editor from './editor';

const Div = styled.div`
    max-width: 50%;
    flex-direction: column;
`;

const StyledButton = styled.button`
    width: 300px;
    margin-bottom: 20px;
    :hover {
    color: white;
    }
`;

const NextButton = styled.button`
    margin-left: auto;
    padding: 10px 0;
    width: 150px;
    cursor: pointer;
     :hover {
    color: white;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const Text = styled.h1`
    text-align: center;
`;

const Link = styled.a`
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    color: inherit;
    :hover {
    color: inherit;
    }
`;

const Welcome = () => {
  const [next, setNext] = useState(false);

  const { ticket, setTicket } = useContext(LogicaContext);

  const useFocus = () => {
    const el = useRef(null);
    const setFocus = () => (el.current && el.current.focus());

    return [el, setFocus];
  };
  const [inputRef, setInputFocus] = useFocus();
  const [ref, setRefFocus] = useFocus();

  const handleEnterClick = useCallback(() => {
    if (ticket) {
      setRefFocus();
    } else {
      setInputFocus();
    }
  }, [setInputFocus, setRefFocus, ticket]);

  useEffect(() => {
    document.body.addEventListener('click', handleEnterClick);

    return () => {
      document.body.removeEventListener('click', handleEnterClick);
    };
  }, [handleEnterClick, setTicket, ticket]);

  return (
    <>
      {ticket && (
        <Div>
          <h3>Ви вже авторизований</h3>
          <NextButton autoFocus onClick={() => setTicket()} ref={ref}>Вийти</NextButton>
        </Div>
      )}

      {!ticket
        && (!next ? (
          <>
            <Div>
              <Text>Увага!</Text>
              <h3>
                Для успішного проходження реєстрації Вам
                необхідно буде використати ключ, за допомогою
                якого здійснюватиметься ідентифікація установи.
                Якщо Ви використовуватимете ключ ДКУ,
                рекомендуємо завчасно завантажити сертифікати,
                так як їх потрібно буде додати самостійно.
                Зробити це можна за наступними посиланнями:
              </h3>
              <ButtonWrapper>
                <StyledButton variant="outline-primary">
                  <Link
                    href="http://acsk.treasury.gov.ua/poshuk_sertificativ/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Сертифікати сформовані до 31.01.2020
                    року
                  </Link>
                </StyledButton>

                <StyledButton variant="outline-primary">
                  <Link
                    href="http://ca.treasury.gov.ua/Content/xPages/Certificates/FindCertificate.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Сертифікати сформовані після 31.01.2020
                    року
                  </Link>
                </StyledButton>

                <NextButton autoFocus onClick={() => setNext(true)} variant="outline-primary" ref={inputRef}>Далі</NextButton>
              </ButtonWrapper>
            </Div>
          </>
        ) : (
          <Editor />
        ))}
    </>
  );
};

export default Welcome;
