import styled from 'styled-components';

export const StyledTable = styled.table`
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  border-radius: 10px;
  border-spacing: 0;
  text-align: center;
`;

export const StyledTh = styled.th`
  background: #7c82d2;
  color: white;
  text-shadow: 0 1px 1px #2d2020;
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: white;
  position: sticky;
  top: -2em;
  z-index: 3; 
`;

export const StyledTd = styled.td`
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: white;
  padding: 10px 20px;
background: #b3c3e7;
`;
