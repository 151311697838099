import ContractPrintForm from './contractPrintForm';
import CalculatePayments from './calculatePayments';
import exchange from './exchange';
import ContractsReload from './contractsreload';
import CommentsReparse from './comentsreparse';
import PayersParser from './parsePayers';
import SendMessage from './sendMessage';
import UnitePayers from './unitePayers';
import Master from './master';
import RemarksProcessing from './remarksProcessing';
import SKDMaster from './SKDMaster';
import RoleManagement from './RoleManagement';
import GroupManagement from './GroupManagement';
import DisabledKdb from './disabledKdb';

export default {
  ContractPrintForm,
  CalculatePayments,
  exchange,
  ContractsReload,
  CommentsReparse,
  PayersParser,
  SendMessage,
  UnitePayers,
  Master,
  SKDMaster,
  RoleManagement,
  GroupManagement,
  RemarksProcessing,
  DisabledKdb,
};
