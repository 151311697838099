import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Item } from '../Styled/Misc';
import { HeaderText, EditorContainer } from '../Styled/Forms';
import { LoadIcon } from '../Styled/Buttons/icons';
import { withAuthConsumer } from '../../providers/authProvider';


const services = [
  {
    title: 'Авторизація',
    desc: 'Авторизація в системі Logica',
    icon: LoadIcon,
    url: '/logica/login/',
  },
  {
    title: 'Отримання казначейських файлів',
    desc: 'Сервіс для отримання казначейських файлів типу FT110, @B',
    icon: LoadIcon,
    url: '/logica/getTreasureFiles/',
  },
];
const ServicePage = ({ currentUser }) => (
  <EditorContainer>
    <HeaderText>Робота з системою Logica</HeaderText>
    {services
      .filter((s) => !s.onlyForAdmins || currentUser.is_superuser)
      .map((s) => (
        <Item
          key={s.url}
          icon={
            <img src={s.icon} alt="menu" />
          }
          header={(
            <Link to={s.url}>{s.title}</Link>
          )}
          content={<ul>{s.desc}</ul>}
        />
      ))}
  </EditorContainer>
);

ServicePage.propTypes = {
  currentUser: PropTypes.shape({
    is_superuser: PropTypes.bool,
  }).isRequired,
};
const mapState = (state) => ({
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  withRouter,
);

export default enhance(ServicePage);
