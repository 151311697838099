import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { ExpandButton, EraseButton, ErrorDiv } from './styles';
import { ItemField, valuePropType } from './itemField';
import { DataList } from './datalist';
import api from '../../api/req';

class cItemSelector extends PureComponent {
  static propTypes = {
    value: valuePropType,
    canErase: PropTypes.bool,
    onChange: PropTypes.func,
    backend: PropTypes.string.isRequired,
    authF: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: { id: null, repr: '' },
    canErase: false,
    onChange: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      isErrored: false,
      errorText: '',
      portalOpened: false,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { authF, backend } = this.props;
    api.options$(backend, authF).then((r) => this.queryParser(r, (options) => {
      if (options.actions && options.actions.GET) {
        this.reload();
      } else {
        this.setState({ isErrored: true, errorText: 'No "GET" action in response from server' });
      }
    }));
  }

  reload = () => {
    const { authF, backend } = this.props;
    this.setState({ isLoading: true });
    api.get$(backend, authF)
      .then((rr) => this.queryParser(rr, (data) => {
        this.setState({
          data, isLoading: false,
        });
      }));
  };

  queryParser = (response, f) => {
    if (response.ok) {
      response.json().then((data) => f(data));
    } else {
      this.setState({ isErrored: true, errorText: `${response.status} ${response.statusText}` });
    }
  };

  render() {
    const { value, canErase, onChange } = this.props;
    const {
      isLoading, isErrored, errorText, portalOpened, data,
    } = this.state;
    return (
      <>
        <ItemField value={value} isLoading={isLoading} ref={this.inputRef}>
          {isErrored ? (<ErrorDiv>{errorText}</ErrorDiv>) : null}
          <ExpandButton onClick={() => this.setState({ portalOpened: !portalOpened })} />
          {canErase ? (<EraseButton onClick={(e) => onChange(e, { id: null, repr: '' })} />) : null}
        </ItemField>
        {portalOpened ? createPortal(
          <DataList
            data={data.results}
            onSelect={(e, v) => {
              this.setState({ portalOpened: false });
              onChange(e, v);
            }}
            activeId={value.id}
          />,
          this.inputRef.current,
        ) : null}
      </>
    );
  }
}

export const ItemSelector = withAuthConsumer(mapStateAuth)(cItemSelector);
export default ItemSelector;
