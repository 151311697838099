import { catalogs } from './catalogs';
import { documents } from './documents';
import { others } from './others';
import { reports } from './reports';

export { catalogs, documents, reports };
export { dataTypes } from './datatypes';

/**
 * @const
 * @type {object}
 * @property {Object} cat
 * @property {Object} doc
 */
const meta = {
  cat: catalogs,
  doc: documents,
  other: others,
  rep: reports,
};

const plainMeta = Object.keys(meta).reduce((R, type) => [
  ...R,
  ...Object.keys(meta[type]).reduce((R2, model) => [...R2, meta[type][model]], []),
], []);

export const getSelectorByBackend = (backend) => {
  const models = plainMeta.filter((m) => m.backend === backend);
  if (!models.length) {
    console.error(`No selector found by backend string ${backend} in `, plainMeta);
    return null;
  }
  return models[0].selector;
};

export const getFrontEndByBackend = (backend) => {
  const models = plainMeta.filter((m) => m.backend === backend);
  if (!models.length) {
    console.error(`No selector found by backend string ${backend} in `, plainMeta);
    return null;
  }
  return models[0].frontend;
};

export default meta;
