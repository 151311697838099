import React from 'react';

export const noBorder = (WrappedComponent) => {
  const NoBordered = ({ ...props }) => (
    <WrappedComponent {...props} noBorder noBackground />
  );

  return NoBordered;
};

export default noBorder;
