import React from 'react';

export const AppContext = React.createContext();

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withAuthConsumer = (mapStateToProps) => (WrappedComponent) => {
  const WithAuthConsumer = ({ ...ownProps }) => (
    <AppContext.Consumer>
      {(context) => (
        <WrappedComponent {...mapStateToProps(context)} {...ownProps} />
      )}
    </AppContext.Consumer>
  );
  WithAuthConsumer.displayName = getDisplayName(WrappedComponent);
  return WithAuthConsumer;
};

export const mapStateAuth = (state) => ({
  authF: state.auth,
  currentUser: state.currentUser,
  onSetSettings: state.setSettings,
});
