import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

const generateGName = (group, k) => `group-${k}`;

/**
 * Генерирует группировки, пригодные для отображения в редакторе
 * @param groups {{}[]}
 * @param metaFields {{ label: string }}
 * @returns {[]}
 */
const useDisplayGroups = (groups, metaFields, fields) => useMemo(
  () => (groups.map((group, k) => ({
    name: generateGName(group, k),
    items: Object.keys(group).map((sGroup) => ({
      name: sGroup,
      label: sGroup in metaFields ? metaFields[sGroup].label : sGroup,
      errored: !(sGroup in metaFields),
      useHierarchy: !!group[sGroup].hierarchy,
      canHierarchy: (sGroup in metaFields) && (fields[metaFields[sGroup].key]
        ? !!fields[metaFields[sGroup].key].hierarchical_key
        : metaFields[sGroup].hierarchical_key),
    })),
  }))),
  [fields, groups, metaFields],
);

/**
 * Генерирует доступные поля группировок
 * @param metaFields {Object}
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableGroups = (metaFields, fields, attributes) => useMemo(
  () => {
    if (attributes && attributes.payer) {
      return (Object.keys(metaFields)
        .filter((f) => (metaFields[f].key in fields && fields[metaFields[f].key].key)
          || attributes.payer.map((el) => el.label === metaFields[f].key))
        .map((f) => ({ ...metaFields[f], name: f })));
    }
    return (Object.keys(metaFields)
      .filter((f) => metaFields[f].key in fields && fields[metaFields[f].key].key)
      .map((f) => ({ ...metaFields[f], name: f }))
    );
  },
  [attributes, fields, metaFields],
);

/**
 * HOOK для работы с группировками отчетов
 * @param reportData {{options: {group: string[]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  groups: Array,
 *  displayGroups: Array,
 *  availableGroups: Array,
 *  groupsHandlers: {
 *      addGroupHandler: function,
 *      removeGroupHandler: function,
 *      swapGroupRowHandler: function,
 *      insertSubgroupHandler: function,
 *      removeSubgroupHandler: function,
 *      clearAllGroupsHandler: function,
 *      onChangeHierarchyHandler: function,
 *  },
 *  }}
 */

export const useGroups = (reportData, schema) => {
  const useMetaFieldsWithAttributes = () => {
    const metaFields = { ...schema.src.meta_fields };
    if (schema.attributes && Object.entries(schema.attributes).length > 0) {
      const attributes = Object.entries(schema.attributes).map((parent) => parent[1].map((i) => (
        Object.entries({
          [i.alias]: {
            ...i, parent: parent[0], name: i.label, key: i.label,
          },
        }))));
      return (Object.fromEntries(Object.entries(metaFields).concat(...attributes[0])));
    }
    return metaFields;
  };

  const metaFieldsWithAttributes = useMetaFieldsWithAttributes();

  const [groups, setGroups] = useState([]);

  useEffect(
    () => setGroups(reportData.options.group),
    [reportData],
  );

  const displayGroups = useDisplayGroups(groups, metaFieldsWithAttributes, schema.src.fields);
  const availableGroups = useAvailableGroups(metaFieldsWithAttributes,
    schema.src.fields, schema.attributes);
  const handlers = useMemo(() => {
    const addGroupHandler = (fname, gname) => {
      const index = groups.reduce(
        (R, g, k) => (generateGName(g, k) === gname ? k : R),
        groups.length,
      );

      // eslint-disable-next-line no-prototype-builtins
      if (!groups.some((el) => el.hasOwnProperty(fname))) {
        setGroups([
          ...groups.slice(0, index),
          {
            [fname]: {
              hierarchy: false,
            },
          },
          ...groups.slice(index),
        ]);
      }
    };

    const removeGroupHandler = (gname) => setGroups(
      groups.filter((g, k) => generateGName(g, k) !== gname),
    );

    const clearAllGroupsHandler = () => setGroups([]);

    const swapGroupRowHandler = (fromName, toName) => {
      const from = groups.reduce((R, g, k) => (generateGName(g, k) === fromName ? k : R), null);
      const to = groups.reduce(
        (R, g, k) => (generateGName(g, k) === toName ? k : R),
        groups.length,
      );

      if (from > to) {
        setGroups([
          ...groups.slice(0, to),
          groups[from],
          ...groups.slice(to, from),
          ...groups.slice(from + 1),
        ]);
      }
      if (from < to) {
        setGroups([
          ...groups.slice(0, from),
          ...groups.slice(from + 1, to),
          groups[from],
          ...groups.slice(to),
        ]);
      }
    };

    const insertSubgroupHandler = (fname, groupName) => {
      const gIndex = groups.reduce((R, g, k) => (generateGName(g, k) === groupName ? k : R), null);
      const subgroups = groups[gIndex];
      if (!(fname in subgroups)) {
        setGroups([
          ...groups.slice(0, gIndex),
          {
            ...subgroups,
            [fname]: {
              herarchy: false,
            },
          },
          ...groups.slice(gIndex + 1),
        ]);
      }
    };

    const removeSubgroupHandler = (groupName, sgroupName) => {
      setGroups(
        groups.map(
          (g, k) => (
            generateGName(g, k) === groupName
              ? Object.keys(g).reduce(
                (R, sgroup) => (sgroup !== sgroupName ? { ...R, [sgroup]: g[sgroup] } : R),
                {},
              )
              : g
          ),
        ).filter((g) => Object.keys(g).length),
      );
    };

    const onChangeHierarchyHandler = (groupName, sgroupName, useHierarchy) => {
      setGroups(
        groups.map(
          (g, k) => (
            generateGName(g, k) === groupName
              ? Object.keys(g).reduce(
                (R, sgroup) => (
                  sgroup === sgroupName
                    ? {
                      ...R,
                      [sgroup]: { ...g[sgroup], hierarchy: useHierarchy },
                    }
                    : {
                      ...R,
                      [sgroup]: {
                        ...g[sgroup],
                        hierarchy: g[sgroup].hierarchy && !useHierarchy,
                      },
                    }
                ),
                {},
              )
              : g
          ),
        ),
      );
    };

    return ({
      addGroupHandler,
      removeGroupHandler,
      swapGroupRowHandler,
      insertSubgroupHandler,
      removeSubgroupHandler,
      clearAllGroupsHandler,
      onChangeHierarchyHandler,
    });
  },
  [groups]);

  return {
    groups,
    displayGroups,
    availableGroups,
    groupsHandlers: handlers,
  };
};


export const availableGroupPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableGroupsPropType = PropTypes.arrayOf(availableGroupPropType);

export const subGroupPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
  useHierarchy: PropTypes.bool,
  canHierarchy: PropTypes.bool,
});

export const groupPropType = PropTypes.shape({
  name: PropTypes.string,
  items: PropTypes.arrayOf(subGroupPropType),
});

export const getPreviousGroup = (displayGroups, name) => {
  const index = displayGroups.reduce((R, g, k) => (g.name === name ? k : R), 0);
  if (!index) return null;
  return displayGroups[index - 1].name;
};

export const groupsPropType = PropTypes.arrayOf(groupPropType);
