import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Item } from '../Styled/Misc';
import { HeaderText, EditorContainer } from '../Styled/Forms';
import { LoadIcon } from '../Styled/Buttons/icons';
import { withAuthConsumer } from '../../providers/authProvider';


const services = [
  {
    title: 'Перезаповнити контракти',
    desc: 'Використовується для автоматичного заповнення контрактів',
    icon: LoadIcon,
    url: '/srv/reload_contracts/',
    section: 'reload_contract',
  },
  {
    title: 'Перезаповнити платников з примітки',
    desc: 'Використовується для автоматичного перезаповненя платнків з коду ІНН в примітці (Транзитний рахунок)',
    icon: LoadIcon,
    url: '/srv/parse-comments/',
    section: 'service_parse_comments',
  },
  {
    title: 'Перезаповнити гендерні ознаки платників',
    desc: 'Використовується для автоматичного перезаповненя статті, признаку юр особи, дати народження и т.і.',
    icon: LoadIcon,
    url: '/srv/parse-payers/',
    section: 'service_parse_payer_code',
  },
  {
    title: 'Надіслати повідомлення користувачам',
    desc: 'Використовується для відправлення повідомлення користувачам',
    icon: LoadIcon,
    url: '/srv/send-message/',
    onlyForAdmins: true,
  },
  {
    title: 'Замінити платника',
    desc: 'Замніна одного платника на іншого. Викристовуються у випадку дублювання плантиків',
    icon: LoadIcon,
    url: '/srv/unite-payers/',
    onlyForAdmins: true,
  },
  {
    title: 'Розширена обробка даних',
    desc: '',
    icon: LoadIcon,
    url: '/srv/master/',
    section: 'service_master',
  },
  {
    title: 'Обробка зауважень',
    desc: '',
    icon: LoadIcon,
    url: '/srv/remarks-processing/',
    section: 'service_warnings',
  },
  {
    title: 'Мастер СКД',
    desc: '',
    icon: LoadIcon,
    url: '/srv/skd-master/',
    onlyForAdmins: true,
  },
  {
    title: 'Управління Ролями',
    desc: '',
    icon: LoadIcon,
    url: '/srv/role_management/',
    onlyForAdmins: true,
  },
  {
    title: 'Управління Групами',
    desc: '',
    icon: LoadIcon,
    url: '/srv/group_management/',
    onlyForAdmins: true,
  },
  {
    title: 'Виключення КДБ',
    desc: '',
    icon: LoadIcon,
    url: '/srv/disabled_kdb/',
  },
];
const ServicePage = ({ currentUser }) => (
  <EditorContainer>
    <HeaderText>Сервісні можливості</HeaderText>
    {services
      .filter((s) => (!s.onlyForAdmins || currentUser.is_superuser)
          && (!s.section || currentUser.available_sections.includes(s.section)))
      .map((s) => (
        <Item
          key={s.url}
          icon={
            <img src={s.icon} alt="menu" />
          }
          header={(
            <Link to={s.url}>{s.title}</Link>
          )}
          content={<ul>{s.desc}</ul>}
        />
      ))}
  </EditorContainer>
);

ServicePage.propTypes = {
  currentUser: PropTypes.shape({
    is_superuser: PropTypes.bool,
    available_sections: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
const mapState = (state) => ({
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  withRouter,
);

export default enhance(ServicePage);
