import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoginInput, PasswordInput } from '../Styled/Input';
import { LoginButton } from '../Styled/Buttons';

const MyLoginButton = styled(LoginButton)`
  background-color: #00b5ad;
  color: white;
  border-radius: .3rem;
  outline: none;
  width: 100%;
  border: none;
  padding: 7px 0;
  font-weight: 700;
`;


const Container = styled.div`
  background: rgba(0,0,0,.3);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  background: white;
  border-radius: .3rem;
  padding: 20px 20px;
  width: 20%;
  box-shadow: 0px 1px 30px -4px rgba(58,140,194,1);
  
  @media (max-width: 600px) {
  width: auto;
  }
`;

const Err = styled.div`
  // font-weight: 700;
  color: red;
  margin-top: 5px;
`;

const Header = styled.div`
  color: #00b5ad;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 10px;
`;

const StyledInputContainer = styled.div`
  margin-bottom: 10px;
`;

class Login extends Component {
  static propTypes = {
    onLogin: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
  };

  static defaultProps = {
    errorMsg: null,
  };

  constructor(props) {
    super(props);
    this.loginRef = React.createRef();
    this.passwdRef = React.createRef();
  }

  render() {
    const { onLogin, errorMsg } = this.props;
    return (
      <Container>
        <Form>
          <Header>Вхід до системи</Header>
          <StyledInputContainer>
            <LoginInput name="username" ref={this.loginRef} />
          </StyledInputContainer>
          <StyledInputContainer>
            <PasswordInput
              name="password"
              type="password"
              ref={this.passwdRef}
              autocomplete="current-password"
            />
          </StyledInputContainer>
          <MyLoginButton
            content="Увійти"
            onClick={() => onLogin(this.loginRef.current.value, this.passwdRef.current.value)}
          />
          { errorMsg && (
            <Err>{errorMsg}</Err>
          )}
        </Form>
      </Container>
    );
  }
}

export default Login;
