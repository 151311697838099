import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  LoadIcon, AllLogoIcon, LogoCiatIcon, InstructionIcon,
} from '../Styled/Buttons/icons';
import ReportLister from '../../containers/gatherer_reports';
import { mapStateAuth, withAuthConsumer } from '../../providers/authProvider';


const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;
const ContainerLink = styled.div`
  display: flex;
  align-items: center;
`;
const StyledLink = styled(Link)`
  margin-left: 0.3em;
  color: #488de4;
  font-size: 1em;
  &:hover{
    color: #1a4aac;
  };
  &:visited{
    color: #3f51b4;
  };
`;
const StyledDownload = styled.a`
  margin-left: 0.3em;
  color: #488de4;
  font-size: 1em;
  &:hover{
    color: #1a4aac;
  };
  &:visited{
    color: #3f51b4;
  };
`;
const ContainerLogoLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 950px) {
  justify-content: flex-start;
  margin: 5px 0;
  }
`;

const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 950px) {
  display: block;
  margin-top: 5px;
  }
`;
const AllLogoImg = styled.img`
  width: 65%;
  
  @media (max-width: 950px) {
  width: 100%;
  }
`;
const LogoImg = styled.img`
  width: 50px;
  
  @media (max-width: 950px) {
  width: 25px;
  }
`;
const FooterText = styled.span`
  color: grey;
  font-size: 0.7em;
  max-width: 15%;
`;
const FooterTextCompany = styled(FooterText)`
  max-width: 100%;
  margin-right: 10px;
  margin-left: 20px;
`;
const rotate = keyframes`
  0% {
    transform: skewX(0deg) translateX(0px);
  }
  90% {
    transform: skewX(0deg) translateX(0px);
  }
  100% {
    transform: skewX(20deg) translateX(200px);
  }
`;

const ContainerShine = styled.a`
  position: relative;
  overflow: hidden;
`;

const Shine = styled.div`
  position: absolute;
  top: 0;
  left: -80px;
  height: 100px;
  width: 30px;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(20deg) translateX(0);
  animation: ${rotate} 10s linear infinite;
`;

const Home = ({ currentUser }) => (
  <Container className="fullscreenParent">
    <div style={{ display: 'flex' }}>
      <ContainerLink>
        <img width="20px" src={LoadIcon} alt="LoadIcon" />
        <StyledLink to="/srv/exchange/create">Завантажити виписку</StyledLink>
      </ContainerLink>
      <ContainerLink style={{ marginLeft: '10px' }}>
        <img width="20px" src={InstructionIcon} alt="LoadIcon" />
        <StyledDownload href="https://www.ciat.net.ua/media/cms_page_media/14/UsersManual.be2d5421.doc" download>
          Довідник користувача
        </StyledDownload>
      </ContainerLink>
    </div>
    <ReportLister />
    {(!currentUser.org || !currentUser.org.hide_e) && (
      <FooterLogoContainer>
        <AllLogoImg src={AllLogoIcon} alt="AllLogoIcon" />
        <FooterText>
          Проект &quot;Е-рішення для громад&quot;
          реалізується ГС &quot;Центр розвитку інновацій&quot;
          за підтримки Програми &quot;U-LEAD з Європою&quot;
        </FooterText>
        <ContainerLogoLink>
          <FooterTextCompany>
            Центр інформаційних та аналітичних технологій
          </FooterTextCompany>
          <ContainerShine href="https://ciat.net.ua/uk/" target="blank">
            <LogoImg src={LogoCiatIcon} alt="ЦІАТ" title="ЦІАТ" />
            <Shine />
          </ContainerShine>
        </ContainerLogoLink>
      </FooterLogoContainer>
    )}
  </Container>
);

Home.propTypes = {
  currentUser: PropTypes.shape({
    org: PropTypes.shape({
      hide_e: PropTypes.bool,
    }),
  }).isRequired,
};

export default withAuthConsumer(mapStateAuth)(Home);
