import React from 'react';
import PropTypes from 'prop-types';
import TableControls from '../../../BaseEditor/tableControls';
import { relationPropType } from '../hooks';

const RelationItem = ({
  relation, active, onClick, alowedTables, onChange, tableFields,
}) => (

  <tr
    style={active ? { background: 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' } : {}}
    onClick={onClick}
  >
    <td>
      <TableControls.Selector
        controlId={`table-from-selector-${relation.key}`}
        value={relation.fromTable}
        values={alowedTables}
        onChange={(e, v) => onChange(relation.key, { fromTable: v })}
        noBackground
      />
    </td>
    <td>
      <TableControls.Selector
        controlId={`field-from-selector-${relation.key}`}
        value={relation.fromField}
        values={(tableFields[relation.fromTable] || [])
          .map((value) => ({ value, display_name: value }))}
        onChange={(e, v) => onChange(relation.key, { fromField: v })}
        noBackground
      />
    </td>
    <td>
      <TableControls.Selector
        controlId={`table-to-selector-${relation.key}`}
        value={relation.toTable}
        values={alowedTables}
        onChange={(e, v) => onChange(relation.key, { toTable: v })}
        noBackground
      />
    </td>
    <td>
      <TableControls.Selector
        controlId={`field-to-selector-${relation.key}`}
        value={relation.toField}
        values={(tableFields[relation.toTable] || [])
          .map((value) => ({ value, display_name: value }))}
        onChange={(e, v) => onChange(relation.key, { toField: v })}
        noBackground
      />
    </td>
    <td>
      <TableControls.CheckboxInput
        controlId={`field-mandatory-${relation.key}`}
        label=" "
        value={relation.mandatory}
        onChange={(e, v) => onChange(relation.key, { mandatory: v })}
        noBackground
      />
    </td>
  </tr>

);

RelationItem.propTypes = {
  relation: relationPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  alowedTables: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  tableFields: PropTypes.shape({}).isRequired,
};

RelationItem.defaultProps = {
  active: false,
};

export default RelationItem;
