import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "rate date org";
  grid-template-columns:  150px 150px 1fr;
  
  @media (max-width: 600px) {
  display: block;
  }
`;

const editorRenderer = (getControl, { currentUser }) => (
  <ThisDocGrid>
    <GridItem area="date">
      {getControl('date')}
    </GridItem>
    <GridItem area="rate">
      {getControl('rate')}
    </GridItem>
    {currentUser.is_superuser && (
      <GridItem area="org">
        {getControl('org', {
          backend: '/api/catalog/orgs/',
        })}
      </GridItem>
    )}
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/catalog/inflationindex/', CommandPanels.catCommandPanel);
