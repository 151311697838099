import getLister, { CommandPanels } from '../../../containers/lister';
import TableComponent from '../../../components/TableComponent';

const backendPrefix = '/api/';
const frontendPrefix = '/';

const defalutListCommandPanel = CommandPanels.catCommandPanel;

const defalutSelectCommandPanel = CommandPanels.catSelectCommandPanel;

// eslint-disable-next-line
export const others = {
  users: {
    backend: `${backendPrefix}core/user/`,
    frontend: `${frontendPrefix}users/`,
    lister: getLister(TableComponent, `${backendPrefix}core/user/`, defalutListCommandPanel),
    selector: getLister(TableComponent, `${backendPrefix}core/user/`, defalutSelectCommandPanel),
  },
  profiles: {
    backend: `${backendPrefix}core/profiles/`,
    frontend: `${frontendPrefix}profiles/`,
    lister: getLister(TableComponent, `${backendPrefix}core/profiles/`, defalutListCommandPanel),
    selector: getLister(TableComponent, `${backendPrefix}core/profiles/`, defalutSelectCommandPanel),
  },
};
