import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MaterialSelect from '../../components/MaterialUI/Select';

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);


class Selector extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    value: valuePropType,
    // eslint-disable-next-line
    values: PropTypes.arrayOf(PropTypes.shape({
      value: valuePropType,
      disabled: PropTypes.bool,
      display_name: PropTypes.string,
      level: PropTypes.number,
      icon: PropTypes.symbol,
    })),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    // eslint-disable-next-line
    maybeNull: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    values: [],
    onChange: () => null,
    readOnly: false,
    maybeNull: false,
  };

  // eslint-disable-next-line
  static getDerivedStateFromProps(props, state) {
    const { values, value, maybeNull } = props;
    // const StringerValue = k => (`value_${k}`);
    if (value !== state.value || values !== state.values) {
      const newValues = [{ value: null, display_name: 'не обрано', disabled: !maybeNull }, ...values];
      return ({
        value,
        // eslint-disable-next-line
        displayValue: newValues.reduce((R, v) => v.value === value ? v.display_name : R, ''),
        values: newValues,
      });
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line
      values: [],
      // eslint-disable-next-line
      value: null,
    };
  }

  render() {
    const {
      onChange, readOnly,
    } = this.props;
    const { values } = this.state;
    return (
      <>
        {!readOnly ? (
          <MaterialSelect onChange={onChange}>
            {values}
          </MaterialSelect>
        ) : null}
      </>
    );
  }
}

export default Selector;
