import React from 'react';
import { DivHeader } from './styles';
import { fieldsPropType } from './PropTypes';

const HeaderComponent = ({ fields }) => (
  <>
    <DivHeader area="contract">
      {fields.contract.label}
    </DivHeader>
    <DivHeader area="amount">
      {fields.amount.label}
    </DivHeader>
  </>
);

HeaderComponent.propTypes = {
  fields: fieldsPropType,
};

HeaderComponent.defaultProps = {
  fields: {},
};
export default HeaderComponent;
