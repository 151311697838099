import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  AddButton, DeleteButton, ExpandButton, FiltersButton,
} from '../../../../components/Styled/Buttons';
import { cpActions } from './actions';

const StyledCP = styled.div`
  display: flex;
  border: none;
  // border-radius: 0.2em;
  margin: 10px 0;
`;

const StyledExpandButton = styled(ExpandButton)`
  margin-left: auto;
`;

const getCommandPanel = (ButtonsComponent = null) => {
  const DefaultCP = ({
    readOnly,
    canDelete,
    canAdd,
    onActionClick,
    fullScreen,
  }) => (
    <StyledCP>
      <AddButton onClick={(e) => onActionClick(e, cpActions.add)} disabled={readOnly || !canAdd} />
      <DeleteButton
        onClick={(e) => onActionClick(e, cpActions.delete)}
        disabled={readOnly || !canDelete}
      />
      { ButtonsComponent && (
        <ButtonsComponent
          onActionClick={onActionClick}
          readOnly={readOnly}
          canAdd={canAdd}
          canDelete={canDelete}
        />
      )}
      <FiltersButton onClick={(e) => onActionClick(e, cpActions.filter)} />
      <StyledExpandButton
        expanded={fullScreen}
        onClick={(e) => onActionClick(e, cpActions.expand)}
      />
    </StyledCP>
  );

  DefaultCP.propTypes = {
    readOnly: PropTypes.bool,
    canDelete: PropTypes.bool,
    canAdd: PropTypes.bool,
    onActionClick: PropTypes.func,
    fullScreen: PropTypes.bool,
  };

  DefaultCP.defaultProps = {
    readOnly: false,
    canDelete: true,
    canAdd: true,
    onActionClick: (e, action) => console.log(`Action ${action} clicked!`),
    fullScreen: false,
  };

  return DefaultCP;
};

export default getCommandPanel;
