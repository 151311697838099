import React from 'react';
import PropTypes from 'prop-types';

import { paramPropType } from '../hooks';
import { ListItem } from '../styles';

const ParamsItem = ({
  value, active, onClick,
}) => (
  <ListItem
    active={active}
    onClick={onClick}
  >
    <span>{value.name}</span>
  </ListItem>
);

ParamsItem.propTypes = {
  value: paramPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ParamsItem.defaultProps = {
  active: false,
};

export default ParamsItem;
