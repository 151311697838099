import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor/index';
import { StyledLabel } from '../../BaseEditor/withLabel';
import TPs from './tp';

const DocGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 2%;
  grid-row-gap: 5px;
`;

const DocGridAdapt = styled(DocGrid)`

  @media (max-width: 600px) {
  display: block;
  } 
`;
const DivElement = styled.div`
  grid-area: ${({ area }) => area};
`;


const GeneralTPColumnTypes = {
  account: { type: '/api/catalog/accounts/' },
  contract: { type: '/api/catalog/contracts/' },
  payer: { type: '/api/catalog/payers/' },
  // payment_notes: { type: 'string' },
  // payment_number: { type: 'string' },
  // order_amount: { type: 'decimal' },
  // revenue_amount: { type: 'decimal' },
};
const editorRenderer = (getControl, {
  data, fields, dataSetter, options,
}) => {
  const D = new Date(data.date);
  const localDate = D.toLocaleString('uk', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return (
    <>
      <StyledLabel>
        {`${options.name} ${data.id ? `#${data.id}` : ''} ${data.date ? `від ${localDate}` : ''}`}
      </StyledLabel>
      <StyledLabel>
        {`${data.account && data.account.kdb ? `КДБ ${data.account.kdb.repr}` : ''} `}
      </StyledLabel>
      <DocGridAdapt>
        {/* {currentUser.is_superuser && ( */}
        {/*  <DivElement area="1/1/1/3"> */}
        {/*    {getControl('org', { backend: '/api/catalog/orgs/' })} */}
        {/*  </DivElement> */}
        {/* )} */}
        <DivElement area="2/1">
          {getControl('date')}
        </DivElement>
        <DivElement area="2/2">
          {getControl('account', { backend: '/api/catalog/accounts/' })}
        </DivElement>
      </DocGridAdapt>
      <TPs.GeneralTP
        data={data.warehouse_set}
        options={fields.warehouse_set}
        onChange={(e, newRecs) => dataSetter({ warehouse_set: newRecs })}
        overrideColumns={GeneralTPColumnTypes}
      />
    </>
  );
};

export default getBaseEditor(editorRenderer, '/api/document/subscriptions/', CommandPanels.docCommandPanel);
