import React from 'react';
import StringInput from './stringInput';
import { ItemPicker, ItemField } from '../itemField';
import getNumerInput from './numberInput';
import Selector from './selector';
import DateInput from './dates';
import Checkbox from './Checkbox';

// eslint-disable-next-line
export const getComponentByType = ({ type }, read_only = false) => {
  switch (type) {
  case 'string':
    return StringInput;
  case 'email':
    return StringInput;
  case 'nested object':
    // eslint-disable-next-line
    return read_only ? ItemField : ItemPicker;
  case 'field':
    // eslint-disable-next-line
    return read_only ? ItemField : ItemPicker;
  case 'integer':
    return getNumerInput(14);
  case 'float':
    return getNumerInput(14, 2);
  case 'decimal':
    return getNumerInput(14, 2);
  case 'choice':
    return Selector;
  case 'date':
    return DateInput;
  case 'boolean':
    return Checkbox;
  default:
    console.error(`Type ${type} not supported by editor controls`);
    return (() => <div style={{ color: 'red' }}>{`Type ${type} not supported by editor controls`}</div>);
  }
};

// eslint-disable-next-line
export default getComponentByType;
