import React from 'react';
import PropTypes from 'prop-types';
import { StyledCellNoData } from './styles';
import { ListDown } from '../Styled/Buttons/icons';

const TableRowNextPage = ({ colCount, rowCount, onClick }) => (
  <StyledCellNoData
    gridArea={`${rowCount + 3} / 1 / ${rowCount + 3} / ${colCount + 1}`}
    onClick={onClick}
  >
    <img src={ListDown} width="20px;" alt="Наступна сторінка" />
  </StyledCellNoData>
);

TableRowNextPage.propTypes = {
  colCount: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TableRowNextPage;
