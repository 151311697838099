import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AscendingIcon, DescendingIcon } from '../Styled/Buttons/icons';

const StyledHeaderCell = styled.div`
  grid-area: ${(props) => `1/${props.colName}/1/${props.colName}`};
  min-width: 2em;
  padding: 20px 9px;
  // text-align: center;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  cursor: pointer;
  border: 1px solid #DDE2E9;
  font-weight: 700;
  font-size: 14px;
  position: sticky;
  top: 0;
  border-bottom: 2px solid #ced4db;
  box-shadow: 0px 5px 9px -4px #a1aad7;
  &:first-letter{
    text-transform: uppercase;
  };
`;

const StyledImg = styled.img`
  padding: 0 10px;
`;

const HeaderRow = ({
  cols, isHierarhical, onClick, ordering, mode,
}) => (
  <>
    { (isHierarhical || mode === 'doc') && (
      <StyledHeaderCell key="toggler" colName="toggler" />
    ) }
    {cols.map((col, k) => (
      // eslint-disable-next-line
      <StyledHeaderCell key={`header${k}`} colName={col.name} onClick={(e) => onClick(e, col.name)}>
        {col.label || col.name || 'Без назви'}
        {ordering.column === col.name && (
          <StyledImg src={ordering.isAscending ? AscendingIcon : DescendingIcon} alt={ordering.isAscending ? 'За зростанням' : 'За спаданням'} />
        )}
      </StyledHeaderCell>
    ))}
  </>
);

HeaderRow.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ).isRequired,
  isHierarhical: PropTypes.bool,
  onClick: PropTypes.func,
  ordering: PropTypes.shape({
    column: PropTypes.string,
    isAscending: PropTypes.bool,
  }),
  mode: PropTypes.oneOf(['cat', 'doc']).isRequired,
};

HeaderRow.defaultProps = {
  isHierarhical: false,
  onClick: () => null,
  ordering: {
    column: '',
    isAscending: true,
  },
};

export default HeaderRow;
