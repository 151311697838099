/**
 * Возвращает функцию, которая не будет срабатывать, пока продолжает вызываться.
 * Она сработает только один раз через N миллисекунд после последнего вызова.
 * Если ей передан аргумент `immediate`, то она будет вызвана один раз сразу после
 * первого запуска.
 * @param {function} fn - Функция которая выполнится через указанное время
 * @param {number} delay - указанное время
 * @return {function}

 */

export const debounce = (fn, delay = 250) => {
  let timer = null;
  return (...args) => {
    // const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

/**
 * Преобразщовывает дату в строку без учета timezone
 * @param {date} date - Дата
 * @return {string}

 */
export const dateToString = (date) => {
  const Year = `${'0000'.slice(0, 4 - date.getFullYear().toString().length)}${date.getFullYear()}`;
  return `${Year}-${date.getMonth() + 1}-${date.getDate()}`;
};

export function NetworkException(message) {
  this.message = message;
  this.name = 'Network exception';
}

export const downloadFileFromJSON = (jsonData, filename) => {
  const a = document.createElement('a');
  a.href = jsonData;
  a.download = filename;
  const child = document.body.appendChild(a);
  a.click();
  document.body.removeChild(child);
};
