import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimmableLoader, ErrorMessage } from '../../../components/Styled/Misc';
import { DefaultInput } from '../../../components/Styled/Input';
import { Button } from '../../../components/Styled/Buttons';
import {
  H2, StyledTr, ButtonContainer, StyledTd, StyledTh, StyledFontAwesomeIcon, SDefaultInput,
} from '../styled/styledComponent';

const CreateGroup = ({ onClose, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [groupParams, setGroupParams] = useState({ name: '', description: '', rolepriorityforgroup_set: [] });
  const [roles, setRoles] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.results.map((el) => ({
          ...el,
          check: false,
          priority: null,
        }));
        setRoles(newData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const updateGroupParams = (e) => {
    const { name, value } = e.target;
    setGroupParams({
      ...groupParams,
      [name]: value,
    });
  };

  const createGroup = useCallback(() => {
    setErr(null);
    const checkedRoles = roles.filter((el) => el.check);
    const checkedRolesWithPriority = checkedRoles.filter((el) => el.priority);

    if (checkedRolesWithPriority.length < checkedRoles.length) {
      setErr('Не для всіх ролей вказано пріоритет!');
      return;
    }

    const rolepriorityforgroupset = checkedRolesWithPriority
      .map((r) => ({ role: { id: r.id }, priority: r.priority, group: null }));

    const params = {
      ...groupParams,
      rolepriorityforgroup_set: rolepriorityforgroupset,
    };
    const loader = async () => {
      const r = await api.post('/api/permissiongroup/', auth, params);
      if (r.status === 400) {
        const error = await r.json();
        Object.entries(error).map((e) => {
          if (e) {
            throw new Error(`${e[0]}: ${e[1]}`);
          }
          return error;
        });
        return error;
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, groupParams, onClose, onError, onLoadEnd, onLoadStart, roles, setUpdateList]);

  const handleCheckbox = useCallback((e, key) => {
    if (key === 'all') {
      setRoles((prev) => prev.map((i) => ({ ...i, check: true })));
    } else if (key === 'nothing') {
      setRoles((prev) => prev.map((i) => ({ ...i, check: false, priority: '' })));
    } else {
      const { id, checked } = e.target;
      setRoles((prev) => prev
        .map((i) => (i.id === parseInt(id, 10)
          ? { ...i, check: checked, priority: '' } : i)));
    }
  }, []);

  const handlePriority = useCallback((e) => {
    const { id, value } = e.target;
    setRoles((prev) => prev
      .map((i) => (i.id === parseInt(id, 10)
        ? { ...i, priority: value } : i)));
  }, []);

  return (
    <>
      <DimmableLoader loading={loading}>

        {err && (
          <ErrorMessage text={err} />
        )}

        <div style={{ overflow: 'auto' }}>
          <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              Назва групи:
              <DefaultInput
                type="text"
                id="name"
                name="name"
                onChange={updateGroupParams}
              />
            </label>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              Опис групи:
              <DefaultInput
                type="text"
                id="description"
                name="description"
                onChange={updateGroupParams}
              />
            </label>

          </div>

          <H2 color="blue">Ролі</H2>

          <div className="mb-2">
            <Button onClick={() => handleCheckbox(null, 'all')} content="Включити всі">
              <StyledFontAwesomeIcon color="green" icon={faCheckSquare} size="lg" />
            </Button>
            <Button onClick={() => handleCheckbox(null, 'nothing')} content="Виключити всі">
              <StyledFontAwesomeIcon icon={faSquare} color="#cbcbcb" size="lg" />
            </Button>
          </div>

          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <StyledTh width="200px">Включити в список</StyledTh>
                <StyledTh width="200px">Пріоритет</StyledTh>
                <StyledTh>Назва</StyledTh>
                <StyledTh>Опис</StyledTh>
              </tr>
            </thead>
            <tbody>
              {roles && roles.map((el) => (
                <StyledTr key={el.id}>
                  <StyledTd textAlign="center">
                    <input
                      type="checkbox"
                      id={el.id}
                      checked={el.check}
                      onChange={handleCheckbox}
                    />
                  </StyledTd>
                  <StyledTd textAlign="center">
                    {el.check
                          && (
                            <SDefaultInput
                              type="number"
                              id={el.id}
                              name="is_active"
                              value={el.priority}
                              onChange={handlePriority}
                              noBorder
                            />
                          )}
                  </StyledTd>
                  <StyledTd paddingLeft="50px">{el.name}</StyledTd>
                  <StyledTd paddingLeft="50px">{el.description}</StyledTd>
                </StyledTr>
              ))}
            </tbody>
          </table>

          <ButtonContainer>
            <Button onClick={createGroup} content="Створити" />
          </ButtonContainer>
        </div>

      </DimmableLoader>
    </>
  );
};

CreateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

export default CreateGroup;
