import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const generateCName = (column, k) => `column-${k}`;

/**
 * Генерирует колонки, пригодные для отображения в редакторе
 * @param columns {{}[]}
 * @param metaFields {{ label: string }}
 * @returns {[]}
 */
const useDisplayColumns = (columns, metaFields, fields) => useMemo(
  () => columns.map((column, k) => ({
    name: generateCName(column, k),
    items: Object.keys(column).map((sColumn) => ({
      name: sColumn,
      label: sColumn in metaFields ? metaFields[sColumn].label : sColumn,
      errored: !(sColumn in metaFields),
      useHierarchy: !!column[sColumn].hierarchy,
      canHierarchy:
            sColumn in metaFields && (fields[metaFields[sColumn].key]
              ? !!fields[metaFields[sColumn].key].hierarchical_key : metaFields[sColumn].hierarchical_key),
    })),
  })),
  [fields, columns, metaFields],
);

/**
 * Генерирует доступные поля группировок колонок
 * @param metaFields {Object}
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableColumns = (metaFields, fields, attributes) => useMemo(
  () => {
    if (attributes && attributes.payer) {
      return (Object.keys(metaFields)
        .filter((f) => (metaFields[f].key in fields && fields[metaFields[f].key].key)
          || attributes.payer.map((el) => el.label === metaFields[f].key))
        .map((f) => ({ ...metaFields[f], name: f })));
    }
    return (Object.keys(metaFields)
      .filter(
        (f) => metaFields[f].key in fields && fields[metaFields[f].key].key,
      )
      .map((f) => ({ ...metaFields[f], name: f })));
  },
  [attributes, fields, metaFields],
);

/**
 * HOOK для работы с группировками колонок отчетов
 * @param reportData {{options: {column: string[]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  columns: Array,
 *  displayColumns: Array,
 *  availableColumns: Array,
 *  columnsHandlers: {
 *      addColumnHandler: function,
 *      removeColumnHandler: function,
 *      swapColumnRowHandler: function,
 *      insertSubColumnHandler: function,
 *      removeSubColumnHandler: function,
 *      clearAllColumnsHandler: function,
 *      onChangeHierarchyHandler: function,
 *  },
 *  }}
 */

export const useColumns = (reportData, schema) => {
  const useMetaFieldsWithAttributes = () => {
    const metaFields = { ...schema.src.meta_fields };
    if (schema.attributes && Object.entries(schema.attributes).length > 0) {
      const attributes = Object.entries(schema.attributes).map((parent) => parent[1].map((i) => (
        Object.entries({
          [i.alias]: {
            ...i, parent: parent[0], name: i.label, key: i.label,
          },
        }))));
      return (Object.fromEntries(Object.entries(metaFields).concat(...attributes[0])));
    }
    return metaFields;
  };

  const metaFieldsWithAttributes = useMetaFieldsWithAttributes();

  const [columns, setColumns] = useState([]);

  useEffect(() => setColumns(reportData.options.column || []), [reportData]);

  const displayColumns = useDisplayColumns(
    columns,
    metaFieldsWithAttributes,
    schema.src.fields,
  );
  const availableColumns = useAvailableColumns(
    metaFieldsWithAttributes,
    schema.src.fields,
    schema.attributes,
  );
  const handlers = useMemo(() => {
    const addColumnHandler = (fname, cname) => {
      const index = columns.reduce(
        (R, g, k) => (generateCName(g, k) === cname ? k : R),
        columns.length,
      );

      // eslint-disable-next-line no-prototype-builtins
      if (!columns.some((el) => el.hasOwnProperty(fname))) {
        setColumns([
          ...columns.slice(0, index),
          {
            [fname]: {
              hierarchy: false,
            },
          },
          ...columns.slice(index),
        ]);
      }
    };

    const removeColumnHandler = (cname) => setColumns(columns.filter((g, k) => generateCName(g, k) !== cname));

    const clearAllColumnsHandler = () => setColumns([]);

    const swapColumnRowHandler = (fromName, toName) => {
      const from = columns.reduce(
        (R, g, k) => (generateCName(g, k) === fromName ? k : R),
        null,
      );
      const to = columns.reduce(
        (R, g, k) => (generateCName(g, k) === toName ? k : R),
        columns.length,
      );

      if (from > to) {
        setColumns([
          ...columns.slice(0, to),
          columns[from],
          ...columns.slice(to, from),
          ...columns.slice(from + 1),
        ]);
      }
      if (from < to) {
        setColumns([
          ...columns.slice(0, from),
          ...columns.slice(from + 1, to),
          columns[from],
          ...columns.slice(to),
        ]);
      }
    };

    const insertSubcolumnHandler = (fname, columnName) => {
      const cIndex = columns.reduce(
        (R, g, k) => (generateCName(g, k) === columnName ? k : R),
        null,
      );
      const subcolumns = columns[cIndex];
      if (!(fname in subcolumns)) {
        setColumns([
          ...columns.slice(0, cIndex),
          {
            ...subcolumns,
            [fname]: {
              herarchy: false,
            },
          },
          ...columns.slice(cIndex + 1),
        ]);
      }
    };

    const removeSubcolumnHandler = (columnName, scolumnName) => {
      setColumns(
        columns
          .map((g, k) => generateCName(g, k) === columnName
            ? Object.keys(g).reduce(
              (R, scolumn) => scolumn !== scolumnName
                ? { ...R, [scolumn]: g[scolumn] }
                : R,
              {},
            )
            : g)
          .filter((g) => Object.keys(g).length),
      );
    };

    const onChangeHierarchyHandler = (
      columnName,
      scolumnName,
      useHierarchy,
    ) => {
      setColumns(
        columns.map((c, k) => generateCName(c, k) === columnName
          ? Object.keys(c).reduce(
            (R, scolumn) => scolumn === scolumnName
              ? {
                ...R,
                [scolumn]: { ...c[scolumn], hierarchy: useHierarchy },
              }
              : {
                ...R,
                [scolumn]: {
                  ...c[scolumn],
                  hierarchy: c[scolumn].hierarchy && !useHierarchy,
                },
              },
            {},
          )
          : c),
      );
    };

    return {
      addColumnHandler,
      removeColumnHandler,
      swapColumnRowHandler,
      insertSubcolumnHandler,
      removeSubcolumnHandler,
      clearAllColumnsHandler,
      onChangeHierarchyHandler,
    };
  }, [columns]);

  return {
    columns,
    displayColumns,
    availableColumns,
    columnsHandlers: handlers,
  };
};

export const availableColumnPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableColumnsPropType = PropTypes.arrayOf(
  availableColumnPropType,
);

export const subColumnPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
  useHierarchy: PropTypes.bool,
  canHierarchy: PropTypes.bool,
});

export const columnPropType = PropTypes.shape({
  name: PropTypes.string,
  items: PropTypes.arrayOf(subColumnPropType),
});

export const getPreviousColumn = (displayColumns, name) => {
  const index = displayColumns.reduce(
    (R, g, k) => (g.name === name ? k : R),
    0,
  );
  if (!index) return null;
  return displayColumns[index - 1].name;
};

export const columnsPropType = PropTypes.arrayOf(columnPropType);
