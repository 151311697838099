const backendPrefix = '/api/';
const frontendPrefix = '/rep/';

export const reports = {
  payers: {
    backend: `${backendPrefix}pay/reports/`,
    frontend: `${frontendPrefix}payReports/`,
    label: 'Аналіз доходів бюджету',
  },
  comparePeriods: {
    backend: `${backendPrefix}cmp/reports/`,
    frontend: `${frontendPrefix}comparePeriods/`,
    label: 'Порівняння даних між аналогічними періодами',
  },
  contractReports: {
    backend: `${backendPrefix}contract/reports/`,
    frontend: `${frontendPrefix}contractReports/`,
    label: 'Аналіз виконання контрактів',
  },
  compare412: {
    backend: `${backendPrefix}compare412/reports/`,
    frontend: `${frontendPrefix}compare412/`,
    label: 'Порівняння з даними файлів ft*.* (форма 412) ',
  },
  compareBetween412: {
    backend: `${backendPrefix}between412/reports/`,
    frontend: `${frontendPrefix}compare_between_412/`,
    label: 'Порівняння файлів ft*.* (форма 412) за періодами',
  },
};

export default reports;
