import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../api/req';
import { checkTask } from '../../../api/checktask';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { ExecuteButton } from '../../../components/Styled/Buttons';
import { ErrorMessage, DimmableLoader, Item } from '../../../components/Styled/Misc';
import { FileDocIcon, ErrorImIcon } from '../../../components/Styled/Buttons/icons';
import { documents } from '../../../const/meta/documents';
import { HeaderText, EditorContainer } from '../../../components/Styled/Forms';
import { StepLabel } from '../exchange/editor/styles';

const NewWindowsLink = styled(Link).attrs({
  target: 'blank',
})`
  font-style: italic;
  color: #3d6ca2;
    &:hover{
      color: #699bd5;
    };
    &:visited{
      color: #8a69d5;
    };
`;

const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;
const SubText = styled(StepLabel)`
  margin-top: 0;
`;
const ErrorText = styled.h3`
  color: red;
  text-align: center;
`;
const ContainerError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class ContractsReloadEditor extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const d = new Date();
    this.state = {
      bdate: `${d.getFullYear()}-01-01`,
      edate: d.toISOString().slice(0, 10),
      org: currentUser.org ? { id: currentUser.org.id, repr: currentUser.org.repr } : null,
      kdb: null,
      payer: null,
      errorText: '',
      errorHeader: '',
      isProcessing: false,
      isErrored: false,
      updatedDocuments: [],
      isDone: false,
    };
  }

  startProcessing = async () => {
    const { authF } = this.props;
    const {
      bdate, edate, kdb, payer, org,
    } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
      updatedDocuments: [],
      isDone: false,
    });

    const options = {
      bdate,
      edate,
      org: org.id,
      ...kdb ? { kdb: kdb.id } : {},
      ...payer ? { payer: payer.id } : {},
    };

    const r = await api.post$('/api/service/reloadcontracts/', authF, options);
    if (r.ok) {
      const d = await r.json();
      checkTask(
        d.task_id,
        authF,
        this.processResult,
        (err) => this.setState({
          errorText: `${err.status} ${err.statusText}`,
          errorHeader: 'Вам все вдалося, але виник несподіваний результат',
          isErrored: true,
          isProcessing: false,
          updatedDocuments: [],
          isDone: false,
        }),
      );
    } else {
      this.setState({
        errorText: r.status === 403 ? 'У Вас немає прав доступу до цього розділу.' : `${r.status} ${r.statusText}`,
        errorHeader: r.status === 403 ? 'Помилка прав доступу' : 'Вам все вдалося, але виник несподіваний результат',
        isErrored: true,
        isProcessing: false,
        updatedDocuments: [],
        isDone: false,
      });
    }
  };

  processResult = async (result) => {
    const { authF } = this.props;
    const node = result.result.updated[Object.keys(result.result.updated)[0]];
    const ids = node.items;
    if (ids.length) {
      const r = await api.get(node.uri, authF, { id_in: String(ids) });
      if (r.ok) {
        const data = await r.json();
        const updatedDocuments = data.results.map((d) => ({ id: d.id, repr: d.repr }));
        this.setState({
          updatedDocuments,
          isErrored: false,
          isProcessing: false,
          isDone: true,
        });
      }
    } else {
      this.setState({
        updatedDocuments: [],
        isErrored: false,
        isProcessing: false,
        isDone: true,
      });
    }
  };


  render() {
    const { currentUser } = this.props;
    const {
      org, kdb, payer, bdate, edate, isProcessing, isErrored, errorText, updatedDocuments, isDone, errorHeader,
    } = this.state;
    return (
      <DimmableLoader loading={isProcessing}>
        {isErrored && (
          <ErrorMessage text={errorText} header={errorHeader} />
        )}
        <div>
          <HeaderText>Перезаповнити контракти</HeaderText>
          <EditorContainer style={{ overflow: 'inherit' }}>
            <TwoColGrid>
              <TwoColGrid>
                <EditorControls.DateInput
                  label="Дата початку"
                  value={bdate}
                  onChange={(e, v) => this.setState({ bdate: v })}
                />
                <EditorControls.DateInput
                  label="Дата закінчення"
                  value={edate}
                  onChange={(e, v) => this.setState({ edate: v })}
                />
              </TwoColGrid>
              {currentUser.is_superuser && (
                <EditorControls.ItemPicker
                  label="Установа"
                  value={org}
                  backend="/api/catalog/orgs/"
                  onChange={(e, v) => {
                    this.setState({ org: v, payer: null });
                  }}
                />
              )}
              <EditorControls.ItemPicker
                label="Обробляти документи тільки по вказаному КДБ"
                value={kdb}
                backend="/api/catalog/kdbs/"
                onChange={(e, v) => {
                  this.setState({ kdb: v });
                }}
                canErase
              />
              <EditorControls.ItemPicker
                label="Обробляти документи тільки по вказаному платнику"
                value={payer}
                backend="/api/catalog/payers/"
                onChange={(e, v) => {
                  this.setState({ payer: v });
                }}
                filter={org ? { org: org.id } : {}}
                canErase
              />
            </TwoColGrid>
          </EditorContainer>
          <ExecuteButton content="Виконати" disabled={isProcessing} onClick={this.startProcessing} />
        </div>
        {isDone && (
          <EditorContainer>
            {updatedDocuments.length ? (
              <>
                <SubText>Оброблено наступні документи:</SubText>
                <TwoColGrid>
                  {updatedDocuments.map((doc) => (
                    <Item
                      key={doc.id}
                      content={(
                        <NewWindowsLink to={`${documents.subscriptions.frontend}${doc.id}/`}>
                          {doc.repr}
                        </NewWindowsLink>
                      )}
                      icon={<img src={FileDocIcon} alt="Icon" />}
                      header="Казначейська виписка"
                    />
                  ))}
                </TwoColGrid>
              </>
            ) : (
              <ContainerError>
                <img src={ErrorImIcon} alt="IconError" />
                <ErrorText>
                  Не було оброблено жодного документу. Перевірте параметри обробки!
                </ErrorText>
              </ContainerError>
            )}
          </EditorContainer>
        )}
      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(ContractsReloadEditor);
