import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import api from '../../api/req';
import { checkTask } from '../../api/checktask';
import { mapStateAuth, withAuthConsumer } from '../../providers/authProvider';
import { dataTypes } from '../../const/meta';
import GroupView from './groups';
import FilterView, { lookups } from './filter';
import OrderView from './order';
import {
  GenerateButton, SettingsButton, PrintButton, ExportXLSButton, CloseButton, Button,
} from '../../components/Styled/Buttons';
import {
  DimmableLoader, ErrorMessage, CommandPanel, Modal,
} from '../../components/Styled/Misc';
import Tab from '../../components/Tab';
import ReportRenderer from './resultShower';
import { NewVariant, SaveVariant } from './variants';
import { ReportContainer } from './styles';
import { RENDER_API_URL } from '../basicReport/hooks/consts';

const Div = styled(ReportContainer)`
  margin-top: 15px;
  
  @media (max-width: 900px) {
  max-height: 95vh;
  }
`;
const TableContainer = styled.div`
  overflow: auto;
`;

const StyledButton = styled(Button)`
z-index: 9;
right: calc(50% - 40px);
bottom: 40%;
position: absolute
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  &>:first-child{
   flex: 1 1 auto;
  }
`;
const ComPanel = styled(CommandPanel)`
  margin-top: 10px;
`;

const EmptyReport = {
  rows: [],
  totals: {},
};

const CloseButtonDiv = styled.div`
  float: right;
`;

class ReportEditor extends Component {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
    }).isRequired,
    overrideColumns: PropTypes.shape(),
    resourceColumns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })),
    params: PropTypes.shape(),
    valuesRepresentator: PropTypes.func,
    excludeColummns: PropTypes.arrayOf(PropTypes.string),
    addColummns: PropTypes.arrayOf(PropTypes.string),
    // Группировки
    groups: PropTypes.arrayOf(PropTypes.shape()),
    // Группировки колонок для переклрестной таблицы
    columnGroups: PropTypes.arrayOf(PropTypes.shape()),
    // фильтры
    filters: PropTypes.arrayOf(PropTypes.shape()),
    // Сортировки
    orders: PropTypes.arrayOf(PropTypes.shape()),
    title: PropTypes.string.isRequired,
    backend: PropTypes.string.isRequired,
    variantID: PropTypes.string,
    variantName: PropTypes.string,
    variantDescription: PropTypes.string,
    // переопределение фильтров. Например период
    overrideFilters: PropTypes.shape(),
    isDetail: PropTypes.bool,
  };

  static defaultProps = {
    overrideColumns: {},
    resourceColumns: [],
    groups: [],
    columnGroups: [],
    filters: [],
    orders: [],
    params: {},
    valuesRepresentator: null,
    excludeColummns: [],
    addColummns: [],
    variantID: null,
    variantName: null,
    variantDescription: null,
    overrideFilters: {},
    isDetail: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      groups, columnGroups, orders, filters,
    } = props;
    const {
      _groups, _columnGroups, _orders, _filters,
    } = state;
    if (groups !== _groups
      || orders !== _orders
      || filters !== _filters
      || columnGroups !== _columnGroups) {
      return {
        ...state,
        _groups: groups,
        _columnGroups: columnGroups,
        _orders: orders,
        _filters: filters,
        groupColumns: groups,
        orderColumns: orders,
        filterColumns: filters,
        columnGroupColumns: columnGroups,
      };
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      settingsOpened: false,
      isErrored: false,
      errMessage: '',
      allowedColumns: [],
      groupColumns: props.groups,
      columnGroupColumns: props.columnGroups,
      orderColumns: props.orders,
      filterColumns: props.filters,
      taskId: null,
      isProcessing: false,
      report: EmptyReport,
      activeGroupColumn: null,
      activeColumnGroupColumn: null,
      activeOrderColumn: null,
      activeFilterColumn: null,
      activeAvailableColumn: null,
      editable: true, // Может ли отчет быть изменен
      _groups: props.groups,
      _columnGroups: props.columnGroups,
      _orders: props.orders,
      _filters: props.filters,
      detailReportSettigs: null,
      updated: false,
    };
    this.ref = createRef();
  }

  componentDidMount() {
    const {
      authF,
      overrideColumns,
      addColummns,
      excludeColummns,
      groups,
      columnGroups,
      orders,
      filters,
      backend,
      isDetail,
    } = this.props;
    this.setState({ isProcessing: true });
    api.options$(backend, authF)
      .then((r) => {
        if (r.ok) {
          r.json().then((d) => {
            const newColumns = [
              ...Object.keys(d.allowed_columns).filter((c) => !excludeColummns.includes(c)),
              ...addColummns,
            ];
            const allowedColumns = newColumns
              .map((cName) => ({
                ...d.allowed_columns[cName],
                key: cName,
                ...overrideColumns[cName],
              }))
              .map((column) => ({
                ...column,
                allowedLookups: this.getAllowedLookupsByType(column.type)
                  .map((al) => ({ value: al, label: d.allowed_lookups[al] })),

              }));

            this.setState({
              groupColumns: groups,
              columnGroupColumns: columnGroups,
              orderColumns: orders,
              filterColumns: filters,
              allowedColumns,
              isProcessing: false,
            }, () => {
              if (isDetail) {
                this.generateReport();
              }
            });
          });
        } else {
          this.setState({
            isProcessing: false,
            isErrored: true,
            errMessage: `${r.status} ${r.statusText}`,
          });
        }
      });
  }

  componentDidUpdate() {
    const { settingsOpened } = this.state;
    if (this.ref.current && !settingsOpened) {
      document.getElementById('print-area').innerHTML = this.ref.current.innerHTML;
    }
  }

  addGroupHandler = (e, columnIndex, index = null) => {
    const { groupColumns, allowedColumns } = this.state;
    this.setState({
      groupColumns: [
        ...groupColumns.slice(0, index === null ? groupColumns.length : index),
        allowedColumns[columnIndex],
        ...groupColumns.slice(index === null ? groupColumns.length : index),
      ],
      report: EmptyReport,
    });
  };

  removeGroupHandler = (e, columnIndex) => {
    const { groupColumns } = this.state;
    this.setState({
      groupColumns: [
        ...groupColumns.slice(0, columnIndex),
        ...groupColumns.slice(columnIndex + 1),
      ],
      report: EmptyReport,
    });
  };

  addColumnGroupHandler = (e, columnIndex, index = null) => {
    const { columnGroupColumns, allowedColumns } = this.state;
    this.setState({
      columnGroupColumns: [
        ...columnGroupColumns.slice(0, index === null ? columnGroupColumns.length : index),
        allowedColumns[columnIndex],
        ...columnGroupColumns.slice(index === null ? columnGroupColumns.length : index),
      ],
      report: EmptyReport,
    });
  };

  removeColumnGroupHandler = (e, columnIndex) => {
    const { columnGroupColumns } = this.state;
    this.setState({
      columnGroupColumns: [
        ...columnGroupColumns.slice(0, columnIndex),
        ...columnGroupColumns.slice(columnIndex + 1),
      ],
      report: EmptyReport,
    });
  };

  addOrderHandler = (e, columnIndex, index = null) => {
    const { orderColumns, allowedColumns } = this.state;
    this.setState({
      orderColumns: [
        ...orderColumns.slice(0, index === null ? orderColumns.length : index),
        { ...allowedColumns[columnIndex], isDescending: false },
        ...orderColumns.slice(index === null ? orderColumns.length : index),
      ],
      report: EmptyReport,
    });
  };

  changeOrderHandler = (e, columnIndex, newColumn) => {
    const { orderColumns } = this.state;
    this.setState({
      orderColumns: [
        ...orderColumns.slice(0, columnIndex),
        newColumn,
        ...orderColumns.slice(columnIndex + 1),
      ],
      report: EmptyReport,
    });
  };

  removeOrderHandler = (e, columnIndex) => {
    const { orderColumns } = this.state;
    this.setState({
      orderColumns: [
        ...orderColumns.slice(0, columnIndex),
        ...orderColumns.slice(columnIndex + 1),
      ],
      report: EmptyReport,
    });
  };

  swapFields = (e, kind, index1, index2) => {
    // eslint-disable-next-line
    const ar = this.state[kind];
    if (index1 < index2) {
      this.setState({
        [kind]: [
          ...ar.slice(0, index1),
          ...ar.slice(index1 + 1, index2),
          ar[index2],
          ar[index1],
          ...ar.slice(index2 + 1),
        ],
        report: EmptyReport,
      });
    } else if (index1 > index2) {
      this.setState({
        [kind]: [
          ...ar.slice(0, index2),
          ar[index1],
          ...ar.slice(index2, index1),
          ...ar.slice(index1 + 1),
        ],
        report: EmptyReport,
      });
    }
  };

  addFilterHandler = (e, columnIndex, index = null) => {
    const { filterColumns, allowedColumns } = this.state;
    const getDefaultValue = (type) => {
      switch (type) {
      case dataTypes.decimal:
        return 0;
      case dataTypes.number:
        return 0;
      case dataTypes.string:
        return '';
      case dataTypes.bool:
        return false;
      default:
        if (type.indexOf('/api') > -1) {
          return {};
        }
        console.error(`Unknow data type ${type}`);
      }
      return null;
    };

    this.setState({
      filterColumns: [
        ...filterColumns.slice(0, index === null ? filterColumns.length : index),
        {
          ...allowedColumns[columnIndex],
          not: false,
          ct: lookups.eq,
          value: getDefaultValue(allowedColumns[columnIndex].type),
        },
        ...filterColumns.slice(index === null ? filterColumns.length : index),
      ],
    });
  };

  removeFilterHandler = (e, columnIndex) => {
    const { filterColumns } = this.state;
    this.setState({
      filterColumns: [
        ...filterColumns.slice(0, columnIndex),
        ...filterColumns.slice(columnIndex + 1),
      ],
    });
  };

  changeFilterHandler = (e, columnIndex, newColumn) => {
    const { filterColumns } = this.state;
    this.setState({
      filterColumns: [
        ...filterColumns.slice(0, columnIndex),
        newColumn,
        ...filterColumns.slice(columnIndex + 1),
      ],
    });
  };

  setActiveGroupColumn = (e, columnIndex) => {
    this.setState({ activeGroupColumn: columnIndex });
  };

  setActiveColumnGroupColumn = (e, columnIndex) => {
    this.setState({ activeColumnGroupColumn: columnIndex });
  };

  setActiveOrderColumn = (e, columnIndex) => {
    this.setState({ activeOrderColumn: columnIndex });
  };

  setActiveFilterColumn = (e, columnIndex) => {
    this.setState({ activeFilterColumn: columnIndex });
  };

  setActiveAvailableColumn = (e, columnIndex) => {
    this.setState({ activeAvailableColumn: columnIndex });
  };

  abortReport = () => {
    const {
      authF,
    } = this.props;
    const { taskId } = this.state;
    if (taskId) { api.delete(`${RENDER_API_URL}?task_id=${taskId}/`, authF); }
  }

  generateReport = () => {
    const {
      authF, params, backend, overrideFilters,
    } = this.props;
    const {
      groupColumns, filterColumns, orderColumns, allowedColumns, columnGroupColumns,
    } = this.state;

    // eslint-disable-next-line
    const valueGetter = (type, value) => {
      if (type.indexOf('/api/') !== -1) return value.id;
      if ((type === dataTypes.decimal || type === dataTypes.number) && !value) return 0;
      return value;
    };

    const order = orderColumns.length
      ? orderColumns.reduce((R, o) => {
        const ac = allowedColumns.filter((a) => a.key === o.key)[0];
        if (ac.columns) {
          return [
            ...R,
            ...ac.columns.map((a) => `${o.key}__${o.isDescending ? '-' : ''}${a}`),
          ];
        }
        return [
          ...R,
          `${o.isDescending ? '-' : ''}${o.key}`,
        ];
      }, [])
      : groupColumns.reduce((R, ac) => {
        if (ac.columns) {
          return [
            ...R,
            ...ac.columns.map((a) => `${ac.key}__${a}`),
          ];
        }
        return [
          ...R,
          `${ac.key}`,
        ];
      }, []);

    api.post$(backend, authF, {
      ...params,
      columns: groupColumns.map((column) => ({
        name: column.query_label,
        children: column.columns ? column.columns.map((sc) => `${column.key}__${sc}`) : [],
      })),
      wide_columns: columnGroupColumns.map((column) => ({
        name: column.query_label,
        children: column.columns ? column.columns.map((sc) => `${column.key}__${sc}`) : [],
      })),
      order,
      filters: filterColumns.filter((c) => !c.not).reduce((R, c) => ({
        ...R,
        [`${c.query_label}${c.ct}`]: c.ct === lookups.in
          ? c.value.map((vv) => valueGetter(c.type, vv))
          : valueGetter(c.type, c.value),
      }), overrideFilters),
      exclude: filterColumns.filter((c) => c.not).reduce((R, c) => ({
        ...R,
        [`${c.query_label}${c.ct}`]: c.ct === lookups.in
          ? c.value.map((vv) => valueGetter(c.type, vv))
          : valueGetter(c.type, c.value),
      }), {}),
    })
      .then((r) => {
        if (r.ok) {
          r.json().then((report) => {
            this.setState({
              taskId: report.task.id,
              isProcessing: true,
              settingsOpened: false,
            });
            checkTask(
              report.task.id,
              authF,
              (result) => this.processResult(result),
              (result) => this.setState({
                isProcessing: false,
                isErrored: true,
                errMessage: result.result.error,
              }),
            );
          });
        } else {
          console.log(r);
        }
      });
  };

  getAllowedLookupsByType = (type) => {
    if (type === dataTypes.date) {
      return Object.values(lookups);
    } if (type === dataTypes.bool) {
      return [lookups.eq];
    } if (type === dataTypes.number || type === dataTypes.decimal) {
      return Object.values(lookups);
    } if (type === dataTypes.string) {
      return [lookups.eq];
    } if (type.indexOf('/api/') !== -1) {
      return [lookups.eq, lookups.in];
    }
    console.error(`Unknow type ${type}`);
    return [];
  };

  getXLS = () => {
    const { taskId } = this.state;
    const { authF } = this.props;
    api.post$('/api/excel/', authF, { from_task_id: taskId }).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          checkTask(
            d.task.id, authF,
            (data) => {
              const fStr = ';base64,';
              const fileData = data.result.file.slice(data.result.file.indexOf(fStr) + fStr.length);
              const contentType = data.result.file.slice(0, data.result.file.indexOf(fStr));
              const byteCharacters = atob(fileData);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i += 1) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: contentType });
              const URL = window.URL.createObjectURL(blob);
              const tempLink = document.createElement('a');
              tempLink.href = URL;
              tempLink.setAttribute('download', `${data.result.filename}.xlsx`);
              const ch = document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(ch);
            },
            (result) => console.log('err', result),
          );
        });
      } else {
        console.log(r);
      }
    });
  };

  dragEndHandler = ({ source, destination }) => {
    if (source && destination) {
      if (source.droppableId === 'AVAILABLE' && destination.droppableId === 'GROUP') {
        this.addGroupHandler(null, source.index, destination.index);
      } else if (source.droppableId === 'GROUP' && destination.droppableId === 'AVAILABLE') {
        this.removeGroupHandler(null, source.index);
      } else if (source.droppableId === 'GROUP' && destination.droppableId === 'GROUP') {
        if (source.index !== destination.index) {
          this.swapFields(null, 'groupColumns', source.index, destination.index);
        }
      } else if (source.droppableId === 'AVAILABLE' && destination.droppableId === 'COLUMN_GROUP') {
        this.addColumnGroupHandler(null, source.index, destination.index);
      } else if (source.droppableId === 'COLUMN_GROUP' && destination.droppableId === 'AVAILABLE') {
        this.removeColumnGroupHandler(null, source.index);
      } else if (source.droppableId === 'COLUMN_GROUP' && destination.droppableId === 'COLUMN_GROUP') {
        if (source.index !== destination.index) {
          this.swapFields(null, 'columnGroupColumns', source.index, destination.index);
        }
      } else if (source.droppableId === 'AVAILABLE' && destination.droppableId === 'FILTER') {
        this.addFilterHandler(null, source.index, destination.index);
      } else if (source.droppableId === 'FILTER' && destination.droppableId === 'AVAILABLE') {
        this.removeFilterHandler(null, source.index);
      } else if (source.droppableId === 'FILTER' && destination.droppableId === 'FILTER') {
        if (source.index !== destination.index) {
          this.swapFields(null, 'filterColumns', source.index, destination.index);
        }
      } else if (source.droppableId === 'AVAILABLE' && destination.droppableId === 'ORDER') {
        this.addOrderHandler(null, source.index, destination.index);
      } else if (source.droppableId === 'ORDER' && destination.droppableId === 'AVAILABLE') {
        this.removeOrderHandler(null, source.index);
      } else if (source.droppableId === 'ORDER' && destination.droppableId === 'ORDER') {
        if (source.index !== destination.index) {
          this.swapFields(null, 'orderColumns', source.index, destination.index);
        }
      }
    }
  };

  getOptions = () => {
    const {
      orderColumns, filterColumns, groupColumns, columnGroupColumns,
    } = this.state;
    return {
      orderColumns, filterColumns, groupColumns, columnGroupColumns,
    };
  };

  handleSaveNewReportUser = async (e, params) => {
    const { backend, authF } = this.props;
    const {
      title, description, isMy, isMyOrg,
    } = params;
    const r = await api.post$('/api/config/report/', authF, {
      title,
      description,
      url: backend,
      is_mine: isMy,
      is_my_org: isMyOrg,
      options: JSON.stringify(this.getOptions()),
    });
    if (r.ok) {
      this.setState({ settingsOpened: false });
    } else {
      console.error(r);
    }
  };

  handleSave = async (e, params) => {
    const { authF, variantID } = this.props;
    const {
      title, description,
    } = params;
    const r = await api.patch$(`/api/config/report/${variantID}/`, authF, {
      title,
      description,
      options: JSON.stringify(this.getOptions()),
    });
    if (r.ok) {
      this.setState({ settingsOpened: false });
    } else {
      console.error(r);
    }
  };

  handleSaveNewReportAdmin = async (e, params) => {
    const { backend, authF } = this.props;
    const {
      title, description, profile, org,
    } = params;
    const r = await api.post$('/api/config/report/', authF, {
      title,
      description,
      url: backend,
      profile,
      org,
      options: JSON.stringify(this.getOptions()),
    });
    if (r.ok) {
      this.setState({ settingsOpened: false });
    } else {
      console.error(r);
    }
  };

  detailProcessingHandler = (e, newSettings) => {
    const { groupColumns, filterColumns, orderColumns } = this.state;
    const newGroups = newSettings.groups || [];
    const newFilters = newSettings.filters || [];
    const newOrders = newSettings.orders || [];
    this.setState({
      detailReportSettigs: {
        groupColumns: [...groupColumns, ...newGroups],
        filterColumns: [...filterColumns, ...newFilters],
        orderColumns: [...orderColumns, ...newOrders],
      },
    });
  };

  processResult(result) {
    const { groupColumns, columnGroupColumns } = this.state;
    const { resourceColumns } = this.props;

    const resourceCount = resourceColumns.length;

    const getNested = (rows, level = 0, totalsBefore = true) => (
      rows.reduce((R, row) => {
        if (row.nested) {
          const nested = getNested(row.nested, level + 1, totalsBefore);
          return [
            ...R,
            ...(!totalsBefore ? nested : []),
            { ...row, level, nestedCount: nested.length },
            ...(totalsBefore ? nested : []),
          ];
        }
        return [...R, { ...row, level, nestedCount: 0 }];
      }, []));

    const getReprs = (collection) => (row) => {
      // const newValues = groupColumns.reduce((R, c) => (Object.assign(
      const newValues = collection.reduce((R, c) => ({

        ...R,
        ...(row[c.query_label] ? {
          [c.query_label]: {
            id__id: row[c.query_label],
            repr: c.columns ? c.columns.reduce((R2, sc) => `${R2}${row[`${c.key}__${sc}`]} `, '') : row[c.key],
            ...c.columns ? c.columns.reduce((R2, sc) => ({ ...R2, [sc]: row[`${c.key}__${sc}`] }), {}) : {},
          },
        } : {}),
      }), {});
      return { ...row, ...newValues };
    };

    const allColumns = getNested(result.result.report.columns || [], 0, false);
    const allColumnsWithReprs = allColumns.map(getReprs(columnGroupColumns));
    const allColumnsWithNumbers = allColumnsWithReprs.map((col, index) => ({
      ...col,
      startColumn: index * resourceCount - col.nestedCount * resourceCount,
      endColumn: (index + 1) * resourceCount - 1,
    }));
    const plainReport = getNested(result.result.report.nested || []);
    const plainReportWithReprs = plainReport.map(getReprs(groupColumns))
      .map((row) => {
        const plainColumns = getNested(row.columns || [], 0, false);
        const ColumnsWithNumbers = plainColumns.map((col) => {
          const findValues = columnGroupColumns
          // eslint-disable-next-line
            .reduce((R, c) => (col[c.query_label])
              ? { ...R, [c.query_label]: col[c.query_label] }
              : { ...R, [c.query_label]: null },
            { level: col.level });
          const oneColumn = allColumnsWithNumbers.filter((ac) => Object.keys(findValues).reduce(
            // eslint-disable-next-line
            (R, key) => (R && key in ac && (ac[key] === findValues[key] || (ac[key] && ac[key].id__id === findValues[key]))),
            true,
          ));
          if (oneColumn.length !== 1) {
            throw new Error(`Columns is undefined. Found columns ${oneColumn.length}`);
          }
          return {
            ...col,
            endColumn: oneColumn[0].endColumn,
          };
        });
        return { ...row, columns: ColumnsWithNumbers };
      });

    this.setState({
      isProcessing: false,
      isErrored: false,
      report: {
        rows: plainReportWithReprs,
        columns: allColumnsWithNumbers,
        totals: result.result.report,
      },
    });
  }

  render() {
    const {
      allowedColumns,
      groupColumns,
      columnGroupColumns,
      isProcessing,
      filterColumns,
      orderColumns,
      isErrored,
      errMessage,
      report,
      settingsOpened,
      taskId,
      activeAvailableColumn,
      activeGroupColumn,
      activeColumnGroupColumn,
      activeOrderColumn,
      activeFilterColumn,
      editable,
      detailReportSettigs,
      // updated,
    } = this.state;

    const {
      resourceColumns,
      valuesRepresentator,
      currentUser,
      title,
      variantID,
      variantName,
      variantDescription,
      authF,
      backend,
      overrideFilters,
      overrideColumns,
      excludeColummns,
      params,
      addColummns,
      isDetail,
    } = this.props;

    const groupRender = () => (
      <GroupView
        allowedColumns={allowedColumns}
        groupColumns={groupColumns}
        onAddColumn={this.addGroupHandler}
        onDeleteColumn={this.removeGroupHandler}
        onActivateGroup={this.setActiveGroupColumn}
        onActivateAvailable={this.setActiveAvailableColumn}
        activeGroup={activeGroupColumn}
        activeAvailable={activeAvailableColumn}
        droppableId="GROUP"
      />
    );

    const columnGroupRender = () => (
      <GroupView
        allowedColumns={allowedColumns}
        groupColumns={columnGroupColumns}
        onAddColumn={this.addColumnGroupHandler}
        onDeleteColumn={this.removeColumnGroupHandler}
        onActivateGroup={this.setActiveColumnGroupColumn}
        onActivateAvailable={this.setActiveAvailableColumn}
        activeGroup={activeColumnGroupColumn}
        activeAvailable={activeAvailableColumn}
        droppableId="COLUMN_GROUP"
      />
    );

    const filterRender = () => (
      <FilterView
        allowedColumns={allowedColumns}
        filterColumns={filterColumns}
        onAddColumn={this.addFilterHandler}
        onDeleteColumn={this.removeFilterHandler}
        onChangeColumn={this.changeFilterHandler}
        onActivateFilter={this.setActiveFilterColumn}
        onActivateAvailable={this.setActiveAvailableColumn}
        activeFilter={activeFilterColumn}
        activeAvailable={activeAvailableColumn}
      />
    );

    const orderRender = () => (
      <OrderView
        allowedColumns={allowedColumns}
        orderColumns={orderColumns}
        onAddColumn={this.addOrderHandler}
        onDeleteColumn={this.removeOrderHandler}
        onChangeColumn={this.changeOrderHandler}
        onActivateOrder={this.setActiveOrderColumn}
        onActivateAvailable={this.setActiveAvailableColumn}
        activeOrder={activeOrderColumn}
        activeAvailable={activeAvailableColumn}
      />
    );

    const panes = [
      { menuItem: 'Групування', render: groupRender },
      { menuItem: 'Групування колонок', render: columnGroupRender },
      { menuItem: 'Фільтр', render: filterRender },
      { menuItem: 'Сортування', render: orderRender },
    ];

    return (
      <ReportContainer>
        {/* Не скасовує на серверній стороні */}
        {/* {isProcessing && updated && ( */}
        {/*  <StyledButton */}
        {/*    variant="outline-danger" */}
        {/*    onClick={() => this.abortReport()} */}
        {/*  > */}
        {/*    Скасувати */}
        {/*  </StyledButton> */}
        {/* )} */}
        <DimmableLoader loading={isProcessing}>
          <DragDropContext onDragEnd={this.dragEndHandler}>
            {isErrored && (
              <ErrorMessage text={errMessage} />
            )}
            {!isDetail && (
              <CommandPanel>
                <GenerateButton onClick={() => this.setState({ updated: true },
                  () => this.generateReport())}
                />
                <SettingsButton
                  content={settingsOpened ? 'Сховати налаштування' : 'Показати налаштування'}
                  onClick={() => this.setState({ settingsOpened: !settingsOpened })}
                />
                <PrintButton onClick={() => window.print()} />
                <ExportXLSButton disabled={!taskId} onClick={this.getXLS} />
              </CommandPanel>
            )}
            <Div>
              <TableContainer>
                <div ref={this.ref}>
                  {settingsOpened ? (
                    <SettingsContainer>
                      <Tab panes={panes} />
                      <ComPanel>
                        <SaveVariant
                          disabled={!editable || !variantID}
                          onSave={this.handleSave}
                          variantName={variantName}
                          variantDescription={variantDescription}
                        />
                        <NewVariant
                          onSave={
                            currentUser.is_superuser
                              ? this.handleSaveNewReportAdmin
                              : this.handleSaveNewReportUser
                          }
                          isAdmin={currentUser.is_superuser}
                        />
                      </ComPanel>
                    </SettingsContainer>
                  ) : (
                    <ReportRenderer
                      report={report}
                      groupColumns={groupColumns}
                      columnGroupColumns={columnGroupColumns}
                      resourceColumns={resourceColumns}
                      allowedColumns={allowedColumns}
                      label={title}
                      valuesRepresentator={valuesRepresentator}
                      onDetailProcessing={this.detailProcessingHandler}
                    />
                  )}
                </div>
              </TableContainer>
            </Div>
          </DragDropContext>
        </DimmableLoader>
        {detailReportSettigs && (
          <Modal open>
            <>
              <CloseButtonDiv>
                <CloseButton onClick={() => this.setState({ detailReportSettigs: null })} />
              </CloseButtonDiv>
              <ReportEditor
                authF={authF}
                currentUser={currentUser}
                title={`Розшифровка ${title}`}
                backend={backend}
                orders={detailReportSettigs.orderColumns}
                filters={detailReportSettigs.filterColumns}
                groups={detailReportSettigs.groupColumns}
                overrideFilters={overrideFilters}
                overrideColumns={overrideColumns}
                excludeColummns={excludeColummns}
                params={params}
                valuesRepresentator={valuesRepresentator}
                addColummns={addColummns}
                resourceColumns={resourceColumns}
                isDetail
              />
            </>
          </Modal>
        )}
      </ReportContainer>
    );
  }
}


export default withAuthConsumer(mapStateAuth)(ReportEditor);

export { lookups };
