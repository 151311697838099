import React, { PureComponent } from 'react';
import ReportEditor from '../../BaseReport';
import { EditorControls } from '../../BaseEditor';
import { dataTypes, reports } from '../../../const/meta';
import { ControlsConrainer, StyledControl, ReportContainer } from '../styles';

const md = reports.payers;

const redefineColumns = {
  id: { type: dataTypes.number },
  account: { type: '/api/catalog/accounts/', columns: ['code', 'name'] },
  budget: { type: '/api/catalog/budgets/', columns: ['name'] },
  contract: { type: '/api/catalog/contracts/', columns: ['name'] },
  date: { type: dataTypes.date },
  kdb: { type: '/api/catalog/kdbs/', columns: ['code', 'name'] },
  org: { type: '/api/catalog/orgs/', columns: ['name'] },
  payer: { type: '/api/catalog/payers/', columns: ['code', 'name'] },
  payer__industry: {
    type: '/api/catalog/industries/',
    columns: ['name'],
    query_label: 'payer__industry',
    label: 'Галузь',
    key: 'payer__industry',
  },
  payer__sex: {
    type: dataTypes.string,
    query_label: 'payer__sex',
    label: 'Стать',
    key: 'payer__sex',
    choices: [
      { value: 'man', display_name: 'Чоловік' },
      { value: 'woman', display_name: 'Жінка' },
      { value: 'none', display_name: '--' },
    ],
  },
  payer__individual: {
    type: dataTypes.bool,
    query_label: 'payer__individual',
    label: 'Є фіз. особою',
    key: 'payer__individual',
  },
  payer__region: {
    type: '/api/catalog/regions/',
    columns: ['name'],
    query_label: 'payer__region',
    label: 'Регіональна ознака (старостинство)',
    key: 'payer__region',
  },
  sum_order_amount: {
    type: 'DECIMAL', query_label: 'sum_order_amount', label: 'Сума до розмежування', key: 'sum_order_amount',
  },
  sum_revenue_amount: {
    type: 'DECIMAL', query_label: 'sum_revenue_amount', label: 'Сума після розмежування', key: 'sum_revenue_amount',
  },
  by_months: {
    type: 'DATE', query_label: 'by_months', label: 'По місяцам', key: 'by_months',
  },
  // order_amount: { type: 'DECIMAL' },
  // revenue_amount: { type: 'DECIMAL' },
  _subscription_document: { type: '/api/document/subscriptions/', columns: ['date'] },
};

const excludeColummns = ['order_amount', 'revenue_amount'];

const addColummns = [
  'sum_order_amount',
  'sum_revenue_amount',
  'payer__industry',
  'payer__region',
  'payer__sex',
  'payer__individual',
];

// const defaultColumns = ['kdb', 'payer'];

const resColumns = [
  { label: 'Сума до розмежування', key: 'sum_order_amount' },
  { label: 'Сума після розмежування', key: 'sum_revenue_amount' },
];

const valuesRepresentator = (field, value) => {
  if (value && (field === 'sum_order_amount' || field === 'sum_revenue_amount')) {
    return value.toLocaleString('uk', { minimumFractionDigits: 2 });
  } if (field === '_subscription_document') {
    return `Казначейська виписка #${value.id__id} від ${value.date}`;
  } if (field === 'payer') {
    return value ? `${value.code} ${value.name}` : '';
  } if (field === 'date') {
    const v = new Date(value.repr);
    return v.toLocaleDateString();
  } if (field === 'by_months') {
    const v = new Date(value.repr);
    return v.toLocaleString('uk', {
      year: 'numeric', month: 'long',
    });
  } if (field === 'payer__sex') {
    if (value && value.id__id === 'man') return 'Чол.';
    if (value && value.id__id === 'woman') return 'Жін.';
    return '-';
  } if (field === 'payer__individual') {
    if (value === null) return 'Невизначено';
    return value ? 'Так' : 'Ні';
  }
  return value && value.repr;
};


class PayersReport extends PureComponent {
  constructor(props) {
    super(props);
    const cDate = new Date();
    this.state = {
      bdate: `${cDate.getFullYear()}-01-01`,
      edate: `${cDate.getFullYear()}-12-31`,
    };
  }

  render() {
    const { bdate, edate } = this.state;
    return (
      <ReportContainer>
        <ControlsConrainer>
          <StyledControl>
            <EditorControls.DateInput
              value={bdate}
              onChange={(e, v) => this.setState({ bdate: v })}
              label="Початок періоду"
            />
          </StyledControl>
          <StyledControl>
            <EditorControls.DateInput
              value={edate}
              onChange={(e, v) => this.setState({ edate: v })}
              label="Кінець періоду"
            />
          </StyledControl>
        </ControlsConrainer>
        <ReportEditor
          valuesRepresentator={valuesRepresentator}
          overrideFilters={{ date__gte: bdate, date__lte: edate }}
          overrideColumns={redefineColumns}
          resourceColumns={resColumns}
          meta={md}
          excludeColummns={excludeColummns}
          addColummns={addColummns}
        />
      </ReportContainer>
    );
  }
}

export default PayersReport;
