import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../components/Styled/Misc';
import { Button } from '../../../components/Styled/Buttons';
import { catalogs as Cat } from '../../../const/meta/catalogs';

const SelectSchemaButton = ({ content, onSelect }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        content={content}
        onClick={() => setShow(true)}
      />
      <Modal open={show}>
        <Cat.schema.selector
          selectMode
          onChoice={(e, v) => {
            onSelect(v.id);
            setShow(false);
          }}
          onClose={() => setShow(false)}
        />
      </Modal>
    </>
  );
};

SelectSchemaButton.propTypes = {
  content: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

SelectSchemaButton.defaultProps = {
  content: null,
};

export default SelectSchemaButton;
