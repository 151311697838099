import React, { PureComponent } from 'react';
import ReportEditor from '../../BaseReport';
import { EditorControls } from '../../BaseEditor';
import { dataTypes, reports } from '../../../const/meta';
import { ControlsConrainer, StyledControl, ReportContainer } from '../styles';

const md = reports.contractReports;

const redefineColumns = {
  contract: { type: '/api/catalog/contracts/', columns: ['name'] },
  date: { type: dataTypes.date },
  kdb: { type: '/api/catalog/kdbs/', columns: ['code', 'name'] },
  org: { type: '/api/catalog/orgs/', columns: ['name'] },
  payer: { type: '/api/catalog/payers/', columns: ['code', 'name'] },
  _subscription_document: { type: '/api/document/subscriptions/', columns: ['date'] },
  revenue_amount: { type: 'DECIMAL' },
  begin_rest: { type: 'DECIMAL' },
  plan_period: { type: 'DECIMAL' },
  fact_period: { type: 'DECIMAL' },
  end_rest: { type: 'DECIMAL' },
  by_months: {
    type: 'DATE', query_label: 'by_months', label: 'По місяцам', key: 'by_months',
  },
  payer__region: {
    type: '/api/catalog/regions/',
    columns: ['name'],
    query_label: 'payer__region',
    label: 'Регіональна ознака (старостинство)',
    key: 'payer__region',
  },
};


const resColumns = [
  { label: 'Залишок на початок періоду', key: 'begin_rest' },
  { label: 'Нараховано', key: 'plan_period' },
  { label: 'Сплачено', key: 'fact_period' },
  { label: 'Залишок на кінець періоду', key: 'end_rest' },
];

const resColumnsItems = resColumns.map((r) => r.key);

const addColummns = [
  'payer__region',
];

const valuesRepresentator = (field, value) => {
  if (value && resColumnsItems.includes(field)) {
    return value.toLocaleString('uk', { minimumFractionDigits: 2 });
  } if (field === '_subscription_document' && value) {
    return `Казначейська виписка #${value.id__id} від ${value.date}`;
  } if (field === 'payer' && value) {
    return value ? `${value.code} ${value.name}` : '';
  } if (field === 'date') {
    const v = new Date(value.repr);
    return v.toLocaleDateString();
  } if (field === 'by_months') {
    const v = new Date(value.repr);
    return v.toLocaleString('uk', {
      year: 'numeric', month: 'long',
    });
  }
  return value && value.repr;
};

class ContractReports extends PureComponent {
  constructor(props) {
    super(props);
    const cDate = new Date();
    this.state = {
      bdate: `${cDate.getFullYear()}-01-01`,
      edate: `${cDate.getFullYear()}-12-31`,
    };
  }

  bdateChangeHandler = (e, v) => this.setState({ bdate: v });

  edateChangeHandler = (e, v) => this.setState({ edate: v });

  render() {
    const {
      bdate, edate,
    } = this.state;
    return (
      <ReportContainer>
        <ControlsConrainer>
          <StyledControl>
            <EditorControls.DateInput
              value={bdate}
              onChange={this.bdateChangeHandler}
              label="Початок періоду"
            />
          </StyledControl>
          <StyledControl>
            <EditorControls.DateInput
              value={edate}
              onChange={this.edateChangeHandler}
              label="Кінець періоду"
            />
          </StyledControl>
        </ControlsConrainer>
        <ReportEditor
          valuesRepresentator={valuesRepresentator}
          params={{
            period_start: bdate,
            period_end: edate,
          }}
          overrideColumns={redefineColumns}
          resourceColumns={resColumns}
          meta={md}
          addColummns={addColummns}
        />
      </ReportContainer>
    );
  }
}

export default ContractReports;
