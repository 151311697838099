import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import AvailableFieldList from '../available';
import {
  Button,
} from '../../../../components/Styled/Buttons';
import itemTypes from '../itemTypes';
import FiltersList from './filtersList';
import { availableFiltersPropType, getPreviousFilter, filtersPropType } from '../../hooks';
import {
  StyledPanel, SColLeft, SColRight, SColButtons,
} from '../styles';


const FiltersEditor = ({
  availableFilters, filters, filtersHandlers,
}) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  const [openedColumns, setOpenedColumns] = useState([]);

  const visibleColumns = useMemo(() => availableFilters.reduce((R, col) => [
    ...R,
    col.parent === null && { ...col, lvl: 0 },
    ...availableFilters.filter((col2) => col2.parent === col.name && openedColumns
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1 })),
  ], []).filter((col) => col), [availableFilters, openedColumns]);

  const onActiveSelectionHandler = useCallback(
    (oname) => setActiveFilter(oname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const onSetOpenedColumns = useCallback((val) => {
    if (openedColumns.includes(val)) {
      return setOpenedColumns((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedColumns((prev) => ([...prev, val]));
  }, [openedColumns]);

  const filtersDragEndHanlder = useCallback(
    ({ name, type }, oname) => {
      if (type === itemTypes.availableField) {
        filtersHandlers.addFilterHandler(name, oname);
      } else if (type === itemTypes.filter) {
        filtersHandlers.swapFilterHandler(name, oname);
      }
    },
    [filtersHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.filter) {
        filtersHandlers.removeFilterHandler(name);
        if (activeFilter === name) setActiveFilter(null);
      }
    },
    [activeFilter, filtersHandlers],
  );

  return (
    <StyledPanel>
      <SColLeft>
        <AvailableFieldList
          fields={availableFilters}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={filtersHandlers.addFilterHandler}
          label="Доступні поля"
          onDragEnd={avaDragEndHanlder}
          setOpenedColumns={onSetOpenedColumns}
          visibleColumns={visibleColumns}
          openedColumns={openedColumns}
        />
      </SColLeft>
      <SColButtons>
        <Button
          onClick={() => filtersHandlers.clearAllFiltersHandler()}
          disabled={!filters.length}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button
          onClick={() => {
            filtersHandlers.removeFilterHandler(activeFilter);
            setActiveFilter(getPreviousFilter(filters, activeFilter));
          }}
          disabled={activeFilter === null}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          onClick={() => filtersHandlers.addFilterHandler(activeAvaField)}
          disabled={!activeAvaField}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </SColButtons>
      <SColRight>
        <FiltersList
          filters={filters}
          activeFilter={activeFilter}
          onClick={onActiveSelectionHandler}
          onDblClick={filtersHandlers.removeFilterHandler}
          onDragEnd={filtersDragEndHanlder}
          changeFilterUse={filtersHandlers.changeFilterUse}
          changeFilterOperation={filtersHandlers.changeFilterOperation}
          changeFilterValue={filtersHandlers.changeFilterValue}
        />
      </SColRight>
    </StyledPanel>
  );
};

FiltersEditor.propTypes = {
  filters: filtersPropType.isRequired,
  availableFilters: availableFiltersPropType.isRequired,
  filtersHandlers: PropTypes.shape({
    addFilterHandler: PropTypes.func,
    removeFilterHandler: PropTypes.func,
    swapFilterHandler: PropTypes.func,
    clearAllFiltersHandler: PropTypes.func,
    changeFilterUse: PropTypes.func,
    changeFilterOperation: PropTypes.func,
    changeFilterAdd: PropTypes.func,
    changeFilterValue: PropTypes.func,
    changeFilterRemove: PropTypes.func,
  }).isRequired,
};

export default FiltersEditor;
