import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledButton } from './styledCommandPanelButton';

const StyledPeriodButton = styled(StyledButton)`
  height: 2.5em;
`;

export const PeriodButton = ({ content, onClick, disabled }) => (
  <StyledPeriodButton onClick={onClick} disabled={disabled} title={content}>
    <span>{content}</span>
  </StyledPeriodButton>
);

PeriodButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

PeriodButton.defaultProps = {
  onClick: () => null,
  content: 'Встановити період',
  disabled: false,
};

export default PeriodButton;
