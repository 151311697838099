import styled from 'styled-components';

const ButtonStyled = styled.button.attrs({
  type: 'button',
})`
  padding: 8px 8px;
  border-radius: .3rem;
  border: 1px solid rgb(177,216,255);
  background: linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  // border: 1px solid rgba(34,36,38,.15);
  // background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  color: #4D4D4D;
  cursor: pointer;
  outline: none;
  &:hover{
    background: #AAC6E6;
  }
`;

export const SelectButton = styled(ButtonStyled)`
  ::after {
    content: "...";
  }
`;

// eslint-disable-next-line
export const EraseButton = styled(ButtonStyled)`
  ::after {
    content: "X";
  }
`;

export const CalendarDiv = styled.div`
  display: grid;
  grid-auto-columns: 31px;
  padding: 10px 5px;
  box-shadow: 0 0 20px rgba(0,0,0,0.16);
  border-radius: 0.3rem;
`;

const DefaultCellDiv = styled.div`
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  padding: 1px 0;
  color: #488de4;
`;

export const CellDiv = styled(DefaultCellDiv)`
  grid-area: ${({ area }) => area};
  color: ${({ color }) => color};
  ${
  // eslint-disable-next-line
  ({ active }) => active ? 'background: #98ccfd' : ''};
  border-radius: 50%; 
  padding: 6px 0 6px 0;
  cursor: pointer;
  :hover {
    background: #99f1ad;
  }
`;

export const HeaderDiv = styled(DefaultCellDiv)`
  grid-area: ${({ area }) => area};
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
   span:first-letter{
     text-transform: uppercase;
  }
  // border-bottom: 1px solid lightgrey;
`;

export const PortalDiv = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  border-radius: .28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  background: white;
  z-index: 30;
  right: 0;
`;

export const DatePcikerButton = styled.div`
  cursor: pointer;
  color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarHr = styled.hr`
  border: 0.5px solid #bae0bd;  
  width: 100%;
`;
