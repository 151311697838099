import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DivElement } from './styles';
import { TableControls } from '../../../../BaseEditor';
import { fieldsPropType } from './PropTypes';

const DecimalInput = TableControls.getNumerInput(14, 2);

class BodyComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.shape({
      date: PropTypes.string,
      order_amount: PropTypes.string,
      payment_notes: PropTypes.string,
    }),
    fields: fieldsPropType,
    rowNumber: PropTypes.number,
    onChange: PropTypes.func,
    active: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    row: {
      date: '',
      order_amount: 0,
      payment_notes: '',
    },
    fields: {
      date: {},
      order_amount: {},
      payment_notes: {},
    },
    rowNumber: 0,
    onChange: () => null,
    active: false,
    onClick: () => null,
  };


  render() {
    const {
      row, rowNumber, onChange, active, fields, onClick,
    } = this.props;
    const cr = (rowNumber * 1 + 2);
    const globalProps = {
      onClick: (e) => onClick(e, rowNumber),
    };
    const globalStyle = active ? {
      background: active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
    } : {};

    return (
      <>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / date`,
          }}
          {...globalProps}
        >
          <TableControls.DateInput
            value={row.date}
            onChange={(e, v) => onChange(e, rowNumber, { date: v })}
            readOnly={fields.date.read_only}
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / order_amount`,
          }}
          {...globalProps}
        >
          <DecimalInput
            value={Number(row.order_amount)}
            onChange={(e, v) => onChange(e, rowNumber, { order_amount: v.toFixed(2) })}
            readOnly={fields.order_amount.read_only}
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / payment_notes`,
          }}
          {...globalProps}
        >
          <TableControls.StringInput
            value={row.payment_notes}
            onChange={(e, v) => onChange(e, rowNumber, { payment_notes: v })}
            readOnly={fields.payment_notes.read_only}
          />
        </DivElement>
      </>
    );
  }
}

export default BodyComponent;
