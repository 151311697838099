import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { GridItem } from '../../../../components/Styled/grids/editorGrid';
import { EditorControls } from '../../../BaseEditor';

const StyledBudgetArea = Styled(GridItem)`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: .3em; 
`;

const BudgetsContainer = ({ budgets, onChange }) => (
  <StyledBudgetArea>
    {budgets.map((budget) => (
      <EditorControls.CheckboxInput
        key={budget.id}
        label={budget.repr}
        value={budget.checked}
        onChange={(e, v) => onChange(e, budgets.map(
          // eslint-disable-next-line
          b => b.id === budget.id ? { ...b, checked: v } : b,
        ))}
      />
    ))}
  </StyledBudgetArea>
);

BudgetsContainer.propTypes = {
  budgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
    checked: PropTypes.bool,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};
export default BudgetsContainer;
