import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import api from '../../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { ExecuteButton } from '../../../components/Styled/Buttons';
import { ErrorMessage, DimmableLoader } from '../../../components/Styled/Misc';
import { HeaderText, EditorContainer } from '../../../components/Styled/Forms';

const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 29% 69%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;

class PayersParserEditor extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
      path: PropTypes.string,
      url: PropTypes.string,
    }).isRequired,
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    this.state = {
      org: currentUser.org ? { id: currentUser.org.id, repr: currentUser.org.repr } : null,
      errorText: '',
      isProcessing: false,
      isErrored: false,
      currentDate: new Date().toISOString()
        .slice(0, 10),
    };
  }

  startProcessing = async () => {
    const { authF, history } = this.props;
    const {
      org, currentDate,
    } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const options = {
      org: { id: org.id },
      date: currentDate,
    };

    const r = await api.post$('/api/service/calculatepayments/', authF, options);

    if (r.ok) {
      const d = await r.json();

      history.push(`/doc/calculationofpayments/${d.id}`);
    } else {
      const d = await r.json();

      this.setState({
        errorText: `${r.status} ${d.error.map((e, idx) => d.error[idx])}`,
        isErrored: true,
        isProcessing: false,
      });
    }
  };


  render() {
    const { currentUser } = this.props;
    const {
      org, isProcessing, isErrored, errorText, currentDate,
    } = this.state;
    return (
      <DimmableLoader loading={isProcessing}>
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <div>
          <HeaderText>Розрахунок платежів</HeaderText>
          <EditorContainer style={{ overflow: 'inherit' }}>
            <TwoColGrid>
              <EditorControls.DateInput
                value={currentDate}
                onChange={(e, v) => this.setState({ currentDate: v })}
                label="Дата"
              />
              {currentUser.is_superuser && (
                <EditorControls.ItemPicker
                  label="Установа"
                  value={org}
                  backend="/api/catalog/orgs/"
                  onChange={(e, v) => {
                    this.setState({ org: v });
                  }}
                />
              )}
            </TwoColGrid>
          </EditorContainer>
          <ExecuteButton content="Виконати" disabled={isProcessing} onClick={this.startProcessing} />
        </div>
      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(PayersParserEditor);
