import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DivStyled, RadioStyled } from '../../components/Styled/Input';
import { Label } from '../../components/Styled/elements';

const StyledLabel = styled(Label)`
  margin-right: 15px;
  cursor: pointer;
`;

const StyledDiv = styled(DivStyled)`
  border: none;
  background: none;
  display: flex;
  flex-direction: ${
  // eslint-disable-next-line
    ({ vertical }) => vertical ? 'column' : 'row'}
    
  @media (max-width: 600px) {
  flex-direction: column;
  margin: 5px 0;
  }
`;
const RadioButtons = ({
  group, value, values, onChange, disabled, readOnly, vertical,
}) => (
  <StyledDiv vertical={vertical}>
    {values.map((o) => (
      <Fragment key={`Radio-${group}-${o.value}`}>
        <RadioStyled
          name={group}
          disabled={o.disabled || disabled}
          readOnly={readOnly}
          value={o.value}
          checked={o.value === value}
          onChange={(e) => !o.disabled && !disabled && !readOnly && onChange(e, o.value)}
        />
        <StyledLabel onClick={(e) => !o.disabled && !disabled && !readOnly && onChange(e, o.value)}>
          {o.display_name || 'Без назви'}
        </StyledLabel>
      </Fragment>
    ))}
  </StyledDiv>
);

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

RadioButtons.propTypes = {
  value: valuePropType,
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  group: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
};

RadioButtons.defaultProps = {
  value: null,
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  vertical: false,
};


export default RadioButtons;
