import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DefaultInput } from '../../../components/Styled/Input';
import { Button } from '../../../components/Styled/Buttons';
import { DimmableLoader, ErrorMessage } from '../../../components/Styled/Misc';
import {
  StyledTr, StyledDiv, ButtonContainer, H2, StyledFontAwesomeIcon, StyledTd, StyledTh,
} from '../styled/styledComponent';

const UpdateRole = ({ onClose, activeId, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [role, setRole] = useState(null);
  const [updatedRole, setUpdatedRole] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const updateRoleParams = (e) => {
    const { name, value } = e.target;
    setUpdatedRole({
      ...updatedRole,
      [name]: value,
    });
  };

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const onError = useCallback((e) => setErr(e), []);

  const getPermissions = useCallback((roleData) => {
    const loader = async () => {
      const r = await api.get('/api/permissions/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();

    loader()
      .then((rData) => {
        const newData = rData.map((el) => {
          if (el.key in roleData.permissions && roleData.permissions[el.key]) {
            return {
              ...el,
              check: true,
              input: true,
            };
          }
          if (el.key in roleData.permissions) {
            return {
              ...el,
              check: false,
              input: true,
            };
          }
          return {
            ...el,
            check: false,
            input: false,
          };
        });
        setRole((prev) => ({ ...prev, permissions: [...newData] }));
        setUpdatedRole((prev) => ({ ...prev, permissions: [...newData] }));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
    /*eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const loader = async () => {
      const params = null;
      const r = await api.get(`/api/role/${activeId}`, auth, params);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRole(rData);
        setUpdatedRole(rData);
        return getPermissions(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, getPermissions, onError, onLoadEnd, onLoadStart]);

  const updateRole = useCallback(() => {
    const loader = async () => {
      const filteredPermissions = updatedRole.permissions.filter((perm) => perm.input)
        .reduce((prev, cur) => ({
          ...prev,
          [cur.key]: cur.check,
        }), {});
      const params = { ...updatedRole, permissions: filteredPermissions };
      const r = await api.put(`/api/role/${activeId}/`, auth, params);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, onClose, onError, onLoadEnd, onLoadStart, setUpdateList, updatedRole]);

  const handleCheckbox = useCallback((type, key, checked) => {
    if (type === 'input') {
      if (key === 'all') {
        setUpdatedRole({
          ...updatedRole,
          permissions: [...updatedRole.permissions
            .map((perm) => ({ ...perm, input: true }))],
        });
      } else if (key === 'nothing') {
        setUpdatedRole({
          ...updatedRole,
          permissions: [...updatedRole.permissions
            .map((perm) => ({ ...perm, input: false, check: false }))],
        });
      } else {
        setUpdatedRole({
          ...updatedRole,
          permissions: [...updatedRole.permissions
            .map((k) => {
              if (k.key === key && !checked) { return { ...k, check: checked, input: checked }; }
              if (k.key === key) { return { ...k, input: checked }; }
              return k;
            })],
        });
      }
    } else if (key === 'all') {
      setUpdatedRole({
        ...updatedRole,
        permissions: [...updatedRole.permissions
          .map((perm) => ({ ...perm, check: perm.input }))],
      });
    } else if (key === 'nothing') {
      setUpdatedRole({
        ...updatedRole,
        permissions: [...updatedRole.permissions
          .map((perm) => ({ ...perm, check: false }))],
      });
    } else {
      setUpdatedRole({
        ...updatedRole,
        permissions: [...updatedRole.permissions
          .map((k) => {
            if (k.key === key) { return { ...k, check: checked }; }
            return k;
          })],
      });
    }
  }, [updatedRole]);

  return (
    <>
      <DimmableLoader loading={loading}>

        {err && (
          <ErrorMessage text={err} />
        )}

        <>
          {updatedRole
            && (
              <div style={{ overflow: 'auto' }}>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>
                    Назва ролі:
                    <DefaultInput
                      type="text"
                      id="name"
                      name="name"
                      value={updatedRole.name}
                      onChange={updateRoleParams}
                    />
                  </label>

                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>
                    Опис ролі:
                    <DefaultInput
                      type="text"
                      id="description"
                      name="description"
                      value={updatedRole.description}
                      onChange={updateRoleParams}
                    />
                  </label>

                </div>

                <H2 color="#6598d2">Права доступу</H2>
                <StyledDiv>
                  <div>
                    <Button className="mr-2" onClick={() => handleCheckbox('input', 'all')} content="Включити всі">
                      <StyledFontAwesomeIcon icon={faCheckSquare} color="green" size="lg" />
                    </Button>
                    <Button variant="outline-primary" onClick={() => handleCheckbox('input', 'nothing')} content="Виключити всі">
                      <StyledFontAwesomeIcon icon={faSquare} color="#cbcbcb" size="lg" />
                    </Button>
                  </div>
                  <div>
                    <Button className="mr-2" variant="info" onClick={() => handleCheckbox('check', 'all')} content="Надати всі доступи">
                      <StyledFontAwesomeIcon icon={faCheckSquare} color="green" size="lg" />
                    </Button>
                    <Button variant="outline-info" onClick={() => handleCheckbox('check', 'nothing')} content="Зняти доступи">
                      <StyledFontAwesomeIcon icon={faSquare} color="#cbcbcb" size="lg" />
                    </Button>
                  </div>
                </StyledDiv>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr>
                      <StyledTh width="200px">Включити в список</StyledTh>
                      <StyledTh width="200px">Надати доступ</StyledTh>
                      <StyledTh>Назва</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {updatedRole.permissions.length && updatedRole.permissions.map((el) => (
                      <StyledTr key={el.key}>
                        <StyledTd textAlign="center">
                          <input
                            type="checkbox"
                            id={el.key}
                            checked={el.input}
                            onChange={(e) => handleCheckbox('input', el.key, e.target.checked)}
                          />
                        </StyledTd>
                        <StyledTd textAlign="center">
                          {el.input
                          && (
                            <input
                              type="checkbox"
                              id={el.key}
                              checked={el.check}
                              onChange={(e) => handleCheckbox('check', el.key, e.target.checked)}
                            />
                          )}
                        </StyledTd>
                        <StyledTd paddingLeft="50px">{el.label}</StyledTd>
                      </StyledTr>
                    ))}
                  </tbody>
                </table>

                <ButtonContainer>
                  <Button className="mt-2 mr-2" variant="outline-success" onClick={updateRole} content="Зберегти" />
                  <Button className="mt-2" variant="outline-danger" onClick={() => setUpdatedRole(role)} content="Повернутись до попередніх значень" />
                </ButtonContainer>
              </div>
            )}
        </>

      </DimmableLoader>
    </>
  );
};

UpdateRole.propTypes = {
  activeId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

UpdateRole.defaultProps = {
  activeId: null,
};

export default UpdateRole;
