import React from 'react';
import PropTypes from 'prop-types';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import SelectSchemaButton from '../selectShemaButton';
import { DefaultInput } from '../../../../components/Styled/Input';

const Button = styled.button`
    color: white;
    background-color: #007bff;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 120px;
     &:hover{
    background-color: #065ebc
     }`;

const NewReport = ({ onCreate, handleChangeNewReportParams }) => (
  <>
    <div style={{ width: '300px' }}>
      <SelectSchemaButton
        content="Завантажити схему"
        icon={faFolderOpen}
        onSelect={handleChangeNewReportParams}
      />
    </div>

    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      Назва звіту:
      <DefaultInput
        type="text"
        id="name"
        name="name"
        onChange={handleChangeNewReportParams}
      />
    </label>

    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      Опис звіту
      <DefaultInput
        type="text"
        id="description"
        name="description"
        onChange={handleChangeNewReportParams}
      />
    </label>

    <Button variant="primary" type="submit" onClick={onCreate}>
      Створити
    </Button>
  </>
);

NewReport.propTypes = {
  handleChangeNewReportParams: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default NewReport;
