import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../providers/authProvider';
import api from '../../../api/req';
import { DimmableLoader } from '../../../components/Styled/Misc';
import EditorControls from '../../BaseEditor/editorControls';

const Editor = ({ onChange, data }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [groupList, setGroupList] = useState([]);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/permissiongroup/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setGroupList(rData.results.map((r) => ({
          id: r.id, name: r.name, value: r.id, display_name: r.name,
        })));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  if (err) {
    console.log(err);
  }

  return (
    <>
      <DimmableLoader loading={loading} fullscreen={false}>
        {groupList && (
          <EditorControls.Selector
            label="Група"
            values={groupList}
            value={data.group_id}
            onChange={(e, v) => onChange({
              group_id: v,
            })}
          />
        )}
      </DimmableLoader>
    </>
  );
};

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    group_id: PropTypes.number,
  }),
};

Editor.defaultProps = {
  data: {},
};

export default Editor;
