import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorControls } from '../../../BaseEditor';
import { Title } from '../styles';
import { ExecuteButton } from '../../../../components/Styled/Buttons';
import { StyledTable, StyledTd, StyledTh } from './styles';

const IntInput = EditorControls.getNumerInput(3, 0);

const TableRecords = ({
  tableName, fields, records, howMany, onChangeHowMany, onRequestRecords,
}) => {
  const [height, setHeight] = useState(0);

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);
  return (
    <>
      <Title>{tableName}</Title>
      <IntInput
        label="Количество записей"
        value={howMany}
        onChange={(e, v) => onChangeHowMany(v)}
        minValue={-1}
      />
      <ExecuteButton
        onClick={() => onRequestRecords()}
        content="Получить записи"
      />

      <div
        className="containerTable"
        ref={containerHeaderRef}
        style={{
          maxHeight: `calc(100vh - ${height}px - 60px)`,
          overflow: 'auto',
        }}
      >
        <StyledTable striped bordered hover>
          <thead>
            <tr>
              {fields.map((f) => (
                <StyledTh key={f}>
                  {f}
                </StyledTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {records.map((row, k) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={k}>
                {fields.map((f, fk) => (
                  <StyledTd key={f}>
                    {row[fk]}
                  </StyledTd>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </>
  );
};

TableRecords.propTypes = {
  tableName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  records: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  howMany: PropTypes.number.isRequired,
  onChangeHowMany: PropTypes.func.isRequired,
  onRequestRecords: PropTypes.func.isRequired,
};

TableRecords.defaultProps = {

  records: [],
};

export default TableRecords;
