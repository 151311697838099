import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledDeleteButton = styled(StyledButton)`
  height: 2.5em;
  &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: #d0f7d4fc;
  }
`;

export const DeleteButton = ({
  content, onClick, disabled, addText,
}) => (
  <StyledDeleteButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={DeleteIcon} alt={content} />
    {addText
    && <StyledText>{content}</StyledText>}
  </StyledDeleteButton>
);

DeleteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  addText: PropTypes.bool,
};

DeleteButton.defaultProps = {
  onClick: () => null,
  content: 'Видалити',
  disabled: false,
  addText: false,
};

export default DeleteButton;
