import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { NpdIcon, Facebook } from '../Styled/Buttons/icons';
import { ImageShower } from '../../containers/BaseEditor/imageField';
import { InfoButton } from '../Styled/Buttons';
import Message from '../../containers/Message';

const StyledHeader = styled.div`
  grid-area: header;  
  border: none;
  padding: 0 3%;
  color: #605b5b;
  background: whitesmoke;
  display: flex;
  justify-content: space-between;;
  align-items: center;
  
  @media (max-width: 600px) {
  flex-direction: column;
  }
`;

const UserInfo = styled.div`
  font-size: 12px;
  font-style: italic;
  _font-weight: 700; 
  display: flex;
  align-items: center;
`;

const lighting = keyframes`
  0%,
  100% {
    color: red; background: none;
  }
  50% {
    color: white; background: red;
  }
`;

const NoOrg = styled.div`
  font-size: 14px;
  font-style: italic;
  display: flex;
  align-items: center;
  color: red;
  animation: ${lighting} ease infinite 4.6s;
`;

const Org = styled.div`
  font-size: 12px;
  font-style: italic;
  display: flex;
  align-items: center;
  color: #11116f;
`;

const ContainerLogoLink = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
`;
const MainNameContainer = styled.div`
  margin-left: 20px;
  text-align: center;
`;
const StyledLinkNPD = styled.a`
  color: #488de4;
  font-size: 0.7em;
      &:hover{
        color: #1a4aac;
      };
      &:visited{
        color: #3f51b4;
      };
`;


const Header = ({ currentUser }) => (
  <StyledHeader>
    <ContainerLogoLink>
      {currentUser.org && currentUser.org.logo && (
        <ImageShower width="30px" value={currentUser.org.logo} alt={currentUser.org.repr} />
      )}
      {(!currentUser.org || !currentUser.org.hide_e) && (
        <>
          <img width="50px" src={NpdIcon} alt="" />
          <MainNameContainer>
            <StyledLinkNPD target="_blank" href="https://cid.center/e-solutions/">Проект &quot;Е-рішення для громад&quot;</StyledLinkNPD>
          </MainNameContainer>
        </>
      )}
    </ContainerLogoLink>
    <div style={{ display: 'flex' }}>
      <a
        href="https://www.facebook.com/ciat.net.ua/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: '5px' }}
      >
        <img src={Facebook} width="24px" alt="Facebook" />
      </a>
      <InfoButton />
      <Message />
      <UserInfo>
        {`Доброго дня ${currentUser.first_name || currentUser.username} ${currentUser.last_name}. `}
        {currentUser.org && currentUser.org.id ? (
          <Org>
            {`Установа ${currentUser.org.repr}`}
          </Org>
        ) : (
          <NoOrg>
            Не задана установа
          </NoOrg>
        )}
      </UserInfo>
    </div>
  </StyledHeader>
);
Header.propTypes = {
  currentUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
    org: PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
      logo: PropTypes.any,
      hide_e: PropTypes.bool,
    }),
  }).isRequired,
};

const enhance = compose(
  withAuthConsumer(mapStateAuth),
  memo,
);

export default enhance(Header);
