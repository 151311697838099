import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { ModalButton, EraseButton, ErrorDiv } from './styles';
import { ItemField, valuePropType } from './itemField';
import { DataList } from './datalist';
import api from '../../api/req';
import { debounce } from '../../api/utils';
import { Modal, ClickerWrapper } from '../../components/Styled/Misc';
import { getSelectorByBackend } from '../../const/meta';

class cItemPicker extends PureComponent {
  static propTypes = {
    value: valuePropType,
    canErase: PropTypes.bool,
    onChange: PropTypes.func,
    backend: PropTypes.string.isRequired,
    authF: PropTypes.func.isRequired,
    noBorder: PropTypes.bool,
    noBackground: PropTypes.bool,
    filter: PropTypes.shape({}),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    value: { id: null, repr: '' },
    canErase: false,
    onChange: () => null,
    noBorder: false,
    noBackground: false,
    filter: null,
    disabled: false,
  };

  // eslint-disable-next-line
  static getDerivedStateFromProps(props, state) {
    if (props.backend !== state.backend) {
      return { ...state, backend: props.backend, selector: getSelectorByBackend(props.backend) };
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isErrored: false,
      errorText: '',
      listOpened: false,
      modalOpened: false,
      editText: null,
      items: [],
      Selector: getSelectorByBackend(props.backend),
    };
    this.reqestor = debounce((v) => this.reload(v));
    this.inputRef = React.createRef();
  }

  reload = (parttext) => {
    const { authF, backend, filter } = this.props;
    this.setState({ isLoading: true });
    const params = { ...filter, search: parttext };
    api.get$(backend, authF, params)
      .then((rr) => this.queryParser(rr, (data) => {
        const items = 'results' in data ? data.results : data;
        this.setState({
          items, listOpened: !!items.length, isLoading: false,
        });
      }));
  };

  queryParser = (response, f) => {
    if (response.ok) {
      response.json().then((data) => f(data));
    } else {
      this.setState({ isErrored: true, errorText: `${response.status} ${response.statusText}` });
    }
  };

  render() {
    const {
      value, canErase, onChange, noBorder, noBackground, filter, disabled,
    } = this.props;
    const {
      isLoading, isErrored, errorText, listOpened, items, editText, modalOpened, Selector,
    } = this.state;
    return (
      <>
        <ItemField
          value={value}
          isLoading={isLoading}
          ref={this.inputRef}
          editText={editText}
          onChange={(e, v) => {
            this.setState({ editText: v });
            this.reqestor(v);
          }}
          onBlur={() => this.setState({ listOpened: false, editText: null })}
          noBorder={noBorder}
          noBackground={noBackground}
          readOnly={disabled}
        >
          {isErrored ? (<ErrorDiv>{errorText}</ErrorDiv>) : null}
          <ModalButton
            onClick={() => this.setState({ modalOpened: true })}
            neighbors={canErase ? 'right' : 'none'}
            disabled={disabled}
          />
          {canErase ? (
            <EraseButton neighbors="left" onClick={(e) => onChange(e, { id: null, repr: '' })} disabled={disabled} />
          ) : null}
        </ItemField>
        {listOpened ? (
          <div>
            <DataList
              data={items}
              onSelect={(e, v) => {
                this.setState({ listOpened: false, editText: null });
                onChange(e, v);
              }}
              activeId={value && value.id}
            />
            {createPortal((
              <ClickerWrapper onClick={() => this.setState({ listOpened: false })} />
            ), document.body)}
          </div>
        ) : null}
        <Modal open={modalOpened}>
          <Selector
            selectMode
            filter={filter}
            onChoice={(e, v) => {
              onChange(e, v);
              this.setState({ modalOpened: false });
            }}
            onClose={() => {
              this.setState({ modalOpened: false });
            }}
            currentId={value && value.id}
          />
        </Modal>
      </>
    );
  }
}

export const ItemPicker = withAuthConsumer(mapStateAuth)(cItemPicker);
// eslint-disable-next-line
export default ItemPicker;
