import React from 'react';
import {
  FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white',
  },
}));

const MaterialSelect = ({
  onChange, content, helperText, required, variant, children,
}) => {
  const classFunc = useStyles();
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl required={required} className={classFunc.formControl} variant={variant}>
      {content && <InputLabel htmlFor="age-native-required">{content}</InputLabel>}
      <Select
        // variant="outlined"
        value={value}
        onChange={handleChange}
      >
        {children.map((i) => (
          <MenuItem
            value={i.value}
            key={i.value}
            onClick={(e) => {
              if (!i.disabled) {
                onChange(e, i.value);
              }
            }}
          >
            {i.display_name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

MaterialSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node,
};

MaterialSelect.defaultProps = {
  content: '',
  helperText: '',
  required: false,
  variant: '',
  children: null,
};

export default MaterialSelect;
