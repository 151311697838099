import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import api from '../../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { SendButton } from '../../../components/Styled/Buttons';
import { HeaderText, EditorContainer } from '../../../components/Styled/Forms';

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-column-gap: 5%;
  align-items: end;
`;
const ContainerUsers = styled.div`
  border: 1px solid #dfe0e0;
  padding: 10px;
  border-radius: 0.3rem;
  margin-bottom: 15px;
  background: #eef1f5;
  display: grid;
  grid-template-rows: repeat(28, auto);
  grid-auto-flow: column;
  // grid-template-columns: auto auto auto;
  height: 50vh;
  overflow: auto;
`;

const GeneralContainer = styled(EditorContainer)` 
  overflow: 'inherit';
  row-gap: 5px;
  display: grid;
`;

const TextStatus = styled.span`
  visibility: visible;
  margin-left: 30px;
  align-items: center;
  display: flex;
  font-weight: 700;
  font-size: 20px;
`;

const typeMessage = {
  first: {
    display_name: 'Інформаційне',
    value: 'info',
  },
  second: {
    display_name: 'Попереджаюче',
    value: 'important',
  },
};

const typeSends = {
  all: 0,
  choose: 1,
};

class SendMessageEditor extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const d = new Date();
    this.state = {
      cdate: `${d}`,
      type: typeMessage.first.value,
      textMessage: null,
      addActualDate: false,
      typeSend: typeSends.all,
      profile: [],
      statusText: '',
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    const { authF } = this.props;
    api.get$('/api/core/profiles/', authF).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          this.setState({
            users: d.map((u) => ({ ...u, checked: false })),
          });
        });
      } else {
        this.setState({
          users: [],
        });
      }
    });
  };

  submitform = () => {
    const {
      type, textMessage, profile, users, typeSend, cdate, addActualDate,
    } = this.state;
    const { authF } = this.props;
    // eslint-disable-next-line no-unused-expressions
    (typeSend === typeSends.all ? profile.push('__all__') : users.map((uu) => uu.checked && profile.push(uu.id)));
    const Forma = {
      type_message: type,
      text: textMessage,
      profile_list: profile,
      actual_date: addActualDate ? cdate : null,
    };
    api.post$('/api/messages/', authF, Forma).then((r) => {
      this.show();
      this.getUsers();
      if (r.ok) {
        this.setState({
          profile: [],
          textMessage: null,
          typeSend: typeSends.all,
          status: true,
          statusText: 'Повідомлення віправлені!',
          addActualDate: false,
          type: typeMessage.first.value,
        });
      } else {
        this.setState({
          status: false,
          statusText: 'Виникла помилка! Перевірте правильність заповнення.',
        });
      }
    });
  };

 show = () => {
   document.getElementById('header').style.visibility = 'visible';
   setTimeout(() => this.hide(), 4000);
 };

  hide =() => {
    document.getElementById('header').style.visibility = 'hidden';
  };


  render() {
    const {
      status, statusText,
      users, type, textMessage, typeSend, addActualDate,
      cdate,
    } = this.state;
    return (
      <div>
        <HeaderText>Надіслати повідомлення користувачам</HeaderText>
        <GeneralContainer>
          <ContainerGrid>
            <EditorControls.Selector
              label="Тип повідомлення"
              values={Object.values(typeMessage)}
              value={type}
              onChange={(e, v) => {
                this.setState({ type: v });
              }}
            />
            <EditorControls.CheckboxInput
              style={{ margin: '0 0 5px' }}
              label="Зазначити дату актуальності повідомлення"
              value={addActualDate}
              onChange={(e, v) => this.setState({ addActualDate: v })}
            />
            <div>
              {addActualDate
            && (
              <EditorControls.DateInput
                label="Дата актуальності"
                value={cdate}
                onChange={(e, v) => this.setState({ cdate: v })}
              />
            )}
            </div>
          </ContainerGrid>
          <EditorControls.TextInput
            label="Повідомлення"
            value={textMessage}
            onChange={(e, v) => {
              this.setState({ textMessage: v });
            }}
          />
          <EditorControls.Radio
            label="Надіслати повідомлення"
            values={[
              { value: typeSends.all, display_name: 'всім користувачам' },
              { value: typeSends.choose, display_name: 'обраним' },
            ]}
            value={typeSend}
            group="typeSend"
            onChange={(e, v) => this.setState({ typeSend: v })}
          />
          {typeSend
            ? (
              <ContainerUsers>
                {users && users.map((u) => (
                  <div key={u.id}>
                    <EditorControls.CheckboxInput
                      value={u.checked}
                      onChange={(e, v) => this.setState({
                        users: users.map((uu) => uu.id === u.id ? { ...uu, checked: v }
                          : uu),
                      })}
                    />
                    {({ ...u.org }).name}
                    {(({ ...u.user }).first_name || ({ ...u.user }).last_name)
                        && (
                          <span style={{
                            color: 'gray',
                            fontSize: '14px',
                            fontStyle: 'italic',
                          }}
                          >
                            &nbsp;
                            (
                            {({ ...u.user }).first_name}
                            {({ ...u.user }).last_name && ' ' }
                            {({ ...u.user }).last_name}
                            )
                          </span>
                        )}
                  </div>
                ))}
              </ContainerUsers>
            ) : null}
          <div style={{ display: 'flex' }}>
            <SendButton
              onClick={this.submitform}
            />
            <TextStatus id="header" style={status ? { color: '#008F21' } : { color: 'red' }}>{statusText}</TextStatus>
          </div>
        </GeneralContainer>
      </div>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(SendMessageEditor);
