import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import OrderColumn, { orderPropType } from './orderColumn';
import { StyledList } from '../styles';

class OrderColumns extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(orderPropType).isRequired,
    onDoubleClick: PropTypes.func,
    activeRow: PropTypes.number,
    onActivateRow: PropTypes.func.isRequired,
    onChangeColumn: PropTypes.func,
  };

  static defaultProps = {
    onDoubleClick: () => null,
    activeRow: null,
    onChangeColumn: () => null,
  };

  render() {
    const {
      columns, onDoubleClick, activeRow, onActivateRow, onChangeColumn,
    } = this.props;
    return (
      <Droppable droppableId="ORDER">
        {(providedDrop) => (
          <StyledList ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            {columns.map((column, index) => (
              <OrderColumn
                // eslint-disable-next-line
                key={index}
                column={column}
                active={index === activeRow}
                onClick={onActivateRow}
                onDoubleClick={onDoubleClick}
                onChangeColumn={onChangeColumn}
                index={index}
              />
            ))}
            {providedDrop.placeholder}
          </StyledList>
        )}
      </Droppable>
    );
  }
}

export default OrderColumns;
