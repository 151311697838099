import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels, EditorControls } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "code  ratio" "name  name" " not_import412  not_import412" "parent parent" "state_tax state_tax" "local_tax local_tax" ;
  grid-template-columns: 150px 1fr;
  
  @media (max-width: 600px) {
  display: block;
  }
`;

const editorRenderer = (getControl, { data, dataSetter }) => (
  <ThisDocGrid>
    <GridItem area="code">
      {getControl('code')}
    </GridItem>
    <GridItem area="name">
      {getControl('name')}
    </GridItem>
    <GridItem area="ratio">
      {getControl('ratio')}
    </GridItem>
    <GridItem area="not_import412">
      {getControl('not_import412')}
    </GridItem>
    <GridItem area="parent">
      <EditorControls.ItemPicker
        label="Батьківський елемент"
        backend="/api/catalog/kdbs/"
        canErase
        value={data.parent}
        onChange={(e, v) => dataSetter({ parent: v })}
        mode="no-repr"
      />
    </GridItem>
    <GridItem area="state_tax">
      {getControl('state_tax')}
    </GridItem>
    <GridItem area="local_tax">
      {getControl('local_tax')}
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/catalog/kdbs/', CommandPanels.catCommandPanel);
