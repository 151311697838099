import React from 'react';
import PropTypes from 'prop-types';
import { StyledCellNoData } from './styles';
import { ListUp } from '../Styled/Buttons/icons';

const TableRowPrevPage = ({ colCount, onClick }) => (
  <StyledCellNoData
    gridArea={`2 / 1 / 2 / ${colCount + 1}`}
    onClick={onClick}
  >
    <img src={ListUp} width="20px;" alt="Попередня сторінка" />
  </StyledCellNoData>
);

TableRowPrevPage.propTypes = {
  colCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TableRowPrevPage;
