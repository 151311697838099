import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import AvailableFieldList from '../available';
import {
  Button,
} from '../../../../components/Styled/Buttons';
import itemTypes from '../itemTypes';
import OrdersList from './ordersList';
import { availableOrdersPropType, getPreviousOrder, ordersPropType } from '../../hooks';
import {
  StyledPanel, SColLeft, SColRight, SColButtons,
} from '../styles';

const OrdersEditor = ({ availableOrders, orders, ordersHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);

  const [openedColumns, setOpenedColumns] = useState([]);

  const visibleColumns = useMemo(() => availableOrders.reduce((R, col) => [
    ...R,
    col.parent === null && { ...col, lvl: 0 },
    ...availableOrders.filter((col2) => col2.parent === col.name && openedColumns
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1 })),
  ], []).filter((col) => col), [availableOrders, openedColumns]);

  const onActiveSelectionHandler = useCallback(
    (oname) => setActiveOrder(oname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const onSetOpenedColumns = useCallback((val) => {
    if (openedColumns.includes(val)) {
      return setOpenedColumns((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedColumns((prev) => ([...prev, val]));
  }, [openedColumns]);

  const ordersDragEndHanlder = useCallback(
    ({ name, type }, oname) => {
      if (type === itemTypes.availableField) {
        ordersHandlers.addOrderHandler(name, oname);
      } else if (type === itemTypes.order) {
        ordersHandlers.swapOrderHandler(name, oname);
      }
    },
    [ordersHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.order) {
        ordersHandlers.removeOrderHandler(name);
        if (activeOrder === name) setActiveOrder(null);
      }
    },
    [activeOrder, ordersHandlers],
  );


  return (
    <StyledPanel>
      <SColLeft>
        <AvailableFieldList
          fields={availableOrders}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={ordersHandlers.addOrderHandler}
          label="Доступні поля"
          onDragEnd={avaDragEndHanlder}
          setOpenedColumns={onSetOpenedColumns}
          visibleColumns={visibleColumns}
          openedColumns={openedColumns}
        />
      </SColLeft>
      <SColButtons>
        <Button
          onClick={() => ordersHandlers.clearAllOrdersHandler()}
          disabled={!orders.length}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button
          onClick={() => {
            ordersHandlers.removeOrderHandler(activeOrder);
            setActiveOrder(getPreviousOrder(orders, activeOrder));
          }}
          disabled={activeOrder === null}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          onClick={() => ordersHandlers.addOrderHandler(activeAvaField)}
          disabled={!activeAvaField}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </SColButtons>
      <SColRight>
        <OrdersList
          orders={orders}
          activeOrder={activeOrder}
          onClick={onActiveSelectionHandler}
          onDblClick={ordersHandlers.removeOrderHandler}
          onDragEnd={ordersDragEndHanlder}
          onDirectionChange={ordersHandlers.changeOrderDirectionHandler}
        />
      </SColRight>
    </StyledPanel>
  );
};

OrdersEditor.propTypes = {
  orders: ordersPropType.isRequired,
  availableOrders: availableOrdersPropType.isRequired,
  ordersHandlers: PropTypes.shape({
    addOrderHandler: PropTypes.func,
    removeOrderHandler: PropTypes.func,
    swapOrderHandler: PropTypes.func,
    clearAllOrdersHandler: PropTypes.func,
    changeOrderDirectionHandler: PropTypes.func,
  }).isRequired,
};

export default OrdersEditor;
