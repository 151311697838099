import React, { useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  DivStyled,
} from '../../components/Styled/Input';
import { DropdownButton } from '../../components/Styled/Buttons/DrowdownButton';
import {
  DivDropStyled, DivInnerDropStyled, ClickerWrapper, Spiner,
} from '../../components/Styled/Misc';
import { CheckedIcon } from '../../components/Styled/Buttons/icons';

const DropDownIcon = styled.img.attrs((props) => props.src)`
  height: 16px;
  margin-right: 5px;
`;

const InputDiv = styled.div`
  width: 100%;
`;

const DivRow = styled(DivInnerDropStyled)`
  ${
  // eslint-disable-next-line
  props => props.active ? 'font-weight: 700' : 'font-weight: 400'};
  ${
  // eslint-disable-next-line
  props => props.level ? `padding-left: ${(props.level + 1) * 10}px` : ''};
`;

export const valuePropType = PropTypes
  .oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]);

const Multiselector = ({
  onChange, disabled, readOnly, noBorder, value, loading, values, className,
}) => {
  const [listOpened, setListOpened] = useState(false);
  const dValues = useMemo(
    () => values.reduce((R, v) => ({ ...R, [v.value]: v.display_name }), {}),
    [values],
  );
  return (
    <div style={{ position: 'relative' }} className={className}>
      <DivStyled noBorder={noBorder}>
        <InputDiv>
          {value && value.map((v) => (
            <span>
              {dValues[v]}
            </span>
          ))}
        </InputDiv>
        {loading && (
          <Spiner />
        )}
        {!readOnly && !disabled ? (
          <DropdownButton onClick={() => setListOpened(!listOpened)} />
        ) : null}
      </DivStyled>
      {listOpened ? (
        <>
          <DivDropStyled>
            {values.map((v) => (
              <DivRow
                key={v.value}
                active={v.value === value}
                level={v.level}
                disabled={v.disabled}
                onClick={(e) => {
                  if (!v.disabled) {
                    if (value && value.includes(v.value)) {
                      onChange(e, value.filter((vv) => vv !== v.value));
                      console.log('delete');
                    } else {
                      onChange(e, [...(value || []), v.value]);
                      console.log('add');
                    }
                    setListOpened(false);
                  }
                }}
              >
                {value && value.includes(v.value) && <DropDownIcon src={CheckedIcon} />}
                {v.display_name}
              </DivRow>
            ))}

          </DivDropStyled>
          {createPortal((
            <ClickerWrapper onClick={() => setListOpened(false)} />
          ), document.body)}
        </>
      ) : null}
    </div>
  );
};

Multiselector.propTypes = {
  // eslint-disable-next-line
  value: PropTypes.arrayOf(valuePropType),
  // eslint-disable-next-line
  values: PropTypes.arrayOf(PropTypes.shape({
    value: valuePropType,
    disabled: PropTypes.bool,
    display_name: PropTypes.string,
    level: PropTypes.number,
    icon: PropTypes.symbol,
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line
  maybeNull: PropTypes.bool,
  noBorder: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

Multiselector.defaultProps = {
  value: null,
  values: [],
  onChange: () => null,
  disabled: false,
  readOnly: false,
  maybeNull: false,
  noBorder: false,
  loading: false,
  className: null,
};

export default Multiselector;
