import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledButton } from './styledCommandPanelButton';
import { Modal } from '../Misc/Modal';
import { CloseButton } from './CloseButton';
import { SaveButton } from './SaveButton';
import api from '../../../api/req';
import { mapStateAuth, withAuthConsumer } from '../../../providers/authProvider';
import { GridItem } from '../grids/editorGrid';
import StringInput from '../../../containers/BaseEditor/stringInput';

const StyledEditButton = styled(StyledButton)`
  height: 2.5em;
`;

export const StyledText = styled.div`
 display: initial;
 margin-left: 5px;
`;
const GridPassword = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    margin-top: 15px;
`;
const TextStatus = styled.span`
  visibility: visible;
`;

class SetPasswordButton extends PureComponent {
    static propTypes = {
      authF: PropTypes.func.isRequired,
      userid: PropTypes.number.isRequired,
      content: PropTypes.string,
      disabled: PropTypes.bool,
      active: PropTypes.bool.isRequired,
      onSuccess: PropTypes.func,
    };

    static defaultProps = {
      content: 'Встановити пароль',
      disabled: false,
      onSuccess: null,
    };

    constructor() {
      super();
      this.state = {
        isVisible: false,
        password: null,
        checkPassword: null,
        statusText: '',
        statusActive: '',
        isActive: false,
      };
    }

    setPassword = () => {
      const {
        password, checkPassword, isVisible,
      } = this.state;
      const { authF, userid, onSuccess } = this.props;
      const Forma = {
        new_password: password,
        confirm_password: checkPassword,
      };
      api.post$(`/api/core/user/${userid}/set_password/`, authF, Forma).then((r) => {
        setTimeout(() => this.setState({ statusText: '' }), 4000);
        if (r.ok) {
          this.setState({
            password: null,
            checkPassword: null,
            statusText: 'Пароль встановлений!',
          });
          this.setState({ isVisible: !isVisible });
          if (onSuccess) onSuccess();
        } else {
          this.setState({
            statusText: 'Виникла помилка! Перевірте правильність заповнення.',
          });
        }
      });
    };

    setActive = () => {
      const {
        isActive,
      } = this.state;
      const {
        authF, userid, active, onSuccess,
      } = this.props;
      this.setState({ isActive: !isActive });
      const Forma = {
        is_activce: isActive,
      };
      if (!active) {
        api.put(`/api/core/user/${userid}/set_active/`, authF, Forma).then((r) => {
          setTimeout(() => this.setState({ statusActive: '' }), 4000);
          if (r.ok) {
            this.setState({
              // statusActive: 'Активовано!',
            });
            if (onSuccess) onSuccess();
          } else {
            this.setState({
              statusActive: 'Виникла помилка! Спершу встановіть пароль.',
            });
          }
        });
      } else {
        api.delete(`/api/core/user/${userid}/set_active/`, authF, Forma).then((r) => {
          if (r.ok) {
            this.setState({
              // statusActive: 'Деактивовано!',
            });
            if (onSuccess) onSuccess();
          } else {
            this.setState({
              statusActive: 'Виникла помилка! Перевірте правильність заповнення.',
            });
          }
        });
      }
    };

    render() {
      const {
        content,
        disabled,
        userid,
        active,
      } = this.props;
      const {
        isVisible,
        password,
        checkPassword,
        statusText,
        isActive,
        statusActive,
      } = this.state;
      return (
        <div>
          <StyledEditButton
            onClick={() => {
              this.setState({ isVisible: !isVisible });
            }}
            disabled={disabled}
            title={content}
            content={content}
            userid={userid}
          >
            <StyledText>{content}</StyledText>
          </StyledEditButton>
          <StyledEditButton
            onClick={() => {
              this.setActive();
            }}
            disabled={!userid}
            title={isActive ? 'Деактивувати' : 'Активувати'}
            active={active}
          >
            <StyledText>{active ? 'Деактивувати' : 'Активувати'}</StyledText>
          </StyledEditButton>
          <TextStatus>{statusActive}</TextStatus>
          {isVisible
            && (
              <Modal open={isVisible}>
                <div>
                  <h3>Встановити пароль</h3>
                  <GridPassword>
                    <GridItem>
                      <StringInput
                        label="Пароль"
                        value={password}
                        onChange={(e, v) => {
                          this.setState({ password: v });
                        }}
                        password
                      />
                    </GridItem>
                    <GridItem>
                      <StringInput
                        errors={password !== checkPassword ? ['Паролі не співпадають'] : null}
                        errored={password !== checkPassword}
                        label="Повторіть пароль"
                        value={checkPassword}
                        onChange={(e, v) => {
                          this.setState({ checkPassword: v });
                        }}
                        password
                      />
                    </GridItem>
                  </GridPassword>
                  <SaveButton
                    onClick={this.setPassword}
                  />
                  <CloseButton onClick={() => this.setState({ isVisible: !isVisible })} />
                  <TextStatus>{statusText}</TextStatus>
                </div>
              </Modal>
            )}
        </div>
      );
    }
}


export default withAuthConsumer(mapStateAuth)(SetPasswordButton);
