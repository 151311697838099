import Styled from 'styled-components';

export const StyledList = Styled.div`
  background: white;
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-height: 60px;
`;

const activeStyle = {
  background: 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21',
};

export const StyledItem = Styled.div.attrs((props) => ({
  style: props.active ? activeStyle : {},
}))`
  cursor: pointer;
  & > div {
    width: fit-content;
    // max-width: 100%;
    padding: 3px;
  }
`;

export const StyledHeader = Styled.h3`
  color: green;
  font-weight: 700; 
`;

export const StyledContainer = Styled.div`
  display: flex;
  border-radius: 0.3rem;
  padding: 5px;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const StyledSubContainer = Styled.div`
  display: flex;
  padding: 5px;
  flex: 1 1 auto;
  &>:first-child{
    flex: 1 1 auto;
  }
  &>:last-child{
    flex: 8 8 auto;
  }
  
  @media (max-width: 900px) {
  flex-direction: column;
  }
`;

export const getDraggableProps = (providedDrag, snapshot) => {
  const newStyle = snapshot.isDragging
    ? {
      ...providedDrag.draggableProps.style,
      background: 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21',
      borderRadius: '0.3em',
    } : providedDrag.draggableProps.style;
  return { ...providedDrag.dragHandleProps, ...providedDrag.draggableProps, style: newStyle };
};

export const VerticalCP = Styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  & > button{
    margin-right: 0;
  }  
  
  @media (max-width: 900px) {
  transform: rotate(90deg);  
  }
`;

export const OrderColumnIcon = Styled.img`
  padding-right: 10px;
  transform: scale(1.5);
  transition: all 0.5s ease-out
  :hover {
    transform: scale(1.6);
  }
`;

export const ReportContainer = Styled.div.attrs({
  className: 'fullscreenContainer fullscreenParent',
})`
`;
