import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableControls } from '../../BaseEditor';

const StyleTD = styled.td`
  padding: 2px 10px;
`;

const Row = ({
  row, rowChange, rowSave,
}) => {
  const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

  return (
    <>
      <tr
        style={{ background: row.save ? '#baf3bab3' : 'white' }}
      >
        <StyleTD rowSpan="2" style={{ textAlign: 'center' }}>
          <TableControls.CheckboxInput
            noMargin
            value={row.save}
            onChange={() => rowSave(row.id, { save: !row.save })}
          />
        </StyleTD>
        <td>
          <TableControls.ItemPicker
            label={null}
            backend="/api/catalog/payers/"
            canErase
            value={row.payer}
            onChange={(e, v) => rowChange(row.id, { payer: v })}
          />
        </td>
        <td>
          <TableControls.ItemPicker
            label={null}
            backend="/api/catalog/contracts/"
            canErase
            value={row.contract}
            onChange={(e, v) => rowChange(row.id, { contract: v })}
          />
        </td>
        <StyleTD style={{ textAlign: 'right' }}>
          {formatter(row.order_amount)}
        </StyleTD>
        <StyleTD style={{ textAlign: 'right' }}>
          {formatter(row.revenue_amount)}
        </StyleTD>
      </tr>
      <tr
        style={{ background: row.save ? '#baf3bab3' : 'white' }}
      >
        <StyleTD colSpan="4">
          {row.payment_notes}
        </StyleTD>
      </tr>
    </>

  );
};
Row.propTypes = {
  row: PropTypes.shape().isRequired,
  rowChange: PropTypes.func.isRequired,
  rowSave: PropTypes.func.isRequired,
};


export default memo(Row);
