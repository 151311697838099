import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';
import { FilterIcon } from './icons';

export const FiltersButton = ({ content, onClick, disabled }) => (
  <StyledButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg style={{ width: '20px' }} src={FilterIcon} alt={content} />
    <StyledText>
      {content}
    </StyledText>
  </StyledButton>
);

FiltersButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

FiltersButton.defaultProps = {
  onClick: () => null,
  content: 'Фільтри',
  disabled: false,
};

export default FiltersButton;
