import styled from 'styled-components';

// .attrs(({ CanDrop, isOver }) => ({
//   style: {
//     ...(CanDrop ? { border: 'red  1px solid' } : {}),
//     ...(isOver ? { background: 'red' } : {}),
//   },
// }))


export const StyledFieldItem = styled.div.attrs(({ active }) => active ? {
  style: { background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), rgb(0, 143, 33)' },
} : {})`
  font-size: medium;
  &>*+* {
    margin-left: 0.5rem;
  }
`;

export const StyledSubItem = styled.div.attrs(({ padded }) => ({
  style: {
    ...(padded ? { height: '2rem' } : { height: '0' }),
  },
}))`
  transition: height 0.3s ease-out;
`;

export const StyledBadge = styled.span`
  font-weight: unset !important;
  font-size: unset !important;
`;

// export const StyledRow = styled(Row)`
//   margin: 0 !important;
// `;
//
// Левая колонка настроек отчета
export const SColLeft = styled.div`
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .3rem;
  padding: 0 1rem ;
  flex: 1 1 auto;
`;
// Правая колонка настроек отчета
export const SColRight = styled.div`
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .3rem;
  padding: 0 1rem ;
  flex: 1 1 auto;
`;

// Средняя колонка настроек отчета
export const SColButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 .5rem;
  &>button>img {
    height: 20px;
  }
`;


// Панель настроек отчета
export const StyledPanel = styled.div`
  display: flex;
`;
export const StyledFieldList22 = styled.div`
  height: 70vh;
  overflow: auto;
`;

export const StyledFieldList = styled.div.attrs(({ height }) => ({
  style: { height: `${height}px` },
}))`
  overflow: auto;
`;
