import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DivDropStyled, DivInnerDropStyled } from '../../components/Styled/Misc';

const DivContainer = styled(DivDropStyled)`
`;

// eslint-disable-next-line
const DivRow = styled(DivInnerDropStyled)`
  font-weight: ${
  // eslint-disable-next-line
  props => props.active ? '700' : 'initial'};
`;

// eslint-disable-next-line
export const DataList = ({
  data, onSelect, activeId,
}) => (
  <DivContainer>
    {data.map((row) => (
      <DivRow key={row.id} onClick={(e) => onSelect(e, row)} active={row.id === activeId}>
        {row.repr}
      </DivRow>
    ))}
  </DivContainer>
);

DataList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    repr: PropTypes.string.isRequired,
  })),
  onSelect: PropTypes.func,
  activeId: PropTypes.number,
};

DataList.defaultProps = {
  data: [],
  // eslint-disable-next-line
  onSelect: () => null,
  activeId: null,
};
