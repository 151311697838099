import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "code name ratio"
                        "org budget kdb"
                        "disabled disabled disabled"
                        ;
   
  @media (max-width: 600px) {
  display: block;
  }                      
`;

const editorRenderer = (getControl, { currentUser }) => (
  <ThisDocGrid>
    <GridItem area="code">
      {getControl('code')}
    </GridItem>
    <GridItem area="name">
      {getControl('name')}
    </GridItem>
    <GridItem area="ratio">
      {getControl('ratio')}
    </GridItem>
    {currentUser.is_superuser && (
      <GridItem area="org">
        {getControl('org', { backend: '/api/catalog/orgs/' })}
      </GridItem>
    )}
    <GridItem area="budget">
      {getControl('budget', { backend: '/api/catalog/budgets/' })}
    </GridItem>
    <GridItem area="kdb">
      {getControl('kdb', { backend: '/api/catalog/kdbs/' })}
    </GridItem>
    <GridItem area="disabled">
      {getControl('disabled')}
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/catalog/accounts/', CommandPanels.catCommandPanel);
