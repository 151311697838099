import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "marker";
  grid-template-columns: 150px 1fr;
  
  @media (max-width: 600px) {
  display: block;
  }
`;

const editorRenderer = (getControl) => (
  <ThisDocGrid>
    <GridItem area="marker">
      {getControl('marker')}
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/markers/', CommandPanels.catCommandPanel);
