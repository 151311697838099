import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DivElement } from './styles';
import { TableControls } from '../../../../BaseEditor';
import { fieldsPropType } from './PropTypes';

const DecimalInput = TableControls.getNumerInput(14, 2);

class BodyComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.shape({
      kdb: PropTypes.shape({
        id: PropTypes.number,
        full_name: PropTypes.string,
      }),
      not_import: PropTypes.bool,
      day_sum: PropTypes.number,
      accum_sum: PropTypes.number,
    }),
    fields: fieldsPropType,
    rowNumber: PropTypes.number,
    onChange: PropTypes.func,
    active: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    row: {
      kdb: {},
      not_import: false,
    },
    fields: {
      kdb: {},
    },
    rowNumber: 0,
    onChange: () => null,
    active: false,
    onClick: () => null,
  };


  render() {
    const {
      row, rowNumber, onChange, active, fields, onClick,
    } = this.props;
    const cr = (rowNumber * 2 + 3);
    const globalProps = {
      onClick: (e) => onClick(e, rowNumber),
    };
    const globalStyle = active ? {
      background: active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
    } : {};
    return (
      <>
        <DivElement
          not_import={row.not_import}
          style={{
            ...globalStyle,
            gridArea: `${cr} / kdb`,
          }}
          {...globalProps}
        >
          <TableControls.ItemPicker
            value={row.kdb}
            backend="/api/catalog/kdbs/"
            onChange={(e, v) => onChange(e, rowNumber, { kdb: v })}
            readOnly={fields.kdb.read_only}
            columns={fields.kdb.children}
            filter={{ payer: row.kdb ? row.kdb.id : null }}
            canErase
          />
        </DivElement>

        <DivElement
          not_import={row.not_import}
          style={{
            ...globalStyle,
            gridArea: `${cr} / day_sum`,
          }}
          {...globalProps}
        >
          <DecimalInput
            value={Number(row.day_sum)}
            onChange={(e, v) => onChange(e, rowNumber, { day_sum: v.toFixed(2) })}
            readOnly={fields.day_sum.read_only}
          />
        </DivElement>
        <DivElement
          not_import={row.not_import}
          style={{
            ...globalStyle,
            gridArea: `${cr} / accum_sum`,
          }}
          {...globalProps}
        >
          <DecimalInput
            value={Number(row.accum_sum)}
            onChange={(e, v) => onChange(e, rowNumber, { accum_sum: v.toFixed(2) })}
            readOnly={fields.accum_sum.read_only}
          />
        </DivElement>
      </>
    );
  }
}

export default BodyComponent;
