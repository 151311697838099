import React, { useRef, useMemo, useState } from 'react';
import { HeaderText } from '../../../components/Styled/Forms';
import {
  CommandPanel, ErrorMessage, DimmableLoader, FileUploadButton,
} from '../../../components/Styled/Misc';
import { CloseButton, NewButton, SaveButton } from '../../../components/Styled/Buttons';
import Tab from '../../../components/Tab';
import { useMaster } from './hooks';
import TablesPage from './tables';
import ParamsPage from './params';
import RelationsPage from './relations';
import FieldsPage from './fields';
import CalcsPage from './calcs';
import MetaFieldsPage from './metafields';
import SettingsPage from './settings';
import SelectSchemaButton from './selectShemaButton';
import TableBrowser from './tableBrowser';
import NewScheme from './newScheme';
import NewReport from './newReport';
import { Modal } from '../../../components/Styled/Misc/Modal';

const Editor = () => {
  const {
    err,
    tables, tableHandlers, activeTable,
    params, activeParam, paramsHandlers,
    options, onAnalyzeHandler, tableFields,
    relations, relationHandlers, activeRelation,
    fields, fieldHandlers, activeField,
    calcs, calcHandlers, activeCalc,
    metaFields, metaFieldHandlers, activeMetaField,
    masterHandlers, tableRows, loading,
    settings, settingHandlers, activeSetting,
  } = useMaster();

  const containerHeaderRef = useRef(null);
  const allowedFields = useMemo(
    () => [...new Set([
      ...fields.map((f) => f.name),
      ...calcs.map((c) => c.name),
    ])],
    [calcs, fields],
  );

  const [showSchemePage, setShowSchemePage] = useState(false);
  const [newSchemeParams, setNewSchemeParams] = useState({
    name: '', description: '', is_active: false, src: 'e30=', identifier: '',
  });

  const [showReportPage, setShowReportPage] = useState(false);
  const [newReportParams, setNewReportParams] = useState({
    schema: { id: null },
    name: '',
    description: '',
    options: {
      group: [], params: {}, order: [], fields: [],
    },
    org: null,
    user: null,
  });

  const handleChangeNewSchemeParams = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { name } = e.target;

    setNewSchemeParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeNewReportParams = (e) => {
    if (typeof (e) === 'number') {
      setNewReportParams((prevState) => ({
        ...prevState,
        schema: { id: e },
      }));
      return;
    }
    const { name, value } = e.target;
    setNewReportParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreate = (type) => {
    if (type === 'scheme') {
      masterHandlers.createNewSchema({ newSchemeParams, setShowSchemePage });
      return;
    }
    if (type === 'report') {
      masterHandlers.createNewReport({ newReportParams, setShowReportPage });
    }
  };

  return (
    <>
      <DimmableLoader loading={loading}>
        <div ref={containerHeaderRef}>
          {err && (
            <ErrorMessage text={err} />
          )}
          <HeaderText>SKD-Master</HeaderText>
          <CommandPanel>
            <SelectSchemaButton
              content="Завантажити схему"
              onSelect={(v) => masterHandlers.loadSchemaFromSrv(v)}
            />
            <SelectSchemaButton
              content="Зберегти схему"
              onSelect={(v) => masterHandlers.saveSchemaToSrv(v)}
            />
            <NewButton
              content="Створити схему"
              onClick={() => setShowSchemePage(true)}
            />
            <NewButton
              content="Створити звіт"
              onClick={() => setShowReportPage(true)}
            />
            <SaveButton
              content="Зберегти у файл"
              onClick={() => {
                const blob = new Blob([masterHandlers.saveToJSON()], { type: 'json' });
                const url = window.URL.createObjectURL(blob);
                const tempLink = document.createElement('a');
                tempLink.href = url;
                tempLink.setAttribute('download', 'shema.json');
                tempLink.click();
              }}
            />
            <FileUploadButton
              content="Завантажити з файлу"
              onChange={(e, v) => masterHandlers.loadFromJSON(Object.values(v)[0])}
            />

          </CommandPanel>
        </div>

        <Modal open={showSchemePage}>
          <>
            <NewScheme onCreate={() => { handleCreate('scheme'); }} handleChangeNewSchemeParams={handleChangeNewSchemeParams} newSchemeParams={newSchemeParams} onClose={() => setShowSchemePage(false)} />
            <CloseButton onClick={() => setShowSchemePage(false)} />
          </>
        </Modal>

        <Modal open={showReportPage} size="xl">
          <>
            <NewReport onCreate={() => { handleCreate('report'); }} handleChangeNewReportParams={handleChangeNewReportParams} newReportParams={newReportParams} onClose={() => setShowReportPage(false)} />
            <CloseButton onClick={() => setShowReportPage(false)} />
          </>
        </Modal>

        <Tab
          onTabSelect={onAnalyzeHandler}
          panes={[
            {
              menuItem: 'Таблиці',
              render: () => (
                <TablesPage
                  tables={tables}
                  onChangeTable={tableHandlers.onChangeTable}
                  onRenameTable={tableHandlers.onRenameTable}
                  onAddTable={tableHandlers.onAddTable}
                  onRemoveTable={tableHandlers.onRemoveTable}
                  activeTable={activeTable}
                  onSetActiveTable={tableHandlers.setActiveTable}
                  showModal={tableHandlers.showModal}
                  setShowModal={tableHandlers.setShowModal}
                  keyValue={tableHandlers.key}
                  setKeyValue={tableHandlers.setKey}
                />
              ),
            },
            {
              menuItem: 'Параметри',
              render: () => (
                <ParamsPage
                  params={params}
                  setActiveParam={paramsHandlers.setActiveParam}
                  activeParam={activeParam}
                  onParamChange={paramsHandlers.onParamChange}
                  allowedCtypes={options.params_ctypes}
                />
              ),
            },
            {
              menuItem: 'Зв\'язки',
              render: () => (
                <RelationsPage
                  relations={relations}
                  onSetActiveRelation={relationHandlers.setActiveRelation}
                  onAddRelation={relationHandlers.onAddRelation}
                  onChangeRelation={relationHandlers.onChangeRelation}
                  onDeleteRelation={relationHandlers.onDeleteRelation}
                  activeRelation={activeRelation}
                  tableFields={tableFields}
                />
              ),
            },
            {
              menuItem: 'Поля',
              render: () => (
                <FieldsPage
                  fields={fields}
                  onSetActiveField={fieldHandlers.setActiveField}
                  onRemoveField={fieldHandlers.onRemoveField}
                  onChangeField={fieldHandlers.onChangeField}
                  onAddField={fieldHandlers.onAddField}
                  onRenameField={fieldHandlers.onRenameField}
                  onFillFields={fieldHandlers.fillFields}
                  activeField={activeField}
                  tableFields={tableFields}
                  allowedCtypes={options.fields_ctypes}
                  allowedFilters={options.allowed_filters}
                  allowedAggregates={options.allowed_aggregates}
                />
              ),
            },
            {
              menuItem: 'Обчислювальні поля',
              render: () => (
                <CalcsPage
                  calcs={calcs}
                  onSetActiveCalc={calcHandlers.setActiveCalc}
                  onRemoveCalc={calcHandlers.onRemoveCalc}
                  onChangeCalc={calcHandlers.onChangeCalc}
                  onAddCalc={calcHandlers.onAddCalc}
                  onRenameCalc={calcHandlers.onRenameCalc}
                  activeCalc={activeCalc}
                  allowedCtypes={options.calcs_ctypes}
                  fields={fields}
                />
              ),
            },
            {
              menuItem: 'Метаполя',
              render: () => (
                <MetaFieldsPage
                  metaFields={metaFields}
                  // tableFields={}
                  onSetActiveField={metaFieldHandlers.setActiveMetaField}
                  onRemoveField={metaFieldHandlers.onRemoveField}
                  onChangeField={metaFieldHandlers.onChangeField}
                  onRenameField={metaFieldHandlers.onRenameField}
                  onAddField={metaFieldHandlers.onAddField}
                  activeMetaField={activeMetaField}
                  fields={allowedFields}
                  allowedCtypes={options.meta_ctypes}
                  allowedFonts={options.allowed_fonts}
                />
              ),
            },
            {
              menuItem: 'Просмотр таблиц',
              render: () => (
                <TableBrowser
                  params={params}
                  tables={tables}
                  tableFields={tableFields}
                  onGetTableRecords={masterHandlers.onGetTableRecords}
                  tableRows={tableRows}
                />
              ),
            },
            {
              menuItem: 'Налаштування',
              render: () => (
                <SettingsPage
                  settings={settings}
                  onChangeSetting={settingHandlers.onChangeSetting}
                  onRenameSetting={settingHandlers.onRenameSetting}
                  onAddSetting={settingHandlers.onAddSetting}
                  onRemoveSetting={settingHandlers.onRemoveSetting}
                  activeSetting={activeSetting}
                  onSetActiveSetting={settingHandlers.setActiveSetting}
                />
              ),
            },
          ]}
        />
      </DimmableLoader>
    </>
  );
};

export default Editor;
