import React from 'react';
import { DivHeader } from './styles';
import { fieldsPropType } from './PropTypes';


const HeaderComponent = ({ fields }) => (
  <>
    {/* <DivHeader area="payment_number"> */}
    {/*  {fields.payment_number.label} */}
    {/* </DivHeader> */}
    {/* <DivHeader2 area="payment_notes"> */}
    {/*  {fields.payment_notes.label} */}
    {/* </DivHeader2> */}

    <DivHeader area="kdb">
      {fields.kdb.label}
    </DivHeader>

    <DivHeader area="accum_sum">
      {fields.accum_sum.label}
    </DivHeader>
    <DivHeader area="day_sum">
      {fields.day_sum.label}
    </DivHeader>
  </>
);

HeaderComponent.propTypes = {
  fields: fieldsPropType,
};

HeaderComponent.defaultProps = {
  fields: {},
};
export default HeaderComponent;
