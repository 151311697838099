import React from 'react';
import PropTypes from 'prop-types';
import { DrowDownIcon } from './icons';
import { StyledDropDownButton, StyledImg } from './styledCommandPanelButton';

export const DropdownButton = ({ content, onClick, disabled }) => (
  <StyledDropDownButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={DrowDownIcon} alt={content} />
  </StyledDropDownButton>
);

DropdownButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

DropdownButton.defaultProps = {
  onClick: () => null,
  content: 'Показати',
  disabled: false,
};

// eslint-disable-next-line
export default DropdownButton;
