import getLister, { CommandPanels } from '../../../containers/lister';
import TableComponent from '../../../components/TableComponent';

const backendPrefix = '/api/catalog/';
const frontendPrefix = '/cat/';

const defalutListCommandPanel = CommandPanels.catCommandPanel;

const defalutSelectCommandPanel = CommandPanels.catSelectCommandPanel;

const cats = ['orgs', 'budgets', 'industries', 'kdbs', 'payers', 'paymentskins', 'accounts', 'regions', 'provinces', 'landcategories', 'inflationindex'];

/**
 * Тип описывающий справочник
 * @typedef {Object} CatDef
 * @property {string} backend
 * @property {string} frontend
 * @property {func} lister
 * @property {func} selector
 */

/**
 * @const
 * @type {object}
 * @property {CatDef} orgs
 * @property {CatDef} budgets
 * @property {CatDef} contracts
 * @property {CatDef} industries
 * @property {CatDef} kdbs
 * @property {CatDef} payers
 * @property {CatDef} paymentskins
 * @property {CatDef} accounts
 */
export const catalogs = cats.reduce((R, cat) => ({
  ...R,
  [cat]: {
    backend: `${backendPrefix}${cat}/`,
    frontend: `${frontendPrefix}${cat}/`,
    lister: getLister(TableComponent, `${backendPrefix}${cat}/`, defalutListCommandPanel),
    selector: getLister(TableComponent, `${backendPrefix}${cat}/`, defalutSelectCommandPanel),
  },
}),
{
  markers: {
    backend: '/api/markers/',
    frontend: `${frontendPrefix}markers/`,
    lister: getLister(TableComponent, '/api/markers/', defalutListCommandPanel),
    selector: getLister(TableComponent, '/api/markers/', defalutSelectCommandPanel),
  },
  schema: {
    backend: '/api/repos/schema/',
    frontend: `${frontendPrefix}schema/`,
    lister: getLister(TableComponent, '/api/repos/schema/', defalutListCommandPanel),
    selector: getLister(TableComponent, '/api/repos/schema/', defalutSelectCommandPanel),
  },
  attributes: {
    backend: '/api/attributes/attributes/',
    frontend: `${frontendPrefix}attributes/`,
    lister: getLister(TableComponent, '/api/attributes/attributes/', defalutListCommandPanel),
    selector: getLister(TableComponent, '/api/attributes/attributes/', defalutSelectCommandPanel),
  },
});

export default catalogs;
