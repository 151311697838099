import { useCallback, useState } from 'react';
import api from '../../../api/req';
import { RENDER_API_URL } from './consts';
import { checkTask } from '../../../api/checktask';

// eslint-disable-next-line import/prefer-default-export
export const useGenerateReport = (
  reportId,
  auth,
  groups,
  selections,
  orders,
  filters,
  columns,
  param,
  onStart,
  onEnd,
  onDone,
  onError,
  renderer = 'json',
) => {
  const [taskId, setTaskId] = useState(null);
  return {
    onGenerateReport: useCallback(() => {
      const loader = async () => {
        const plainGroups = groups.reduce(
          (R, g) => [...R, ...Object.keys(g)],
          [],
        );
        const plainColumns = columns.reduce(
          (R, g) => [...R, ...Object.keys(g)],
          [],
        );
        const gc = new Set([...plainColumns, plainGroups]);
        const fields = [...[...gc].map((g) => [g, null]), ...selections];
        const params = {
          report: reportId,
          render: renderer,
          details: false,
          params: param,
          options: {
            group: groups,
            column: columns,
            order: orders,
            filter: filters,
            fields,
          },
        };
        const r = await api.post(RENDER_API_URL, auth, params);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        const { task_id } = await r.json();
        setTaskId(task_id);
        return task_id;
      };
      onStart();
      loader()
        .then((d) => checkTask(d, auth, (res) => {
          res.result.errors ? onError(res.result.errors[0]) : onDone(res.result);
          res.status !== 'PENDING' && onEnd();
        },
        (e) => {
          onError(e ? e.message : 'Скасовано');
          onEnd();
        }))
        .catch((e) => {
          onError(e.message);
          onEnd();
        });
    }, [
      onStart,
      groups,
      columns,
      selections,
      reportId,
      renderer,
      param,
      orders,
      filters,
      auth,
      onDone,
      onError,
      onEnd,
    ]),
    onAbortReport: () => {
      if (taskId) { api.delete(`${RENDER_API_URL}?task_id=${taskId}`, auth); }
    },
  };
};
