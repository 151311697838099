import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';


/**
 * Генерирует параметры, пригодные для отображения в редакторе
 * @param params {{}}
 * @returns {{}}
 */
const useDisplaysParams = (params) => useMemo(
  () => params && params,
  [params],
);

/**
 * Генерирует доступные поля параметров
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableParams = (fields) => useMemo(
  () => fields && Object.keys(fields)
    .map((name) => ({ ...fields[name], name })),
  [fields],
);

/**
 * HOOK для работы с параметрами отчетов
 * @param reportData {{options: {params: string[]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  params: Object,
 *  displayParams: Object,
 *  availableParams: Array,
 *  groupsHandlers: {
 *      changeParams: function,
 *  },
 *  }}
 */

export const useParams = (reportData, schema) => {
  const [params, setParams] = useState({});
  useEffect(() => setParams(reportData.options.params), [reportData]);

  const displayParams = useDisplaysParams(params);
  const availableParams = useAvailableParams(schema.src.params);
  const handlers = useMemo(() => {
    const changeParams = (name, value) => {
      setParams({ ...params, [name]: value });
    };


    return ({
      changeParams,
    });
  },
  [params]);

  return {
    params,
    displayParams,
    availableParams,
    paramsHandlers: handlers,
  };
};


export const availableParamPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableParamsPropType = PropTypes.arrayOf(availableParamPropType);

export const subParamPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
});

export const paramPropType = PropTypes.shape({
  name: PropTypes.string,
  items: PropTypes.arrayOf(subParamPropType),
});


export const paramsPropType = PropTypes.arrayOf(paramPropType);
