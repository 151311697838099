import PropTypes from 'prop-types';

export const fieldsPropType = PropTypes.shape({
  account: PropTypes.shape({
    label: PropTypes.string,
  }),
  payer: PropTypes.shape({
    label: PropTypes.string,
  }),
  contract: PropTypes.shape({
    label: PropTypes.string,
  }),
  payment_number: PropTypes.shape({
    label: PropTypes.string,
  }),
  payment_date: PropTypes.shape({
    label: PropTypes.string,
  }),
  payment_notes: PropTypes.shape({
    label: PropTypes.string,
  }),
  order_amount: PropTypes.shape({
    label: PropTypes.string,
  }),
  revenue_amount: PropTypes.shape({
    label: PropTypes.string,
  }),
});

export default fieldsPropType;
