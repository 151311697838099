import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import EditorControls from '../../BaseEditor/editorControls';

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 19% 19% 19%;
  grid-column-gap: 1%;
  align-items: end;
`;

const Button = styled.button`
    color: white;
    background-color: #007bff;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    width: 120px;
     &:hover{
    background-color: #065ebc
     }`;

const DKSUDates = ({
  year, setYear, getDKSUDates, logicaPingSuccess,
}) => (
  <ContainerGrid>
    <EditorControls.StringInput
      label="Рік"
      required
      value={year}
      onChange={(e) => setYear(e.target.value)}
    />

    <Button type="submit" onClick={getDKSUDates} disabled={!logicaPingSuccess}>Пошук</Button>

  </ContainerGrid>
);

DKSUDates.propTypes = {
  year: propTypes.string.isRequired,
  setYear: propTypes.func.isRequired,
  getDKSUDates: propTypes.func.isRequired,
  logicaPingSuccess: propTypes.bool.isRequired,
};

export default DKSUDates;
