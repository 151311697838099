import React from 'react';
import PropTypes from 'prop-types';
import { Question, CommandPanel, QuestionActions } from '../../../components/Styled/Misc';
import {
  NewButton, EditButton, DeleteButton, SelectAllButton, ExecuteButton, CancelExecuteButton,
} from '../../../components/Styled/Buttons';
import { PeriodDateModal } from '../../BaseEditor/dates';
import { actions } from './actions';

export const docListerCP = ({
  onActionClick, canNew, canEdit, canDelete, canExecute, canCancelExecute, period,
}) => (
  <CommandPanel>
    <NewButton onClick={(e) => onActionClick(e, actions.new)} disabled={!canNew} />
    <EditButton onClick={(e) => onActionClick(e, actions.edit)} disabled={!canEdit} />
    <Question
      actions={Object.values(QuestionActions.YesNoAction)}
      questionText="Видалити цей елемент?"
      actionHandler={(e, action) => {
        if (action === QuestionActions.YesNoAction.yes.value) onActionClick(e, actions.delete);
      }}
    >
      <DeleteButton disabled={!canDelete} />
    </Question>
    <SelectAllButton onClick={(e) => onActionClick(e, actions.selectAll)} />
    <ExecuteButton
      onClick={(e) => onActionClick(e, actions.executeSelected)}
      disabled={!canExecute}
    />
    <CancelExecuteButton
      onClick={(e) => onActionClick(e, actions.cancelExecuteSelected)}
      disabled={!canCancelExecute}
    />
    <PeriodDateModal
      period={period}
      onChange={(e, p) => onActionClick(e, actions.setPeriod, p)}
    />
  </CommandPanel>
);

docListerCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canNew: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  canExecute: PropTypes.bool,
  canCancelExecute: PropTypes.bool,
  period: PropTypes.shape({
    bDate: PropTypes.string,
    eDate: PropTypes.string,
  }),
};

docListerCP.defaultProps = {
  canNew: true,
  canEdit: true,
  canDelete: true,
  canExecute: true,
  canCancelExecute: true,
  period: {
    bDate: null,
    eDate: null,
  },
};

export default docListerCP;
