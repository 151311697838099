import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import getBaseEditor, { CommandPanels, EditorControls } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import Tab from '../../../components/Tab';
import Addr from '../addr';
import Attributes from './attributes';
import Notes from './notes';
import { AppContext } from '../../../providers/authProvider';
import api from '../../../api/req';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "code name name"
    "full_name full_name full_name"
    "industry industry region"
    "individual date_of_birth sex"
    "org org org"
    "comment comment comment"
    ;
  grid-template-columns: 130px 1fr 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;

const AddrGrid = styled(DocGrid)`
  grid-template-areas:  
    "region district locality area street house"
    "addr addr addr addr addr addr"
    ;
  grid-template-columns: repeat(6, 1fr);
`;

const ActGrid = styled(DocGrid)`
  grid-template-areas:  
    "name_of_position"
    "position"
    ;
`;

const EditorRenderer = ({
  getControl, data, dataSetter, currentUser,
}) => {
  const [bdate, setBDate] = useState(null);
  const [edate, setEDate] = useState(null);
  const [kdb, setKdb] = useState(null);
  const [notesList, setNotesList] = useState(null);
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { auth } = useContext(AppContext);

  const getNotes = useCallback((e) => {
    e.preventDefault();
    const notes = async () => {
      setErr(null);
      setIsLoading(true);
      const params = {
        bdate,
        edate,
      };
      if (kdb) {
        params.kdb_id = kdb.id;
      }
      const r = await api.post$(`/api/catalog/payers/${data.id}/get_notes/`, auth, { ...params });
      if (r.ok) {
        const d = await r.json();
        setNotesList(d);
        setIsLoading(false);
      } else {
        setErr(setErr);
        setIsLoading(false);
      }
    };
    notes();
  }, [auth, bdate, data.id, edate, kdb]);

  const renderP1 = () => (
    <ThisDocGrid>
      <GridItem area="code">
        {getControl('code')}
      </GridItem>
      <GridItem area="name">
        {getControl('name', {
          onChange: (e, v) => {
            if (!data.full_name || data.name === data.full_name) {
              dataSetter({ name: v, full_name: v });
            } else {
              dataSetter({ name: v });
            }
          },
        })}
      </GridItem>
      <GridItem area="full_name">
        <EditorControls.TextInput value={data.full_name} onChange={(e, v) => dataSetter({ full_name: v })} label="Повна назва" />
      </GridItem>
      <GridItem area="industry">
        {getControl('industry', {
          canErase: true,
          backend: '/api/catalog/industries/',
          filter: {
            org: currentUser.is_superuser ? data.org && data.org.id : currentUser.org.id,
          },
        })}
      </GridItem>
      <GridItem area="region">
        {getControl('region', {
          canErase: true,
          backend: '/api/catalog/regions/',
          filter: {
            org: currentUser.is_superuser ? data.org && data.org.id : currentUser.org.id,
          },
        })}
      </GridItem>
      <GridItem area="individual">
        {getControl('individual')}
      </GridItem>
      {data.individual && (
        <>
          <GridItem area="date_of_birth">
            {getControl('date_of_birth')}
          </GridItem>
          <GridItem area="sex">
            {getControl('sex')}
          </GridItem>
        </>
      )}
      {currentUser.is_superuser && (
        <GridItem area="org">
          {getControl('org', {
            backend: '/api/catalog/orgs/',
          })}
        </GridItem>
      )}
      <GridItem area="comment">
        <EditorControls.TextInput value={data.comment} onChange={(e, v) => dataSetter({ comment: v })} label="Коментар" />
      </GridItem>
    </ThisDocGrid>
  );
  const renderP2 = () => (
    <AddrGrid>
      <Addr
        value={{ id: data.address_uuid, repr: data.address }}
        onChange={(e, v) => dataSetter({ address: v.strAddr, address_uuid: v.id })}
      />
    </AddrGrid>
  );
  const renderP3 = () => (
    <ActGrid>
      <GridItem area="name_of_position">
        <EditorControls.TextInput value={data.name_of_position} onChange={(e, v) => dataSetter({ name_of_position: v })} label="Повне ім'я для акту" />
      </GridItem>
      <GridItem area="position">
        {getControl('position')}
      </GridItem>
    </ActGrid>
  );

  const { attributes, attributes_list: attributesList } = data;

  const panes = [
    { menuItem: 'Загальне', render: renderP1 },
    { menuItem: 'Адреса', render: renderP2 },
    { menuItem: 'Акт', render: renderP3 },
    { menuItem: 'Додаткові властивості', render: () => Attributes({ attributes, attributesList, dataSetter }) },
    {
      menuItem: 'Нотатки',
      render: () => (
        <Notes
          bdate={bdate}
          setBDate={setBDate}
          edate={edate}
          setEDate={setEDate}
          kdb={kdb}
          setKdb={setKdb}
          notesList={notesList}
          getNotes={getNotes}
          err={err}
          isLoading={isLoading}
        />
      ),
    },
  ];
  return (
    <Tab panes={panes} />
  );
};

EditorRenderer.propTypes = {
  getControl: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  dataSetter: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
};

export default getBaseEditor(
  (getControl, { data, dataSetter, currentUser }) => (
    <EditorRenderer
      getControl={getControl}
      data={data}
      dataSetter={dataSetter}
      currentUser={currentUser}
    />
  ),
  '/api/catalog/payers/', CommandPanels.catCommandPanel,
);
