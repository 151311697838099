import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DivElement } from './styles';
import { TableControls } from '../../../../BaseEditor';
import { fieldsPropType } from './PropTypes';

const DecimalInput = TableControls.getNumerInput(14, 2);

class BodyComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.shape({
      contract: PropTypes.object,
      amount: PropTypes.number,
    }),
    fields: fieldsPropType,
    rowNumber: PropTypes.number,
    onChange: PropTypes.func,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    shift: PropTypes.number,
  };

  static defaultProps = {
    row: {
      contract: {},
      amount: 0,
    },
    fields: {
      contract: {},
    },
    rowNumber: 0,
    onChange: () => null,
    active: false,
    onClick: () => null,
    shift: 0,
  };


  render() {
    const {
      row, rowNumber, onChange, active, fields, onClick, shift,
    } = this.props;
    const cr = (rowNumber * 2 + 3) - shift * 2;
    // const cr = (rowNumber * 2 + 3) ;
    const globalProps = {
      onClick: (e) => onClick(e, rowNumber),
    };
    const globalStyle = active ? {
      background: active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
    } : {};

    return (
      <>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / contract`,
          }}
          {...globalProps}
        >
          <TableControls.ItemPicker
            value={row.contract}
            backend="/api/catalog/calculatedcontracts/"
            onChange={(e, v) => onChange(e, rowNumber, { contract: v })}
            readOnly={fields.contract.read_only}
            columns={fields.contract.children}
            canErase
          />
        </DivElement>

        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / amount`,
          }}
          {...globalProps}
        >
          <DecimalInput
            value={row.amount}
            onChange={(e, v) => onChange(e, rowNumber, { amount: v })}
            readOnly={fields.amount.read_only}
          />
        </DivElement>

      </>
    );
  }
}

export default BodyComponent;
