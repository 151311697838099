import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import File from './file';
import ShowResults from './results';

const StyledFilesArea = Styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

export const StyledFiles = Styled.div`
  display: flex;
  overflow: auto;
`;


class FilesContainer extends PureComponent {
  static propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.shape({
      fileName: PropTypes.string,
      id: PropTypes.string,
      err: PropTypes.string,
    })).isRequired,
    onTaskDone: PropTypes.func,
  };

  static defaultProps = {
    onTaskDone: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTask: null,
      results: {},
      // isOpened: false,
    };
  }

  handleResult = (fileName, isOk, result) => {
    const { onTaskDone } = this.props;
    const { results } = this.state;
    if (isOk) {
      this.setState({ results: { ...results, [fileName]: result } });
    }
    onTaskDone(fileName);
  };

  handleActivateFile = (e, fileName) => {
    // const { isOpened } = this.state;
    this.setState({
      activeTask: fileName,
      // isOpened: !isOpened,
    });
  };

  render() {
    const { tasks } = this.props;
    const { activeTask, results } = this.state;
    return (
      <StyledFiles>
        <StyledFilesArea>
          {tasks.map((task) => ([(
            <File
              key={task.name}
              name={task.name}
              taskId={task.id}
              onClick={this.handleActivateFile}
              active={task.name === activeTask}
              onDone={this.handleResult}
            />),
          task.name === activeTask && (
            <ShowResults key="results" result={results[activeTask]} />
          ),
          ]))}
        </StyledFilesArea>
      </StyledFiles>
    );
  }
}

export default FilesContainer;
