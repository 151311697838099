import React from 'react';
import PropTypes from 'prop-types';
import meta, { dataTypes } from '../../../const/meta';
import getNumberInput from '../../BaseEditor/numberInput';
import Selector from '../../BaseEditor/selector';
import StringInput from '../../BaseEditor/stringInput';
import CheckBox from '../../BaseEditor/Checkbox';
import DateInput from '../../BaseEditor/dates';
import { ItemPicker } from '../../itemField';

const NumberInput = getNumberInput(18, 2);


const MultiValueControl = ({
  type, value, onChange, choices,
}) => (
  <>
    {choices && (
      <Selector
        value={value}
        onChange={onChange}
        values={choices}
      />
    )}
    {!choices && type === dataTypes.string && (
      <StringInput
      // noBorder
        value={value}
        onChange={onChange}
      />
    )}
    {!choices && type === dataTypes.number && (
      <NumberInput
      // noBorder
        value={value}
        onChange={onChange}
      />
    )}
    {!choices && type === dataTypes.decimal && (
      <NumberInput
        // noBorder
        value={value}
        onChange={onChange}
      />
    )}
    {!choices && type === dataTypes.bool && (
      <CheckBox
        value={value}
        onChange={onChange}
      />
    )}
    {!choices && type === dataTypes.date && (
      <DateInput
      // noBorder
        value={value}
        onChange={onChange}
      />
    )}
    {!choices && type.indexOf('/api/') !== -1 && (
      <ItemPicker
      // noBorder
        backend={type}
        value={value}
        onChange={onChange}
      />
    )}
  </>
);

MultiValueControl.propTypes = {
  type: PropTypes.oneOf([
    ...Object.values(dataTypes),
    ...Object.values(meta).map((m) => m.backend)]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
    }),
  ]),
  onChange: PropTypes.func,
  choices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    display_name: PropTypes.string,
  })),
};

MultiValueControl.defaultProps = {
  value: null,
  onChange: () => null,
  choices: null,
};

export default MultiValueControl;
