import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { getTPCommandPanel } from '../../../../BaseEditor';
import DateInput from '../../../../BaseEditor/dates';
import getNumberInput from '../../../../BaseEditor/numberInput';
import { Modal } from '../../../../../components/Styled/Misc';
import { HeaderText } from '../../../../../components/Styled/Forms';
import { Label } from '../../../../../components/Styled/elements';
import {
  Button, OKButton, CloseButton,
} from '../../../../../components/Styled/Buttons';
import AddSumModalButton from './addSumModalButton';

const StyledModalContainer = Styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  &>.resizable{
    flex: 1 1 auto;
  }
`;

const StyledCommandContainer = Styled.div`
  display: flex;
  border: none;
  margin: 10px 0;
`;

const StyledInputsContainer = Styled.div`
  display: flex;
  border: none;
  align-items: baseline;
  &>*{
    padding: 0 10px;
  }
  &>:first-child{
    padding-left: 0;
  }
  &>:last-child{
    padding-right: 0;
  }
`;

const SumInput = getNumberInput(14, 2);

const QuantInput = getNumberInput(3, 0, true);


class PrivateButtons extends PureComponent {
  static propTypes = {
    onActionClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      sum: 0,
      date: new Date().toISOString().slice(0, 10),
      quantOfMonth: 12,
    };
  }

  render() {
    const { onActionClick } = this.props;
    const {
      modalOpened, date, sum, quantOfMonth,
    } = this.state;
    return (
      <>
        <Button
          onClick={() => this.setState({ modalOpened: true })}
          content="Заповнити рівними долями"
        />
        <Modal open={modalOpened}>
          <StyledModalContainer>
            <HeaderText>Заповнення графіку платежів</HeaderText>
            <StyledInputsContainer className="resizable">
              <Label>Розподілити рівномірно суму</Label>
              <SumInput
                value={sum}
                onChange={(e, v) => this.setState({ sum: v })}
              />
              <Label>грн на</Label>
              <QuantInput
                value={quantOfMonth}
                onChange={(e, v) => this.setState({ quantOfMonth: v })}
              />
              <Label>місяців, починаючи з</Label>

              <DateInput
                value={date}
                onChange={(e, v) => this.setState({ date: v })}
              />
            </StyledInputsContainer>
            <StyledCommandContainer>
              <OKButton onClick={(e) => {
                this.setState({ modalOpened: false });
                onActionClick(e, 'AutomaticFill', { sum, quantOfMonth, date });
              }}
              />
              <CloseButton onClick={() => this.setState({ modalOpened: false })} />
            </StyledCommandContainer>
          </StyledModalContainer>
        </Modal>
        <AddSumModalButton
          onActionClick={onActionClick}
        />
      </>
    );
  }
}

export default getTPCommandPanel(PrivateButtons);
