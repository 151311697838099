import React, {
  useMemo, memo, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddButton,
  RemoveSelectionButton,
  DraftButton,
} from '../../../../components/Styled/Buttons';
import { CommandPanel } from '../../../../components/Styled/Misc';
import { fieldPropType } from '../hooks';
import FieldItem from './item';
import FieldProps from './props';
import { EditorContainer } from '../../../../components/Styled/Forms';
import { TwoColGrid } from '../styles';

const FieldsPage = ({
  fields, activeField, onRenameField, onChangeField,
  onAddField, onRemoveField, onSetActiveField, tableFields, allowedCtypes, allowedFilters,
  allowedAggregates, onFillFields,
}) => {
  const [height, setHeight] = useState(0);

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const displayFields = useMemo(
    () => fields.map((t, itemKey) => ({
      ...t,
      itemKey,
    })), [fields],
  );
  const fValue = useMemo(
    () => fields.filter((t) => t.name === activeField)
      .reduce((R, f) => f, {}),
    [activeField, fields],
  );
  const alowedTables = useMemo(
    () => Object.keys(tableFields)
      .map((value) => ({
        value,
        display_name: value,
      })),
    [tableFields],
  );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({
      value,
      display_name: value,
    })),
    [allowedCtypes],
  );

  const displayAlowedAggregates = useMemo(
    () => allowedAggregates.map((value) => ({
      value,
      display_name: value,
    })),
    [allowedAggregates],
  );

  return (
    <div>
      <EditorContainer>
        <CommandPanel>
          <AddButton
            title="Додати поле"
            content=""
            onClick={() => onAddField(Math.random()
              .toString(36))}
          />
          <RemoveSelectionButton
            title="Видалити поле"
            content=""
            onClick={() => onRemoveField(activeField)}
            disabled={!activeField}
          />
          <DraftButton
            title="Заполнить поями таблиц"
            onClick={onFillFields}
          />
        </CommandPanel>
        <TwoColGrid ref={containerHeaderRef} height={height}>
          <div>
            {displayFields.map((field) => (
              <FieldItem
                key={field.itemKey}
                active={field.name === activeField}
                onClick={() => onSetActiveField(field.name)}
                field={field}
                onRenameField={onRenameField}
              />
            ))}
          </div>
          <div>
            <FieldProps
              onChange={(v) => onChangeField(activeField, v)}
              value={fValue}
              readOnly={!('name' in fValue)}
              alowedTables={alowedTables}
              allowedCtypes={displayAlowedCtypes}
              tableFields={tableFields}
              allowedFilters={allowedFilters}
              allowedAggregates={displayAlowedAggregates}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>
  );
};

FieldsPage.propTypes = {
  fields: PropTypes.arrayOf(fieldPropType).isRequired,
  activeField: PropTypes.string,
  onRenameField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  onSetActiveField: PropTypes.func.isRequired,
  tableFields: PropTypes.shape({}).isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  allowedFilters: PropTypes.shape({}),
  allowedAggregates: PropTypes.arrayOf(PropTypes.string),
  onFillFields: PropTypes.func.isRequired,
};

FieldsPage.defaultProps = {
  activeField: null,
  allowedCtypes: [],
  allowedFilters: {},
  allowedAggregates: [],
};

export default memo(FieldsPage);
