import React, {
  useMemo, memo, useRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { paramPropType } from '../hooks';
import ParamsItem from './item';
import ParamsProps from './props';
import { EditorContainer } from '../../../../components/Styled/Forms';
import { TwoColGrid } from '../styles';

const ParamsPage = ({
  params,
  activeParam,
  setActiveParam,
  onParamChange,
  allowedCtypes,
}) => {
  const [height, setHeight] = useState(0);

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const displayParams = useMemo(
    () => params
      .map((param, key) => ({
        ...param,
        key,
      }))
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }),
    [params],
  );

  const activeProperties = useMemo(
    () => params.reduce((R, p) => (p.name === activeParam ? p : R), {}),
    [activeParam, params],
  );
  return (
    <div>
      <EditorContainer>
        <TwoColGrid ref={containerHeaderRef} height={height}>
          <div>
            {displayParams.map((param) => (
              <ParamsItem
                key={param.key}
                onClick={() => setActiveParam(param.name)}
                value={param}
                active={param.name === activeParam}
              />
            ))}
          </div>
          <div>
            <ParamsProps
              onChange={(v) => onParamChange(activeParam, v)}
              allowedCtypes={allowedCtypes}
              value={activeProperties}
              readOnly={!('name' in activeProperties)}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>
  );
};

ParamsPage.propTypes = {
  params: PropTypes.arrayOf(paramPropType).isRequired,
  activeParam: PropTypes.string,
  setActiveParam: PropTypes.func.isRequired,
  onParamChange: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
};

ParamsPage.defaultProps = {
  activeParam: null,
  allowedCtypes: [],
};

export default memo(ParamsPage);
