import React, {
  useMemo, memo, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddButton,
  RemoveSelectionButton,
} from '../../../../components/Styled/Buttons';
import { CommandPanel } from '../../../../components/Styled/Misc';
import { relationPropType } from '../hooks';
import RelationItem from './item';
import { StyleTable } from '../styles';
import { EditorContainer } from '../../../../components/Styled/Forms';

const RelationsPage = ({
  relations, activeRelation, tableFields,
  onAddRelation, onSetActiveRelation, onChangeRelation, onDeleteRelation,
}) => {
  const [height, setHeight] = useState(0);

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const alowedTables = useMemo(
    () => Object.keys(tableFields)
      .map((value) => ({
        value,
        display_name: value,
      })),
    [tableFields],
  );
  return (
    <div>
      <EditorContainer>
        <CommandPanel>
          <AddButton
            title="Додати зв'язок"
            content=""
            onClick={onAddRelation}
          />
          <RemoveSelectionButton
            title="Видалити зв'язок"
            content=""
            onClick={() => onDeleteRelation(activeRelation)}
            disabled={!activeRelation}
          />
        </CommandPanel>
        <div
          ref={containerHeaderRef}
          style={{
            maxHeight: `calc(100vh - ${height}px - 60px)`,
            overflow: 'auto',
          }}
        >
          <StyleTable striped bordered hover>
            <thead>
              <tr>
                <th>Таблиця</th>
                <th>Поле</th>
                <th>Таблиця</th>
                <th>Поле</th>
                <th>Обязятельное</th>
              </tr>
            </thead>
            <tbody>
              {relations.map((rel) => (
                <RelationItem
                  key={rel.key}
                  onClick={() => onSetActiveRelation(rel.key)}
                  onChange={onChangeRelation}
                  tableFields={tableFields}
                  alowedTables={alowedTables}
                  active={rel.key === activeRelation}
                  relation={rel}
                />
              ))}
            </tbody>
          </StyleTable>
        </div>
      </EditorContainer>
    </div>
  );
};

RelationsPage.propTypes = {
  relations: PropTypes.arrayOf(relationPropType).isRequired,
  activeRelation: PropTypes.string,
  onAddRelation: PropTypes.func.isRequired,
  onChangeRelation: PropTypes.func.isRequired,
  onDeleteRelation: PropTypes.func.isRequired,
  onSetActiveRelation: PropTypes.func.isRequired,
  tableFields: PropTypes.shape({}).isRequired,
};

RelationsPage.defaultProps = {
  activeRelation: null,
};

export default memo(RelationsPage);
