import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor/index';
import { StyledLabel } from '../../BaseEditor/withLabel';
import TPs from './tp';

const DocGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 2%;
  grid-row-gap: 5px;
`;

const DocGridAdapt = styled(DocGrid)`

  @media (max-width: 600px) {
  display: block;
  } 
`;
const DivElement = styled.div`
  grid-area: ${({ area }) => area};
`;


const GeneralTPColumnTypes = {
  kdb: { type: '/api/catalog/kdb/' },
  day_sum: { type: 'decimal' },
  accum_sum: { type: 'decimal' },
};

const editorRenderer = (getControl, {
  data, fields, dataSetter, options, currentUser,
}) => {
  const D = new Date(data.date);
  const localDate = D.toLocaleString('uk', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return (
    <>
      <StyledLabel>{`${options.name} #${data.id} від ${localDate}`}</StyledLabel>
      <DocGridAdapt>
        {currentUser.is_superuser && (
          <DivElement area="1/1">
            {getControl('org', { backend: '/api/catalog/orgs/' })}
          </DivElement>
        )}
        <DivElement area="1/2">
          {getControl('budget', {
            backend: '/api/catalog/budgets/',
            filter: { org: data.org ? data.org.id : null },
          })}
        </DivElement>
        <DivElement area="2/1">
          {getControl('date')}
        </DivElement>
      </DocGridAdapt>
      <TPs.GeneralTP
        data={data.warehouse_set}
        options={fields.warehouse_set}
        onChange={(e, newRecs) => dataSetter({ warehouse_set: newRecs })}
        overrideColumns={GeneralTPColumnTypes}
      />
    </>
  );
};

export default getBaseEditor(editorRenderer, '/api/document/subscriptions412/', CommandPanels.docCommandPanel);
