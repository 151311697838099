import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

const generateOName = (order, k) => `order-${k}`;

export const DIRECTIONS = {
  ascending: 'asc',
  descending: 'desc',
};
/**
 * Генерирует группировки, пригодные для отображения в редакторе
 * @param orders {[][]}
 * @param fields {{ label: string }}
 * @returns {[]}
 */
const useDisplayOrders = (orders, fields) => useMemo(
  () => orders.map(([fName, direction], k) => ({
    name: generateOName([fName, direction], k),
    label: fName in fields ? fields[fName].label : fName,
    errored: !(fName in fields),
    direction: direction === DIRECTIONS.descending ? DIRECTIONS.descending : DIRECTIONS.ascending,
  })),
  [fields, orders],
);

/**
 * Генерирует доступные поля группировок
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableOrders = (fields) => useMemo(
  () => Object.keys(fields)
    .filter((f) => fields[f].ordered).map((f) => ({ ...fields[f], name: f })),
  [fields],
);

/**
 * HOOK для работы с сорировками отчетов
 * @param reportData {{options: {orders: [][]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  orders: Array,
 *  displayOrders: Array,
 *  availableOrders: Array,
 *  ordersHandlers: {
 *      addOrderHandler: function,
 *      removeOrderHandler: function,
 *      swapOrderHandler: function,
 *      clearAllOrdersHandler: function,
 *      changeOrderDirectionHandler: function,
 *  },
 *  }}
 */

export const useOrders = (reportData, schema) => {
  const useMetaFieldsWithAttributes = () => {
    const metaFields = { ...schema.src.meta_fields };
    if (schema.attributes && Object.entries(schema.attributes).length > 0) {
      const attributes = Object.entries(schema.attributes).map((parent) => parent[1].map((i) => (
        Object.entries({
          [i.alias]: {
            ...i, parent: parent[0], name: i.label, key: i.label,
          },
        }))));
      return (Object.fromEntries(Object.entries(metaFields).concat(...attributes[0])));
    }
    return metaFields;
  };

  const metaFieldsWithAttributes = useMetaFieldsWithAttributes();

  const [orders, setOrders] = useState([]);

  useEffect(() => setOrders(reportData.options.order), [reportData]);

  const displayOrders = useDisplayOrders(orders, metaFieldsWithAttributes);
  const availableOrders = useAvailableOrders(metaFieldsWithAttributes);
  const handlers = useMemo(() => {
    const addOrderHandler = (fname, oname) => {
      const index = orders.reduce(
        (R, o, k) => (generateOName(o, k) === oname ? k : R),
        orders.length,
      );
      if (!orders.some((el) => el[0] === fname)) {
        setOrders([
          ...orders.slice(0, index),
          [fname, DIRECTIONS.ascending],
          ...orders.slice(index),
        ]);
      }
    };

    const removeOrderHandler = (oname) => setOrders(
      orders.filter((o, k) => generateOName(o, k) !== oname),
    );

    const clearAllOrdersHandler = () => setOrders([]);

    const swapOrderHandler = (fromName, toName) => {
      const from = orders.reduce((R, g, k) => (generateOName(g, k) === fromName ? k : R), null);
      const to = orders.reduce(
        (R, g, k) => (generateOName(g, k) === toName ? k : R),
        orders.length,
      );

      if (from > to) {
        setOrders([
          ...orders.slice(0, to),
          orders[from],
          ...orders.slice(to, from),
          ...orders.slice(from + 1),
        ]);
      }
      if (from < to) {
        setOrders([
          ...orders.slice(0, from),
          ...orders.slice(from + 1, to),
          orders[from],
          ...orders.slice(to),
        ]);
      }
    };

    const changeOrderDirectionHandler = (oname, newDirection) => setOrders(
      orders.map(([fName, direction], k) => (generateOName([fName, direction], k) !== oname
        ? [fName, direction] : [fName, newDirection])),
    );

    return ({
      addOrderHandler,
      removeOrderHandler,
      swapOrderHandler,
      clearAllOrdersHandler,
      changeOrderDirectionHandler,
    });
  },
  [orders]);

  return {
    orders,
    displayOrders,
    availableOrders,
    ordersHandlers: handlers,
  };
};


export const availableOrderPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableOrdersPropType = PropTypes.arrayOf(availableOrderPropType);

export const orderPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
});

export const getPreviousOrder = (displayOrders, name) => {
  const index = displayOrders.reduce((R, o, k) => (o.name === name ? k : R), 0);
  if (!index) return null;
  return displayOrders[index - 1].name;
};

export const ordersPropType = PropTypes.arrayOf(orderPropType);
