import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { paramPropType } from '../hooks';
import EditorControls from '../../../BaseEditor/editorControls';

const ParamsProps = ({
  value, readOnly, onChange, allowedCtypes,
}) => {
  const ctypeValues = useMemo(
    () => allowedCtypes.map((ac) => ({ value: ac, display_name: ac })),
    [allowedCtypes],
  );
  return (
    <div className="containerField p-3 border rounded">
      <EditorControls.StringInput
        controlId="param_label_input"
        label="Label"
        required
        description="Представление параметра для пользователя"
        value={value.label}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ label: v })}
      />
      <EditorControls.Selector
        controlId="param_ctype_input"
        label="Тип"
        required
        description="Описание типа параметра"
        value={value.ctype}
        values={ctypeValues}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ ctype: v })}
      />
    </div>
  );
};

ParamsProps.propTypes = {
  value: paramPropType.isRequired,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
};

ParamsProps.defaultProps = {
  readOnly: false,
  allowedCtypes: [],
};

export default ParamsProps;
