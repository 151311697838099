import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import api from '../../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { ExecuteButton } from '../../../components/Styled/Buttons';
import {
  ErrorMessage, DimmableLoader,
} from '../../../components/Styled/Misc';
import { HeaderText, EditorContainer } from '../../../components/Styled/Forms';

const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 29% 69%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;

class ContactPrintForm extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    this.state = {
      org: currentUser.org ? { id: currentUser.org.id, repr: currentUser.org.repr } : null,
      errorText: '',
      isProcessing: false,
      isErrored: false,
      currentDate: new Date().toISOString()
        .slice(0, 10),
      account: null,
      payer: null,
      contract: null,
      payments: null,
      file: null,
      type: 'receipt',
    };
  }

  startProcessing = async () => {
    const { authF } = this.props;
    const {
      org, currentDate, account, payer, contract, payments, type,
    } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
      file: null,
    });

    const options = {
      org: { id: org.id },
      date: currentDate,
      act: type === 'act',
    };

    if (account) { options.account = account; }

    if (payer) { options.payer = payer; }

    if (contract) { options.contract = contract; }

    if (payments) { options.payments = payments; }

    const r = await api.post$('/api/service/contractprintform/ ', authF, options);

    if (r.ok) {
      const d = await r.json();
      const encodedfile = d.file.split(',')[1];
      const decodedFile = this.b64DecodeUnicode(encodedfile);

      this.setState({
        isProcessing: false,
        file: decodedFile,
      });

      if (decodedFile) {
        window.frames['print-area'].print();
      }
    } else {
      const d = await r.json();

      this.setState({
        errorText: `${r.status} ${d.error.map((e, idx) => d.error[idx])}`,
        isErrored: true,
        isProcessing: false,
      });
    }
  };

  b64DecodeUnicode = (str) => decodeURIComponent(atob(str).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))

  render() {
    const { currentUser } = this.props;
    const {
      org, isProcessing, isErrored, errorText, currentDate, account,
      payer, contract, payments, file, type,
    } = this.state;

    return (
      <DimmableLoader loading={isProcessing}>
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <div>
          <HeaderText>Отримання друкованої форми</HeaderText>
          <EditorContainer style={{ overflow: 'inherit' }}>
            <TwoColGrid>
              <EditorControls.DateInput
                value={currentDate}
                onChange={(e, v) => this.setState({ currentDate: v })}
                label="Дата"
              />
              {currentUser.is_superuser && (
                <EditorControls.ItemPicker
                  label="Установа"
                  value={org}
                  backend="/api/catalog/orgs/"
                  onChange={(e, v) => this.setState({
                    org: v,
                  })}
                />
              )}
            </TwoColGrid>
            <TwoColGrid>
              <EditorControls.ItemPicker
                label="Рахунок"
                value={account}
                backend="/api/catalog/accounts/"
                onChange={(e, v) => this.setState({ account: v })}
              />
              <EditorControls.ItemPicker
                label="Платник"
                value={payer}
                backend="/api/catalog/payers/"
                onChange={(e, v) => this.setState({
                  payer: v,
                })}
              />
            </TwoColGrid>
            <TwoColGrid>
              <EditorControls.ItemPicker
                label="Контракт"
                value={contract}
                backend="/api/catalog/calculatedcontracts/"
                onChange={(e, v) => this.setState({
                  contract: v,
                })}
              />
              <EditorControls.ItemPicker
                label="Розрахований платіж"
                value={payments}
                backend="/api/document/calculationofpayments/"
                onChange={(e, v) => this.setState({
                  payments: v,
                })}
              />
            </TwoColGrid>
            <EditorControls.Radio
              group="SAVE_MODE"
              label="Тип документів для печатної форми"
              values={[
                { value: 'receipt', display_name: 'Квитанція' },
                { value: 'act', display_name: 'Акт' },
              ]}
              value={type}
              onChange={(e, v) => this.setState({ type: v })}
            />
          </EditorContainer>
          <ExecuteButton content="Виконати" disabled={isProcessing} onClick={this.startProcessing} />
        </div>

        {file && createPortal((
          <iframe id="print-area" className="print-area" name="print-area" srcDoc={file} title="Print" style={{ width: '100vh', height: 'auto' }} />
        ), document.body)}

      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(ContactPrintForm);
