import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import api from '../../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { ExecuteButton } from '../../../components/Styled/Buttons';
import { ErrorMessage, DimmableLoader } from '../../../components/Styled/Misc';
import { HeaderText, EditorContainer } from '../../../components/Styled/Forms';


const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;

class UnitePayers extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      wrongPayer: null,
      rightPayer: null,
      isProcessing: false,
      isErrored: false,
      errorText: '',
      isDone: false,
      message: null,
    };
  }

  startProcessing = async () => {
    const { authF } = this.props;
    const {
      wrongPayer, rightPayer,
    } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
      isDone: false,
    });

    const options = {
      wrong_payer: wrongPayer.id,
      right_payer: rightPayer.id,
    };

    const r = await api.post$('/api/service/unite-payers/', authF, options);
    if (r.ok) {
      const d = await r.json();
      this.setState({
        isProcessing: false,
        isErrored: false,
        errorText: '',
        isDone: true,
        message: d.success,
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
        isDone: true,
      });
    }
  };

  render() {
    const { currentUser } = this.props;
    const {
      wrongPayer, rightPayer, isProcessing,
      isErrored, errorText, isDone, message,
    } = this.state;
    return (
      <DimmableLoader loading={isProcessing}>
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <div>
          <HeaderText>Замінити платника</HeaderText>
          <EditorContainer style={{ overflow: 'inherit' }}>
            <TwoColGrid>
              <EditorControls.ItemPicker
                label="Старий платник"
                value={wrongPayer}
                backend="/api/catalog/payers/"
                onChange={(e, v) => {
                  this.setState({ wrongPayer: v });
                }}
                filter={currentUser.org ? { org: currentUser.org.id } : {}}
              />
              <EditorControls.ItemPicker
                label="Новий платник"
                value={rightPayer}
                backend="/api/catalog/payers/"
                onChange={(e, v) => {
                  this.setState({ rightPayer: v });
                }}
                filter={currentUser.org ? { org: currentUser.org.id } : {}}
              />
            </TwoColGrid>
          </EditorContainer>
          <ExecuteButton content="Виконати" disabled={isProcessing || !wrongPayer || !rightPayer} onClick={this.startProcessing} />
        </div>
        {isDone && (
          <EditorContainer style={{ overflow: 'auto' }}>
            {message && (
              <ul>{message}</ul>
            )}
          </EditorContainer>
        )}
      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(UnitePayers);
