import React from 'react';
import PropTypes from 'prop-types';
import { TableFooter, ResTableFooter } from './styles';

const ListFooter = ({
  resourceColumns, xOffset, yOffset, row, valuesRepresentator, columnColumns,
}) => (
  <>
    <TableFooter row={1 + yOffset} col={1 + xOffset}>
      Разом
    </TableFooter>
    {columnColumns.map((col) => resourceColumns.map((rColumn, k) => (
      <ResTableFooter
        key={`col${col.endColumn} - ${rColumn.key}`}
        row={1 + yOffset}
        col={xOffset + 3 + k + col.endColumn - resourceColumns.length}
      >
        {valuesRepresentator
          ? valuesRepresentator(rColumn.key, col[rColumn.key])
          : col[rColumn.key]}
      </ResTableFooter>
    )))}
    {resourceColumns.map((rColumn, k) => (
      <ResTableFooter
        key={rColumn.key}
        row={1 + yOffset}
        col={k + 2 + xOffset + columnColumns.length * resourceColumns.length}
      >
        {valuesRepresentator
          ? valuesRepresentator(rColumn.key, row[rColumn.key])
          : row[rColumn.key]}
      </ResTableFooter>
    ))}
  </>
);

ListFooter.propTypes = {
  resourceColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  row: PropTypes.shape({}),
  valuesRepresentator: PropTypes.func,
  columnColumns: PropTypes.arrayOf(PropTypes.shape()),
};

ListFooter.defaultProps = {
  resourceColumns: [],
  xOffset: 0,
  yOffset: 0,
  row: {},
  valuesRepresentator: null,
  columnColumns: [],
};


export default ListFooter;
