import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ClearIcon } from '../../components/Styled/Buttons/icons';

export const ErrorDiv = styled.div`
  font-size: small;
  color: red;
`;

const getBorderRadius = (neighbors) => {
  const br = '.3rem';
  switch (neighbors) {
  case 'left':
    return `0 ${br} ${br} 0`;
  case 'right':
    return '0';
  case 'both':
    return br;
  case 'none':
    return `0 ${br} ${br} 0`;
  default:
    return `0 ${br} ${br} 0`;
  }
};

export const ButtonStyled = styled.button.attrs({
  type: 'button',
})`
  padding: 8px 8px;
  // border-radius: ${({ neighbors }) => getBorderRadius(neighbors)};
  border-radius: 0.3rem;
  border: 1px solid rgb(177, 216, 255);
  background: linear-gradient(0deg,rgba(204, 230, 255, 0.88),rgba(255, 255, 255, 0.88)),#4281C9;
  // border: 1px solid rgba(34,36,38,.15);
  // background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  color: #4D4D4D;
  cursor: pointer;
  outline: none;
  &:hover{
    background: #AAC6E6;
  }
`;

export const ModalButton = styled(ButtonStyled)`
  ::after {
    content: "...";
  }
`;

export const EraseButton = (props) => (
  <ButtonStyled style={{ padding: '6.4px' }} {...props}>
    <img src={ClearIcon} alt="Очистити" />
  </ButtonStyled>
);

export const ExpandButton = styled(ButtonStyled)`
  ::after {
    content: "V";
  }
`;

export const LoadStyled = styled.div`
  padding: 8px 10px;
  border: none;
  border-left: none;
  background-color: transparent;
  }
`;


const fadeIn = keyframes`
 0%, 100% { box-shadow: 4px 4px rgb(79,77,73), -4px 4px rgb(223,223,223), -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223);}
 25% { box-shadow: -4px 4px rgb(223,223,223), -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223), 4px 4px rgb(79,77,73);}
 50% { box-shadow: -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223), 4px 4px rgb(79,77,73), -4px 4px rgb(223,223,223);}
 75% { box-shadow: 4px -4px #dfdfdf, 4px 4px #4f4d49, -3px 3px #dfdfdf, -4px -4px #4f4d49;}
`;

export const DivLoadStyled = styled.div`
  margin-top: 5px;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  box-shadow: 3px 3px rgb(79,77,73), -3px 3px rgb(223,223,223), -3px -3px rgb(79,77,73), 3px -3px rgb(223,223,223);
  animation: ${fadeIn} ease infinite 4.6s;
`;
