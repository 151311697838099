import React from 'react';
import PropTypes from 'prop-types';
import { CommandPanel } from '../../../components/Styled/Misc';
import { SelectButton, CloseButton } from '../../../components/Styled/Buttons';
import { actions } from './actions';
import { PeriodDateModal } from '../../BaseEditor/dates';

export const docSelectorCP = ({
  onActionClick, canSelect, period,
}) => (
  <CommandPanel>
    <SelectButton onClick={(e) => onActionClick(e, actions.select)} disabled={!canSelect} />
    <CloseButton onClick={(e) => onActionClick(e, actions.close)} />
    <PeriodDateModal
      period={period}
      onChange={(e, p) => onActionClick(e, actions.setPeriod, p)}
    />
  </CommandPanel>
);

docSelectorCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canSelect: PropTypes.bool,
  period: PropTypes.shape({
    bDate: PropTypes.string,
    eDate: PropTypes.string,
  }),
};

docSelectorCP.defaultProps = {
  canSelect: true,
  period: {
    bDate: null,
    eDate: null,
  },
};

export default docSelectorCP;
