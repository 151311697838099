import React from 'react';
import PropTypes from 'prop-types';
import useDetailsShower from './hooks/details';
import { DimmableLoader, ErrorMessage } from '../../components/Styled/Misc';
import Results from './results';

const DetailsShower = ({ reportId, schema, settings }) => {
  const {
    loading,
    err,
    results,
  } = useDetailsShower(reportId, schema, settings);
  return (
    <>
      {err && (
        <ErrorMessage text={err} />
      )}

      <DimmableLoader loading={loading} fullscreen>
        {results ? (
          <div style={{ overflow: 'auto', height: '100%' }}>
            <Results
              result={results}
              // ref={resultRef}
              // caption={reportData.name}
              // onContextMenu={onContextMenu}
            />
          </div>
        ) : <div style={{ height: '100px' }} />}

      </DimmableLoader>
    </>
  );
};

DetailsShower.propTypes = {
  reportId: PropTypes.number.isRequired,
  settings: PropTypes.shape({}).isRequired,
  schema: PropTypes.shape({}).isRequired,
};

export default DetailsShower;
