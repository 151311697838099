import styled from 'styled-components';

export const StyleTable = styled.table`
  th {
    position: sticky;
    top: 0;
    z-index: 3; 
    background: white;
    border-color: #dde1e5;
  };
`;
export const Title = styled.h5`
    color: var(--colorTitle);
    font-weight: 700;
`;

export const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 16% 80%;
  grid-gap: 2%;
  margin-bottom: 10px;
  max-height: ${(props) => props.height && `calc(100vh - ${props.height}px - 60px)`};
  overflow: auto;
`;

export const ListItem = styled.div.attrs((props) => ({
  style: {
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '.3rem',
    marginTop: '4px',
    background: props.active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
  },
}))`

`;
