import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import api from '../../../api/req';
import {
  RemoveSelectionButton, GenerateButton, SaveButton, SelectAllButton, OKButton,
} from '../../../components/Styled/Buttons';
import { CommandPanel, DimmableLoader, ErrorMessage } from '../../../components/Styled/Misc';
import { EditorContainer } from '../../../components/Styled/Forms';
import Row from './rowWarnings';
import RowKDB from './rowKDB';


const StyleHeader = styled.thead`
  >tr {
   >th{
    background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
    padding: 10px 5px;
    // font-size: 14px;
    position: sticky;
    top: 0;
    background: #e8f0f8;
    z-index: 1;
   }}
`;

const ContainerTable = styled.div`
  margin-top: 10px;
  display: grid;
  overflow: auto;
  background: white;
`;
const MessageText = styled.span`
  font-weight: 700;
  color: #5e93d0;
  font-size: 24px;
  margin-left: 15px;
`;
const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SpanKDB = styled.span`
  font-style: italic;
  color: #2d2828;
`;
const ContainerKDB = styled.div`
  margin-top: 10px;
  max-height: 50px;
  overflow-y: scroll;
  max-width: 100%;
  overflow-wrap: break-word;
  border: 1px solid #dce1e8;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px;
`;


class Warnings extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
    currentOrg: PropTypes.shape(),
    currentDate: PropTypes.string.isRequired,
  };

  static defaultProps = {
    currentOrg: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      isErrored: false,
      errorText: '',
      message: null,
      resultKDB: null,
      showKDB: false,
      result: null,
      headerHeightTable: 0,
      headerHeight: null,
    };
    this.divElement = React.createRef();
  }

  componentDidMount() {
    this.setState({
      headerHeight: this.divElement.current.getBoundingClientRect().top
          + this.divElement.current.getBoundingClientRect().height,
    });
  }


  startProcessing = async () => {
    const {
      authF, currentOrg, currentDate,
    } = this.props;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.post('/api/service/master/get_kdb_views/', authF, {
      org: currentOrg.id,
      date: currentDate,
    });
    if (r.ok) {
      const d = await r.json();
      this.setState({
        resultKDB: d.map((dKDB) => ({
          ...dKDB,
          save: false,
        })),
        showKDB: true,
        message: (d.length === 0) && 'Дані для обробки відсутні!',
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  getWarning = async () => {
    const {
      authF, currentOrg, currentDate,
    } = this.props;
    const { resultKDB } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.patch('/api/service/master/warnings/', authF, {
      org: currentOrg.id,
      date: currentDate,
      accounts: resultKDB.filter((res) => res.save === true).map((res) => res.id),
    });
    if (r.ok) {
      const d = await r.json();
      this.setState({
        result: d.map((dW) => ({
          ...dW,
          save: false,
        })),
        showKDB: false,
        message: (d.length === 0) && 'Дані для обробки відсутні!',
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };


  saveChanges = async () => {
    const { authF } = this.props;
    const { result } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.post('/api/service/master/warnings/', authF, {
      to_save: result.filter((res) => res.save === true),
    });
    if (r.ok) {
      this.startProcessing();
      this.setState({
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  changeSelect =(v) => {
    const { result, resultKDB, showKDB } = this.state;
    if (showKDB) {
      this.setState({
        resultKDB: resultKDB
          .map((r) => ({ ...r, save: v })),
      });
    } else {
      this.setState({
        result: result
          .map((r) => ({ ...r, save: v })),
      });
    }
  };

  rowSave =(id, value) => {
    const { result, resultKDB, showKDB } = this.state;
    if (showKDB) {
      this.setState({
        resultKDB: resultKDB
          .map((r) => r.id === id
            ? { ...r, ...value } : r),
      });
    } else {
      this.setState({
        result: result
          .map((r) => r.id === id
            ? { ...r, ...value } : r),
      });
    }
  };

  rowChange =(id, value) => {
    const { result } = this.state;
    this.setState({
      result: result
        .map((r) => r.id === id
          ? { ...r, ...value, save: true } : r),
    });
  };

  render() {
    const {
      currentUser, currentOrg, currentDate,
    } = this.props;
    const {
      message, isProcessing, errorText, showKDB,
      result, resultKDB, isErrored, headerHeightTable, headerHeight,
    } = this.state;

    const checkKDB = resultKDB && resultKDB.filter((res) => res.save === true).length > 0;
    const checkWarnings = result && result.filter((res) => res.save === true).length > 0;

    const currentKDB = resultKDB && resultKDB.filter((res) => res.save === true).map((res) => (
      <SpanKDB key={res.id}>
        {res.kdb.code}
        &sbquo;&nbsp;
      </SpanKDB>
    ));

    return (
      <DimmableLoader loading={isProcessing} fullscreen={false}>
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <div ref={this.divElement}>
          <CommandPanel>
            <GenerateButton
              content="Почати обробку"
              onClick={this.startProcessing}
              disabled={(currentUser.org && !currentUser.org.repr) || !currentOrg || !currentDate}
            />
            <OKButton
              content="Відібрати за КДБ"
              onClick={this.getWarning}
              disabled={!checkKDB}
            />
            <SelectAllButton
              onClick={() => this.changeSelect(true)}
              addText
            />
            <RemoveSelectionButton
              onClick={() => this.changeSelect(false)}
              addText
            />
            <SaveButton
              content="Записати"
              onClick={this.saveChanges}
              disabled={showKDB || !checkWarnings}
            />
          </CommandPanel>
          {!message && resultKDB && showKDB
          && (
            <ContainerTable
              style={{ maxHeight: `calc(100vh - ${headerHeight}px - 60px)` }}
            >
              <table border="1" cellSpacing="0">
                <StyleHeader>
                  <tr>
                    <th>Відібрати</th>
                    <th>КДБ код</th>
                    <th>КДБ назва</th>
                  </tr>
                </StyleHeader>
                <tbody>
                  {resultKDB.map((row) => (
                    <RowKDB
                      key={row.id}
                      row={row}
                      rowSave={this.rowSave}
                    />
                  ))}
                </tbody>
              </table>
            </ContainerTable>
          )}

          {!message && result && !showKDB
          && (
            <>
              <ContainerKDB>
                <span>КДБ:&nbsp;</span>
                {currentKDB}
              </ContainerKDB>
              <ContainerTable
                style={{ maxHeight: `calc(100vh - ${headerHeight}px - 60px - 56px)` }}
                onScroll={() => this.setState(
                  { headerHeightTable: this.divElement && this.divElement.current.clientHeight },
                )}
              >
                <table border="1" cellSpacing="0">
                  <StyleHeader>
                    <tr>
                      <th rowSpan="2">Записати</th>
                      <th>Платник</th>
                      <th>Договір</th>
                      <th>Сума до розмежування</th>
                      <th ref={this.divElement}>
                        Сума після розмежування
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="4" style={{ top: `${headerHeightTable}px` }}>Примітка</th>
                    </tr>
                  </StyleHeader>
                  <tbody>
                    {result.map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        rowChange={this.rowChange}
                        rowSave={this.rowSave}
                      />
                    ))}
                  </tbody>
                </table>
              </ContainerTable>
            </>
          )}

          {message && (
            <EditorContainer style={{ overflow: 'auto' }}>
              {message && (
                <MessageContainer>
                  <img width="130px" src="https://img.icons8.com/clouds/2x/open-envelope.png" alt="" />
                  <MessageText>{message}</MessageText>
                </MessageContainer>
              )}
            </EditorContainer>
          )}
        </div>
      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(Warnings);
