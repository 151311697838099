import React from 'react';
import styled from 'styled-components';
import { HashRouter as Router } from 'react-router-dom';
import Header from './header';
import Body from './body';
import Menu from './menu';


const StyledLayoutGrid = styled.div`
 display: grid;
 height: 100vh;
 overflow: hidden;
 grid-template-areas: "nav header"
                      "nav body";
 grid-template-columns: auto 1fr;                       
 grid-template-rows: 55px 1fr;
 // margin-left: 1em;
 
  @media (max-width: 900px) {
  height: auto;
}
`;

const LayoutGrid = () => (
  <Router>
    <div>
      <StyledLayoutGrid>
        <Header />
        <Menu />
        <Body />
      </StyledLayoutGrid>
    </div>
  </Router>
);

export default LayoutGrid;
