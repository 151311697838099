import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CalendarDiv, CellDiv, DatePcikerButton, HeaderDiv, CalendarHr,
} from './styles';
import {
  TwoArrowLeftIcon, OneArrowLeftIcon, OneArrowRightIcon, TwoArrowRightIcon,
} from '../../../components/Styled/Buttons/icons';


class DatePicker extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => null,
    value: new Date(),
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    const initialValue = props.value ? new Date(props.value.valueOf()) : new Date();
    this.state = {
      cMonth: initialValue.getMonth(),
      cYear: initialValue.getFullYear(),
    };
  }

  componentDidMount() {
    this.ref.current.addEventListener('dblclick', this.onDoubleClickHandler);
  }


  componentWillUnmount() {
    this.ref.current.removeEventListener('dblclick', this.onDoubleClickHandler);
  }

  onDoubleClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  getDayColor = (day) => {
    switch (day) {
    case 6:
      return 'blue';
    case 0:
      return 'red';
    default:
      return 'unset';
    }
  };

  prevMonth = () => {
    const { cMonth, cYear } = this.state;
    if (cMonth > 0) {
      this.setState({ cMonth: cMonth - 1 });
    } else {
      this.setState({ cMonth: 11, cYear: cYear - 1 });
    }
  };

  nextMonth = () => {
    const { cMonth, cYear } = this.state;
    if (cMonth < 11) {
      this.setState({ cMonth: cMonth + 1 });
    } else {
      this.setState({ cMonth: 0, cYear: cYear + 1 });
    }
  };

  render() {
    const { cMonth, cYear } = this.state;
    const { value, onChange } = this.props;
    const monthOptions = {
      month: 'long',
      year: 'numeric',
    };
    const newDate = new Date(cYear, cMonth, 1);

    const monthName = newDate.toLocaleString('uk', monthOptions);

    const days = [...Array(31).keys()].reduce((R, d) => {
      newDate.setDate(d + 1);
      if (newDate.getMonth() !== cMonth) return R;
      const dw = newDate.getDay();
      const lastWeek = R.length ? R[R.length - 1].week : 1;
      return [...R, {
        day: d + 1,
        dw: dw || 7,
        week: dw === 1 ? lastWeek + 1 : lastWeek,
        color: this.getDayColor(dw),
        value: newDate.valueOf(),
      }];
    },
    []);

    const currentDay = value
      ? new Date(value.getFullYear(), value.getMonth(), value.getDate())
      : new Date();

    return (
      <CalendarDiv ref={this.ref} onClick={(e) => e.preventDefault()}>
        <HeaderDiv area="1/1">
          <DatePcikerButton onClick={() => this.setState({ cYear: cYear - 1 })}>
            <img width="20px" src={TwoArrowLeftIcon} alt="" />
          </DatePcikerButton>
        </HeaderDiv>
        <HeaderDiv area="1/2">
          <DatePcikerButton onClick={this.prevMonth}>
            <img width="13px" src={OneArrowLeftIcon} alt="" />
          </DatePcikerButton>
        </HeaderDiv>
        <HeaderDiv area="1/3/1/6">
          <span>{monthName}</span>
        </HeaderDiv>
        <HeaderDiv area="1/6">
          <DatePcikerButton onClick={this.nextMonth}>
            <img width="13px" src={OneArrowRightIcon} alt="" />
          </DatePcikerButton>
        </HeaderDiv>
        <HeaderDiv area="1/7">
          <DatePcikerButton onClick={() => this.setState({ cYear: cYear + 1 })}>
            <img width="20px" src={TwoArrowRightIcon} alt="" />
          </DatePcikerButton>
        </HeaderDiv>
        <HeaderDiv area="2/1/2/8">
          <CalendarHr />
        </HeaderDiv>
        {days.map((d) => (
          <CellDiv
            key={d.day}
            area={`${d.week + 2}/${d.dw}`}
            color={d.color}
            active={d.value === currentDay.valueOf()}
            onClick={(e) => onChange(e, new Date(d.value))}
          >
            {d.day}
          </CellDiv>
        ))}
      </CalendarDiv>
    );
  }
}

export default DatePicker;
