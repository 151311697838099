import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

const generateFName = (filter, k) => `filter-${k}`;

const signDictionary = (sign) => {
  switch (sign) {
  case '=':
    return 'дорівнює';
  case '!=':
    return 'не дорівнює';
  case 'in':
    return 'у списку';
  case 'not in':
    return 'не в списку';
  case '<':
    return 'менше';
  case '<=':
    return 'менше/дорівнює';
  case '>':
    return 'більше';
  case '>=':
    return 'більше/дорівнює';
  case 'between':
    return 'між';
  case 'not between':
    return 'не між';
  case 'in group':
    return 'в групі';
  case 'not in group':
    return 'не в групі';
  case 'in group list':
    return 'в групі зі списку';
  case 'not in group list':
    return 'не в групі зі списку';
  default:
    return '';
  }
};

const plurals = ['in', 'not in', 'in group list', 'not in group list'];

const choice = (filtered) => filtered
  .map((f) => ({ value: f, display_name: signDictionary(f) }));

/**
 * Генерирует фильтры, пригодные для отображения в редакторе
 * @param filters {[][]}
 * @param metaFields {{ label: string }}
 * @param fields {{ label: string }}
 * @returns {[]}
 */
const useDisplayFilters = (filters, metaFields, fields) => useMemo(
  () => (filters.map(([fName, operation, args, use], k) => ({
    name: generateFName([fName, operation, args, use], k),
    label: fName in metaFields ? metaFields[fName].label : fName,
    errored: !(fName in metaFields),
    allowedOperations: fName in metaFields
        && (metaFields[fName].filtered
          ? choice(metaFields[fName].filtered)
          : choice(fields[metaFields[fName].key].filtered)),
    ctype: fName in metaFields && metaFields[fName].ctype,
    resource: fName in metaFields ? metaFields[fName].resource : '',
    use: !!use,
    operation,
    value: args,
    isPlural: plurals.includes(operation),
  }))),
  [fields, filters, metaFields],
);

/**
 * Генерирует доступные поля фильтров
 * @param metaFields {Object}
 * @param fields {Object}
 * @returns {Array}
 */
const useAvailableFilters = (metaFields, fields, attributes) => useMemo(
  () => {
    if (attributes && attributes.payer) {
      return (Object.keys(metaFields)
        .filter((f) => (metaFields[f].key in fields && !!fields[metaFields[f].key].filtered)
            || attributes.payer.map((el) => el.label === metaFields[f].key))
        .map((f) => ({ ...metaFields[f], name: f })));
    }
    return (
      Object.keys(metaFields)
        .filter((f) => metaFields[f].key in fields
      && !!fields[metaFields[f].key].filtered)
        .map((f) => ({ ...metaFields[f], name: f }))
    );
  },
  [attributes, fields, metaFields],
);

/**
 * HOOK для работы с фильтами отчетов
 * @param reportData {{options: {orders: [][]}}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  filters: Array,
 *  displayFilters: Array,
 *  availableFilters: Array,
 *  filtersHandlers: {
 *      addFilterHandler: function,
 *      removeFilterHandler: function,
 *      swapOrderHandler: function,
 *      clearAllFiltersHandler: function,
 *      changeOrderDirectionHandler: function,
 *      changeFilterUse: function,
 *      changeFilterType: function,
 *
 *  },
 *  }}
 */

export const useFilters = (reportData, schema) => {
  const useMetaFieldsWithAttributes = () => {
    const metaFields = { ...schema.src.meta_fields };
    if (schema.attributes && Object.entries(schema.attributes).length > 0) {
      const attributes = Object.entries(schema.attributes).map((parent) => parent[1].map((i) => (
        Object.entries({
          [i.alias]: {
            ...i, parent: parent[0], name: i.label, key: i.label,
          },
        }))));
      return (Object.fromEntries(Object.entries(metaFields).concat(...attributes[0])));
    }
    return metaFields;
  };

  const metaFieldsWithAttributes = useMetaFieldsWithAttributes();
  const [filters, setFilters] = useState([]);
  useEffect(() => setFilters(reportData.options.filters ? reportData.options.filters : []),
    [reportData]);
  const displayFilters = useDisplayFilters(filters, metaFieldsWithAttributes, schema.src.fields);
  const availableFilters = useAvailableFilters(metaFieldsWithAttributes,
    schema.src.fields, schema.attributes);
  const handlers = useMemo(() => {
    const clearAllFiltersHandler = () => setFilters([]);

    const addFilterHandler = (fname, beforeFilterName) => {
      const index = filters.reduce(
        (R, o, k) => (generateFName(o, k) === beforeFilterName ? k : R),
        filters.length,
      );

      if (!filters.some((el) => el[0] === fname)) {
        setFilters([
          ...filters.slice(0, index),
          [fname, '=', null, false],
          ...filters.slice(index),
        ]);
      }
    };

    const removeFilterHandler = (filterName) => setFilters(
      filters.filter((o, k) => generateFName(o, k) !== filterName),
    );

    const changeFilterUse = (name, value) => {
      setFilters(
        filters.map(
          ([f, o, v, u], k) => (
            generateFName([f, o, v, u], k) !== name ? [f, o, v, u] : [f, o, v, value]
          ),
        ),
      );
    };
    const changeFilterOperation = (name, operation) => {
      const fixValue = (v) => {
        if (v === null) return plurals.includes(operation) ? [] : v;
        if (plurals.includes(operation) && !v.length) return [v];
        if (!plurals.includes(operation) && v.length) return v[0];
        return v;
      };
      setFilters(
        filters.map(
          ([f, o, v, u], k) => (
            generateFName([f, o, v, u], k) !== name ? [f, o, v, u] : [f, operation, fixValue(v), u]
          ),
        ),
      );
    };
    const changeFilterValue = (name, value) => {
      setFilters(
        filters.map(
          ([f, o, v, u], k) => (
            (generateFName([f, o, v, u], k) !== name) ? [f, o, v, u] : [f, o, value, true]
          ),
        ),
      );
    };

    const swapFilterHandler = (fromName, toName) => {
      const from = filters.reduce((R, g, k) => (generateFName(g, k) === fromName ? k : R), null);
      const to = filters.reduce(
        (R, g, k) => (generateFName(g, k) === toName ? k : R),
        filters.length,
      );

      if (from > to) {
        setFilters([
          ...filters.slice(0, to),
          filters[from],
          ...filters.slice(to, from),
          ...filters.slice(from + 1),
        ]);
      }
      if (from < to) {
        setFilters([
          ...filters.slice(0, from),
          ...filters.slice(from + 1, to),
          filters[from],
          ...filters.slice(to),
        ]);
      }
    };

    return ({
      addFilterHandler,
      removeFilterHandler,
      swapFilterHandler,
      clearAllFiltersHandler,
      changeFilterUse,
      changeFilterOperation,
      changeFilterValue,
    });
  },
  [filters]);

  return {
    filters,
    displayFilters,
    availableFilters,
    filtersHandlers: handlers,
  };
};


export const availableFilterPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableFiltersPropType = PropTypes.arrayOf(availableFilterPropType);

export const filterPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  errored: PropTypes.bool,
  allowedOperations: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, display_value: PropTypes.string }),
  ),
  ctype: PropTypes.string,
  resource: PropTypes.string,
  use: PropTypes.bool,
  operation: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      id: PropTypes.number,
      repr: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      })])),
  ]),
  isPlural: PropTypes.bool,
});

export const getPreviousFilter = (displayFilters, name) => {
  const index = displayFilters.reduce((R, o, k) => (o.name === name ? k : R), 0);
  if (!index) return null;
  return displayFilters[index - 1].name;
};

export const filtersPropType = PropTypes.arrayOf(filterPropType);
