import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MultiValueControl from '../multiValueControl';
import {
  StyledDiv, StyledItem, StyledAddButton, StyledRemoveIcon,
} from './styles';
import meta, { dataTypes } from '../../../../const/meta';

class ValuesShower extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.any),
    type: PropTypes.oneOf([
      ...Object.values(dataTypes),
      ...Object.values(meta).map((m) => m.backend)]).isRequired,
    active: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    onChange: (e, v) => console.error('Array changed to ', v.toJS()),
    // defaultValue: '',
    active: false,
  };

  render() {
    const {
      value, onChange, active, type,
    } = this.props;
    const vl = value || [];
    const repr = vl.reduce((R, v) => (`${R}${R ? ', ' : ''}${v ? v.repr || v : 'Пусте значення'}`), '');
    if (!active) {
      return (
        <StyledDiv>{repr}</StyledDiv>
      );
    }
    return (
      <StyledDiv>
        {vl.map((itemValue, k) => (
          // eslint-disable-next-line
          <StyledItem key={k}>
            <MultiValueControl
              value={itemValue}
              type={type}
              onChange={(e, v) => onChange(e, [...vl.slice(0, k), v, ...vl.slice(k + 1)])}
            />
            <StyledRemoveIcon
              onClick={(e) => onChange(e, [...vl.slice(0, k), ...vl.slice(k + 1)])}
            />

          </StyledItem>
        ))}
        <StyledAddButton
          onClick={(e) => onChange(e, [...vl, null])}
        />
      </StyledDiv>
    );
  }
}

export default ValuesShower;
