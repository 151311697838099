import React from 'react';
import PropTypes from 'prop-types';
import StringInput from '../../../BaseEditor/stringInput';
import { tablePropType } from '../hooks';
import { ListItem } from '../styles';

const TableItem = ({
  table, active, onRenameTable, onClick,
}) => (

  <ListItem
    key={table.key}
    active={active}
    style={{ marginTop: '4px' }}
    onClick={onClick}
  >
    <StringInput
      controlId={`input-table-${table.key}`}
      value={table.name}
      readOnly={table.name === 'main'}
      onChange={(e, v) => onRenameTable(table.name, v)}
      noBackground
    />
  </ListItem>
);

TableItem.propTypes = {
  table: tablePropType.isRequired,
  active: PropTypes.bool,
  onRenameTable: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

TableItem.defaultProps = {
  active: false,
};

export default TableItem;
