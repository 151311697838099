import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { DATATYPES } from './consts';
import EditorControls from '../../BaseEditor/editorControls';

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 19% 19% 19% 19% 19%;
  grid-column-gap: 1%;
  align-items: end;
`;

const Button = styled.button`
    color: white;
    background-color: #28a745;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    width: 120px;
     &:hover{
    background-color: #28823d
     }`;

const DKSUData = ({
  dataType, setDataType, date, setDate, getDKSUData, datesByType, lastDownload, logicaPingSuccess,
}) => (
  <ContainerGrid>
    <EditorControls.Selector
      label="Оберіть тип даних для завантаження"
      required
      value={dataType}
      values={DATATYPES.map((data) => ({ value: data, display_name: data }))}
      onChange={(e, data) => setDataType(data)}
    />

    <EditorControls.StringInput
      label="В останнє завантажено на:"
      value={lastDownload}
      readOnly
    />

    <EditorControls.Selector
      label="Оберіть дату"
      required
      value={date}
      values={datesByType() || []}
      onChange={(e, data) => setDate(data)}
      readOnly={!dataType}
    />

    <Button onClick={getDKSUData} disabled={!logicaPingSuccess}>Завантажити</Button>
  </ContainerGrid>
);

DKSUData.propTypes = {
  dataType: propTypes.string,
  setDataType: propTypes.func.isRequired,
  date: propTypes.string,
  setDate: propTypes.func.isRequired,
  getDKSUData: propTypes.func.isRequired,
  datesByType: propTypes.func.isRequired,
  lastDownload: propTypes.string,
  logicaPingSuccess: propTypes.bool.isRequired,
};

DKSUData.defaultProps = {
  dataType: null,
  date: null,
  lastDownload: null,
};

export default DKSUData;
