import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "type label org content_type"
    ;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;

const editorRenderer = (getControl, { currentUser }) => (
  <>
    <ThisDocGrid>
      <GridItem area="type">
        {getControl('type')}
      </GridItem>
      <GridItem area="label">
        {getControl('label')}
      </GridItem>
      {currentUser.is_superuser && (
        <GridItem area="org">
          {getControl('org', { backend: '/api/catalog/orgs/' })}
        </GridItem>
      )}
      {/* <GridItem area="content_type"> */}
      {/*  {getControl('content_type')} */}
      {/* </GridItem> */}
    </ThisDocGrid>
  </>
);

export default getBaseEditor(editorRenderer, '/api/attributes/attributes/', CommandPanels.catCommandPanel);
