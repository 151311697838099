import Styled from 'styled-components';
import { Form } from '../elements';

export const HeaderText = Styled.h2`
  color: #008F21;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  margin-bottom: 15px ;
  font-size: 1.6em;
  
  @media (max-width: 600px) {
  margin-bottom: 10px;
  }
`;

export const DescriptionText = Styled.p`
  }
`;

export const StyledForm = Styled(Form)`
  overflow: auto;
`;

export const EditorContainer = Styled.div`
  // max-height: 65vh;
  overflow: auto;
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 20px 10px;
  margin: 10px 0;
  background: white;
  // background: #e9f0f85e;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
`;
