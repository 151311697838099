import React from 'react';
import PropTypes from 'prop-types';
import { PreviousIcon } from './icons';
import { StyledColorButton, StyledImg } from './styledCommandPanelButton';

export const PreviousButton = ({ content, onClick, disabled }) => (
  <StyledColorButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={PreviousIcon} alt={content} />
  </StyledColorButton>
);

PreviousButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

PreviousButton.defaultProps = {
  onClick: () => null,
  content: 'Назад',
  disabled: false,
};

export default PreviousButton;
