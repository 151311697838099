import React from 'react';
import PropTypes from 'prop-types';
import { FiltersItem } from './styles';
import TableControls from '../../tableControls';
import getNumberInput from '../../numberInput';
import { dataTypes } from '../../../../const/meta';

export const eqTypes = {
  eq: { display_name: 'дорівнює', value: 'eq' },
  neq: { display_name: 'не дорівнює', value: 'neq' },
  lt: { display_name: 'меньше', value: 'lt' },
  lte: { display_name: 'меньше або дорівнює', value: 'lte' },
  gt: { display_name: 'більше', value: 'gt' },
  gte: { display_name: 'більше або дорівнює', value: 'gte' },
  like: { display_name: 'містить', value: 'like' },
};

export const eqTypesByType = (type) => {
  if (type.indexOf('/api/') !== -1) {
    return [eqTypes.eq, eqTypes.neq];
  } if (type.toUpperCase() === dataTypes.string) {
    return [eqTypes.eq, eqTypes.neq, eqTypes.like];
  } if (type.toUpperCase() === dataTypes.bool) {
    return [eqTypes.eq, eqTypes.neq];
  }
  return Object.values(eqTypes).filter((e) => e.value !== eqTypes.like.value);
};

const NumberInput = getNumberInput(16, 2);

const FilterItem = ({
  fName, rowId, row, onChange,
}) => (
  <>
    <FiltersItem rowId={rowId + 2} colName="use" style={{ justifyContent: 'center' }}>
      <TableControls.CheckboxInput
        noMargin
        value={row.use}
        onChange={(e, v) => onChange(e, fName, { ...row, use: v })}
      />
    </FiltersItem>
    <FiltersItem rowId={rowId + 2} colName="field">{row.label}</FiltersItem>
    <FiltersItem rowId={rowId + 2} colName="eq">
      <TableControls.Selector
        value={row.eq}
        values={row.eqs}
        onChange={(e, v) => onChange(e, fName, { ...row, use: true, eq: v })}
      />
    </FiltersItem>
    <FiltersItem rowId={rowId + 2} colName="value">
      {row.type.indexOf('/api/') !== -1 && (
        <TableControls.ItemPicker
          value={row.value}
          backend={row.type}
          noBorder
          onChange={(e, v) => onChange(e, fName, { ...row, use: true, value: v })}
        />
      )}
      {row.type.toUpperCase() === dataTypes.decimal && (
        <NumberInput
          value={row.value}
          noBorder
          onChange={(e, v) => onChange(e, fName, { ...row, use: true, value: v })}
        />
      )}
      {row.type.toUpperCase() === dataTypes.string && (
        <TableControls.StringInput
          value={row.value}
          noBorder
          onChange={(e, v) => onChange(e, fName, { ...row, use: true, value: v })}
        />
      )}
      {row.type.toUpperCase() === dataTypes.bool && (
        <TableControls.CheckboxInput
          value={row.value}
          noBorder
          onChange={(e, v) => onChange(e, fName, { ...row, use: true, value: v })}
        />
      )}
    </FiltersItem>
  </>
);

FilterItem.propTypes = {
  fName: PropTypes.string.isRequired,
  rowId: PropTypes.number.isRequired,
  row: PropTypes.shape({
    use: PropTypes.bool,
    label: PropTypes.string,
    eq: PropTypes.oneOf(Object.keys(eqTypes)),
    type: PropTypes.oneOf(Object.values(dataTypes)),
    value: PropTypes.any,
    eqs: PropTypes.arrayOf(PropTypes.shape({
      display_name: PropTypes.string,
      value: PropTypes.oneOf(Object.keys(eqTypes)),
    })),
  }).isRequired,
  onChange: PropTypes.func,
};

FilterItem.defaultProps = {
  onChange: () => null,
};

export default FilterItem;
