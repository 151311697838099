import { getTablePartController, getTPCommandPanel } from '../../../../BaseEditor';
import { GridTemplate } from './styles';
import BodyComponent from './body';
import HeaderComponent from './header';

const CommandPanel = getTPCommandPanel();

const newRecordData = () => ({
  id: null,
  accum_sum: '',
  kdb: { id: null, full_name: '' },
  kdb_id: null,
  day_sum: null,
  not_import: false,
});

export default getTablePartController(
  GridTemplate,
  BodyComponent,
  newRecordData,
  CommandPanel,
  HeaderComponent,
);
