import React from 'react';
import PropTypes from 'prop-types';
import EditorControls from '../../../BaseEditor/editorControls';
import { fieldPropType } from '../hooks';

const CalcProps = ({
  value, onChange, readOnly,
  allowedCtypes, fields,
}) => (
  <div className="containerField p-3 border rounded">
    <EditorControls.Multiselector
      controlId="calc_include_input"
      label="Include"
      required
      description="Перечень полей (из раздела fields) которые включает данная сущность. При использовании данного поля в запрос будут включены все поля из include."
      value={value.include}
      values={fields}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ include: v })}
    />
    <EditorControls.Selector
      controlId="calc_ctype_input"
      label="Ctype"
      required
      description="Тип поля"
      value={value.ctype}
      readOnly={readOnly}
      values={allowedCtypes}
      onChange={(e, v) => onChange({
        ctype: v,
      })}
    />
    <EditorControls.StringInput
      controlId="calc_formula_input"
      label="Formula"
      required
      description="ФОрмула для вычисления поля. Представляет собой python-выражение"
      value={value.formula}
      readOnly={readOnly}
      onChange={(e, v) => onChange({ formula: v })}
    />
  </div>
);

CalcProps.propTypes = {
  value: fieldPropType,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  allowedCtypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({}).isRequired,
};

CalcProps.defaultProps = {
  value: '',
  readOnly: false,
};

export default CalcProps;
