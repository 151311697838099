import api from './req';

const TIMEOUT = 3000;

export const checkTask = (taskId, authF, onDone, onError) => {
  if (taskId) {
    api.get$(`/api/tasks/${taskId}/`, authF).then((r) => {
      if (r.ok) {
        r.json().then((json) => {
          switch (json.status) {
          case 'PENDING':
            setTimeout(() => checkTask(taskId, authF, onDone, onError), TIMEOUT);
            break;
          case 'FAILURE':
            onError(json);
            break;
          case 'REVOKED':
            onError();
            break;
          case 'SUCCESS':
            onDone(json);
            break;
          default:
            onError({ error: `Unknow task status ${json.status}` });
          }
        });
      } else {
        onError({ error: `${r.status} ${r.statusText}` });
      }
    });
  }
};

export default checkTask;
