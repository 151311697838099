import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DivStyled, InputStyled } from '../../components/Styled/Input';
import {
  LoadStyled, DivLoadStyled,
} from './styles';


export const ItemField = forwardRef(({
  value, children, isLoading, editText, onChange, readOnly, noBorder, noBackground,
}, ref) => (
  <DivStyled ref={ref} noBorder={noBorder} noBackground={noBackground}>
    <InputStyled value={editText === null ? ((value && value.repr) || '') : editText} onChange={(e) => onChange(e, e.target.value)} readOnly={readOnly} />
    {isLoading ? (
      <LoadStyled>
        <DivLoadStyled />
      </LoadStyled>
    ) : null}
    {children}
  </DivStyled>
));

export const valuePropType = PropTypes.shape({
  id: PropTypes.number,
  repr: PropTypes.string,
});

ItemField.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  value: valuePropType,
  editText: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  noBorder: PropTypes.bool,
  noBackground: PropTypes.bool,
};

ItemField.defaultProps = {
  value: { id: null, repr: '' },
  isLoading: false,
  children: null,
  editText: null,
  onChange: () => null,
  readOnly: false,
  noBorder: false,
  noBackground: false,
};

export default ItemField;
