import React, { PureComponent } from 'react';
import ReportEditor from '../../BaseReport';
import { EditorControls } from '../../BaseEditor';
import { reports } from '../../../const/meta';
import { ControlsConrainer, StyledControl, ReportContainer } from '../styles';

const md = reports.compareBetween412;

const redefineColumns = {
  org: { type: '/api/catalog/orgs/', columns: ['name'] },
  budget: { type: '/api/catalog/budgets/', columns: ['name'] },
  kdb: { type: '/api/catalog/kdbs/', columns: ['code', 'name'] },
  sum_period1: { type: 'DECIMAL' },
  sum_period2: { type: 'DECIMAL' },
};

const addColummns = [];

const resColumns = [
  { label: 'Сума поточного періоду, грн', key: 'sum_period_1' },
  { label: 'Сума попереднього періоду, грн', key: 'sum_period_2' },
  { label: 'Відхилення, грн', key: 'sum_difference' },
  // { label: 'Відхилення, %', key: 'rel_diff_order_amount' },
// abs_diff_revenue_amount: -704.8
// curr_revenue_amount: 704.8
// prev_revenue_amount: 0`
// rel_diff_revenue_amount: -100
];

const resColumnsItems = resColumns.map((r) => r.key);

const valuesRepresentator = (field, value) => {
  if (value && resColumnsItems.includes(field)) {
    return value.toLocaleString('uk', { minimumFractionDigits: 2 });
  }
  return value && value.repr;
};

class CompareBetween412Report extends PureComponent {
  constructor(props) {
    super(props);
    const cDate = new Date();
    this.state = {
      bdate: `${cDate.getFullYear()}-01-01`,
      edate: `${cDate.getFullYear()}-12-31`,
      bdate2: `${cDate.getFullYear() - 1}-01-01`,
      edate2: `${cDate.getFullYear() - 1}-12-31`,
    };
  }

  bdateChangeHandler = (e, v) => {
    const cDate = new Date(v);
    this.setState({
      bdate: v,
      bdate2: `${cDate.getFullYear() - 1}-${cDate.getMonth() + 1}-${cDate.getDate()}`,
    });
  };

  edateChangeHandler = (e, v) => {
    const cDate = new Date(v);
    this.setState({
      edate: v,
      edate2: `${cDate.getFullYear() - 1}-${cDate.getMonth() + 1}-${cDate.getDate()}`,
    });
  };

  render() {
    const {
      bdate, edate, bdate2, edate2,
    } = this.state;
    return (
      <ReportContainer>
        <ControlsConrainer>
          <StyledControl>
            <EditorControls.DateInput
              value={bdate}
              onChange={this.bdateChangeHandler}
              label="Початок періоду"
            />
          </StyledControl>
          <StyledControl>
            <EditorControls.DateInput
              value={edate}
              onChange={this.edateChangeHandler}
              label="Кінець періоду"
            />
          </StyledControl>
        </ControlsConrainer>
        <ReportEditor
          valuesRepresentator={valuesRepresentator}
          params={{
            begin_date: bdate,
            end_date: edate,
            begin_date2: bdate2,
            end_date2: edate2,
          }}
          overrideColumns={redefineColumns}
          resourceColumns={resColumns}
          addColummns={addColummns}
          meta={md}
        />
      </ReportContainer>
    );
  }
}

export default CompareBetween412Report;
