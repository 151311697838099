import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../providers/authProvider';
import { LogicaContext } from '../../../providers/authLogicaProvider';
import api from '../../../api/req';
import init from './eusign';
import { ErrorMessage, Loader } from '../../../components/Styled/Misc';
import LogicaPing from '../Ping';

//= ================================================================
// Ідентифікатор батківського елементу для відображення iframe,
// який завантажує сторінку SignWidget

const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
// Ідентифікатор iframe, який завантажує сторінку SignWidget

const SIGN_WIDGET_ID = 'sign-widget';

// URI з адресою за якою розташована сторінка SignWidget
const SIGN_WIDGET_URI = 'https://certs.ciat.net.ua/';

const Button = styled.button`
height: 40px;
cursor: pointer;
 :hover {
color: white;
         }`;

const Authentication = () => {
  const EndUserRef = useRef(null);
  const EuSignRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [logicaPingSuccess, setLogicaPingSuccess] = useState(true);

  const { auth } = useContext(AppContext);
  const { ticket, setTicket } = useContext(LogicaContext);

  const useFocus = () => {
    const el = useRef(null);
    const setFocus = () => (el.current && el.current.focus());

    return [el, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();

  const handleEnterClick = () => {
    setInputFocus();
  };

  useEffect(() => {
    EndUserRef.current = init();

    EuSignRef.current = new EndUserRef.current(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      EndUserRef.current.FormType.ReadPKey,
    );

    //= ============================================================================

    // Створення об'єкту типу endUser для взаємодії з iframe,
    // який завантажує сторінку SignWidget

    EuSignRef.current
      .ReadPrivateKey()
      .then(() => {
        document.getElementById('sign-widget-parent').style.display = 'none';
        document.getElementById('sign-data-block').style.display = 'block';
        setInputFocus();
      })
      .catch((e) => {
        alert(
          `${'Виникла помилка при зчитуванні ос. ключа. Опис помилки: '}${
            e.message || e
          }`,
        );
      });

    document.body.addEventListener('click', handleEnterClick);

    return () => {
      document.body.removeEventListener('click', handleEnterClick);
    };
    // eslint-disable-next-line
  }, []);

  //= ============================================================================
  // Накладання підпису на дані

  const onSign = (strSign) => {
    function uint8array(string) {
      return Buffer.from(string, 'utf16le');
    }

    const strUTF16 = uint8array(strSign);
    const external = false;
    const asBase64String = true;
    const signAlgo = EndUserRef.current.SignAlgo.DSTU4145WithGOST34311;
    const signType = EndUserRef.current.SignType.CAdES_X_Long;

    return (
      EuSignRef.current
        .SignData(strUTF16, external, asBase64String, signAlgo, null, signType)
        // .then((sign) => (document.getElementById("textAreaSign").value = sign))

        .catch((e) => {
          alert(
            `${'Виникла помилка при підписі даних. Опис помилки: '}${
              e.message || e
            }`,
          );
          setError(e.message);
        })
    );
  };

  const processHandler = useCallback(() => {
    setIsLoading(true);
    const getTicketClick = async (signBase64, requestId) => {
      try {
        const body = {
          requestId,
          signBase64,
          userAgent: 'Test',
        };
        const responseLogin = await api.post$('/api/logica/login/', auth, body);

        if (responseLogin.ok) {
          setIsLoading(false);
          const answearLogin = await responseLogin.json();
          if (answearLogin.ticket) {
            setTicket(answearLogin.ticket);
          } else if (answearLogin.errorMessage) {
            setError(answearLogin.errorMessage);
          }
        } else if (responseLogin.status === 422) {
          const errData = await responseLogin.json();
          setError(errData.error_data.reduce((R, ed) => [...R, ...ed.messages], []).join(','));
        } else {
          setIsLoading(false);
          setError(`${responseLogin.status} ${responseLogin.statusText}`);
        }
      } catch (e) {
        setError(e);
      }
    };

    const getIdClick = async () => {
      try {
        const response = await api.get$('/api/logica/login/', auth);

        if (response) {
          const payload = await response.json();
          const signBase64 = await onSign(payload.stringToSign);
          const { requestId } = payload;
          getTicketClick(signBase64, requestId);
        }
      } catch (e) {
        setError(e);
      }
    };
    getIdClick();
  }, [auth, setTicket]);

  return (
    <div style={{ marginLeft: '15px' }}>
      <LogicaPing available={logicaPingSuccess} handleAvailable={setLogicaPingSuccess} />
      <div
        id="sign-widget-parent"
        style={{ width: '700px', height: '500px' }}
      />
      <div id="sign-data-block" style={{ display: 'none' }}>
        {error && (
          <ErrorMessage text={error} header="Помилка авторизації" />
        )}
        {error === 'Сертифікат не знайдено' && (
          <ErrorMessage header="Немає серитифікату">
            <p>
              Ви можете завантажити сертифікат з
              {' '}
              <a
                href="http://acsk.treasury.gov.ua/poshuk_sertificativ/"
                target="_blank"
                rel="noopener noreferrer"
              >
                центру сертифікації
              </a>
            </p>
          </ErrorMessage>
        )}
        {ticket && ticket !== 'null' && <h3>Авторизація пройшла успішно</h3>}
        {!error && (!ticket || ticket === 'null') && (
          <Button autoFocus onClick={processHandler} ref={inputRef} disabled={!logicaPingSuccess}>
            {isLoading && (
              <Loader
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Перевірка ЕЦП у &quot;LOGICA&quot;
          </Button>
        )}
      </div>
    </div>
  );
};

export default Authentication;
