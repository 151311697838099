import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { XLSIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledXLSButton = Styled(StyledButton)`
  border: 1px solid #a2b4e8;
  height: 2.5em;
  align-self: flex-end;
  &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: #d0f7d4fc;
  }
`;

export const ExportXLSButton = ({ content, onClick, disabled }) => (
  <StyledXLSButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={XLSIcon} alt={content} />
    <StyledText>
      {content}
    </StyledText>
  </StyledXLSButton>
);

ExportXLSButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExportXLSButton.defaultProps = {
  onClick: () => null,
  content: 'Експортувати в xls',
  disabled: false,
};

export default ExportXLSButton;
