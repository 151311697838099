import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GridContainer, ReportHeader, ReportContainer } from './styles';
import ListHeader from './ListHeader';
import ListRow from './ListRow';
import ListFooter from './ListFooter';
import ContextMenu from './contextMenu';

const ROWS_PER_TABLE = 1000;

class ReportRenderer extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    // eslint-disable-next-line
    report: PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.shape()),
      columns: PropTypes.arrayOf(PropTypes.shape()),
      totals: PropTypes.shape(),
    }),
    groupColumns: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    columnGroupColumns: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    resourceColumns: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    valuesRepresentator: PropTypes.func,
    allowedColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onDetailProcessing: PropTypes.func,
  };

  static defaultProps = {
    label: 'Звіт без назви',
    report: [],
    groupColumns: [],
    columnGroupColumns: [],
    resourceColumns: [],
    valuesRepresentator: null,
    onDetailProcessing: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.report !== state.report) {
      const tables = [...Array(Math.floor(props.report.rows.length / ROWS_PER_TABLE) + 1).keys()]
        .map((k) => ({
          key: k,
          rows: props.report.rows.slice(k * ROWS_PER_TABLE, (k + 1) * ROWS_PER_TABLE),
        }));
      return ({
        ...state,
        report: props.report,
        tables,
      });
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false,
      menuX: 0,
      menuY: 0,
      detail: null,
      // eslint-disable-next-line
      report: null,
      tables: null,
    };
  }

  onContextMenu = (e, row) => {
    const { groupColumns, allowedColumns } = this.props;
    const currentGroup = groupColumns[row.level];

    const knowGroups = groupColumns
      .filter((i, k) => k <= row.level)
      .reduce((R, g) => ({
        ...R,
        [g.query_label]: row[g.query_label] && row[g.query_label].id__id,
      }), {});
    const expandColumns = allowedColumns.filter((c) => !(c.query_label in knowGroups));

    this.setState({
      menuOpened: true,
      menuX: e.clientX,
      menuY: e.clientY,
      detail: {
        currentItem: {
          backend: currentGroup.type,
          id: row[currentGroup.query_label] && row[currentGroup.query_label].id__id,
          repr: row[currentGroup.query_label] && row[currentGroup.query_label].repr,
        },
        knowGroups,
        expandColumns,
      },
    });
    e.preventDefault();
  };

  render() {
    const {
      label,
      groupColumns,
      columnGroupColumns,
      resourceColumns,
      report,
      valuesRepresentator,
      allowedColumns,
      onDetailProcessing,
    } = this.props;
    const {
      menuOpened, menuX, menuY, detail, tables,
    } = this.state;
    return (
      <ReportContainer>
        <ReportHeader>{label}</ReportHeader>
        <GridContainer>
          <ListHeader
            groupColumns={groupColumns}
            resourceColumns={resourceColumns}
            columnGroupColumns={columnGroupColumns}
            columnColumns={report.columns}
          />
        </GridContainer>
        {tables.map((t) => (
          <GridContainer key={t.key}>
            {t.rows.map((row, k) => (
              <ListRow
                // eslint-disable-next-line
                key={k}
                resourceColumns={resourceColumns}
                groupLevel={row.level}
                yOffset={groupColumns.length + k}
                row={row}
                valuesRepresentator={valuesRepresentator}
                onContextMenu={this.onContextMenu}
                groupColumns={groupColumns}
                allColumns={report.columns}
              />
            ))}
          </GridContainer>
        ))}
        <GridContainer>
          <ListFooter
            groupColumns={groupColumns}
            resourceColumns={resourceColumns}
            row={report.totals}
            yOffset={groupColumns.length + report.rows.length}
            valuesRepresentator={valuesRepresentator}
            // columnGroupColumns={columnGroupColumns}
            columnColumns={report.columns}
          />
        </GridContainer>
        {menuOpened && (
          <ContextMenu
            left={menuX}
            top={menuY}
            onCloseMenu={() => this.setState({ menuOpened: false })}
            detail={detail}
            onDetailProcessing={onDetailProcessing}
            allowedColumns={allowedColumns}
          />
        )}
      </ReportContainer>
    );
  }
}


export default ReportRenderer;
