import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NewButton, OKButton, CloseButton } from '../../../components/Styled/Buttons';
import { Modal, CommandPanel } from '../../../components/Styled/Misc';
import { HeaderText } from '../../../components/Styled/Forms';
import { EditorControls } from '../../BaseEditor';

class NewVariant extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      title: 'Новий звіт',
      description: '',
      saveForMe: 0,
      profile: null,
      org: null,
    };
  }

  save = (e) => {
    const { isAdmin, onSave } = this.props;
    const {
      title, description, saveForMe, profile, org,
    } = this.state;
    if (!isAdmin) {
      onSave(e, {
        title, description, isMy: saveForMe === 0, isMyOrg: saveForMe !== 0,
      });
    } else {
      switch (saveForMe) {
      case 0:
        console.log(profile);
        onSave(e, {
          title, description, profile: profile.id,
        });
        break;
      case 1:
        onSave(e, {
          title, description, org: org.id,
        });
        break;
      case 2:
        onSave(e, {
          title, description,
        });
        break;
      default:
        throw new Error(`saveForMe ${saveForMe} is not valid value`);
      }
    }
    this.setState({ modalOpened: false });
  };

  render() {
    const {
      modalOpened, title, description, saveForMe, profile, org,
    } = this.state;
    const { isAdmin } = this.props;
    return (
      <>
        <NewButton content="Створити звіт" onClick={() => this.setState({ modalOpened: !modalOpened })} />
        <Modal open={modalOpened}>
          <>
            <HeaderText>Створення нового звіту</HeaderText>
            <EditorControls.StringInput
              label="Назва звіту"
              value={title}
              onChange={(e, v) => this.setState({ title: v })}
            />
            <EditorControls.TextInput
              label="Опис"
              value={description}
              onChange={(e, v) => this.setState({ description: v })}
            />
            {!isAdmin ? (
              <EditorControls.Radio
                group="SAVE_MODE"
                label="Режми використання"
                values={[
                  { value: 0, display_name: 'Тількі для мене' },
                  { value: 1, display_name: 'Для всіх' },
                ]}
                value={saveForMe}
                onChange={(e, v) => this.setState({ saveForMe: v })}
              />
            ) : (
              <EditorControls.Radio
                group="SAVE_MODE"
                label="Режми використання"
                values={[
                  { value: 0, display_name: 'Для вказаного користувача' },
                  { value: 1, display_name: 'Для вказаної установи' },
                  { value: 2, display_name: 'Для всіх' },
                ]}
                value={saveForMe}
                onChange={(e, v) => this.setState({ saveForMe: v })}
              />
            )}
            {isAdmin && saveForMe === 0 && (
              <EditorControls.ItemPicker
                backend="/api/core/profiles/"
                label="Користувач"
                value={profile}
                onChange={(e, v) => this.setState({ profile: v })}
              />
            )}
            {isAdmin && saveForMe === 1 && (
              <EditorControls.ItemPicker
                disabled={saveForMe !== 1}
                backend="/api/catalog/orgs/"
                label="Установа"
                value={org}
                onChange={(e, v) => this.setState({ org: v })}
              />
            )}
            <CommandPanel>
              <OKButton
                disabled={!title}
                content="Зберегти"
                onClick={this.save}
              />
              <CloseButton
                content="Закрити"
                onClick={() => {
                  this.setState({ modalOpened: false });
                }}
              />
            </CommandPanel>
          </>
        </Modal>
      </>
    );
  }
}

export default NewVariant;
