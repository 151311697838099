import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import api from '../../api/req';
import {
  Container,
  ContainerText,
  Div,
  Button,
  ContainerVarButton,
  Header,
  Text,
  ButtonRepeat,
  Img,
  StyledMessageButton,
  NumMes,
} from './styles';
import { mapStateAuth, withAuthConsumer } from '../../providers/authProvider';
import { MessageIcon } from '../../components/Styled/Buttons/icons';


class Message extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    content: PropTypes.string,
    disabled: PropTypes.bool,
    currentUser: PropTypes.shape({
      messages: PropTypes.number,
      messagesNEW: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    content: 'Повідомлення',
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      // messages: message,
    };
    // this.isAdmin = session.get('is_admin');
  }

  componentDidMount() {
    this.allMessages();
    this.getNewMessages();
  }

  allMessages = () => {
    const { authF } = this.props;
    api.get$('/api/messages/', authF).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          this.setState({
            messages: d.map((u) => ({ ...u, show: true })),
          });
        });
      } else {
        this.setState({
          messages: [],
        });
      }
    });
  };

  getNewMessages = () => {
    const { authF } = this.props;
    api.get$('/api/core/currentuser/', authF).then((r) => {
      if (r.ok) {
        r.json().then((d) => {
          this.setState({
            messagesNEW: d.results[0].messages,
          });
          setTimeout(() => this.getNewMessages(), 900000);
        });
      } else {
        this.setState({
          messagesNEW: 0,
        });
      }
    });
  };

  deleteMessages = (messageId) => {
    const { authF } = this.props;
    api.delete$(`/api/messages/${messageId}/`, authF).then((r) => {
      if (r.ok) {
        this.getNewMessages();
        this.allMessages();
      } else {
        this.setState({
          messages: [],
        });
      }
    });
  };

  render() {
    const {
      content,
      disabled,
    } = this.props;
    const {
      messages, isVisible, messagesNEW,
    } = this.state;
    const item = messages && messages.filter((i) => i.show === true).reduce((R, r) => r, null);
    return (
      <div>
        <StyledMessageButton
          onClick={() => {
            this.setState({ isVisible: true });
            this.getNewMessages();
            this.allMessages();
          }}
          disabled={disabled}
          title={content}
        >
          <img width="23px" src={MessageIcon} alt="MessageIcon" />
          <NumMes style={{ background: messagesNEW > 0 ? 'red' : '#2196f3' }}>
            {messagesNEW}
          </NumMes>
        </StyledMessageButton>
        {messagesNEW > 0 && isVisible
        && (
          <div>
            { item
        && (
          <Container>
            <ContainerText key={item.text}>
              {item.type_message === 'info'
                ? <Header>Інформація!</Header>
                : <Header style={{ color: '#fd6472' }}>Попередження!</Header>}
              <Div>
                <Img
                  src={item.type_message === 'info'
                    ? 'https://img.icons8.com/cute-clipart/2x/info.png'
                    : 'https://img.icons8.com/cute-clipart/2x/warning-shield.png'}
                  alt=""
                />
                <Text>
                  {item.text}
                </Text>
              </Div>
              <ContainerVarButton style={{ marginTop: '0' }}>
                <ButtonRepeat
                  onClick={() => {
                    this.setState({
                      messages: messages.map((i) => i.id === item.id ? { ...i, show: false } : i),
                    });
                  }}
                >
                  Переглянути пізніше
                </ButtonRepeat>
                <Button
                  onClick={() => this.deleteMessages(item.id)}
                >
                  Ознайомлений
                </Button>
              </ContainerVarButton>
            </ContainerText>
          </Container>
        )}
          </div>
        )}
      </div>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(Message);
