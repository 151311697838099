import orgs from './orgs';
import budgets from './budgets';
import industries from './industries';
import regions from './regions';
import provinces from './provinces';
import kdbs from './kdbs';
import paymenyskils from './paymenyskils';
import payers from './payers';
import contracts from '../documents/contracts';
import accounts from './accounts';
import users from './users';
import profiles from './profiles';
import markers from './markers';
import inflationindex from './inflationIndex';
import calculatedcontracts from '../documents/calculatedcontracts';
import enteringtheremainder from '../documents/enteringtheremainder';
import attributes from './attributes';
import landcategories from './landcategories';

export default {
  orgs,
  budgets,
  industries,
  regions,
  kdbs,
  paymenyskils,
  payers,
  contracts,
  accounts,
  users,
  profiles,
  provinces,
  landcategories,
  markers,
  inflationindex,
  calculatedcontracts,
  enteringtheremainder,
  attributes,
};
