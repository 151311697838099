import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DivElement } from './styles';
import { TableControls } from '../../../../BaseEditor';
import { fieldsPropType } from './PropTypes';

const DecimalInput = TableControls.getNumerInput(14, 2);

class BodyComponent extends PureComponent {
  static propTypes = {
    row: PropTypes.shape({
      payer: PropTypes.shape({
        id: PropTypes.number,
        full_name: PropTypes.string,
        payment_number: PropTypes.string,
        payment_notes: PropTypes.string,
        order_amount: PropTypes.number,
        revenue_amount: PropTypes.number,
        warning: PropTypes.bool,
      }),
      contract: PropTypes.object,
      payment_number: PropTypes.string,
      payment_notes: PropTypes.string,
      order_amount: PropTypes.number,
      revenue_amount: PropTypes.number,
      warning: PropTypes.bool,
    }),
    fields: fieldsPropType,
    rowNumber: PropTypes.number,
    onChange: PropTypes.func,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    shift: PropTypes.number,
    index: PropTypes.number.isRequired,
  };

  static defaultProps = {
    row: {
      payer: {},
      contract: {},
    },
    fields: {
      account: {},
      payer: {},
      contract: {},
    },
    rowNumber: 0,
    onChange: () => null,
    active: false,
    onClick: () => null,
    shift: 0,
  };


  render() {
    const {
      row, rowNumber, onChange, active, fields, onClick, shift, index,
    } = this.props;
    // const cr = (index * 2 + 3) - shift * 2;
    const cr = (index * 2 + 3);
    const globalProps = {
      onClick: (e) => onClick(e, rowNumber),
    };

    const globalStyle = active ? {
      background: active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
    } : {};
    return (
      <>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / payer`,
          }}
          {...globalProps}
        >
          <TableControls.ItemPicker
            value={row.payer}
            backend="/api/catalog/payers/"
            onChange={(e, v) => onChange(e, rowNumber, { payer: v })}
            readOnly={fields.payer_id.read_only}
            columns={fields.payer.children}
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / contract`,
          }}
          {...globalProps}
        >
          <TableControls.ItemPicker
            value={row.contract}
            backend="/api/catalog/contracts/"
            onChange={(e, v) => onChange(e, rowNumber, { contract: v })}
            readOnly={fields.contract_id.read_only}
            columns={fields.contract.children}
            filter={{ payer: row.payer ? row.payer.id : null }}
            canErase
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / payment_number`,
          }}
          {...globalProps}
        >
          <TableControls.StringInput
            value={row.payment_number}
            onChange={(e, v) => onChange(e, rowNumber, { payment_number: v })}
            readOnly={fields.payment_number.read_only}
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr + 1} / payment_notes`,
          }}
          {...globalProps}
        >
          <TableControls.StringInput
            value={row.payment_notes}
            onChange={(e, v) => onChange(e, rowNumber, { payment_notes: v })}
            readOnly={fields.payment_notes.read_only}
          />
        </DivElement>

        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / order_amount`,
          }}
          {...globalProps}
        >
          <DecimalInput
            value={Number(row.order_amount)}
            onChange={(e, v) => onChange(e, rowNumber, { order_amount: v.toFixed(2) })}
            readOnly={fields.order_amount.read_only}
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / revenue_amount`,
          }}
          {...globalProps}
        >
          <DecimalInput
            value={Number(row.revenue_amount)}
            onChange={(e, v) => onChange(e, rowNumber, { revenue_amount: v.toFixed(2) })}
            readOnly={fields.revenue_amount.read_only}
          />
        </DivElement>
        <DivElement
          style={{
            ...globalStyle,
            gridArea: `${cr} / warning`,
          }}
          {...globalProps}
        >
          <TableControls.CheckboxInput
            value={row.warning}
            onChange={(e, v) => onChange(e, rowNumber, { warning: v })}
            readOnly={fields.warning.read_only}
          />
        </DivElement>
      </>
    );
  }
}

export default BodyComponent;
