import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import TPs from './tp';
import Tab from '../../../components/Tab';
// import Addr from '../../catalogs/addr';
// import EstateChanger from '../../estateChanger';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "number date"
    "name name"
    "payer kdb"
    "check check"
    "org org"
    ;
  grid-template-columns: 1fr 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;

const LandGrid = styled(DocGrid)`
  grid-template-areas: 
    "date_to percent" 
    "land_category region"
    "square price"
    "in_area in_area"
    ;
  grid-template-columns: 1fr 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;

const GeneralTPColumnTypes = {
};

// const AddrGrid = styled(DocGrid)`
//   grid-template-areas:
//     "region district locality area street house"
//     "addr addr addr addr addr addr"
//     ;
//   grid-template-columns: repeat(6, 1fr);
// `;


const editorRenderer = (getControl, {
  data, fields, dataSetter, currentUser,
}) => {
  const renderP1 = () => (
    <>
      <ThisDocGrid>
        <GridItem area="number">
          {getControl('number')}
        </GridItem>
        <GridItem area="date">
          {getControl('date')}
        </GridItem>
        <GridItem area="name">
          {getControl('name')}
        </GridItem>
        <GridItem area="payer">
          {getControl('payer', { backend: '/api/catalog/payers/' })}
        </GridItem>
        {currentUser.is_superuser && (
          <GridItem area="org">
            {getControl('org', {
              backend: '/api/catalog/orgs/',
            })}
          </GridItem>
        )}
        <GridItem area="kdb">
          {getControl('kdb', { backend: '/api/catalog/kdbs/' })}
        </GridItem>
        <GridItem area="check">
          {getControl('is_default')}
        </GridItem>
      </ThisDocGrid>
      <TPs.GeneralTP
        data={data.warehouse_set}
        options={fields.warehouse_set}
        onChange={(e, newRecs) => dataSetter({ warehouse_set: newRecs })}
        overrideColumns={GeneralTPColumnTypes}
      />
    </>
  );

  const renderP2 = () => (
    <>
      <LandGrid>
        <GridItem area="land_category">
          {getControl('land_category', {
            backend: '/api/catalog/landcategories/',
          })}
        </GridItem>
        <GridItem area="region">
          {getControl('region', {
            canErase: true,
            backend: '/api/catalog/regions/',
            filter: {
              org: currentUser.is_superuser ? data.org && data.org.id : currentUser.org.id,
            },
          })}
        </GridItem>
        <GridItem area="price">
          {getControl('price')}
        </GridItem>
        <GridItem area="square">
          {getControl('square')}
        </GridItem>
        <GridItem area="date_to">
          {getControl('date_to')}
        </GridItem>
        <GridItem area="in_area">
          {getControl('in_area')}
        </GridItem>
        <GridItem area="percent">
          {getControl('percent')}
        </GridItem>
      </LandGrid>
    </>
  );

  // const renderP3 = () => (
  //   <>
  //     <AddrGrid>
  //       <Addr
  //         value={{ id: data.address_uuid, repr: data.address }}
  //         onChange={(e, v) => dataSetter({
  //           address: v.strAddr, address_uuid: v.id, estate_name: '', estate_uuid: null,
  //         })}
  //       />
  //     </AddrGrid>
  //     {data.address && (
  //       <EstateChanger
  //         address={data.address_uuid}
  //         estate_name={data.estate_name}
  //         estate_uuid={data.estate_uuid}
  //         onChange={
  //           (e, v) => dataSetter({ estate_name: v.estate_name, estate_uuid: v.estate_uuid })
  //         }
  //         label="Майно"
  //       />
  //     )}
  //   </>
  // );
  const panes = [
    { menuItem: 'Загальне', render: renderP1 },
    { menuItem: 'Відомості про землю', render: renderP2 },
  ];
  return (
    <Tab panes={panes} />
  );
};

export default getBaseEditor(editorRenderer, '/api/catalog/contracts/', CommandPanels.docCommandPanel);
