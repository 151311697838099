import PayersReport from './payers';
import CMPReport from './comparePeriods';
import ContractReprot from './contractReports';
import ReportLister from './reportList';
import Compare412 from './compare412';
import CompareBetween412 from './compareBetween412';

export default {
  PayersReport,
  ReportLister,
  CMPReport,
  ContractReprot,
  Compare412,
  CompareBetween412,
};
