import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Spiner } from '../../../../components/Styled/Misc';
import { SuccesIcon, ErrorIcon } from '../../../../components/Styled/Buttons/icons';
import api from '../../../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../../../providers/authProvider';
import { getFrontEndByBackend } from '../../../../const/meta';

const StyledFileContainer = Styled.div.attrs((props) => ({
  style: {
    background: props.active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
  },
}))`
  display: flex;
  align-item: center;
  justify-content: left;
  margin: 3px 0;
  cursor: pointer;
`;

const StyledImg = Styled.img`
`;


const StyledFileName = Styled.div`
  display: flex;
  align-items: center;
`;

const StyledErrMessage = Styled.div`
  color: red;
  padding-left: 5px;
`;

const TIMEOUT = 3000;

class FileRecord extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    taskId: PropTypes.string,
    authF: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    onDone: PropTypes.func,
  };

  static defaultProps = {
    taskId: null,
    onClick: () => null,
    active: false,
    onDone: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      status: 'PENDING',
      isErrored: false,
      errMessage: '',
    };
  }

  componentDidMount() {
    this.checkTask();
  }

  checkTask = () => {
    const {
      authF,
      taskId,
      onDone,
      name,
    } = this.props;
    if (!taskId) {
      setTimeout(this.checkTask, TIMEOUT);
    } else {
      api.get$(`/api/tasks/${taskId}/`, authF)
        .then((r) => {
          if (r.ok) {
            r.json()
              .then((d) => {
                switch (d.status) {
                case 'PENDING':
                  setTimeout(this.checkTask, TIMEOUT);
                  break;
                case 'FAILURE':
                  onDone(name, false);
                  this.setState({
                    status: 'FAILURE',
                    // result: null,
                    isErrored: true,
                    errMessage: d.result.error,
                  });
                  break;
                case 'SUCCESS':
                  this.processResult(d);
                  break;
                default:
                  console.error(`Unknow task status ${d.status}`);
                  onDone(name, false);
                }
              });
          } else {
            console.log(r);
            onDone(name, false);
            this.setState({
              status: 'FAILURE',
              // result: null,
              isErrored: true,
              errMessage: `${r.status} ${r.statusText}`,
            });
          }
        });
    }
  };

  processResult = (resp) => {
    const {
      authF,
      name,
      onDone
    } = this.props;
    // Получаем информацию о добавленых объектах

    const types = Object.values(resp.result.created)
      .filter((cl) => cl.items.length)
      .map((cl) => ({
        ...cl,
        frontend: getFrontEndByBackend(cl.uri),
      }));
    const labelGetter = async (type) => {
      const r = await api.options$(type.uri, authF);
      if (r.ok) {
        const d = await r.json();
        return {
          ...type,
          label: d.name,
        };
      }
      console.error(r);
      return {
        ...type,
        label: null,
      };
    };

    const itemsGetter = async (type) => {
      console.log(1, type);
      const r = await api.get$(type.uri, authF, { id_in: String(type.items) });
      console.log(2, r);
      if (r.ok) {
        const d = await r.json();
        if (d.results) {
          return d.results.map((item) => ({
            id: item.id,
            repr: item.repr,
          }));
        }
        return d.map((item) => ({
          id: item.id,
          repr: item.repr,
        }));
      }
      console.error(r);
      return type.items;
    };

    const allGetter = async (type) => {
      const labeled = await labelGetter(type);
      const repred = await itemsGetter(type);
      return {
        ...type,
        ...labeled,
        items: repred,
      };
    };

    Promise.all(types.map((type) => allGetter(type)))
      .then((results) => {
        onDone(name, true, results);
      });

    this.setState({
      status: 'SUCCESS',
      isErrored: false,
      errMessage: '',
    });
  };

  render() {
    const {
      name,
      onClick,
      active,
      taskId,
    } = this.props;
    const {
      status,
      isErrored,
      errMessage
    } = this.state;
    return (
      <StyledFileContainer onClick={(e) => onClick(e, name)} active={active}>
        {status === 'PENDING' && (
          <Spiner/>
        )}
        {status === 'SUCCESS' && (
          <StyledImg src={SuccesIcon} alt="OK"/>
        )}
        {status === 'FAILURE' && (
          <StyledImg src={ErrorIcon} alt="Error"/>
        )}
        <StyledFileName>
          {process.env.NODE_ENV === 'development'
            ? `${name} (${taskId})`
            : `Завантаження файл ${name}`}
        </StyledFileName>
        {isErrored && (
          <StyledErrMessage>
            {errMessage}
          </StyledErrMessage>
        )}
      </StyledFileContainer>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(FileRecord);
