import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  OpenedItemIcon, ClosedItemIcon, YesIcon, NoIcon,
} from '../Styled/Buttons/icons';
import {
  StyledDiv, StyledNumber, StyledString, StyledTableCell,
} from './styles';

const getFieldContent = (value, { type, choices }) => {
  switch (type) {
  case 'string':
    return (<StyledString>{value}</StyledString>);
  case 'email':
    return (<StyledString>{value}</StyledString>);
  case 'integer':
    return (<StyledNumber>{value}</StyledNumber>);
  case 'float':
    return (<StyledNumber>{value}</StyledNumber>);
  case 'decimal':
    return (<StyledNumber>{value}</StyledNumber>);
  case 'choice':
    return (
      <StyledString>
        {
          // eslint-disable-next-line
          choices.reduce((R, c) => c.value === value ? c.display_name : R, '')
        }
      </StyledString>
    );
  case 'date':
    if (!value) return '-';
    return (
      <StyledString>
        {(new Date(value)).toLocaleString('uk', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </StyledString>
    );
  case 'nested object':
    return (<StyledString>{value && value.repr}</StyledString>);
  case 'boolean':
    return value ? (<img src={YesIcon} alt="Yes" />) : (<img src={NoIcon} alt="No" />);
  default:
    return `Type "${type}" unsupported`;
  }
};

class TableRow extends PureComponent {
  static propTypes = {
    cols: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    rowNumber: PropTypes.number.isRequired,
    row: PropTypes.shape({
      id: PropTypes.number,
      pk: PropTypes.number,
      executed: PropTypes.bool,
    }).isRequired,
    isHierarhical: PropTypes.bool,
    level: PropTypes.number,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    opened: PropTypes.bool,
    canOpened: PropTypes.bool,
    onOpen: PropTypes.func,
    mode: PropTypes.oneOf(['cat', 'doc']).isRequired,
  };

  static defaultProps = {
    active: false,
    isHierarhical: false,
    level: 0,
    // eslint-disable-next-line
    onClick: () => null,
    // eslint-disable-next-line
    onDoubleClick: () => null,
    opened: false,
    canOpened: false,
    // eslint-disable-next-line
    onOpen: () => null,
  };

  render() {
    const {
      cols,
      rowNumber,
      row,
      isHierarhical,
      level,
      active,
      onClick,
      onDoubleClick,
      opened,
      canOpened,
      onOpen,
      mode,
    } = this.props;
    return (
      <>
        { isHierarhical && (
          <StyledTableCell key="toggler" colName="toggler" rowId={rowNumber + 1} active={active} noBG>
            <StyledDiv
              level={level}
              opened={opened}
              disabled={!canOpened}
              onClick={(e) => onOpen(e, row.id)}
            >
              {!opened ? (
                <img src={ClosedItemIcon} alt="Закрити" />
              ) : (
                <img src={OpenedItemIcon} alt="Відкрити" />
              )}
            </StyledDiv>
          </StyledTableCell>
        )}
        { mode === 'doc' && (
          <StyledTableCell key="toggler" colName="toggler" rowId={rowNumber + 1} active={active} noBG>
            <StyledDiv style={{ justifyContent: 'center', display: 'flex' }}>
              {row.executed && (
                <img src={YesIcon} alt="Проведен" />
              )}
            </StyledDiv>
          </StyledTableCell>
        )}
        {cols.map((col) => (
          <StyledTableCell
            key={`cell${col.name}.${row.id}`}
            colName={col.name}
            rowId={rowNumber + 1}
            active={active}
            onClick={(e) => onClick(e, row.id, row)}
            onDoubleClick={(e) => onDoubleClick(e, row.id, row)}
          >
            {getFieldContent(row[col.name], col)}
          </StyledTableCell>
        ))}
      </>
    );
  }
}

export default TableRow;
