import React, {
  useEffect, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import { paramPropType, tablePropType } from '../hooks';
import ParamsEditor from './paramValues';
import TableRecords from './records';
import { EditorContainer } from '../../../../components/Styled/Forms';
import { TwoColGrid } from '../styles';
import Tab from '../../../../components/Tab';

const TableBrowser = ({
  params, tables, tableFields, onGetTableRecords, tableRows,
}) => {
  const [paramValues, setParamValues] = useState({});
  const [howManys, setHowManys] = useState({});
  useEffect(
    () => setParamValues(
      (oldVals) => params.reduce((R, p) => ({
        ...R,
        [p.name]: oldVals[p.name] || null,
      }), {}),
    ), [params, setParamValues],
  );
  useEffect(
    () => setHowManys(
      (oldVals) => tables.reduce((R, t) => ({
        ...R,
        [t.name]: oldVals[t.name] || -1,
      }), {}),
    ), [tables, setHowManys],
  );

  return (
    <div>
      <EditorContainer>
        <TwoColGrid>
          <div>
            <ParamsEditor
              params={params}
              onChange={(name, value) => setParamValues((oldVals) => ({
                ...oldVals,
                [name]: value,
              }))}
              paramValues={paramValues}
            />
          </div>
          <div>
            <Tab
              panes={tables.map((t) => ({
                menuItem: t.name,
                render: () => (
                  <TableRecords
                    fields={tableFields[t.name] || []}
                    tableName={t.name}
                    howMany={howManys[t.name] || 0}
                    onChangeHowMany={(v) => setHowManys((oldVal) => ({
                      ...oldVal,
                      [t.name]: v,
                    }))}
                    onRequestRecords={
                      () => onGetTableRecords(t.name, paramValues, howManys[t.name])
                    }
                    records={tableRows[t.name]}
                  />

                ),
              }))}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>

  );
};

TableBrowser.propTypes = {
  params: PropTypes.arrayOf(paramPropType).isRequired,
  tables: PropTypes.arrayOf(tablePropType).isRequired,
  tableFields: PropTypes.shape({}).isRequired,
  onGetTableRecords: PropTypes.func.isRequired,
  tableRows: PropTypes.shape({}).isRequired,
};


export default memo(TableBrowser);
