import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import GroupColumn, { groupcolumnPropType } from './groupColumn';
import { StyledList } from '../styles';

class GroupColumns extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(groupcolumnPropType).isRequired,
    onDoubleClick: PropTypes.func,
    activeRow: PropTypes.number,
    onActivateRow: PropTypes.func.isRequired,
    droppableId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onDoubleClick: () => null,
    activeRow: null,
  };

  render() {
    const {
      columns, onDoubleClick, activeRow, onActivateRow, droppableId,
    } = this.props;
    return (
      <Droppable droppableId={droppableId}>
        {(providedDrop) => (
          <StyledList ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            {columns.map((column, index) => (
              <GroupColumn
                // eslint-disable-next-line
                key={index}
                column={column}
                active={index === activeRow}
                onClick={onActivateRow}
                onDoubleClick={onDoubleClick}
                index={index}
              />
            ))}
            {providedDrop.placeholder}
          </StyledList>
        )}
      </Droppable>
    );
  }
}

export default GroupColumns;
