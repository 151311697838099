import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NetworkException } from '../../../api/utils';
import { EditorControls } from '../../BaseEditor';
import { ErrorMessage } from '../../../components/Styled/Misc';

const API_URL = 'https://api2.maino.ciat.net.ua/api/v1/';
const UKRAINE_UID = '1437c9b6-370f-11e7-8ed7-000c29ff5864';

class Addr extends PureComponent {
  static propTypes = {
    value: PropTypes.shape({
      id: PropTypes.string,
      repr: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const value = props.value || { addr_data: {} };
    this.state = {
      isErrored: false,
      errText: null,
      regions: [], // Области
      districts: [], // райони областей
      localities: [], // нас. пункты
      areas: [], // районы города
      streets: [],
      houses: [],
      token: null,
      loadingRegions: false,
      loadingDistricts: false,
      loadingLocalities: false,
      loadingAreas: false,
      loadingStreets: false,
      loadingHouses: false,
      addr: {
        regionId: null,
        districtId: null,
        localityId: null,
        areaId: null,
        streetId: null,
        houseId: value.id,
        strAddr: value.repr,
      },
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const { addr } = this.state;
    this.login().then((token) => {
      if (!value.id) {
        this.loadRegions(token);
      } else {
        // this.setState({ loadingHouses: true });
        this.getResources(`${API_URL}addressobjects/${value.id}`, token).then((d) => {
          this.setState({ });
          if (d.length) {
            const newAddr = {
              ...addr,
              regionId: d[0].regionGuid,
              districtId: d[0].districtGuid,
              localityId: d[0].localityGuid,
              areaId: d[0].localityAreaGuid,
              streetId: d[0].streetGuid,
            };
            this.loadRegions(token);
            this.loadDistricts(token, newAddr.regionId);
            this.loadLocalities(token, newAddr.regionId, newAddr.districtId);
            this.loadAreas(token, newAddr.localityId);
            this.loadStreets(token, newAddr.localityId, newAddr.areaId);
            this.loadHouses(token, newAddr.streetId);
            this.setState({
              // loadingHouses: false,
              addr: newAddr,
            });
          } else {
            // this.setState({
            //   loadingHouses: false,
            // });
          }
        });
      }
    });
  }


  login = async () => {
    const r = await fetch(`${API_URL}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: 'FDI',
        password: 'fdi',
      }),
    });
    if (r.ok) {
      const d = await r.json();
      this.setState({ token: d.token });
      return d.token;
    }
    return false;
  }


  getResources = async (url, token, signal = null, page = 1) => {
    const params = page === 1 ? '' : `?page=${page}`;
    const r = await fetch(`${url}${params}`, {
      method: 'GET',
      signal,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    });
    if (r.ok) {
      const d = await r.json();
      if (d.length === 50) {
        const d2 = await this.getResources(url, token, signal, page + 1);
        return [...d, ...d2];
      }
      return d;
    }
    throw new NetworkException(`${r.status} ${r.statusText}`);
  };

  loadRegions = async (token) => {
    if (this.regionsAbortController) {
      this.regionsAbortController.abort();
    }
    this.regionsAbortController = new AbortController();
    this.setState({ loadingRegions: true });
    try {
      const d = await this.getResources(`${API_URL}countries/${UKRAINE_UID}/regions`, token, this.regionsAbortController.signal);
      this.regionsAbortController = null;
      this.setState({
        regions: d.map((data) => ({ id: data.guid, repr: `${data.regionName} ${data.regionTypeFull}` })),
        loadingRegions: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadDistricts = async (token, regionId) => {
    if (this.disctrictsAbortController) {
      this.disctrictsAbortController.abort();
    }
    this.disctrictsAbortController = new AbortController();
    this.setState({ loadingDistricts: true });
    try {
      const d = await this.getResources(`${API_URL}regions/${regionId}/districts`, token, this.disctrictsAbortController.signal);
      this.disctrictsAbortController = null;
      this.setState({
        districts: d.map((data) => ({ id: data.guid, repr: `${data.districtName} ${data.districtTypeFull}` })),
        loadingDistricts: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadLocalities= async (token, regionId, districtId = null) => {
    if (this.localitiesAbortController) {
      this.localitiesAbortController.abort();
    }
    this.localitiesAbortController = new AbortController();
    this.setState({ loadingLocalities: true });
    const uri = districtId ? `${API_URL}districts/${districtId}/localities` : `${API_URL}regions/${regionId}/localities`;
    try {
      const d = await this.getResources(uri, token, this.localitiesAbortController.signal);
      this.localitiesAbortController = null;
      this.setState({
        localities: d.map((data) => ({ id: data.guid, repr: `${data.localityName} ${data.localityTypeFull}` })),
        loadingLocalities: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadAreas = async (token, localityId) => {
    if (this.areasAbortController) {
      this.areasAbortController.abort();
    }
    this.areasAbortController = new AbortController();
    this.setState({ loadingAreas: true });
    try {
      const d = await this.getResources(`${API_URL}localities/${localityId}/localityareas`, token, this.areasAbortController.signal);
      this.areasAbortController = null;
      this.setState({
        areas: d.map((data) => ({ id: data.guid, repr: `${data.localityAreaName} ${data.localityAreaTypeFull}` })),
        loadingAreas: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadStreets= async (token, localytyId, areaId = null) => {
    if (this.streetsAbortController) {
      this.streetsAbortController.abort();
    }
    this.streetsAbortController = new AbortController();
    this.setState({ loadingStreets: true });
    const uri = areaId ? `${API_URL}localityareas/${areaId}/streets` : `${API_URL}localities/${localytyId}/streets`;
    try {
      const d = await this.getResources(uri, token, this.streetsAbortController.signal);
      this.streetsAbortController = null;
      this.setState({
        streets: d.map((data) => ({ id: data.guid, repr: `${data.streetName} ${data.streetTypeShort}` })),
        loadingStreets: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  loadHouses = async (token, streetId) => {
    if (this.housesAbortController) {
      this.housesAbortController.abort();
    }
    this.housesAbortController = new AbortController();
    this.setState({ loadingHouses: true });
    try {
      const d = await this.getResources(`${API_URL}streets/${streetId}/addressobjects`, token, this.housesAbortController.signal);
      this.housesAbortController = null;
      this.setState({
        houses: d.map((data) => ({
          id: data.guid,
          repr: `${data.addressObjectOwnName}`,
          strAddr: `${data.countryName}, ${data.regionName} ${data.districtName || ''} ${data.localityTypeFull} ${data.localityName}, ${data.localityAreaName || ''} ${data.streetTypeName} ${data.streetName} ${data.addressObjectTypeShortName} ${data.addressObjectOwnName}`,
        })),
        loadingHouses: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      regions, districts, localities, areas, streets, houses, addr, errText, isErrored,
      loadingRegions, loadingDistricts, loadingLocalities, loadingAreas, loadingStreets,
      loadingHouses, token,
    } = this.state;
    const { onChange } = this.props;
    return (
      <>
        {isErrored && (
          <ErrorMessage text={errText} />
        )}
        <div style={{ gridArea: 'region' }}>
          <EditorControls.Selector
            label="Область"
            values={regions.map((c) => ({ value: c.id, display_name: c.repr }))}
            value={addr.regionId}
            loading={loadingRegions}
            onChange={(e, v) => {
              this.setState({ addr: { ...addr, regionId: v } });
              this.loadDistricts(token, v);
              this.loadLocalities(token, v);
            }}
          />
        </div>
        <div style={{ gridArea: 'district' }}>
          <EditorControls.Selector
            label="Район"
            maybeNull
            values={districts.map((c) => ({ value: c.id, display_name: c.repr }))}
            value={addr.districtId}
            loading={loadingDistricts}
            onChange={(e, v) => {
              this.setState({ addr: { ...addr, districtId: v } });
              this.loadLocalities(token, addr.regionId, v);
            }}
          />
        </div>
        <div style={{ gridArea: 'locality' }}>
          <EditorControls.Selector
            label="Нас. пункт"
            values={localities.map((c) => ({ value: c.id, display_name: c.repr }))}
            value={addr.localityId}
            loading={loadingLocalities}
            onChange={(e, v) => {
              this.setState({ addr: { ...addr, localityId: v } });
              this.loadAreas(token, v);
              this.loadStreets(token, v);
            }}
          />
        </div>
        <div style={{ gridArea: 'area' }}>
          <EditorControls.Selector
            label="Район неселеного пункту"
            maybeNull
            values={areas.map((c) => ({ value: c.id, display_name: c.repr }))}
            value={addr.areaId}
            loading={loadingAreas}
            onChange={(e, v) => {
              this.setState({ addr: { ...addr, areaId: v } });
              this.loadStreets(token, addr.localityId, v);
            }}
          />
        </div>
        <div style={{ gridArea: 'street' }}>
          <EditorControls.Selector
            label="Вулиця"
            values={streets.map((c) => ({ value: c.id, display_name: c.repr }))}
            value={addr.streetId}
            loading={loadingStreets}
            onChange={(e, v) => {
              this.setState({ addr: { ...addr, streetId: v } });
              this.loadHouses(token, v);
            }}
          />
        </div>
        <div style={{ gridArea: 'house' }}>
          <EditorControls.Selector
            label="Будинок"
            values={houses.map((c) => ({ value: c.id, display_name: c.repr }))}
            value={addr.houseId}
            onChange={(e, v) => {
              const strAddr = houses.filter((h) => h.id === v).reduce((R, r) => ({ strAddr: r.strAddr, id: r.id }), '');
              console.log(strAddr);
              this.setState({ addr: { ...addr, houseId: v, ...strAddr } });
              onChange(e, { id: v, ...strAddr });
            }}
            loading={loadingHouses}
          />
        </div>
        <div style={{ gridArea: 'addr' }}>
          {addr.strAddr ? (
            <span>{addr.strAddr}</span>
          ) : (
            <span>Адресу не обрано</span>
          )}
        </div>
      </>
    );
  }
}

export default Addr;
