import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styledCommandPanelButton';

export const Button = ({
  content, onClick, className, disabled, children, title, onDoubleClick,
}) => (
  <StyledButton
    onClick={onClick}
    className={className}
    disabled={disabled}
    title={title}
    onDoubleClick={onDoubleClick}
  >
    {content}
    {children}
  </StyledButton>
);

Button.propTypes = {
  onDoubleClick: PropTypes.func,
  content: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => null,
  onDoubleClick: () => null,
  content: null,
  children: null,
  className: '',
  disabled: false,
  title: null,
};

export default Button;
