import React, {
  useMemo, memo, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddButton,
  RemoveSelectionButton,
} from '../../../../components/Styled/Buttons';
import { CommandPanel } from '../../../../components/Styled/Misc';
import { tablePropType } from '../hooks';
import SettingItem from './item';
import SettingProps from './props';
import { EditorContainer } from '../../../../components/Styled/Forms';
import { TwoColGrid } from '../styles';

const SettingsPage = ({
  settings, activeSetting, onRenameSetting, onChangeSetting,
  onAddSetting, onRemoveSetting, onSetActiveSetting,
}) => {
  const [height, setHeight] = useState(0);

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const displaySettings = useMemo(
    () => settings.map((t, key) => ({
      ...t,
      key,
    })), [settings],
  );

  const entries = useMemo(() => Object.entries(settings.filter((t) => t.name === activeSetting)
    .reduce((R, t) => t.text, [])), [activeSetting, settings]);

  return (
    <div>
      <EditorContainer>
        <TwoColGrid ref={containerHeaderRef} height={height}>
          <div>
            <CommandPanel>
              <AddButton
                title="Додати налаштування"
                content=""
                onClick={() => onAddSetting(Math.random()
                  .toString(36))}
              />
              <RemoveSelectionButton
                title="Видалити налаштування"
                content=""
                onClick={() => onRemoveSetting(activeSetting)}
                disabled={activeSetting === 'main' || !activeSetting}
              />
            </CommandPanel>
            <div>
              {displaySettings.map((setting) => (
                <SettingItem
                  key={setting.key}
                  active={setting.name === activeSetting}
                  onClick={() => onSetActiveSetting(setting.name)}
                  setting={setting}
                  onRenameSetting={onRenameSetting}
                />
              ))}
            </div>
          </div>
          <div>
            <SettingProps
              onChange={(v, k) => onChangeSetting(activeSetting, v, k)}
              entries={entries}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>
  );
};

SettingsPage.propTypes = {
  settings: PropTypes.arrayOf(tablePropType).isRequired,
  activeSetting: PropTypes.string,
  onRenameSetting: PropTypes.func.isRequired,
  onChangeSetting: PropTypes.func.isRequired,
  onAddSetting: PropTypes.func.isRequired,
  onRemoveSetting: PropTypes.func.isRequired,
  onSetActiveSetting: PropTypes.func.isRequired,
};

SettingsPage.defaultProps = {
  activeSetting: null,
};

export default memo(SettingsPage);
