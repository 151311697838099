import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import Tab from '../../../components/Tab';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "number date"
    "date_start date_end"
    "payer estate"
    "account account"
    ;
  grid-template-columns: 1fr 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;


const editorRenderer = (getControl) => {
  const renderP1 = () => (
    <>
      <ThisDocGrid>
        <GridItem area="number">
          {getControl('number')}
        </GridItem>
        <GridItem area="date">
          {getControl('date')}
        </GridItem>
        <GridItem area="date_start">
          {getControl('date_start')}
        </GridItem>
        <GridItem area="date_end">
          {getControl('date_end')}
        </GridItem>
        <GridItem area="payer">
          {getControl('payer', { backend: '/api/catalog/payers/' })}
        </GridItem>
        <GridItem area="estate">
          {getControl('estate')}
        </GridItem>
        <GridItem area="account">
          {getControl('account', { backend: '/api/catalog/accounts/' })}
        </GridItem>
      </ThisDocGrid>
    </>
  );
  const renderP2 = () => (
    <>
      <GridItem area="address">
        {getControl('address')}
      </GridItem>
    </>
  );

  const panes = [
    { menuItem: 'Загальне', render: renderP1 },
    { menuItem: 'Адреса', render: renderP2 },
  ];
  return (
    <Tab panes={panes} />
  );
};

export default getBaseEditor(editorRenderer, '/api/catalog/calculatedcontracts/', CommandPanels.catCommandPanel);
