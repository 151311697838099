import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextArea } from '../../components/Styled/elements';

const StyledTA = styled(TextArea)`
`;


class TextInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    value: '',
    onChange: () => console.log('This control id ReadOnly because prop "onChange" is not defined'),
    disabled: false,
    readOnly: false,
    maxLength: 0,
    errors: null,
  };

  changeHandler = (e) => {
    const { readOnly, maxLength, onChange } = this.props;
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };

  render() {
    const {
      value, disabled, errors,
    } = this.props;
    return (
      <StyledTA value={value || ''} onChange={this.changeHandler} disabled={disabled} errored={!!errors} />
    );
  }
}

export default TextInput;
