import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const StyledContainer = Styled.div`
  display: flex;
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  padding: 5px;
  &:hover{
    border: 1px solid #37a0a7;
    box-shadow: 0 0 20px rgba(0,0,0,0.16);
  };
`;

const StyledContainer2 = Styled.div`
  margin-left: 10px;
  flex: 1 1 auto;
`;

const StyledIcon = Styled.div`
  &>* {
    height: 40px;
  }
`;

const StyledHeader = Styled.div`
  // color: #008F21;
  color: #488de4;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
  font-size: 1.05em;
   &>*{
    color: #3f51b4;
     &:hover{
       color: #103278;
     };
   }
`;

const StyledContent = Styled.div`
  font-size: 0.8em;
  font-style: italic;
  color: grey;
`;

const Item = ({ header, icon, content }) => (
  <StyledContainer>
    {icon && (
      <StyledIcon>
        {icon}
      </StyledIcon>
    )}
    <StyledContainer2>
      {header && (
        <StyledHeader>
          {header}
        </StyledHeader>
      )}
      <StyledContent>
        {content}
      </StyledContent>
    </StyledContainer2>
  </StyledContainer>
);

Item.propTypes = {
  header: PropTypes.node,
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
};

Item.defaultProps = {
  header: null,
  icon: null,
};

export default Item;
