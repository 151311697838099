import React from 'react';
import PropTypes from 'prop-types';
import { NextIcon } from './icons';
import { StyledColorButton, StyledImg } from './styledCommandPanelButton';

export const NextButton = ({
  content, onClick, disabled, displayText,
}) => (
  <StyledColorButton onClick={onClick} disabled={disabled} title={content}>
    {displayText && content}
    <StyledImg src={NextIcon} alt={content} />
  </StyledColorButton>
);

NextButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  displayText: PropTypes.bool,
};

NextButton.defaultProps = {
  onClick: () => null,
  content: 'Далі',
  disabled: false,
  displayText: false,
};

export default NextButton;
