import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableControls from '../../../BaseEditor/tableControls';
import { DeleteIcon, AddIcon } from '../../../../components/Styled/Buttons/icons';
import { Button } from '../../../../components/Styled/Buttons';

const SelectedDiv = styled.div`
  grid-template-columns:  10% 1fr;
`;

const MultiContainer = styled.div`
  grid-template-columns: 1fr 10%;
`;
const TextBlock = styled.div`
  font-size: 14px;
  background: #f7f7f7;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 2px 5px;
`;


const PluralValues = ({
  short, value, onChange, ctype, resource,
}) => (
  <SelectedDiv>
    {!short ? (
      <>
        <Button
          onClick={(e) => onChange(e, [...value, null])}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          <img src={AddIcon} alt="Додати" title="Додати" />
        </Button>
        <MultiContainer>
          {value.map((itemValue, k) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${itemValue}-${k}`} style={{ display: 'flex' }}>
              <TableControls.MultiVaueInput
                ctype={ctype}
                resource={resource}
                value={itemValue}
                onChange={(e, v) => onChange(e, [
                  ...value.slice(0, k),
                  v,
                  ...value.slice(k + 1),
                ])}
                id={`${k}_${itemValue === null ? 'null' : itemValue.repr || itemValue}`}
              />
              <Button
                onClick={(e) => onChange(e, [
                  ...value.slice(0, k),
                  ...value.slice(k + 1),
                ])}
                onDoubleClick={(e) => e.stopPropagation()}
              >
                <img
                  src={DeleteIcon}
                  title="Видалити"
                  alt="Видалити"
                />
              </Button>
            </div>
          ))}
        </MultiContainer>
      </>
    ) : (
      <TextBlock>repr</TextBlock>
    )}
  </SelectedDiv>

);

PluralValues.propTypes = {
  short: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    ]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  ctype: PropTypes.string.isRequired,
  resource: PropTypes.string,
};

PluralValues.defaultProps = {
  short: false,
  resource: null,
};
export default PluralValues;
