import React from 'react';
import PropTypes from 'prop-types';
import { Question, CommandPanel, QuestionActions } from '../../../components/Styled/Misc';
import {
  NewButton, EditButton, DeleteButton, SelectAllButton, ExportXLSButton,
} from '../../../components/Styled/Buttons';
import { actions } from './actions';
import { mapStateAuth, withAuthConsumer } from '../../../providers/authProvider';

const DcatListerCP = ({
  onActionClick, canNew, canEdit, canDelete, currentUser,
}) => (
  <CommandPanel>
    <NewButton onClick={(e) => onActionClick(e, actions.new)} disabled={!canNew} />
    <EditButton onClick={(e) => onActionClick(e, actions.edit)} disabled={!canEdit} />
    <Question
      actions={Object.values(QuestionActions.YesNoAction)}
      questionText="Видалити цей елемент?"
      actionHandler={(e, action) => {
        if (action === QuestionActions.YesNoAction.yes.value) onActionClick(e, actions.delete);
      }}
    >
      <DeleteButton disabled={!canDelete} />
    </Question>
    <SelectAllButton onClick={(e) => onActionClick(e, actions.selectAll)} />
    {currentUser.is_superuser && (
      <ExportXLSButton onClick={(e) => onActionClick(e, actions.exportXLS)} />
    )}
  </CommandPanel>
);

DcatListerCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canNew: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  currentUser: PropTypes.shape({
    is_superuser: PropTypes.bool,
  }).isRequired,
};

DcatListerCP.defaultProps = {
  canNew: true,
  canEdit: true,
  canDelete: true,
};

export const catListerCP = withAuthConsumer(mapStateAuth)(DcatListerCP);

export default catListerCP;
