import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Root from './containers/Root';
import ErrorBoundary from './components/MaterialUI/errorBoundary';
// import * as serviceWorker from './serviceWorker';


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c1f2ff41563b4d8090f6d05040a61280@o576767.ingest.sentry.io/5773691',
    integrations: [...Sentry.defaultIntegrations, new Integrations.BrowserTracing()],

    // Установите tracesSampleRate на 1.0,
    // чтобы захватывать 100% транзакций для мониторинга производительности.
    // Рекомендуется скорректировать это значение в процессе производства.
    // Чтобы уменьшить объем собираемых данных о производительности,
    // измените значение tracesSampleRate на значение от 0 до 1.
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<ErrorBoundary><Root /></ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
