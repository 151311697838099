import React, {
  useMemo, memo, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddButton,
  RemoveSelectionButton,
} from '../../../../components/Styled/Buttons';
import { CommandPanel } from '../../../../components/Styled/Misc';
import { calcPropType } from '../hooks';
import FieldItem from './item';
import CalcProps from './props';
import { EditorContainer } from '../../../../components/Styled/Forms';
import { TwoColGrid } from '../styles';

const CalcsPage = ({
  calcs,
  activeCalc,
  onRenameCalc,
  onChangeCalc,
  onAddCalc,
  onRemoveCalc,
  onSetActiveCalc,
  allowedCtypes,
  fields,
}) => {
  const [height, setHeight] = useState(0);
  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const displayCalcs = useMemo(
    () => calcs.map((t, itemKey) => ({
      ...t,
      itemKey,
    })),
    [calcs],
  );
  const cValue = useMemo(
    () => calcs.filter((c) => c.name === activeCalc).reduce((R, f) => f, {}),
    [activeCalc, calcs],
  );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({
      value,
      display_name: value,
    })),
    [allowedCtypes],
  );

  const allowedFields = useMemo(
    () => fields.map((f) => ({
      value: f.name,
      display_name: f.name,
    })),
    [fields],
  );

  return (
    <div>
      <EditorContainer>
        <CommandPanel>
          <AddButton
            title="Додати поле"
            content=""
            onClick={() => onAddCalc(Math.random().toString(36))}
          />
          <RemoveSelectionButton
            title="Видалити поле"
            content=""
            onClick={() => onRemoveCalc(activeCalc)}
            disabled={!activeCalc}
          />
        </CommandPanel>
        <TwoColGrid ref={containerHeaderRef} height={height}>
          <div>
            {displayCalcs.map((calc) => (
              <FieldItem
                key={calc.itemKey}
                active={calc.name === activeCalc}
                onClick={() => onSetActiveCalc(calc.name)}
                field={calc}
                onRenameField={onRenameCalc}
              />
            ))}
          </div>
          <div>
            <CalcProps
              onChange={(v) => onChangeCalc(activeCalc, v)}
              value={cValue}
              readOnly={!('name' in cValue)}
              allowedCtypes={displayAlowedCtypes}
              fields={allowedFields}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>
  );
};

CalcsPage.propTypes = {
  calcs: PropTypes.arrayOf(calcPropType).isRequired,
  activeCalc: PropTypes.string,
  onRenameCalc: PropTypes.func.isRequired,
  onChangeCalc: PropTypes.func.isRequired,
  onAddCalc: PropTypes.func.isRequired,
  onRemoveCalc: PropTypes.func.isRequired,
  onSetActiveCalc: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CalcsPage.defaultProps = {
  activeCalc: null,
  allowedCtypes: [],
};

export default memo(CalcsPage);
