import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import Layout from '../../components/Layout';
import api from '../../api/req';
import { AppContext } from '../../providers/authProvider';
import { LogicaContext } from '../../providers/authLogicaProvider';
import LoginForm from '../../components/Login';
import { DimmableLoader, ErrorMessage } from '../../components/Styled/Misc';

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      accessToken: null,
      refreshToken: null,
      isLoading: true,
      errorMsg: '',
      settings: {}, // key-value store for all settings in current session
      ticket: localStorage.getItem('ticket'),
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.tryRefresh().then(() => this.setState({ isLoading: false }));
  }

  clearSession = (addData) => {
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('ticket');
    this.setState({
      currentUser: null,
      accessToken: null,
      refreshToken: null,
      ticket: null,
      ...addData,
    });
  };

  tryRefresh = async () => {
    // eslint-disable-next-line
    const refreshToken = this.state.refreshToken || window.localStorage.getItem('refreshToken');
    if (refreshToken) {
      const r = await api.post$('/api/token/refresh', () => null, { refresh: refreshToken });
      if (r.ok) {
        const d = await r.json();
        window.localStorage.setItem('refreshToken', refreshToken);
        this.setState({ refreshToken });
        const result = await this.tryCurrentUser(d.access, refreshToken);
        if (result) {
          return result;
        }
        window.localStorage.removeItem('refreshToken');
      }
    }
    return false;
  };

  tryCurrentUser = async (accessToken, refreshToken) => {
    const resp = await api.get$('/api/core/currentuser/', () => ({ access: accessToken }));
    if (resp.ok) {
      const d = await resp.json();
      await this.setState({
        currentUser: d.results[0],
        isLoading: false,
        errorMsg: '',
        accessToken,
        refreshToken,
      });
      if (process.env.NODE_ENV === 'production') {
        Sentry.setUser(d.results[0]);
      }
      return () => ({
        access: accessToken,
        onRefresh: this.tryRefresh,
      });
    }
    this.clearSession({
      isLoading: false,
      errorMsg: `${resp.status} ${resp.statusText}`,
    });
    return false;
  };

  tryLogout = () => {
    const { auth } = this.state;
    this.setState({ isLoading: true });
    api.get$('/api-auth/logout', () => auth).then((r) => {
      if (r.ok) {
        this.clearSession({
          isLoading: false,
          errorMsg: '',
        });
      } else {
        this.setState({ isLoading: false, errorMsg: `${r.status} ${r.statusText}` });
      }
    });
  };

  tryLogin = async (username, password) => {
    this.setState({ isLoading: true });
    const r = await api.post$('/api/token/', () => null, { username, password });
    if (r.ok) {
      const d = await r.json();
      if (await this.tryCurrentUser(d.access, d.refresh)) {
        window.localStorage.setItem('refreshToken', d.refresh);
      }
    } else {
      try {
        const d = await r.json();
        const errors = Object.keys(d).reduce((R, k) => [...R, ...d[k]], []);
        const errorMsg = errors.reduce((R, err) => `${R} ${err}`, '');
        this.clearSession({
          isLoading: false,
          errorMsg,
        });
      } catch {
        this.clearSession({
          isLoading: false,
          errorMsg: `${r.status} ${r.statusText}`,
        });
      }
    }
  };

  setSettings = (key, value) => {
    const { settings } = this.state;
    this.setState({ settings: { ...settings, [key]: value } });
  };

  getAuth = () => {
    const { accessToken } = this.state;
    return ({
      access: accessToken,
      onRefresh: this.tryRefresh,
    });
  };

    setTicket = (ticket) => {
      if (ticket) {
        this.setState({ ticket });
        localStorage.setItem('ticket', ticket);
      } else { localStorage.removeItem('ticket'); this.setState({ ticket: null }); }
    }

    render() {
      const {
        currentUser, isLoading, accessToken, refreshToken, errorMsg, settings, ticket,
      } = this.state;
      return (
        <DimmableLoader loading={isLoading}>
          {!currentUser ? (
            <LoginForm onLogin={this.tryLogin} errorMsg={errorMsg} />
          ) : (
            <AppContext.Provider value={{
              currentUser,
              accessToken,
              refreshToken,
              logoutHandler: this.tryLogout,
              refreshHandler: this.tryRefresh,
              setSettings: this.setSettings,
              settings,
              auth: this.getAuth,
            }}
            >
              <LogicaContext.Provider value={{ ticket, setTicket: this.setTicket }}>
                {errorMsg && (
                  <ErrorMessage text={errorMsg} />
                )}
                <Layout />
              </LogicaContext.Provider>
            </AppContext.Provider>
          )}
        </DimmableLoader>
      );
    }
}

export default Root;
