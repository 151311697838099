export const lookups = {
  eq: '__exact',
  gt: '__gt',
  gte: '__gte',
  in: '__in',
  lt: '__lt',
  lte: '__lte',
};

export default lookups;
