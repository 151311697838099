import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TableControls } from '../../BaseEditor';
import { StyledTD } from '../../basicReport/results/styles';

const StyleTD = styled.td`
  padding: 2px 10px;
`;

const RowKDB = ({
  row, rowSave,
}) => (
  <>
    <tr
      style={{ background: row.save ? '#baf3bab3' : 'white' }}
    >
      <StyleTD style={{ textAlign: 'center' }}>
        <TableControls.CheckboxInput
          noMargin
          value={row.save}
          onChange={() => rowSave(row.id, { save: !row.save })}
        />
      </StyleTD>
      <StyledTD>{row.kdb.code}</StyledTD>
      <StyleTD>
        {row.kdb.name}
      </StyleTD>
    </tr>
  </>

);
RowKDB.propTypes = {
  row: PropTypes.shape().isRequired,
  rowSave: PropTypes.func.isRequired,
};


export default memo(RowKDB);
