import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels, EditorControls } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "code code_of_treasury  mask_of_file mask_ft"
                        "name org org org"
                        "parent parent parent parent"
                        "ch1 ch1 ch2 ch2";
                        
  @media (max-width: 600px) {
  display: block;
  } 
`;

const editorRenderer = (getControl, { data, dataSetter, currentUser }) => (
  <ThisDocGrid>
    <GridItem area="code">
      {getControl('code')}
    </GridItem>
    <GridItem area="code_of_treasury">
      {getControl('code_of_treasury')}
    </GridItem>
    <GridItem area="mask_of_file">
      {getControl('mask_of_file')}
    </GridItem>
    <GridItem area="mask_ft">
      {getControl('mask_ft')}
    </GridItem>
    <GridItem area="name">
      {getControl('name')}
    </GridItem>
    {currentUser.is_superuser && (
      <GridItem area="org">
        {getControl('org', { backend: '/api/catalog/orgs/', onChange: (e, v) => { dataSetter({ org: v, parent: null }); } })}
      </GridItem>
    )}
    <GridItem area="parent">
      <EditorControls.ItemPicker
        label="Батьківський елемент"
        backend="/api/catalog/budgets/"
        canErase
        value={data.parent}
        onChange={(e, v) => dataSetter({ parent: v })}
        mode="no-repr"
        filter={{ org: data.org ? data.org.id : null }}
      />
    </GridItem>
    <GridItem area="ch1">
      {getControl('load_3rd_group')}
    </GridItem>
    <GridItem area="ch2">
      {getControl('multi_files_ft')}
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/catalog/budgets/', CommandPanels.catCommandPanel);
