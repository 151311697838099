/* eslint-disable max-len */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls, TableControls } from '../../BaseEditor';
import api from '../../../api/req';
import {
  DeleteButton, DraftButton, GenerateButton, SaveButton,
} from '../../../components/Styled/Buttons';
import { CommandPanel, DimmableLoader, ErrorMessage } from '../../../components/Styled/Misc';
import { EditorContainer } from '../../../components/Styled/Forms';

const StyleTD = styled.td`
  padding: 2px 10px;
`;
const StyleHeader = styled.thead`
  >tr {
   >th{
    background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
    padding: 10px;
    position: sticky;
    top: 0;
    background: #e8f0f8;
    z-index: 1;
   }}
`;

const ContainerTable = styled.div`
  margin-top: 10px;
  display: grid;
  overflow: auto;
  background: white;
`;
const TrKdb = styled.tr`
  font-size: 18px;
`;
const MessageText = styled.span`
  font-weight: 700;
  color: #5e93d0;
  font-size: 24px;
  margin-left: 15px;
`;
const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const operationTypes = {
  1: 1,
  [-1]: -1,
  0: 0,
};

class Master extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
    currentOrg: PropTypes.shape(),
    budgets: PropTypes.arrayOf(PropTypes.shape),
    currentDate: PropTypes.string.isRequired,
  };

  static defaultProps = {
    budgets: null,
    currentOrg: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      isErrored: false,
      errorText: '',
      message: null,
      result412: null,
      resultRaw: null,
      editablePs: null,
      ignoredKdbs: null,
      difference: false,
      abilityToEdit: false,
    };
    this.divElement = React.createRef();
  }

  componentDidMount() {
    this.setState({
      headerHeight: this.divElement.current.getBoundingClientRect().top
          + this.divElement.current.getBoundingClientRect().height,
    });
  }

  startProcessing = async () => {
    const {
      authF, currentOrg, budgets, currentDate,
    } = this.props;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.get('/api/service/master/', authF, {
      org: currentOrg.id,
      budgets: budgets.map((b) => b.id),
      date: currentDate,
    });
    if (r.ok) {
      const d = await r.json();
      this.setState({
        result412: d['412'],
        resultRaw: d.raw,
        editablePs: d.editable_ps,
        ignoredKdbs: d.ignored_kdbs,
        message: ((d['412']).length === 0 || (d.raw).length === 0) && 'Дані для обробки відсутні!',
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  saveChanges = async () => {
    const { authF } = this.props;
    const { editablePs } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.post('/api/service/master/', authF, {
      updated_ps: editablePs,
      finish: false,
    });
    if (r.ok) {
      this.startProcessing();
      this.setState({
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  clean = async () => {
    const {
      authF, currentOrg, budgets, currentDate,
    } = this.props;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.post('/api/service/clean_master/', authF, {
      org: currentOrg.id,
      budget: budgets.map((b) => b.id),
      date: currentDate,
      clean_ps: true,
    });
    if (r.ok) {
      this.setState({
        message: 'Дані для обробки очищені!',
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  finish = async () => {
    const { authF, currentOrg } = this.props;
    const { editablePs } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.post('/api/service/master/', authF, {
      updated_ps: editablePs,
      finish: true,
      org: currentOrg.id,
    });
    if (r.ok) {
      this.startProcessing();
      this.setState({
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  changePs =(value, uniquePaymentskinId) => {
    const { editablePs } = this.state;
    this.setState({
      editablePs: editablePs
        .map((ePs) => ePs.id === uniquePaymentskinId
          ? { ...ePs, operation_type: value } : ePs),
    });
  };


  render() {
    const {
      currentUser, currentOrg, budgets, currentDate,
    } = this.props;
    const {
      message, isProcessing, errorText, difference, abilityToEdit,
      result412, resultRaw, editablePs, ignoredKdbs, isErrored, headerHeight,
    } = this.state;
    const formatter = (f) => f.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

    return (
      <DimmableLoader loading={isProcessing} fullscreen={false}>
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <div ref={this.divElement}>
          <CommandPanel>
            <GenerateButton
              content="Почати обробку"
              onClick={this.startProcessing}
              disabled={(currentUser.org && !currentUser.org.repr) || !currentOrg || !currentDate
              || (budgets && budgets.filter((b) => b.checked).length === 0)}
              style={{ alignSelf: 'center' }}
            />
            <DraftButton
              onClick={this.saveChanges}
              addText
            />
            <DeleteButton
              content="Видалити всі дані"
              onClick={this.clean}
              addText
            />
            <SaveButton
              content="Закінчити обробку"
              onClick={this.finish}
            />
            <EditorControls.CheckboxInput
              label="Показати тільки розбіжності"
              value={difference}
              noBorder
              onChange={() => this.setState({ difference: !difference })}
            />
            <EditorControls.CheckboxInput
              label="Показати тільки з редагуванням"
              value={abilityToEdit}
              noBorder
              onChange={() => this.setState({ abilityToEdit: !abilityToEdit })}
            />
          </CommandPanel>
          {!message && result412
          && (
            <ContainerTable style={{ maxHeight: `calc(100vh - ${headerHeight}px - 60px)` }}>
              <table border="1" cellSpacing="0">
                <StyleHeader>
                  <tr>
                    <th colSpan="2">КДБ код</th>
                    <th>Різниця</th>
                    <th>Сума(форма 412)</th>
                    <th>Разом по строках</th>
                  </tr>
                </StyleHeader>
                <tbody>
                  {result412.filter((item412) => (!ignoredKdbs
                    .includes(item412.kdb.id) && item412.day_sum !== 0))
                    .map((item412) => {
                      const uniquePaymentskin = resultRaw
                    && [...new Set(resultRaw
                      .filter((iRaw) => item412.kdb.id === iRaw.kdb)
                      .map((iRaw) => iRaw.paymentskin.id))];
                      const currentEdPsId = editablePs
                        .map((edPS) => edPS.id);
                      const sumResultRaw = resultRaw
                        .filter((itemRaw) => item412.kdb.id === itemRaw.kdb)
                        .reduce((R, itemRaw) => {
                          const index = currentEdPsId.indexOf(itemRaw.paymentskin.id);
                          const operation = currentEdPsId.includes(itemRaw.paymentskin.id)
                            ? editablePs[index].operation_type : itemRaw.paymentskin.operation_type;
                          return (
                            R + operation * itemRaw.raw_amount * itemRaw.ratio
                          );
                        }, 0);
                      const canChangePs = uniquePaymentskin
                        .filter((ups) => currentEdPsId.includes(ups)).length > 0;
                      const warning = Math.abs((item412.day_sum - sumResultRaw).toFixed(2)) > 1;
                      return (
                        <>
                          {
                            ((difference && !warning) || (abilityToEdit && !canChangePs))
                              ? <></>
                              : (
                                <Fragment key={item412.id}>
                                  <TrKdb>
                                    <StyleTD colSpan="2">{item412.kdb.code}</StyleTD>
                                    <StyleTD style={{ textAlign: 'right', background: warning ? '#fda1a1ad' : 'unset' }}>
                                      {formatter((item412.day_sum - sumResultRaw).toFixed(2))}
                                    </StyleTD>
                                    <StyleTD
                                      style={{ textAlign: 'right' }}
                                    >
                                      {formatter(`${(item412.day_sum).toFixed(2)}`)}
                                    </StyleTD>
                                    <StyleTD style={{ textAlign: 'right' }}>
                                      {formatter(sumResultRaw.toFixed(2))}
                                    </StyleTD>
                                  </TrKdb>

                                  <tr style={{ background: '#e3e3e3' }}>
                                    <StyleTD>Префікс</StyleTD>
                                    <StyleTD>Знак операції</StyleTD>
                                    <StyleTD>Сума до розмежування</StyleTD>
                                    <StyleTD>Коефіцієнт</StyleTD>
                                    <StyleTD>Підсумок</StyleTD>
                                  </tr>
                                  {uniquePaymentskin.map((uniquePaymentskinId) => {
                                    const currentItemRaw = resultRaw
                                      .filter((itemRaw) => item412.kdb.id === itemRaw.kdb);
                                    const currentEdPs = editablePs
                                      .filter((edPS) => edPS.id === uniquePaymentskinId);
                                    const canChange = editablePs
                                      .filter((edPS) => edPS.id === uniquePaymentskinId).length > 0;
                                    return (
                                      <tr
                                        key={uniquePaymentskinId}
                                        style={{ background: canChange ? '#feff88a6' : '#baf3bab3' }}
                                      >
                                        <>
                                          <StyleTD style={{ paddingLeft: '20px' }}>
                                            {currentItemRaw
                                              .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                              .reduce((R, ir) => (
                                                ir.paymentskin.import_prefix
                                              ), null)}
                                          </StyleTD>
                                          <StyleTD style={{ textAlign: 'right' }}>
                                            {canChange
                                              ? (
                                                <TableControls.Radio
                                                  label={null}
                                                  values={[
                                                    { value: operationTypes['1'], display_name: '+' },
                                                    { value: operationTypes['-1'], display_name: '-' },
                                                    { value: operationTypes['0'], display_name: '0' },
                                                  ]}
                                                  value={currentEdPs[0].operation_type}
                                                  group="uniquePaymentskinId"
                                                  onChange={(e, v) => this.changePs(v, uniquePaymentskinId)}
                                                />
                                              )
                                              : (
                                                <>
                                                  {currentItemRaw
                                                    .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                                    .reduce((R, ir) => (
                                                      ir.paymentskin.operation_type
                                                    ), null)}
                                                </>
                                              )}
                                          </StyleTD>
                                          <StyleTD style={{ textAlign: 'right' }}>
                                            {formatter((currentItemRaw
                                              .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                              .reduce((R, ir) => (
                                                R + +ir.raw_amount
                                              ), 0)).toFixed(2))}
                                            {canChange
                                            && (
                                              <>
                                        &nbsp;&nbsp;(
                                                {formatter((currentItemRaw
                                                  .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                                  .reduce((R, ir) => (
                                                    R + +ir.raw_amount
                                                  ), 0) * currentItemRaw
                                                  .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                                  .reduce((R, ir) => (
                                                    ir.ratio
                                                  ), null)
                                                ).toFixed(2))}
                                                )
                                              </>
                                            )}
                                          </StyleTD>
                                          <StyleTD style={{ textAlign: 'right' }}>
                                            {currentItemRaw
                                              .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                              .reduce((R, ir) => (
                                                ir.ratio
                                              ), null)}
                                          </StyleTD>
                                          <StyleTD style={{ textAlign: 'right' }}>
                                            {editablePs
                                              .filter((edPS) => edPS.id === uniquePaymentskinId).length > 0
                                              ? (
                                                <>
                                                  {formatter((currentItemRaw
                                                    .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                                    .reduce((R, ir) => (
                                                      R + currentEdPs[0].operation_type
                                                      * ir.raw_amount * ir.ratio
                                                    ), 0)).toFixed(2))}
                                                </>
                                              )
                                              : (
                                                <>
                                                  {formatter((currentItemRaw
                                                    .filter((ir) => ir.paymentskin.id === uniquePaymentskinId)
                                                    .reduce((R, ir) => (
                                                      R + ir.paymentskin.operation_type
                                                      * ir.raw_amount * ir.ratio
                                                    ), 0)).toFixed(2))}
                                                </>
                                              )}
                                          </StyleTD>
                                        </>
                                      </tr>
                                    );
                                  })}
                                </Fragment>
                              )
                          }
                        </>
                      );
                    })}
                </tbody>
              </table>
            </ContainerTable>
          )}

          {message && (
            <EditorContainer style={{ overflow: 'auto' }}>
              {message && (
                <MessageContainer>
                  <img width="130px" src="https://img.icons8.com/clouds/2x/open-envelope.png" alt="" />
                  <MessageText>{message}</MessageText>
                </MessageContainer>
              )}
            </EditorContainer>
          )}
        </div>
      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(Master);
