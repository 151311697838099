import getLister, { CommandPanels } from '../../../containers/lister';
import TableComponent from '../../../components/TableComponent';

const backendPrefix = '/api/document/';
const frontendPrefix = '/doc/';

const defalutListCommandPanel = CommandPanels.docCommandPanel;

const defalutSelectCommandPanel = CommandPanels.catSelectCommandPanel;
const { docSelectCommandPanel } = CommandPanels;

/**
 * Тип описывающий документ
 * @typedef {Object} DocDef
 * @property {string} backend
 * @property {string} frontend
 * @property {func} lister
 * @property {func} selector
 */

/**
 * @const
 * @type {object}
 * @property {DocDef} subscriptions
 */

// eslint-disable-next-line
export const documents = {
  subscriptions: {
    backend: `${backendPrefix}subscriptions/`,
    frontend: `${frontendPrefix}subscriptions/`,
    lister: getLister(TableComponent, `${backendPrefix}subscriptions/`, defalutListCommandPanel, 'doc'),
    selector: getLister(TableComponent, `${backendPrefix}subscriptions/`, defalutSelectCommandPanel, 'doc'),
  },
  contracts: {
    backend: '/api/catalog/contracts/',
    frontend: `${frontendPrefix}contracts/`,
    lister: getLister(TableComponent, '/api/catalog/contracts/', defalutListCommandPanel, 'doc'),
    selector: getLister(TableComponent, '/api/catalog/contracts/', defalutSelectCommandPanel, 'doc'),
  },
  subscriptions412: {
    backend: `${backendPrefix}subscriptions412/`,
    frontend: `${frontendPrefix}subscriptions412/`,
    lister: getLister(TableComponent, `${backendPrefix}subscriptions412/`, defalutListCommandPanel, 'doc'),
    selector: getLister(TableComponent, `${backendPrefix}subscriptions412/`, defalutSelectCommandPanel, 'doc'),
  },
  calculatedcontracts: {
    backend: '/api/catalog/calculatedcontracts/',
    frontend: `${frontendPrefix}calculatedcontracts/`,
    lister: getLister(TableComponent, '/api/catalog/calculatedcontracts/', CommandPanels.catCommandPanel),
    selector: getLister(TableComponent, '/api/catalog/calculatedcontracts/', defalutSelectCommandPanel),
  },
  enteringtheremainder: {
    backend: '/api/document/enteringtheremainder/',
    frontend: `${frontendPrefix}enteringtheremainder/`,
    lister: getLister(TableComponent, '/api/document/enteringtheremainder/', defalutListCommandPanel, 'doc'),
    selector: getLister(TableComponent, '/api/document/enteringtheremainder/', defalutSelectCommandPanel, 'doc'),
  },
  calculationofpayments: {
    backend: '/api/document/calculationofpayments/',
    frontend: `${frontendPrefix}calculationofpayments/`,
    lister: getLister(TableComponent, '/api/document/calculationofpayments/', defalutListCommandPanel, 'doc'),
    selector: getLister(TableComponent, '/api/document/calculationofpayments/', docSelectCommandPanel, 'doc'),
  },
};

export default documents;
