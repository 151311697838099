// leave blank for localhost
const addr = process.env.REACT_APP_API_SERVER
  || (process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://taxpayers.ciat.net.ua');
console.log(`Connecting to ${addr}`);
export default addr;
// console.log('Hi Ruslan')
// http://172.16.201.25:8000 //Ваня
// http://172.16.103.254:8000 // Сервер
// http://172.16.201.25:8000 // Игорь
// http://taxpayers.ciat.net.ua
// http://172.16.201.60:8000 //Олег

// https://stage.tpay.ciat.net.ua/
// 10.8.0.29:8000
// 172.16.201.60:8000
// https://taxpayers.ciat.net.ua
