import React from 'react';
import { DivHeader, DivHeader2 } from './styles';
import { fieldsPropType } from './PropTypes';


const HeaderComponent = ({ fields }) => (
  <>
    <DivHeader area="payer">
      {fields.payer.label}
    </DivHeader>
    <DivHeader area="contract">
      {fields.contract.label}
    </DivHeader>
    <DivHeader area="payment_number">
      {fields.payment_number.label}
    </DivHeader>
    <DivHeader2 area="payment_notes">
      {fields.payment_notes.label}
    </DivHeader2>

    <DivHeader area="order_amount">
      {fields.order_amount.label}
    </DivHeader>
    <DivHeader area="revenue_amount">
      {fields.revenue_amount.label}
    </DivHeader>
    <DivHeader area="warning">
      {fields.warning.label}
    </DivHeader>
  </>
);

HeaderComponent.propTypes = {
  fields: fieldsPropType,
};

HeaderComponent.defaultProps = {
  fields: {},
};
export default HeaderComponent;
