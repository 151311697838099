import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import api from '../../api/req';
import { getComponentByType } from './editorComponents';
import { HeaderText, EditorContainer, StyledForm } from '../../components/Styled/Forms';
import { actions } from './commandPanels';
import { ErrorMessage, DimmableLoader } from '../../components/Styled/Misc';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';
import { withLabel } from './withLabel';
import { withErrors } from './withErrors';

const headerComponentEnhancer = compose(withLabel, withErrors);

const ComponentErrorDiv = styled.div`
  color: red;
  font-weight: 700;
`;

const MyEditorContainer = styled(EditorContainer)`
  overflow: auto;
`;

const getBaseEditor = (
  editorRenderer,
  backend,
  CommandPanel = () => null,
  privateActionHandler = null,
) => {
  class BaseEditor extends Component {
    static propTypes = {
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }),
        path: PropTypes.string,
        url: PropTypes.string,
      }).isRequired,
      authF: PropTypes.func.isRequired,
      currentUser: PropTypes.shape({
        first_name: PropTypes.string,
        id: PropTypes.number,
        is_staff: PropTypes.bool,
        is_superuser: PropTypes.bool,
        last_name: PropTypes.string,
        org: PropTypes.shape({
          id: PropTypes.number,
          repr: PropTypes.string,
        }),
        username: PropTypes.string,
      }).isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        options: {
          actions: {
            PUT: {},
          },
        },
        data: null,
        controls: {},
        modified: false,
        isErrored: false,
        errorText: '',
        isReadOnly: false,
        isNew: false,
        erroredFields: {},
      };
    }

    componentDidMount() {
      const { match, authF } = this.props;
      const { id } = match.params;
      if (id !== 'create') {
        api.options$(`${backend}${match.params.id}/`, authF)
          .then((r) => this.queryParser(r, (options) => this.reload(options, false)));
      } else {
        api.options$(`${backend}`, authF)
          .then((r) => this.queryParser(r, (options) => this.processOptions(options, {}, true)));
      }
    }

    reload = (options) => {
      const { match, authF } = this.props;
      api.get$(`${backend}${match.params.id}/`, authF)
        .then((rd) => this.queryParser(rd, (data) => this.processOptions(options, data, false)));
    };

    queryParser = (response, f) => {
      if (response.ok) {
        response.json().then((data) => f(data));
      } else if (response.status === 400) {
        response.json()
          .then((erroredFields) => this.setState({ isLoading: false, erroredFields }))
          .catch(() => this.setState({ isLoading: false, isErrored: true, errorText: `${response.status} ${response.statusText}` }));
      } else {
        this.setState({ isLoading: false, isErrored: true, errorText: `${response.status} ${response.statusText}` });
      }
    };

    getFields = (options, isReadOnly, isNew) => {
      if (isReadOnly) return options.actions.GET.fields;
      if (!isNew) return options.actions.PUT;
      return options.actions.POST;
    };

    processOptions = (options, loadedData, isNew) => {
      const isReadOnly = !isNew && !options.actions.PUT;
      const fields = this.getFields(options, isReadOnly, isNew);
      const controls = Object.keys(fields).reduce((R, k) => (
        {
          ...R,
          [k]: headerComponentEnhancer(
            getComponentByType(
              fields[k],
              fields[this.getBaseFieldName(k, fields)].read_only,
            ),
          ),
        }
      ), {});
      const MyData = Object.keys(loadedData).reduce((R, field) => {
        const baseFieldName = this.getBaseFieldName(field, fields);
        if (baseFieldName === field) {
          return R;
        }
        return { ...R, [baseFieldName]: loadedData[field] ? loadedData[field].id : null };
      }, loadedData);

      this.setState({
        options,
        data: MyData,
        isLoading: false,
        controls,
        isReadOnly,
        modified: false,
        erroredFields: {},
        isNew,
      });
    };

    getBaseProps = (field) => {
      if (!field) return {};
      switch (field.type) {
      case 'string':
        return {
          maxLength: field.max_length || 0,
        };
      case 'choice':
        return {
          values: field.choices || [],
        };
      case 'nested object':
        return {
          columns: field.children,
        };
      default:
        return {};
      }
    };

    getBaseFieldName = (field, fields) => {
      if (!fields[field]) return '';
      if (fields[field].type === 'nested object' && fields[field].read_only && fields[`${field}_id`]) {
        return `${field}_id`;
      }
      if (fields[field].type === 'field' && fields[field].read_only && fields[`${field}_id`]) {
        return `${field}_id`;
      }
      return field;
    };

    getControl = (field, newProps = {}) => {
      const {
        controls, data, options, isReadOnly, isNew, erroredFields,
      } = this.state;

      const Control = controls[field];
      const fields = this.getFields(options, isReadOnly, isNew);
      const baseProps = this.getBaseProps(fields[field]);
      if (!Control) {
        return (
          <ComponentErrorDiv>
            {`Not found coltrol for field "${field}"`}
          </ComponentErrorDiv>
        );
      }
      return (
        <Control
          value={data[field]}
          onChange={(e, v) => this.dataSetter({ [field]: v })}
          label={fields[field].label}
          readOnly={isReadOnly}
          errors={erroredFields[this.getBaseFieldName(field, fields)]}
          {...baseProps}
          {...newProps}
        />
      );
    };

    dataSetter = (partialData) => {
      const {
        data, erroredFields, options, isReadOnly, isNew,
      } = this.state;
      const fields = this.getFields(options, isReadOnly, isNew);
      const addPartData = Object.keys(partialData).reduce((R, k) => {
        if (this.getBaseFieldName(k, fields) !== k) {
          return { ...R, [`${k}_id`]: partialData[k] && partialData[k].id };
        }
        return R;
      }, partialData);
      this.setState({
        data: { ...data, ...addPartData },
        modified: true,
        erroredFields: Object.keys(erroredFields)
          .filter((ef) => !(ef in partialData))
          .reduce((R, ef) => ({ ...R, [ef]: erroredFields[ef] }), {}),
      });
    };

    save = () => {
      this.setState({ isLoading: true });
      const { match, authF, history } = this.props;
      const {
        isNew, data, options, isReadOnly,
      } = this.state;
      const fields = this.getFields(options, isReadOnly, isNew);
      const newState = Object.keys(data).reduce((R, k) => {
        if (!fields[k] || fields[k].read_only) return R;
        if (fields[k].type === 'nested object') return data[k] === null ? R : { ...R, [k]: data[k].id };
        return { ...R, [k]: data[k] };
      }, {});
      if (!isNew) {
        api.put$(`${backend}${match.params.id}/`, authF, newState)
          .then((r) => this.queryParser(r,
            () => this.reload(options)));
      } else {
        api.post$(`${backend}`, authF, newState)
          .then((r) => this.queryParser(r, (d) => {
            this.setState({ isLoading: false, modified: false, erroredFields: {} });
            history.push(match.path.replace(':id', d.id));
            api.options$(`${backend}${d.id}/`, authF)
              .then((ro) => this.queryParser(ro, (newOtpions) => this.reload(newOtpions, false)));
          }));
      }
    };

    processAction = (e, action, ...r) => {
      const { match, history } = this.props;
      const { data } = this.state;
      switch (action) {
      case actions.save:
        this.save();
        break;
      case actions.execute:
        this.setState({ data: { ...data, executed: true } }, this.save);
        break;
      case actions.cancel:
        this.setState({ data: { ...data, executed: false } }, this.save);
        break;
      case actions.close:
        history.push(match.url.replace(`/${match.params.id}`, '/'));
        break;
      default:
        if (privateActionHandler) {
          privateActionHandler(e, action, {
            values: { ...r },
            data,
            dataSetter: this.dataSetter,
          });
        } else {
          console.log('Unknow action', action);
        }
      }
    };

    reloadData = () => {
      const { options } = this.state;
      this.reload(options);
    };

    render() {
      const {
        isLoading, options, data, modified, isErrored, errorText, erroredFields, isReadOnly, isNew,
      } = this.state;
      const { currentUser } = this.props;
      const fields = this.getFields(options, isReadOnly, isNew);
      return (
        <StyledForm className="fullscreenContainer fullscreenParent">
          <DimmableLoader loading={isLoading}>
            <HeaderText>{`${options.name || ''}${modified ? '*' : ''}`}</HeaderText>
            {isErrored && <ErrorMessage text={errorText} />}
            <CommandPanel
              onActionClick={this.processAction}
              isChanged={modified}
              isExecuted={data && data.executed}
            />
            <MyEditorContainer className="fullscreenParent">
              {options && data
              && editorRenderer(this.getControl, {
                options,
                fields: this.getFields(options, isReadOnly, isNew),
                data,
                dataSetter: this.dataSetter,
                erroredFields,
                currentUser,
                reload: this.reloadData,
              })}
            </MyEditorContainer>
            {Object.keys(erroredFields).map((field) => {
              if (field === 'error_data') {
                const text = erroredFields[field][0].messages.reduce((R, er) => `${R}${!R ? '' : ','} ${er}`, '');
                return (
                  <ErrorMessage key={field} text={text} header="При виконанні наступної дії виник несподіваний результат" />
                );
              }
              const text = erroredFields[field].reduce((R, er) => `${R}${!R ? '' : ','} ${er}`, '');
              return (
                <ErrorMessage key={field} header={fields[field].label} text={text} />
              );
            })}
          </DimmableLoader>
        </StyledForm>

      );
    }
  }
  const enhance = compose(withRouter, withAuthConsumer(mapStateAuth));
  return enhance(BaseEditor);
};


export default getBaseEditor;
