import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { StyledItem, getDraggableProps } from '../styles';

const AllowedColumn = ({
  column, active, onClick, onDoubleClick, index,
}) => (
  <StyledItem
    active={active}
    onClick={(e) => onClick(e, index)}
    onDoubleClick={(e) => onDoubleClick(e, index)}
  >
    <Draggable draggableId={`AVAILABLE-${column.key}-${index}`} index={index} key={column.key}>
      {(providedDrag, snapshot) => (
        <div
          ref={providedDrag.innerRef}
          {...getDraggableProps(providedDrag, snapshot)}
        >
          {column.label}
        </div>
      )}
    </Draggable>
  </StyledItem>
);

export const allowedColumnPropType = PropTypes.shape({
  label: PropTypes.string,
  query_label: PropTypes.string,
  key: PropTypes.string.isRequired,
});

AllowedColumn.propTypes = {
  column: allowedColumnPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

AllowedColumn.defaultProps = {
  active: false,
  onClick: () => null,
  onDoubleClick: () => null,
};

export default AllowedColumn;
