import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledEditButton = styled(StyledButton)`
  height: 2.5em;
`;

export const EditButton = ({ content, onClick, disabled }) => (
  <StyledEditButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={EditIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledEditButton>
);

EditButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

EditButton.defaultProps = {
  onClick: () => null,
  content: 'Редагувати',
  disabled: false,
};

export default EditButton;
