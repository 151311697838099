import React, {
  memo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import EditorControls from '../../BaseEditor/editorControls';

const ParamsField = ({
  param, currentParam, changeParam,
}) => {
  const onChange = useCallback(
    (e, v) => changeParam(param.name, v),
    [changeParam, param.name],
  );

  return (
    <EditorControls.MultiVaueInput
      id={`param_${param.name}`}
      label={param.label}
      required
      ctype={param.ctype}
      resource={param.resource}
      onChange={onChange}
      value={currentParam}
    />
  );
};

ParamsField.propTypes = {
  param: PropTypes.shape({
    // name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    ctype: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    // id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    resource: PropTypes.string,
    // min_value: PropTypes.number,
    // max_value: PropTypes.number,
  }).isRequired,
  changeParam: PropTypes.func.isRequired,
  currentParam: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape()],
  ),
};

ParamsField.defaultProps = {
  currentParam: null,
};

export default memo(ParamsField);
