import React from 'react';
import { useParams } from 'react-router-dom';
import StdReportEditor from './editor';

const StdRouterReportEditor = () => {
  const { id } = useParams();
  return (
    <StdReportEditor reportId={id} />
  );
};

export default StdRouterReportEditor;
