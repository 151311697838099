import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import {
  ErrorMessage, DimmableLoader,
} from '../../../components/Styled/Misc';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { Button } from '../../../components/Styled/Buttons';
import { DefaultInput } from '../../../components/Styled/Input';
import {
  StyledTr, StyledDiv, ButtonContainer, H2, StyledFontAwesomeIcon, StyledTd, StyledTh,
} from '../styled/styledComponent';

const CreateRole = ({ onClose, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [role, setRole] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  useEffect(() => {
    const loader = async () => {
      const r = await api.get('/api/permissions/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const newData = rData.map((el) => (
          {
            ...el,
            check: false,
            input: false,
          }
        ));
        setRole((prev) => ({ ...prev, permissions: [...newData] }));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [auth, onError, onLoadEnd, onLoadStart]);

  const updateRoleParams = (e) => {
    const { name, value } = e.target;
    setRole({
      ...role,
      [name]: value,
    });
  };

  const createRole = () => {
    const loader = async () => {
      const filteredPermissions = role.permissions.filter((perm) => perm.input)
        .reduce((prev, cur) => ({
          ...prev,
          [cur.key]: cur.check,
        }), {});
      const params = { ...role, permissions: filteredPermissions };
      const r = await api.post('/api/role/', auth, params);
      if (r.status === 400) {
        const error = await r.json();

        Object.entries(error).map((e) => {
          if (e) {
            throw new Error(`${e[0]}: ${e[1]}`);
          }
          return error;
        });

        return error;
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  };

  const handleCheckbox = useCallback((type, key, checked) => {
    if (type === 'input') {
      if (key === 'all') {
        setRole({
          ...role,
          permissions: [...role.permissions
            .map((perm) => ({ ...perm, input: true }))],
        });
      } else if (key === 'nothing') {
        setRole({
          ...role,
          permissions: [...role.permissions
            .map((perm) => ({ ...perm, input: false, check: false }))],
        });
      } else {
        setRole({
          ...role,
          permissions: [...role.permissions
            .map((k) => {
              if (k.key === key && !checked) { return { ...k, check: checked, input: checked }; }
              if (k.key === key) { return { ...k, input: checked }; }
              return k;
            })],
        });
      }
    } else if (key === 'all') {
      setRole({
        ...role,
        permissions: [...role.permissions
          .map((perm) => ({ ...perm, check: perm.input }))],
      });
    } else if (key === 'nothing') {
      setRole({
        ...role,
        permissions: [...role.permissions
          .map((perm) => ({ ...perm, check: false }))],
      });
    } else {
      setRole({
        ...role,
        permissions: [...role.permissions
          .map((k) => {
            if (k.key === key) { return { ...k, check: checked }; }
            return k;
          })],
      });
    }
  }, [role]);

  return (
    <>
      <DimmableLoader loading={loading}>

        {err && (
          <ErrorMessage text={err} />
        )}

        {role && (
          <div style={{ overflow: 'auto' }}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Назва ролі:
                <DefaultInput
                  type="text"
                  id="name"
                  name="name"
                  onChange={updateRoleParams}
                />
              </label>

              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Опис ролі:
                <DefaultInput
                  type="text"
                  id="description"
                  name="description"
                  onChange={updateRoleParams}
                />
              </label>

            </div>

            <H2 color="#6598d2">Права доступу</H2>
            <StyledDiv style={{ marginBottom: '10px' }}>
              <div>
                <Button onClick={() => handleCheckbox('input', 'all')} content="Включити всі">
                  <StyledFontAwesomeIcon icon={faCheckSquare} color="green" size="lg" />
                </Button>
                <Button onClick={() => handleCheckbox('input', 'nothing')} content="Виключити всі">
                  <StyledFontAwesomeIcon icon={faSquare} color="#cbcbcb" size="lg" />
                </Button>
              </div>
              <div>
                <Button onClick={() => handleCheckbox('check', 'all')} content="Надати всі доступи">
                  <StyledFontAwesomeIcon icon={faCheckSquare} color="green" size="lg" />
                </Button>
                <Button onClick={() => handleCheckbox('check', 'nothing')} content="Зняти доступи">
                  <StyledFontAwesomeIcon icon={faSquare} color="#cbcbcb" size="lg" />
                </Button>
              </div>
            </StyledDiv>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <StyledTh width="200px">Включити в список</StyledTh>
                  <StyledTh width="200px">Надати доступ</StyledTh>
                  <StyledTh>Назва</StyledTh>
                </tr>
              </thead>
              <tbody>
                {role.permissions && role.permissions.map((el) => (
                  <StyledTr key={el.key}>
                    <StyledTd textAlign="center">
                      <input
                        type="checkbox"
                        id={el.key}
                        name="is_active"
                        checked={el.input}
                        onChange={(e) => handleCheckbox('input', el.key, e.target.checked)}
                      />
                    </StyledTd>
                    <StyledTd textAlign="center">
                      {el.input
                          && (
                            <input
                              type="checkbox"
                              id={el.key}
                              name="is_active"
                              checked={el.check}
                              onChange={(e) => handleCheckbox('check', el.key, e.target.checked)}
                            />
                          )}
                    </StyledTd>
                    <StyledTd paddingLeft="50px">{el.label}</StyledTd>
                  </StyledTr>
                ))}
              </tbody>
            </table>
            <ButtonContainer>
              <Button onClick={createRole} content="Створити" />
            </ButtonContainer>
          </div>
        )}
      </DimmableLoader>
    </>
  );
};

CreateRole.propTypes = {
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
};

export default CreateRole;
