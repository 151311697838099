import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import AvailableFieldList from '../available';
import {
  SColButtons, SColLeft, SColRight, StyledPanel,
} from '../styles';
import {
  Button,
} from '../../../../components/Styled/Buttons';
import itemTypes from '../itemTypes';
import SelectionList from './selectionList';
import { availableSelectionsPropType, getPreviousSelection, selectionsPropType } from '../../hooks';

const SelectionEditor = ({ availableSelections, selections, selectionHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeSelection, setActiveSelection] = useState(null);

  const [openedColumns, setOpenedColumns] = useState([]);

  const visibleColumns = useMemo(() => availableSelections.reduce((R, col) => [
    ...R,
    col.parent === null && { ...col, lvl: 0 },
    ...availableSelections.filter((col2) => col2.parent === col.name && openedColumns
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1 })),
  ], []).filter((col) => col), [availableSelections, openedColumns]);

  const onActiveSelectionHandler = useCallback(
    (sname) => setActiveSelection(sname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fname) => setActiveAvaField(fname),
    [],
  );

  const onSetOpenedColumns = useCallback((val) => {
    if (openedColumns.includes(val)) {
      return setOpenedColumns((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedColumns((prev) => ([...prev, val]));
  }, [openedColumns]);

  const selectionDragEndHanlder = useCallback(
    ({ name, type }, sname) => {
      if (type === itemTypes.availableField) {
        selectionHandlers.addSelectionHandler(name, sname);
      } else if (type === itemTypes.selection) {
        selectionHandlers.swapSelectionsHandler(name, sname);
      }
    },
    [selectionHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.selection) {
        selectionHandlers.removeSelectionHandler(name);
        if (activeSelection === name) setActiveSelection(null);
      }
    },
    [activeSelection, selectionHandlers],
  );


  return (
    <StyledPanel>
      <SColLeft>
        <AvailableFieldList
          fields={availableSelections}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={selectionHandlers.addSelectionHandler}
          label="Доступні поля"
          onDragEnd={avaDragEndHanlder}
          setOpenedColumns={onSetOpenedColumns}
          visibleColumns={visibleColumns}
          openedColumns={openedColumns}
        />
      </SColLeft>
      <SColButtons>
        <Button
          onClick={() => selectionHandlers.clearAllSelections()}
          disabled={!selections.length}
          title="Очистити"
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button
          onClick={() => {
            selectionHandlers.removeSelectionHandler(activeSelection);
            setActiveSelection(getPreviousSelection(selections, activeSelection));
          }}
          disabled={activeSelection === null}
          title="Видалити"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          onClick={() => selectionHandlers.addSelectionHandler(activeAvaField)}
          disabled={!activeAvaField}
          title="Додати"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </SColButtons>
      <SColRight>
        <SelectionList
          selections={selections}
          activeSelection={activeSelection}
          onClick={onActiveSelectionHandler}
          onDblClick={selectionHandlers.removeSelectionHandler}
          onDragEnd={selectionDragEndHanlder}
        />
      </SColRight>
    </StyledPanel>
  );
};

SelectionEditor.propTypes = {
  selections: selectionsPropType.isRequired,
  availableSelections: availableSelectionsPropType.isRequired,
  selectionHandlers: PropTypes.shape({
    clearAllSelections: PropTypes.func,
    removeSelectionHandler: PropTypes.func,
    addSelectionHandler: PropTypes.func,
    swapSelectionsHandler: PropTypes.func,
    changeFunctionHandler: PropTypes.func,
  }).isRequired,
};

export default SelectionEditor;
