import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  StyledContainer, StyledHeader, StyledSubContainer, VerticalCP,
} from '../styles';
import AllowedColumns, { allowedColumnPropType } from '../alowed';
import FilterColumns from './filterColumns';
import { filterColumnPropType } from './filterColumn';
import { NextButton, PreviousButton } from '../../../components/Styled/Buttons';

class FilterView extends PureComponent {
  static propTypes = {
    allowedColumns: PropTypes.arrayOf(allowedColumnPropType),
    filterColumns: PropTypes.arrayOf(filterColumnPropType),
    onAddColumn: PropTypes.func,
    onDeleteColumn: PropTypes.func,
    onChangeColumn: PropTypes.func,
    onActivateFilter: PropTypes.func.isRequired,
    onActivateAvailable: PropTypes.func.isRequired,
    activeFilter: PropTypes.number,
    activeAvailable: PropTypes.number,
  };

  static defaultProps = {
    allowedColumns: [],
    filterColumns: [],
    onAddColumn: () => null,
    onDeleteColumn: () => null,
    onChangeColumn: () => null,
    activeFilter: null,
    activeAvailable: null,
  };

  render() {
    const {
      allowedColumns, filterColumns,
      onAddColumn, onDeleteColumn,
      onChangeColumn, activeAvailable, activeFilter, onActivateAvailable, onActivateFilter,
    } = this.props;
    return (
      <StyledContainer>
        <StyledHeader>Фільтр</StyledHeader>
        <StyledSubContainer>
          <AllowedColumns
            columns={allowedColumns}
            onDoubleClick={onAddColumn}
            activeRow={activeAvailable}
            onActivateRow={onActivateAvailable}
          />
          <VerticalCP>
            <NextButton
              onClick={(e) => onAddColumn(e, activeAvailable)}
              disabled={activeAvailable === null}
            />
            <PreviousButton
              onClick={(e) => onDeleteColumn(e, activeFilter)}
              disabled={!activeFilter === null}
            />
          </VerticalCP>
          <FilterColumns
            columns={filterColumns}
            onDoubleClick={onDeleteColumn}
            onChange={onChangeColumn}
            activeRow={activeFilter}
            onActivateRow={onActivateFilter}
          />
        </StyledSubContainer>
      </StyledContainer>
    );
  }
}

export default FilterView;
