import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  EditorContainer,
  EditorHeader,
  FiltersGrid,
  FiltersItemHeader,
  InfoLabel,
  ContainerButton,
} from './styles';
import FilterItem from './filterItem';
import { CloseButton } from '../../../../components/Styled/Buttons';

class FiltersEditor extends PureComponent {
  static propTypes = {
    filterFields: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    recordsCount: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    recordsCount: 0,
  };

  handleChangeRow = (e, rowName, row) => {
    const { onChange, filterFields } = this.props;
    onChange(e, { ...filterFields, [rowName]: row });
  };

  render() {
    const { filterFields, recordsCount, onClose } = this.props;
    return (
      <EditorContainer>
        <EditorHeader>Фільтри</EditorHeader>
        <InfoLabel>{`Знайдено ${recordsCount} записів`}</InfoLabel>
        <FiltersGrid>
          <FiltersItemHeader rowId={1} colName="use">Вик.</FiltersItemHeader>
          <FiltersItemHeader rowId={1} colName="field">Назва поля</FiltersItemHeader>
          <FiltersItemHeader rowId={1} colName="eq">Порівняння</FiltersItemHeader>
          <FiltersItemHeader rowId={1} colName="value">Значення</FiltersItemHeader>
          {Object.keys(filterFields).map((fName, index) => (
            <FilterItem
              rowId={index}
              key={fName}
              fName={fName}
              row={filterFields[fName]}
              onChange={this.handleChangeRow}
            />
          ))}
        </FiltersGrid>
        <ContainerButton>
          <CloseButton onClick={onClose} />
        </ContainerButton>
      </EditorContainer>
    );
  }
}

export default FiltersEditor;
