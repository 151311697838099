import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import api from '../../api/req';
import ReprotEditor from './editor';
import { mapStateAuth, withAuthConsumer } from '../../providers/authProvider';
import { DimmableLoader, ErrorMessage } from '../../components/Styled/Misc';
import { ReportContainer } from './styles';

class BaseEditor extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired, // Параметры запроса - загрузка варианта если присутсвтует id
    overrideColumns: PropTypes.shape(), // Переопределение колонок отета
    resourceColumns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })), // Определение ресурсов
    // переопределение фильтров. Например период
    overrideFilters: PropTypes.shape(),
    // парамтеры отчета
    params: PropTypes.shape(),
    // группировки по умолчаниб
    defaultColumns: PropTypes.arrayOf(PropTypes.string),
    // Функция которая возвращает строковое представление объекта нпр формат
    valuesRepresentator: PropTypes.func,
    meta: PropTypes.shape({
      label: PropTypes.string,
      backend: PropTypes.string,
    }).isRequired, // метаданные отчета
    // исклбчить колонки из метадланных, которые пришли в options
    excludeColummns: PropTypes.arrayOf(PropTypes.string),
    // добавить колонки в метаданные, которых нет в options
    addColummns: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    overrideColumns: {},
    resourceColumns: [],
    overrideFilters: {},
    defaultColumns: [],
    params: {},
    valuesRepresentator: null,
    excludeColummns: [],
    addColummns: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      groupColumns: props.defaultColumns,
      filterColumns: [],
      orderColumns: [],
      columnGroupColumns: [],
      title: props.meta.label,
      description: '',
      isProcessing: true,
      isErrored: false,
      errMessage: '',
    };
  }

  componentDidMount() {
    const {
      authF, match,
    } = this.props;
    this.setState({ isProcessing: !!match.params.id });
    if (match.params.id) {
      api.get$(`/api/config/report/${match.params.id}/`, authF).then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            const options = JSON.parse(data.options);
            this.setState({
              groupColumns: options.groupColumns,
              filterColumns: options.filterColumns,
              orderColumns: options.orderColumns,
              columnGroupColumns: options.columnGroupColumns,
              title: data.title,
              isProcessing: false,
              isErrored: false,
              errMessage: '',
              description: data.description,
            });
          });
        } else {
          this.setState({
            isProcessing: false,
            isErrored: true,
            errMessage: `Помилка при завантаженні варіанту звіту ${resp.status} ${resp.statusText}`,
          });
        }
      });
    }
  }

  render() {
    const {
      isProcessing,
      title,
      isErrored,
      errMessage,
      groupColumns,
      filterColumns,
      orderColumns,
      columnGroupColumns,
      description,
    } = this.state;
    const {
      authF,
      meta,
      addColummns,
      excludeColummns,
      overrideColumns,
      params,
      resourceColumns,
      valuesRepresentator,
      overrideFilters,
      match,
    } = this.props;
    return (
      <ReportContainer>
        <DimmableLoader loading={isProcessing}>
          {isErrored && (
            <ErrorMessage text={errMessage} />
          )}
          <ReprotEditor
            authF={authF}
            title={title}
            backend={meta.backend}
            overrideColumns={overrideColumns}
            resourceColumns={resourceColumns}
            addColummns={addColummns}
            valuesRepresentator={valuesRepresentator}
            params={params}
            excludeColummns={excludeColummns}
            overrideFilters={overrideFilters}
            groups={groupColumns}
            filters={filterColumns}
            orders={orderColumns}
            columnGroups={columnGroupColumns}
            variantID={match.params.id}
            variantName={title}
            variantDescription={description}
          />
        </DimmableLoader>
      </ReportContainer>
    );
  }
}

const enchance = compose(
  withAuthConsumer(mapStateAuth),
  withRouter,
);

export default enchance(BaseEditor);
