import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { GridItem } from '../../../components/Styled/grids/editorGrid';
import { EditorControls } from '../../BaseEditor';

const StyledBudgetArea = Styled(GridItem)`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: .3em; 
`;

const BudgetsContainer = ({
  kdbs, searchedKdbsList, onChangeKdbs, onChangeSearchedKdbsList,
}) => (
  <StyledBudgetArea>
    {searchedKdbsList.map((budget) => (
      <EditorControls.CheckboxInput
        key={budget.id}
        label={budget.repr}
        value={budget.checked}
        onChange={(e, v) => {
          onChangeSearchedKdbsList(e, searchedKdbsList.map(
            // eslint-disable-next-line
            b => b.id === budget.id ? { ...b, checked: v } : b,
          ));
          onChangeKdbs(e, kdbs.map(
            // eslint-disable-next-line
            b => b.id === budget.id ? { ...b, checked: v } : b,
          ));
        }}
      />
    ))}
  </StyledBudgetArea>
);

BudgetsContainer.propTypes = {
  kdbs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
    checked: PropTypes.bool,
  })).isRequired,
  searchedKdbsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
    checked: PropTypes.bool,
  })).isRequired,
  onChangeKdbs: PropTypes.func.isRequired,
  onChangeSearchedKdbsList: PropTypes.func.isRequired,
};
export default BudgetsContainer;
