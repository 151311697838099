import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveSelectionIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledRemoveSelection = styled(StyledButton)`
    border: 1px solid #a2b4e8;
    height: 2.5em;
    align-self: flex-end;
    &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: #d0f7d4fc;
    }
`;

export const RemoveSelectionButton = ({ content, onClick, disabled }) => (
  <StyledRemoveSelection onClick={onClick} disabled={disabled} title={content}>
    <StyledImg style={{ width: '20px' }} src={RemoveSelectionIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledRemoveSelection>
);

RemoveSelectionButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RemoveSelectionButton.defaultProps = {
  onClick: () => null,
  content: 'Зняти виділення',
  disabled: false,
};

export default RemoveSelectionButton;
