import styled from 'styled-components';

export const Label = styled.label`
  // height: 100%;
  position: relative;
  color: #605b5b;
`;

// eslint-disable-next-line
export default Label;
