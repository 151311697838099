import React, { useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledFieldList } from './styles';

const Panel = forwardRef(({ children }, ref) => {
  const [height, setHeight] = useState(0);
  const measuredRef = useCallback((node) => {
    ref(node);
    if (node !== null) {
      setHeight(window.innerHeight - node.getBoundingClientRect().top);
    }
  }, [ref]);

  return (
    <StyledFieldList ref={measuredRef} height={height}>
      {children}
    </StyledFieldList>
  );
});

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

Panel.defaultProps = {
  children: null,
};

export default Panel;
