import React from 'react';
import PropTypes from 'prop-types';
import { AddIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

export const AddButton = ({ content, onClick, disabled }) => (
  <StyledButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={AddIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledButton>
);

AddButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AddButton.defaultProps = {
  onClick: () => null,
  content: 'Додати',
  disabled: false,
};

export default AddButton;
