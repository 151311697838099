import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../api/req';
import { checkTask } from '../../../api/checktask';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { ExecuteButton } from '../../../components/Styled/Buttons';
import { ErrorMessage, DimmableLoader, Item } from '../../../components/Styled/Misc';
import { FileDocIcon, ErrorImIcon } from '../../../components/Styled/Buttons/icons';
import { catalogs } from '../../../const/meta/catalogs';
import { HeaderText, EditorContainer } from '../../../components/Styled/Forms';
import { StepLabel } from '../exchange/editor/styles';

const NewWindowsLink = styled(Link).attrs({
  target: 'blank',
})`
  font-style: italic;
  color: #3d6ca2;
    &:hover{
      color: #699bd5;
    };
    &:visited{
      color: #8a69d5;
    };
`;

const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;
const SubText = styled(StepLabel)`
  margin-top: 0;
`;
const ErrorText = styled.h3`
  color: red;
  text-align: center;
`;
const ContainerError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class PayersParserEditor extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    this.state = {
      org: currentUser.org ? { id: currentUser.org.id, repr: currentUser.org.repr } : null,
      errorText: '',
      isProcessing: false,
      isErrored: false,
      isDone: false,
      updatedCatalogs: [],
    };
  }

  startProcessing = async () => {
    const { authF } = this.props;
    const {
      org,
    } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
      isDone: false,
      updatedCatalogs: [],
    });

    const options = {
      org: org.id,
    };

    const r = await api.post$('/api/service/parse-inn/', authF, options);
    if (r.ok) {
      const d = await r.json();
      checkTask(
        d.task_id,
        authF,
        this.processResult,
        (err) => this.setState({
          errorText: `${err.status} ${err.statusText}`,
          isErrored: true,
          isProcessing: false,
          isDone: false,
          updatedCatalogs: [],
        }),
      );
    } else {
      this.setState({
        errorText: `${r.status} ${r.statusText}`,
        isErrored: true,
        isProcessing: false,
        isDone: false,
      });
    }
  };

  processResult = async (result) => {
    const { authF } = this.props;
    const node = result.result.updated[Object.keys(result.result.updated)[0]];
    const ids = node.items;
    if (ids.length) {
      const r = await api.get(node.uri, authF, { id_in: String(ids) });
      if (r.ok) {
        const data = await r.json();
        const updatedCatalogs = data.results.map((d) => ({ id: d.id, repr: d.repr }));
        this.setState({
          updatedCatalogs,
          isErrored: false,
          isProcessing: false,
          isDone: true,
        });
      }
    } else {
      this.setState({
        updatedCatalogs: [],
        isErrored: false,
        isProcessing: false,
        isDone: true,
      });
    }
  };


  render() {
    const { currentUser } = this.props;
    const {
      org, isProcessing, isErrored, errorText, isDone, updatedCatalogs,
    } = this.state;
    return (
      <DimmableLoader loading={isProcessing}>
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <div>
          <HeaderText>Перезаповнити гендерні ознаки платнків</HeaderText>
          <EditorContainer style={{ overflow: 'inherit' }}>
            {currentUser.is_superuser && (
              <EditorControls.ItemPicker
                label="Установа"
                value={org}
                backend="/api/catalog/orgs/"
                onChange={(e, v) => {
                  this.setState({ org: v });
                }}
              />
            )}
          </EditorContainer>
          <ExecuteButton content="Виконати" disabled={isProcessing} onClick={this.startProcessing} />
        </div>
        {isDone && (
          <EditorContainer style={{ overflow: 'auto' }}>
            {updatedCatalogs.length ? (
              <>
                <SubText>Оброблено наступних платників:</SubText>
                <TwoColGrid>
                  {updatedCatalogs.map((cat) => (
                    <Item
                      key={cat.id}
                      content={(
                        <NewWindowsLink to={`${catalogs.payers.frontend}${cat.id}/`}>
                          {cat.repr}
                        </NewWindowsLink>
                      )}
                      icon={<img src={FileDocIcon} alt="Icon" />}
                      header="Платник"
                    />
                  ))}
                </TwoColGrid>
              </>
            ) : (
              <ContainerError>
                <img src={ErrorImIcon} alt="IconError" />
                <ErrorText>
                  Не було оброблено жодного платнику. Перевірте параметри обробки!
                </ErrorText>
              </ContainerError>
            )}
          </EditorContainer>
        )}
      </DimmableLoader>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(PayersParserEditor);
