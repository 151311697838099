import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import { compose } from 'recompose';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import meta from '../../const/meta';
import {
  MainIcon,
  LoadIcon,
  ReportsIcon,
  CompanyIcon,
  BudgetIcon,
  BranchIcon,
  KDBIcon,
  KindPaymentsIcon,
  PayersIcon,
  ContractIcon,
  CheckIcon,
  ExtractIcon,
  Logo,
  GroupsUsersIcon,
  NewExitIcon,
  Regions,
  EditIcon,
  InstructionIcon,
} from '../Styled/Buttons/icons';
import { ExitButton } from '../Styled/Buttons';
import { withAuthConsumer } from '../../providers/authProvider';

const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.isVisible && '54px'};
  font-size: 0.9em;
  font-weight: 700;
  color: #5e93d0;
  padding: ${(props) => props.isVisible ? '15px 12px' : '5px 3px'};
`;

const StyledMenu = styled.div`
  grid-area: nav;  
  box-shadow: inset 0 0 4px 0 rgb(128, 151, 224);
  background: white;
  overflow: auto;
  height: 100vh;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  width: ${(props) => props.isVisible ? '220px' : '54px'};
  
  @media (max-width: 900px) {
  // width: ${(props) => !props.isVisible ? '220px' : '54px'};
  height: auto;
}
`;

const StyledUl = styled.ul`
   margin-bottom: 50px
   // position: absolute;
   // left: 0;
   // margin: 0;
   // padding: 0;
   // width: 15em;
`;

const StyledLi = styled.li`
  width: 100%;
  list-style-type: none;
  &:last-child{
    border-bottom: 0;
  };
  &:hover{
    background: #f2f6fa;
  }
`;
const StyledLiLogo = styled.li`
  background: linear-gradient(130deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  border-bottom: 1px solid lightblue;
  box-shadow: inset 0 0 4px 0 rgb(128,151,224);
`;

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${(props) => props.isVisible ? '0 20' : '0 15px'};
  // width: 100%;
  height: 2.5em;
  color: #605b5b;
`;
const HideDashboard = styled.div`
  color: #5e93d0;
  fontSize: 14px;
  font-weight: bold;
  text-align: right;
  margin: 2px 5px;
  cursor: pointer;
`;
const ExitImg = styled.img`
  width: 35px;
  margin-bottom: 15px;
`;
// _background: ${props => props.isActive ? '#b9c9d8' : 'unset'};

const StyledEm = styled.em`
   position: absolute;
   margin-left: 15px;
   top: 50%;
   left: 3em;
   right: 2em;
   transform: translateY(-50%);
   font-style: unset;
   font-weight: 500;
   font-family: 'Roboto', sans-serif;
   font-size: 0.8em;
`;
const ContainerExit = styled.div`
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: white;
  position: absolute;
  bottom: 0;
  z-index: 1
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  98% {
    transform: rotate(0deg);
  }
  99% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const StyledImg = styled.img`
  height: 75px;
  margin-right: 5px;
  animation: ${rotate} 60s linear infinite;
  
  @media (max-width: 600px) {
  // width: 45px;
}
`;


const menus = [
  { url: '/', label: 'Головна', src: MainIcon },
  {
    url: '/srv/exchange/', label: 'Завантажити виписку', src: LoadIcon, section: 'load_subscription',
  },
  {
    url: '/rep/', label: 'Звітність (стара)', src: ReportsIcon, onlyForAdmins: true,
  },
  {
    url: '/grep/', label: 'Звітність (нова)', src: ReportsIcon, section: 'report',
  },
  {
    url: meta.cat.orgs.frontend, label: 'Установи', src: CompanyIcon, section: 'org',
  },
  {
    url: meta.cat.budgets.frontend, label: 'Бюджети', src: BudgetIcon, section: 'budget',
  },
  {
    url: meta.cat.industries.frontend, label: 'Галузі', src: BranchIcon, section: 'industry',
  },
  {
    url: meta.cat.regions.frontend, label: 'Регіональні ознаки (старостинства)', src: Regions, section: 'region',
  },
  {
    url: meta.cat.kdbs.frontend, label: 'КДБ', src: KDBIcon, section: 'kdb',
  },
  {
    url: meta.cat.paymentskins.frontend, label: 'Види платежів', src: KindPaymentsIcon, section: 'paymentskin',
  },
  {
    url: meta.cat.payers.frontend, label: 'Платники', src: PayersIcon, section: 'payers',
  },
  {
    url: meta.cat.attributes.frontend, label: 'Додаткові властивості', src: InstructionIcon, section: 'attribute',
  },
  {
    url: meta.doc.contracts.frontend, label: 'Договори (старі)', src: ContractIcon, section: 'contract',
  },
  {
    url: meta.cat.accounts.frontend, label: 'Казначейскі рахунки', src: CheckIcon, section: 'account',
  },
  {
    url: meta.doc.subscriptions.frontend, label: 'Казначейська виписка', src: ExtractIcon, section: 'subscription',
  },
  {
    url: meta.doc.subscriptions412.frontend, label: 'Файли FT*.* (форма 412)', src: ExtractIcon, section: 'subscription',
  },
  {
    url: meta.other.users.frontend, label: 'Користувачі', src: GroupsUsersIcon, onlyForAdmins: true,
  },
  {
    url: meta.cat.provinces.frontend, label: 'Області', src: Regions, onlyForAdmins: true,
  },
  {
    url: meta.cat.landcategories.frontend, label: 'Категорія земель', src: Regions, onlyForAdmins: true,
  },
  {
    url: '/calcofcontr/', label: 'Розрахунок по контрактах', src: CheckIcon, section: 'contract',
    // onlyForAdmins: true,
  },
  {
    url: '/srv/', label: 'Сервіс', src: LoadIcon, section: 'service',
  },
  {
    url: '/logica/', label: 'Сервіси для роботи з Logica', src: LoadIcon, section: 'logica',
  },
  {
    url: meta.cat.markers.frontend, label: 'Маркери', src: EditIcon, onlyForAdmins: true,
  },
];


class Menu extends PureComponent {
    static propTypes = {
      logoutHandler: PropTypes.func.isRequired,
      currentUser: PropTypes.shape({
        available_sections: PropTypes.arrayOf(PropTypes.string),
        is_superuser: PropTypes.bool,
      }).isRequired,
    };

    constructor() {
      super();
      this.state = {
        isVisible: true,
      };
    }

    render() {
      const {
        logoutHandler,
        currentUser,
      } = this.props;
      const {
        isVisible,
      } = this.state;
      return (
        <StyledMenu isVisible={isVisible}>
          <StyledUl>
            <StyledLiLogo>
              <ContainerLogo>
                {isVisible
                  ? <StyledImg src={Logo} alt="Logo" />
                  : <StyledImg width="45px" src={Logo} alt="Logo" />}
                {isVisible
                && <span>Аналіз доходів бюджету в розрізі платників податків</span>}
              </ContainerLogo>
              <div>
                <HideDashboard
                  onClick={() => this.setState({ isVisible: !isVisible })}
                  title={isVisible ? 'Згорнути' : 'Розгорнути'}
                >
                  {isVisible
                    ? '<<' : '>>'}
                </HideDashboard>
              </div>
            </StyledLiLogo>
            {menus
              .filter((menuItem) => (!menuItem.onlyForAdmins || currentUser.is_superuser)
                  && (!menuItem.section
                      || currentUser.available_sections.includes(menuItem.section)))
              .map((menuItem) => (
                <StyledLi key={menuItem.url}>
                  <StyledLink
                    to={menuItem.url}
                    exact
                    activeStyle={{
                      color: '#4272d7',
                      boxShadow: '2px 0 12px 0 rgb(177,177,177)',
                    }}
                  >
                    <img width="20" src={menuItem.src} alt="menu" />
                    {isVisible
                  && <StyledEm>{menuItem.label}</StyledEm>}
                  </StyledLink>
                </StyledLi>
              ))}
          </StyledUl>
          <ContainerExit>
            {isVisible
              ? <ExitButton content="Выйти" onClick={logoutHandler} />
              : <ExitImg src={NewExitIcon} alt="Logo" onClick={logoutHandler} /> }
          </ContainerExit>
        </StyledMenu>
      );
    }
}

const mapState = (state) => ({
  logoutHandler: state.logoutHandler,
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  withRouter,
);
export default enhance(Menu);
