import React from 'react';
import styled from 'styled-components';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import getBaseEditor, { CommandPanels } from '../../BaseEditor/index';
import Tab from '../../../components/Tab';
import TPs from '../enteringtheremainder/tp';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "date org"
    "comment comment"
    ;
  grid-template-columns: 300px 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;

const GeneralTPColumnTypes = {
  contract: { type: '/api/catalog/contracts/' },
};

const editorRenderer = (getControl, {
  data, fields, dataSetter, currentUser,
}) => {
  const renderP1 = () => (
    <>
      <ThisDocGrid>
        <GridItem area="date">
          {getControl('date')}
        </GridItem>
        {currentUser.is_superuser && (
          <GridItem area="org">
            {getControl('org', {
              backend: '/api/catalog/orgs/',
            })}
          </GridItem>
        )}
        <GridItem area="comment">
          {getControl('comment')}
        </GridItem>
      </ThisDocGrid>
      <TPs.GeneralTP
        data={data.calculationofpaymentsrows_set}
        options={fields.calculationofpaymentsrows_set}
        onChange={(e, newRecs) => dataSetter({ calculationofpaymentsrows_set: newRecs })}
        overrideColumns={GeneralTPColumnTypes}
      />
    </>
  );

  const panes = [
    { menuItem: 'Загальне', render: renderP1 },
  ];
  return (
    <Tab panes={panes} />
  );
};

export default getBaseEditor(editorRenderer, '/api/document/calculationofpayments/', CommandPanels.docCommandPanel);
