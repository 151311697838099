import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "name name import_prefix "
    "account type operation_type"
    "disabled disabled disabled "
    "org org org "
    ;
  grid-auto-columns: 1fr;
  
  @media (max-width: 600px) {
  display: block;
  } 
`;

const editorRenderer = (getControl, { data, currentUser }) => (
  <ThisDocGrid>
    <GridItem area="name">
      {getControl('name')}
    </GridItem>
    <GridItem area="account">
      {getControl('account', {
        backend: '/api/catalog/accounts/',
        filter: {
          org: currentUser.is_superuser && data.org
            ? data.org.id
            : currentUser.org && currentUser.org.id,
        },
      })}
    </GridItem>
    <GridItem area="operation_type">
      {getControl('operation_type')}
    </GridItem>
    <GridItem area="type">
      {getControl('type')}
    </GridItem>
    <GridItem area="import_prefix">
      {getControl('import_prefix')}
    </GridItem>
    <GridItem area="disabled">
      {getControl('disabled')}
    </GridItem>
    {currentUser.is_superuser && (
      <GridItem area="org">
        {getControl('org', {
          backend: '/api/catalog/orgs/',
        })}
      </GridItem>
    )}
  </ThisDocGrid>
);


export default getBaseEditor(editorRenderer, '/api/catalog/paymentskins/', CommandPanels.catCommandPanel);
