import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DefaultInput } from '../../../../components/Styled/Input';

const Button = styled.button`
    color: white;
    background-color: #007bff;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    width: 120px;
     &:hover{
    background-color: #065ebc
     }`;

const NewScheme = ({ onCreate, handleChangeNewSchemeParams, onClose }) => (
  <>

    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      Унікальний ідентифікатор:
      <DefaultInput
        type="text"
        id="identifier"
        name="identifier"
        onChange={handleChangeNewSchemeParams}
      />
    </label>

    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      Назва схеми:
      <DefaultInput
        type="text"
        id="name"
        name="name"
        onChange={handleChangeNewSchemeParams}
      />
    </label>

    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      Опис схеми:
      <DefaultInput
        type="text"
        id="description"
        name="description"
        onChange={handleChangeNewSchemeParams}
      />
    </label>

    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label style={{ marginBottom: '5px', marginTop: '5px' }}>
      Ознака активності:
      <input
        type="checkbox"
        id="is_active"
        name="is_active"
        onChange={handleChangeNewSchemeParams}
      />
    </label>

    <Button variant="primary" type="submit" onClick={() => { onCreate(); onClose(); }}>
      Створити
    </Button>
  </>
);

NewScheme.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleChangeNewSchemeParams: PropTypes.func.isRequired,
};

export default NewScheme;
