// import { dateToString } from '../../../../../api/utils';
import { getTablePartController } from '../../../../BaseEditor';
import { GridTemplate } from './styles';
import BodyComponent from './body';
import HeaderComponent from './header';
import FooterComponent from './footer';
import CommandPanel from './commandPanel';

const newRecordData = () => ({
  date: null,
  order_amount: 0,
  payment_notes: '',
});

const curDateToString = (d) => {
  const Year = `${'0000'.slice(0, 4 - d.getFullYear().toString().length)}${d.getFullYear()}`;
  const Month = `${d.getMonth() + 1}` < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  return `${Year}-${Month}-${d.getDate()}`;
};

const autoFill = (e, params) => {
  const {
    onDataChange,
    values: { sum, quantOfMonth, date },
  } = params;
  console.log((new Date(date)).toISOString());
  const newData = [...Array(quantOfMonth).keys()].reduce((R) => {
    const cSum = Math.round((R.rSum / R.rq) * 100) / 100;
    const nextDate = new Date(R.cDate.valueOf());
    nextDate.setMonth(R.cDate.getMonth() + 1);
    return {
      data: [
        ...R.data,
        {
          date: curDateToString(R.cDate),
          order_amount: cSum.toFixed(2),
          payment_notes: '',
        },
      ],
      rSum: R.rSum - cSum,
      rq: R.rq - 1,
      cDate: nextDate,
    };
  }, {
    data: [],
    rSum: sum,
    rq: quantOfMonth,
    cDate: new Date(date),
  });
  onDataChange(e, newData.data);
};

const addAutoFill = (e, params) => {
  const {
    onDataChange,
    values: {
      sum, quantOfMonth, date,
    },
    data,
  } = params;

  const prevData = data;
  const newData = [...Array(quantOfMonth).keys()].reduce((R) => {
    const cSum = Math.round((R.rSum / R.rq) * 100) / 100;
    const nextDate = new Date(R.cDate.valueOf());
    nextDate.setMonth(R.cDate.getMonth() + 1);

    return {
      data: [
        ...R.data,
        {
          // date: dateToString(R.cDate),
          date: curDateToString(R.cDate),
          order_amount: cSum.toFixed(2),
          payment_notes: '',
        },
      ],
      rSum: R.rSum - cSum,
      rq: R.rq - 1,
      cDate: nextDate,
    };
  }, {
    data: [],
    rSum: sum,
    rq: quantOfMonth,
    cDate: new Date(date),
  });

  const onlyData = [...new Set(newData.data.map((d) => d.date))];
  const updatePrevData = prevData.filter((d) => !onlyData.includes(d.date)).map((d) => d);
  const updateDate = [...updatePrevData, ...newData.data].sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    }
    if (a.date < b.date) {
      return -1;
    }
    return 0;
  });

  onDataChange(e, updateDate);
};

const actionHandler = (e, action, params) => {
  switch (action) {
  case 'AutomaticFill':
    autoFill(e, params);
    break;
  case 'AddAutomaticFill':
    addAutoFill(e, params);
    break;
  default:
    console.log('Unknow action', action);
  }
};

export default getTablePartController(
  GridTemplate,
  BodyComponent,
  newRecordData,
  CommandPanel,
  HeaderComponent,
  FooterComponent,
  actionHandler,
);
