import React from 'react';
import {
  Link, withRouter,
} from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Item } from '../Styled/Misc';
import { HeaderText, EditorContainer } from '../Styled/Forms';
import {
  CheckIcon, ContractIcon, InflationIcon, PrintIcon, CorrectIcon, ExecuteIcon,
} from '../Styled/Buttons/icons';
import { withAuthConsumer } from '../../providers/authProvider';
import meta from '../../const/meta';

const services = [
  {
    title: 'Розрахувати платежі',
    desc: 'Використовується для автоматичного розрахунку платежів',
    icon: CorrectIcon,
    url: '/srv/calculate_payments/',
  },
  {
    title: 'Розраховані платежі',
    desc: 'Використовується для створення та перегляду платежів',
    icon: ExecuteIcon,
    url: `${meta.doc.calculationofpayments.frontend}`,
  },
  {
    title: 'Введення залишків',
    desc: 'Використовується для введення залишків',
    icon: CheckIcon,
    url: `${meta.doc.enteringtheremainder.frontend}`,
  },
  {
    title: 'Індекс інфляції',
    desc: 'Використовується для створення індексу інфляції',
    icon: InflationIcon,
    url: `${meta.cat.inflationindex.frontend}`,
  },
  {
    title: 'Договори (нові)',
    desc: 'Використовується для створення та перегляду договорів нового зразка',
    icon: ContractIcon,
    url: `${meta.doc.calculatedcontracts.frontend}`,
  },
  {
    title: 'Отримати друковані форми',
    desc: 'Використовується для автоматичного отримання друкованих форм',
    icon: PrintIcon,
    url: '/srv/contractprintform/',
  },
];
const ServicePage = ({ currentUser }) => (
  <EditorContainer>
    <HeaderText>Розрахунок по контрактах</HeaderText>
    {services
      .filter((s) => !s.onlyForAdmins || currentUser.is_superuser)
      .map((s) => (
        <Item
          key={s.url}
          icon={
            <img src={s.icon} alt="menu" />
          }
          header={(
            <Link to={s.url}>{s.title}</Link>
          )}
          content={<ul>{s.desc}</ul>}
        />
      ))}
  </EditorContainer>
);

ServicePage.propTypes = {
  currentUser: PropTypes.shape({
    is_superuser: PropTypes.bool,
  }).isRequired,
};
const mapState = (state) => ({
  currentUser: state.currentUser,
});

const enhance = compose(
  withAuthConsumer(mapState),
  withRouter,
);

export default enhance(ServicePage);
