import React from 'react';
import PropTypes from 'prop-types';
import { SelectIcon } from './icons';
import { StyledButton, StyledImg } from './styledCommandPanelButton';

export const SelectButton = ({ content, onClick, disabled }) => (
  <StyledButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={SelectIcon} alt={content} />
    <span>{content}</span>
  </StyledButton>
);

SelectButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SelectButton.defaultProps = {
  onClick: () => null,
  content: 'Обрати',
  disabled: false,
};

export default SelectButton;
