import React from 'react';
import PropTypes from 'prop-types';
import TableControls from '../../../BaseEditor/tableControls';
import { metaFieldPropType } from '../hooks/metafields';
import { ListItem } from '../styles';

const MetaFieldItem = ({
  metaField, active, onRenameField, onClick,
}) => (

  <ListItem
    active={active}
    style={{ marginTop: '4px' }}
    onClick={onClick}
  >
    <TableControls.StringInput

      controlId={`input-metafield-${metaField.itemKey}`}
      value={metaField.name}
      onChange={(e, v) => onRenameField(metaField.name, v)}
    />
  </ListItem>
);

MetaFieldItem.propTypes = {
  metaField: metaFieldPropType.isRequired,
  active: PropTypes.bool,
  onRenameField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

MetaFieldItem.defaultProps = {
  active: false,
};

export default MetaFieldItem;
