import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels, EditorControls } from '../../BaseEditor';
import ImageInput from '../../BaseEditor/imageField/imageField';
import { withLabel } from '../../BaseEditor/withLabel';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import Tab from '../../../components/Tab';

const LabeledImageInput = withLabel(ImageInput);

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
    "name name"
    "logo province"
    "logo hide_e"
    "logo comment"
    ;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content 1fr;
    
  @media (max-width: 600px) {
  display: block;
  } 
`;

const DetailsDocGrid = styled(DocGrid)`
grid-template-areas:  
"city position"
"name_of_position address"
"landlord edrpou"
"mfo name_for_account"
"recipient_of_funds type_of_contract"
`;

const CommentD = styled(GridItem).attrs({
  area: 'comment',
})`
  &>*{
    height: 100%;
    &>*{
      height: 90%;
    }
  }
`;

const editorRenderer = (getControl, { data, dataSetter, currentUser }) => {
  const onChangeFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const value = {
        file: reader.result,
        filename: file.name,
      };
      dataSetter({ logo: value });
    };
  };

  const renderP1 = () => (
    <ThisDocGrid>
      <GridItem area="name">
        {getControl('name')}
      </GridItem>
      <CommentD>
        {/* {getControl('comment')} */}
        <EditorControls.TextInput
          value={data.comment}
          onChange={(e, v) => dataSetter({ comment: v })}
          label="Коментар"
        />
      </CommentD>
      <GridItem area="hide_e">
        {getControl('hide_e')}
      </GridItem>
      <GridItem area="logo">
        <LabeledImageInput
          label="Логотип"
          onChange={onChangeFile}
          value={data.logo}
          width="150px"
        />
      </GridItem>
      <GridItem area="province">
        {getControl('province', { backend: '/api/catalog/provinces/', canErase: true })}
      </GridItem>

    </ThisDocGrid>
  );

  const renderP2 = () => (
    <DetailsDocGrid>
      <GridItem area="city">
        {getControl('city')}
      </GridItem>
      <EditorControls.TextInput value={data.address} onChange={(e, v) => dataSetter({ address: v })} label="Повна адреса для акту" />
      <GridItem area="mfo">
        {getControl('mfo')}
      </GridItem>
      <GridItem area="position">
        {getControl('position')}
      </GridItem>
      {currentUser.is_superuser && (
        <GridItem area="type_of_contract">
          {getControl('type_of_contract')}
        </GridItem>
      )}
      <EditorControls.TextInput value={data.name_of_position} onChange={(e, v) => dataSetter({ name_of_position: v })} label="Повне ім'я для акту" />
      <GridItem area="landlord">
        {getControl('landlord')}
      </GridItem>
      <GridItem area="recipient_of_funds">
        {getControl('recipient_of_funds')}
      </GridItem>
      <GridItem area="edrpou">
        {getControl('edrpou')}
      </GridItem>
      <GridItem area="name_for_account">
        {getControl('name_for_account')}
      </GridItem>
    </DetailsDocGrid>
  );

  const panes = [
    { menuItem: 'Деталі', render: renderP2 },
  ];

  if (currentUser.is_superuser) {
    panes.unshift({ menuItem: 'Загальне', render: renderP1 });
  }

  return (
    <Tab panes={panes} />
  );
};

export default getBaseEditor(editorRenderer, '/api/catalog/orgs/', CommandPanels.catCommandPanel);
