import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StringInput from '../../BaseEditor/stringInput';
import DateInput from '../../BaseEditor/dates';
import Checkbox from '../../BaseEditor/Checkbox';
import getNumberInput from '../../BaseEditor/numberInput';

const Table = styled.div`
display: inline-grid;
grid-template-columns: auto auto;
background-color: #eee;
grid-gap: 2px;
border-radius: 5px;
margin-bottom: 15px;
`;

const Div = styled.div`
padding: 4px;
text-align: center;
background-color: white;
border-radius: 5px;
border: 1px solid rgba(34,36,38,.15);
border-radius: .3rem;
`;

const Head = styled.div`
padding: 4px;
text-align: center;
background: linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9;
border: 1px solid rgba(34,36,38,.15);
border-radius: .3rem;
`;

const Button = styled.button`
height: 40px;
width: 250px;
color: red;
cursor: pointer;
border: 1px solid red;
background-color: white;
border-radius: 5px;
&:hover{
color: white;
background-color: red;}
`;

const Attributes = ({ attributes, attributesList, dataSetter }) => {
  const arrOfAttr = Object.entries(attributesList);

  const FloatInput = getNumberInput(18, 4);
  const IntegerInput = getNumberInput(18, 0);

  return (
    <>
      {arrOfAttr.length > 0
        ? (
          <>
            <Table>
              <Head>Назва</Head>
              <Head>Значення</Head>
              {arrOfAttr && arrOfAttr.map((attr) => (
                <>
                  <Div>{attr[1].label}</Div>
                  {attr[1].type === 'REAL' && (
                    <FloatInput
                      value={attributes && attributes[attr[0]]}
                      onChange={(e, v) => dataSetter({
                        attributes:
                            { ...attributes, [attr[0]]: v },
                      })}
                      label={false}
                    />
                  )}
                  {attr[1].type === 'DATE' && (
                    <DateInput
                      value={attributes && attributes[attr[0]]}
                      onChange={(e, v) => dataSetter({
                        attributes:
                            { ...attributes, [attr[0]]: v },
                      })}
                      label={false}
                    />
                  )}
                  {attr[1].type === 'BOOLEAN' && (
                    <Checkbox
                      style={{ textAlign: 'center', verticalAlign: 'center' }}
                      value={attributes && attributes[attr[0]]}
                      onChange={(e, v) => dataSetter({
                        attributes:
                            { ...attributes, [attr[0]]: v },
                      })}
                      label={false}
                    />
                  )}
                  {attr[1].type === 'TEXT' && (
                    <StringInput
                      value={attributes && attributes[attr[0]]}
                      onChange={(e, v) => dataSetter({
                        attributes:
                            { ...attributes, [attr[0]]: v },
                      })}
                      label={false}
                    />
                  )}
                  {attr[1].type === 'INTEGER' && (
                    <IntegerInput
                      value={attributes && attributes[attr[0]]}
                      onChange={(e, v) => dataSetter({
                        attributes:
                            { ...attributes, [attr[0]]: v },
                      })}
                      label={false}
                    />
                  )}
                </>
              ))}
            </Table>
            <Button onClick={() => dataSetter({ attributes: {} })}>Скинути всі значення</Button>
          </>
        )
        : (
          <h2>
            Для даної установи немає додаткових властивостей. Для створення перейдіть
            {' '}
            <a href="/#/cat/attributes/create" target="_self">за посиланням.</a>
          </h2>
        )}
    </>
  );
};

Attributes.propTypes = {
  attributes: PropTypes.arrayOf({}).isRequired,
  attributesList: PropTypes.arrayOf({}).isRequired,
  dataSetter: PropTypes.func.isRequired,
};

export default Attributes;
