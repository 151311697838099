import React from 'react';
import PropTypes from 'prop-types';
import { CommandPanel, Question, QuestionActions } from '../../../components/Styled/Misc';
import { SaveButton, CloseButton } from '../../../components/Styled/Buttons';
import { actions } from './actions';

export const catEditorCP = ({ onActionClick, isChanged }) => (
  <CommandPanel>
    <SaveButton onClick={(e) => onActionClick(e, actions.save)} disabled={!isChanged} />
    {isChanged ? (
      <Question
        questionText="Вийти без запису?"
        actionHandler={(e, v) => {
          if (v === QuestionActions.YesNoAction.yes.value) {
            onActionClick(e, actions.close);
          }
          e.stopPropagation();
        }}
        actions={Object.values(QuestionActions.YesNoAction)}
      >
        <CloseButton onClick={(e) => onActionClick(e, actions.close)} />
      </Question>
    ) : (
      <CloseButton onClick={(e) => onActionClick(e, actions.close)} />
    )}
  </CommandPanel>
);

catEditorCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  isChanged: PropTypes.bool,
};

catEditorCP.defaultProps = {
  isChanged: false,
};

export default catEditorCP;
