import PropTypes from 'prop-types';

export const fieldsPropType = PropTypes.shape({
  date: PropTypes.shape({
    label: PropTypes.string,
  }),
  order_amount: PropTypes.shape({
    label: PropTypes.string,
  }),
  payment_notes: PropTypes.shape({
    label: PropTypes.string,
  }),
});

export default fieldsPropType;
