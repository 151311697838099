import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api/req';
import { AppContext } from '../../../providers/authProvider';
import { DimmableLoader, ErrorMessage } from '../../../components/Styled/Misc';
import { DefaultInput } from '../../../components/Styled/Input';
import { Button } from '../../../components/Styled/Buttons';
import {
  H2, StyledTr, ButtonContainer, StyledTd, StyledTh, StyledFontAwesomeIcon, SDefaultInput,
} from '../styled/styledComponent';

const UpdateGroup = ({ onClose, activeId, setUpdateList }) => {
  const { auth } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [roles, setRoles] = useState(null);
  const [updatedRoles, setUpdatedRoles] = useState(null);

  const onLoadStart = useCallback(() => {
    setLoading(true);
    setErr(null);
  }, []);

  const onLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback((e) => setErr(e), []);

  const getRoles = useCallback((permGroup) => {
    const loader = async () => {
      const r = await api.get('/api/role/', auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        const fillIn = (str) => permGroup.rolepriorityforgroup_set
          .find((perm) => perm.role.id === str.id);

        const newData = rData.results.map((el) => (fillIn(el) ? {
          role: { id: fillIn(el).role.id, name: fillIn(el).role.name },
          priority: fillIn(el).priority,
          group: null,
          check: true,
        } : {
          role: { id: el.id, name: el.name, description: el.description }, priority: '', group: null, check: false,
        }));

        setRoles((prev) => ({ ...prev, rolepriorityforgroup_set: [...newData] }));
        setUpdatedRoles((prev) => ({ ...prev, rolepriorityforgroup_set: [...newData] }));
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
    /*eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const loader = async () => {
      const r = await api.get(`/api/permissiongroup/${activeId}`, auth);
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then((rData) => {
        setRoles(rData);
        setUpdatedRoles(rData);
        return getRoles(rData);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, getRoles, onError, onLoadEnd, onLoadStart]);

  const updateGroupParams = (e) => {
    const { name, value } = e.target;
    setUpdatedRoles({
      ...updatedRoles,
      [name]: value,
    });
  };

  const updateGroup = useCallback(() => {
    setErr(null);
    const checkedRoles = updatedRoles.rolepriorityforgroup_set.filter((el) => el.check);
    const checkedRolesWithPriority = checkedRoles
      .filter((el) => el.priority !== null && el.priority !== undefined);

    const rolepriorityforgroupset = checkedRolesWithPriority
      .map((r) => ({ role: { id: r.role.id }, priority: r.priority, group: null }));

    if (checkedRolesWithPriority.length < checkedRoles.length) {
      setErr('Не для всіх ролей вказано пріоритет!');
      return;
    }

    const params = {
      ...updatedRoles,
      rolepriorityforgroup_set: rolepriorityforgroupset,
    };
    const loader = async () => {
      const r = await api.put(`/api/permissiongroup/${activeId}/`, auth, params);
      if (r.status === 400) {
        const error = await r.json();
        Object.entries(error).map((e) => {
          if (e) {
            throw new Error(`${e[0]}: ${e[1]}`);
          }
          return error;
        });
        return error;
      }
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    };
    onLoadStart();
    loader()
      .then(() => {
        onClose();
        setUpdateList((prev) => !prev);
      })
      .catch((e) => onError(e.message))
      .finally(() => onLoadEnd());
  }, [activeId, auth, onClose, onError, onLoadEnd, onLoadStart, setUpdateList, updatedRoles]);

  const handleCheckbox = useCallback((e, key) => {
    if (key === 'all') {
      setUpdatedRoles((prev) => ({
        ...prev,
        rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
          .map((r) => ({ ...r, check: true }))],
      }));
    } else if (key === 'nothing') {
      setUpdatedRoles((prev) => ({
        ...prev,
        rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
          .map((r) => ({ ...r, check: false, priority: '' }))],
      }));
    } else {
      const { id, checked } = e.target;
      setUpdatedRoles((prev) => ({
        ...prev,
        rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
          .map((i) => (i.role.id === parseInt(id, 10)
            ? { ...i, check: checked, priority: '' } : i))],
      }));
    }
  }, []);

  const handlePriority = useCallback((e) => {
    const { id, value } = e.target;
    setUpdatedRoles((prev) => ({
      ...prev,
      rolepriorityforgroup_set: [...prev.rolepriorityforgroup_set
        .map((i) => (i.role.id === parseInt(id, 10)
          ? { ...i, priority: value } : i))],
    }));
  }, []);

  return (
    <>
      <DimmableLoader loading={loading}>
        {err && (
          <ErrorMessage text={err} />
        )}

        {updatedRoles
        && (
          <div style={{ overflow: 'auto' }}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Назва групи:
                <DefaultInput
                  type="text"
                  id="name"
                  name="name"
                  onChange={updateGroupParams}
                  value={updatedRoles.name}
                />
              </label>

              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Опис групи:
                <DefaultInput
                  type="text"
                  id="description"
                  name="description"
                  onChange={updateGroupParams}
                  value={updatedRoles.description}
                />
              </label>
            </div>

            <H2 color="blue">Ролі</H2>

            <div className="mb-2">
              <Button onClick={() => handleCheckbox(null, 'all')} content="Включити всі">
                <StyledFontAwesomeIcon color="green" icon={faCheckSquare} size="lg" />
              </Button>
              <Button onClick={() => handleCheckbox(null, 'nothing')} content="Виключити всі">
                <StyledFontAwesomeIcon color="#cbcbcb" icon={faSquare} size="lg" />
              </Button>
            </div>

            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <StyledTh width="200px">Включити в список</StyledTh>
                  <StyledTh width="200px">Пріоритет</StyledTh>
                  <StyledTh>Назва</StyledTh>
                  <StyledTh>Опис</StyledTh>
                </tr>
              </thead>
              <tbody>
                {updatedRoles.rolepriorityforgroup_set.map((el) => (
                  <StyledTr key={el.role.id}>
                    <StyledTd textAlign="center">
                      <input
                        type="checkbox"
                        id={el.role.id}
                        checked={el.check}
                        onChange={handleCheckbox}
                      />
                    </StyledTd>
                    <StyledTd textAlign="center">
                      {el.check
                          && (
                            <SDefaultInput
                              type="number"
                              id={el.role.id}
                              name="is_active"
                              checked={el.check}
                              value={el.priority}
                              onChange={handlePriority}
                              noBorder
                            />
                          )}
                    </StyledTd>
                    <StyledTd paddingLeft="50px">{el.role.name}</StyledTd>
                    <StyledTd paddingLeft="50px">{el.role.description}</StyledTd>
                  </StyledTr>
                ))}
              </tbody>
            </table>
            <ButtonContainer>
              <Button className="mt-2 mr-2" variant="outline-success" onClick={updateGroup} content="Зберегти" />
              <Button className="mt-2" variant="outline-danger" onClick={() => setUpdatedRoles(roles)} content="Повернутись до попередніх значень" />
            </ButtonContainer>
          </div>
        )}

      </DimmableLoader>
    </>
  );
};

UpdateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  setUpdateList: PropTypes.func.isRequired,
  activeId: PropTypes.number,
};

UpdateGroup.defaultProps = {
  activeId: null,
};

export default UpdateGroup;
