import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  StyledContainer, StyledHeader, StyledSubContainer, VerticalCP,
} from '../styles';
import AllowedColumns, { allowedColumnPropType } from '../alowed';
import GroupColumns from './groupColumns';
import { groupcolumnPropType } from './groupColumn';
import { NextButton, PreviousButton } from '../../../components/Styled/Buttons';


class GroupsView extends PureComponent {
  static propTypes = {
    allowedColumns: PropTypes.arrayOf(allowedColumnPropType),
    groupColumns: PropTypes.arrayOf(groupcolumnPropType),
    onAddColumn: PropTypes.func,
    onDeleteColumn: PropTypes.func,
    onActivateGroup: PropTypes.func.isRequired,
    onActivateAvailable: PropTypes.func.isRequired,
    activeGroup: PropTypes.number,
    activeAvailable: PropTypes.number,
    droppableId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    allowedColumns: [],
    groupColumns: [],
    onAddColumn: () => null,
    onDeleteColumn: () => null,
    activeGroup: null,
    activeAvailable: null,
  };

  render() {
    const {
      allowedColumns, groupColumns,
      onAddColumn, onDeleteColumn,
      activeAvailable, activeGroup, onActivateGroup, onActivateAvailable, droppableId,
    } = this.props;
    return (
      <StyledContainer>
        <StyledHeader>Группування</StyledHeader>
        <StyledSubContainer>
          <AllowedColumns
            columns={allowedColumns}
            onDoubleClick={onAddColumn}
            activeRow={activeAvailable}
            onActivateRow={onActivateAvailable}
          />
          <VerticalCP>
            <NextButton
              onClick={(e) => onAddColumn(e, activeAvailable)}
              disabled={activeAvailable === null}
            />
            <PreviousButton
              onClick={(e) => onDeleteColumn(e, activeGroup)}
              disabled={!activeGroup === null}
            />
          </VerticalCP>
          <GroupColumns
            columns={groupColumns}
            onDoubleClick={onDeleteColumn}
            activeRow={activeGroup}
            onActivateRow={onActivateGroup}
            droppableId={droppableId}
          />
        </StyledSubContainer>
      </StyledContainer>
    );
  }
}

export default GroupsView;
