import React from 'react';
import PropTypes from 'prop-types';
import { CommandPanel } from '../../../components/Styled/Misc';
import { SelectButton, CloseButton } from '../../../components/Styled/Buttons';
import { actions } from './actions';

export const catSelectorCP = ({
  onActionClick, canSelect,
}) => (
  <CommandPanel>
    <SelectButton onClick={(e) => onActionClick(e, actions.select)} disabled={!canSelect} />
    <CloseButton onClick={(e) => onActionClick(e, actions.close)} />
  </CommandPanel>
);

catSelectorCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  canSelect: PropTypes.bool,
};

catSelectorCP.defaultProps = {
  canSelect: true,
};

export default catSelectorCP;
