import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckedIcon } from '../../components/Styled/Buttons/icons';

export const InputField = styled.div`
  ${(props) => props.disabled ? 'filter: grayscale(1) opacity(.275);' : `cursor: pointer;
    &> * { cursor: pointer; }`}
    display: inline-block;
    margin-left: ${({ noMargin }) => noMargin ? '0px' : '5px'};
`;

export const StyleCheckbox = styled.input`
    display:none;
    +label:before{
      ${({ checked }) => checked ? `background:url("${CheckedIcon}") 2px 3px no-repeat white` : 'background: white'};
      content: "";
      display: inline-block;
      margin: -5px 4px 0 0;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      border: 1px solid #BFBFBF;
      border-radius: 2px;
      }    
`;

export const LabelCheck = styled.label`
    line-height: 20px;
    font-family: Roboto,sans-serif;
    font-size: 14px;
    color: black;
`;

class ChekboxInput extends React.PureComponent {
    static propTypes = {
      id: PropTypes.string,
      value: PropTypes.bool,
      children: PropTypes.node,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,
      label: PropTypes.node,
      noMargin: PropTypes.bool,
    };

    static defaultProps = {
      id: null,
      disabled: null,
      children: null,
      label: null,
      value: false,
      onChange: () => {
        // eslint-disable-next-line
            throw 'Not implemented action';
      },
      noMargin: false,
    };

    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
      const { props: { onChange, value, disabled } } = this;
      e.stopPropagation();
      return !disabled && onChange(e, !value);
    }

    render() {
      const {
        props: {
          id, value, children, label, disabled, noMargin,
        },
      } = this;
      return (
        <InputField
          disabled={disabled}
          onClick={this.handleClick}
          noMargin={noMargin}
          {...this.props}
        >
          <StyleCheckbox
            type="checkbox"
            id={id}
            checked={value}
            onChange={(e) => { e.preventDefault(); }}
          />
          <LabelCheck>{label || children}</LabelCheck>
        </InputField>
      );
    }
}

export default ChekboxInput;
