import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DefaultInput } from '../../../components/Styled/Input';

export const StyledTr = styled.tr`
border-bottom: 1px solid #eee;
height: 30px`;

export const ButtonContainer = styled.div`
margin-top: 10px`;

export const StyledTd = styled.td`
border: 1px solid grey;
${({ textAlign }) => `text-align: ${textAlign}`};
${({ paddingLeft }) => `padding-left: ${paddingLeft}`}`;

export const StyledTh = styled.th`
position: sticky;
z-index: 9;
top: 0;
background: whitesmoke;
padding: 8px;
${({ width }) => `width: ${width}`}`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
margin-left: 5px`;

export const H2 = styled.h2`
text-align: center;
  ${({ color }) => `color: ${color}`}`;

export const StyledDiv = styled.div`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;`;

export const SDefaultInput = styled(DefaultInput)`
text-align: center`;
