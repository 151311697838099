import { getTablePartController, getTPCommandPanel } from '../../../../BaseEditor';
import { GridTemplate } from './styles';
import BodyComponent from './body';
import HeaderComponent from './header';

const CommandPanel = getTPCommandPanel();

const newRecordData = () => ({
  account: { id: null, repr: '' },
  account_id: null,
  contract: { id: null, repr: '' },
  contract_id: null,
  id: null,
  order_amount: '',
  payer: { id: null, full_name: '' },
  payer_id: null,
  payment_date: null,
  payment_notes: null,
  payment_number: '',
  revenue_amount: null,
  warning: false,
});

export default getTablePartController(
  GridTemplate,
  BodyComponent,
  newRecordData,
  CommandPanel,
  HeaderComponent,
);
