import React from 'react';
import { Route, Switch } from 'react-router-dom';
import cats from '../../containers/catalogs';
import docs from '../../containers/documents';
import srvs from '../../containers/services';
import logicaSrvs from '../../containers/logica';
import Reports from '../../containers/reports';
import Home from './home';
import meta from '../../const/meta';
import Service from './servicesPage';
import Logica from './logicaPage';
import CalculateOfContractsPage from './calculateOfContractsPage';
import ReportPanel from '../../containers/gatherer_reports';
import NewReportEditor from '../../containers/basicReport';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    {Object.keys(meta).filter((model) => model === 'cat' || model === 'doc' || model === 'other').map((model) => (
      Object.keys(meta[model]).map((m) => (
        <Route exact key={`${model}-${m}`} path={meta[model][m].frontend} component={meta[model][m].lister} />
      ))
    ))}

    <Route exact path="/logica/" component={Logica} />
    <Route exact path="/logica/login/" component={logicaSrvs.LoginInLogica.Editor} />
    <Route exact path="/logica/getTreasureFiles/" component={logicaSrvs.ImportTreasureFiles.Editor} />

    <Route exact path={`${meta.cat.orgs.frontend}:id`} component={cats.orgs.Editor} />
    <Route exact path={`${meta.cat.budgets.frontend}:id`} component={cats.budgets.Editor} />
    <Route exact path={`${meta.cat.industries.frontend}:id`} component={cats.industries.Editor} />
    <Route exact path={`${meta.cat.regions.frontend}:id`} component={cats.regions.Editor} />
    <Route exact path={`${meta.cat.kdbs.frontend}:id`} component={cats.kdbs.Editor} />
    <Route exact path={`${meta.cat.paymentskins.frontend}:id`} component={cats.paymenyskils.Editor} />
    <Route exact path={`${meta.cat.payers.frontend}:id`} component={cats.payers.Editor} />
    <Route exact path={`${meta.cat.accounts.frontend}:id`} component={cats.accounts.Editor} />
    <Route exact path={`${meta.cat.provinces.frontend}:id`} component={cats.provinces.Editor} />
    <Route exact path={`${meta.cat.landcategories.frontend}:id`} component={cats.landcategories.Editor} />
    <Route exact path={`${meta.cat.markers.frontend}:id`} component={cats.markers.Editor} />
    <Route exact path={`${meta.cat.inflationindex.frontend}:id`} component={cats.inflationindex.Editor} />
    <Route exact path={`${meta.cat.attributes.frontend}:id`} component={cats.attributes.Editor} />

    <Route exact path={`${meta.doc.enteringtheremainder.frontend}:id`} component={cats.enteringtheremainder.Editor} />
    <Route exact path={`${meta.doc.contracts.frontend}:id`} component={cats.contracts.Editor} />
    <Route exact path={`${meta.doc.calculatedcontracts.frontend}:id`} component={cats.calculatedcontracts.Editor} />
    <Route exact path={`${meta.doc.subscriptions.frontend}:id`} component={docs.subscriptions.Editor} />
    <Route exact path={`${meta.doc.subscriptions412.frontend}:id`} component={docs.subscriptions412.Editor} />
    <Route exact path={`${meta.doc.calculationofpayments.frontend}:id`} component={docs.calculationofpayments.Editor} />

    <Route exact path="/calcofcontr/" component={CalculateOfContractsPage} />

    <Route exact path="/srv/" component={Service} />

    <Route exact path="/srv/contractprintform/" component={srvs.ContractPrintForm.Editor} />
    <Route exact path="/srv/calculate_payments/" component={srvs.CalculatePayments.Editor} />
    <Route exact path="/srv/exchange/" component={srvs.exchange.Editor} />
    <Route exact path="/srv/reload_contracts/" component={srvs.ContractsReload.Editor} />
    <Route exact path="/srv/parse-comments/" component={srvs.CommentsReparse.Editor} />
    <Route exact path="/srv/parse-payers/" component={srvs.PayersParser.Editor} />
    <Route exact path="/srv/send-message/" component={srvs.SendMessage.Editor} />
    <Route exact path="/srv/unite-payers/" component={srvs.UnitePayers.Editor} />
    <Route exact path="/srv/master/" component={srvs.Master.Editor} />
    <Route exact path="/srv/remarks-processing/" component={srvs.RemarksProcessing.Editor} />
    <Route exact path="/srv/skd-master/" component={srvs.SKDMaster.Editor} />
    <Route exact path="/srv/role_management/" component={srvs.RoleManagement.Editor} />
    <Route exact path="/srv/group_management/" component={srvs.GroupManagement.Editor} />
    <Route exact path="/srv/disabled_kdb/" component={srvs.DisabledKdb.Editor} />

    <Route exact path="/rep" component={Reports.ReportLister} />
    <Route exact path={meta.rep.payers.frontend} component={Reports.PayersReport} />
    <Route exact path={meta.rep.comparePeriods.frontend} component={Reports.CMPReport} />
    <Route exact path={meta.rep.contractReports.frontend} component={Reports.ContractReprot} />
    <Route exact path={meta.rep.compare412.frontend} component={Reports.Compare412} />
    <Route exact path={meta.rep.compareBetween412.frontend} component={Reports.CompareBetween412} />
    {/* <Route exact path="/rep/:id" component={ReportEditor} /> */}
    <Route exact path={`${meta.rep.payers.frontend}:id`} component={Reports.PayersReport} />
    <Route exact path={`${meta.rep.comparePeriods.frontend}:id`} component={Reports.CMPReport} />
    <Route exact path={`${meta.rep.contractReports.frontend}:id`} component={Reports.ContractReprot} />
    <Route exact path={`${meta.rep.compare412.frontend}:id`} component={Reports.Compare412} />
    <Route exact path={`${meta.rep.compareBetween412.frontend}:id`} component={Reports.CompareBetween412} />

    <Route exact path="/users/:id" component={cats.users.Editor} />
    <Route exact path="/profiles/:id" component={cats.profiles.Editor} />

    <Route exact path="/grep" component={ReportPanel} />
    <Route exact path="/grep/:id" component={NewReportEditor} />

    <Route component={() => (<div>Ошиблись адресом?</div>)} />
  </Switch>
);

export default Routes;
