import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  DataCell, ResDataCell,
} from './styles';

class ListRow extends PureComponent {
  static propTypes = {
    row: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    resourceColumns: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })),
    xOffset: PropTypes.number,
    yOffset: PropTypes.number,
    groupLevel: PropTypes.number.isRequired,
    valuesRepresentator: PropTypes.func,
    onContextMenu: PropTypes.func,
    // eslint-disable-next-line
    groupColumns: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      query_label: PropTypes.string,
    })).isRequired,
    allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    resourceColumns: [],
    xOffset: 0,
    yOffset: 0,
    valuesRepresentator: null,
    onContextMenu: null,
    allColumns: [],
  };

  static getDerivedStateFromProps(props, state) {
    const {
      groupColumns, groupLevel, valuesRepresentator, row,
    } = props;
    const currentGroup = groupColumns[groupLevel];
    if (state.row !== props.row) {
      return {
        row: props.row,
        repr: valuesRepresentator
          ? valuesRepresentator(currentGroup.key, row[currentGroup.query_label])
          : row[currentGroup.query_label] && row[currentGroup.query_label].repr,
      };
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      row: null,
      repr: null,
    };
  }

  render() {
    const {
      resourceColumns,
      groupLevel,
      xOffset,
      yOffset,
      valuesRepresentator,
      onContextMenu,
      allColumns,
    } = this.props;
    const {
      repr, row,
    } = this.state;
    const filledColumns = row.columns.map((col) => col.endColumn);
    const blankColumns = allColumns.filter((col) => !filledColumns.includes(col.endColumn));
    return (
      <>
        <DataCell
          className={`g${groupLevel}`}
          row={yOffset + 1}
          col={xOffset + 1}
          level={groupLevel}
          onContextMenu={(e) => onContextMenu(e, row)}
        >
          {repr}
        </DataCell>
        {row.columns.map((col) => resourceColumns.map((rc, rk) => (
          <ResDataCell
            className={`g${groupLevel}`}
            key={`${rc.key}-col${col.endColumn}`}
            row={yOffset + 1}
            col={xOffset + 3 + col.endColumn - resourceColumns.length + rk}
          >
            {valuesRepresentator ? valuesRepresentator(rc.key, col[rc.key]) : col[rc.key]}
          </ResDataCell>
        )))}
        {blankColumns.map((col) => resourceColumns.map((rc, rk) => (
          <ResDataCell
            className={`g${groupLevel}`}
            key={`${rc.key}-col${col.endColumn}`}
            row={yOffset + 1}
            col={xOffset + 3 + col.endColumn - resourceColumns.length + rk}
          />
        )))}
        {resourceColumns.map((rc, k) => (
          <ResDataCell
            className={`g${groupLevel}`}
            key={rc.key}
            row={yOffset + 1}
            col={xOffset + 2 + k + resourceColumns.length * allColumns.length}
          >
            {valuesRepresentator ? valuesRepresentator(rc.key, row[rc.key]) : row[rc.key]}
          </ResDataCell>
        ))}
      </>
    );
  }
}


export default ListRow;
