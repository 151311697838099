import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
// import styled from 'styled-components';
import PropTypes from 'prop-types';
import EditorControls from '../../BaseEditor/editorControls';
import { CommandPanel } from '../../../components/Styled/Misc';
import { Button } from '../../../components/Styled/Buttons';
import { AppContext } from '../../../providers/authProvider';

const SAVE_MODES = {
  forMe: 0,
  forOrg: 1,
  forAll: 2,
};

const SaveReport = ({
  reportData, onHide, onSave, isNew,
}) => {
  const [newReportData, setReportData] = useState({
    name: '',
    description: '',
    mode: SAVE_MODES.forMe,
    title: null,
  });
  const { currentUser } = useContext(AppContext);

  const isReadOnly = !isNew
    && !currentUser.is_superuser
    && reportData.author !== currentUser.profile;

  useEffect(() => {
    let newMode = SAVE_MODES.forAll;
    if (reportData.user !== null) {
      newMode = SAVE_MODES.forMe;
    } else if (reportData.org !== null) {
      newMode = SAVE_MODES.forOrg;
    } else if (!currentUser.is_superuser) newMode = SAVE_MODES.forMe;
    setReportData((oldParam) => ({
      ...oldParam,
      name: reportData.name,
      description: reportData.description,
      mode: newMode,
    }));
  }, [
    currentUser.is_superuser,
    reportData.description,
    reportData.name,
    reportData.org,
    reportData.user,
    reportData.author,
  ]);

  const saveHandler = useCallback(() => {
    const saveData = {
      name: newReportData.name,
      description: newReportData.description,
      schema_id: reportData.schema.id,
      settings: { title: { display: newReportData.title } },
      user:
        newReportData.mode === SAVE_MODES.forMe ? currentUser.profile : null,
      org: newReportData.mode === SAVE_MODES.forOrg ? currentUser.org.id : null,
    };
    // console.log(saveData)
    onSave(saveData, isNew);
  }, [
    newReportData.name,
    newReportData.description,
    newReportData.title,
    newReportData.mode,
    reportData.schema.id,
    currentUser.profile,
    currentUser.org.id,
    onSave,
    isNew,
  ]);

  const saveModes = useMemo(
    () => [
      {
        value: SAVE_MODES.forMe,
        display_name: 'Тількі для мене',
      },
      {
        value: SAVE_MODES.forOrg,
        display_name: 'Для моєї установи',
      },
      ...(currentUser.is_superuser
        ? [
          {
            value: SAVE_MODES.forAll,
            display_name: 'Для всіх',
            disabled: !currentUser.is_superuser,
          },
        ]
        : []),
    ],
    [currentUser.is_superuser],
  );

  const [height, setHeight] = useState(0);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(
        node.parentNode.parentNode.getBoundingClientRect().height
          - node.getBoundingClientRect().top,
      );
    }
  }, []);
  return (
    <div
      style={{
        padding: '20px',
        overflow: 'auto',
        height: `${height || 'autos'}px`,
      }}
      ref={measuredRef}
    >
      <EditorControls.StringInput
        controlId="name"
        label="Назва"
        value={newReportData.name}
        onChange={(e, v) => setReportData((oldV) => ({
          ...oldV,
          name: v,
        }))}
        readOnly={isReadOnly}
      />
      <EditorControls.TextInput
        controlId="description"
        label="Опис"
        value={newReportData.description}
        onChange={(e, v) => setReportData((oldV) => ({
          ...oldV,
          description: v,
        }))}
        readOnly={isReadOnly}
      />
      <h5>Додаткові налаштування</h5>
      <hr />
      <div>
        <EditorControls.Radio
          group="save_mode"
          value={newReportData.mode}
          values={saveModes}
          onChange={(e, v) => setReportData((oldV) => ({
            ...oldV,
            mode: v,
          }))}
          readOnly={isReadOnly}
        />
        <EditorControls.StringInput
          label="Заголовок"
          value={newReportData.title}
          onChange={(e, v) => setReportData((oldV) => ({
            ...oldV,
            title: v,
          }))}
          readOnly={isReadOnly}
        />
      </div>
      <CommandPanel
        style={{
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <Button
          disabled={isReadOnly}
          onClick={() => {
            saveHandler();
            onHide();
          }}
        >
          Зберегти
        </Button>
        <Button
          onClick={() => {
            onHide();
          }}
        >
          Закрити
        </Button>
      </CommandPanel>
    </div>
  );
};

SaveReport.propTypes = {
  onHide: PropTypes.func.isRequired,
  reportData: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    org: PropTypes.number,
    user: PropTypes.number,
    author: PropTypes.number,
    schema: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  onSave: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

SaveReport.defaultProps = {
  reportData: {
    description: '',
    name: '',
    org: null,
    user: null,
    author: null,
  },
  isNew: false,
};

export default SaveReport;
