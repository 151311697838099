import React from 'react';
import styled from 'styled-components';
import Routes from './routes';

const StyledBody = styled.div`
  grid-area: body;  
  // border: 1px solid;
  padding: 0 3%;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  background: whitesmoke;
  
`;

const Body = () => (
  <StyledBody className="fullscreenContainer">
    <Routes />
  </StyledBody>
);
export default Body;
