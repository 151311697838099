import getBaseEditor from './baseEditor';
import { catEditorCP, docEditorCP } from './commandPanels';
import getTablePartController, { getTPCommandPanel } from './tablePart';
import EditorControls from './editorControls';
import TableControls from './tableControls';

export default getBaseEditor;

const CommandPanels = {
  catCommandPanel: catEditorCP,
  docCommandPanel: docEditorCP,
};

export {
  CommandPanels,
  EditorControls,
  TableControls,
  getTablePartController,
  getTPCommandPanel,
};
