import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StyledCellNoData } from './styles';
import { Label } from '../Styled/elements';

const TableRowNoRecords = ({ colCount, readOnly, canAdd }) => (
  <StyledCellNoData
    gridArea={`2 / 1 / 2 / ${colCount + 1}`}
  >
    {readOnly ? (
      <Label>Немає записів. Звеніться до адміністратора системи</Label>
    ) : (
      <>
        <Label>
          Немає записів.
          {canAdd && (
            <>
              Натисніть &nbsp;
              <Link to="create">тут</Link>
              &nbsp; для створення нового.
            </>
          )}
        </Label>
      </>
    )}
  </StyledCellNoData>
);

TableRowNoRecords.propTypes = {
  colCount: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  canAdd: PropTypes.bool,
};

TableRowNoRecords.defaultProps = {
  readOnly: false,
  canAdd: true,
};

export default TableRowNoRecords;
