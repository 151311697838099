import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import api from '../../../api/req';
import { withAuthConsumer, mapStateAuth } from '../../../providers/authProvider';
import { EditorControls } from '../../BaseEditor';
import { ErrorMessage, DimmableLoader } from '../../../components/Styled/Misc';
import { EditorContainer } from '../../../components/Styled/Forms';
import Budgets from '../exchange/editor/budgets';
import Warnings from './warnings';
import Tab from '../../../components/Tab';

const ThreeColGrid = styled.div`
  display: grid;
  grid-template-columns: 16% 40% 40%;
  grid-gap: 2%;
  margin-bottom: 10px;
`;

class Editor extends PureComponent {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    this.state = {
      isProcessing: false,
      isErrored: false,
      errorText: '',
      currentOrg: currentUser.org,
      budgets: null,
      currentDate: new Date().toISOString()
        .slice(0, 10),
    };
  }

  componentDidMount() {
    const { currentOrg } = this.state;
    if (currentOrg) {
      this.getBudget();
    }
  }

  getBudget = async (curOrg) => {
    const { authF } = this.props;
    const { currentOrg } = this.state;
    this.setState({
      isProcessing: true,
      isErrored: false,
    });

    const r = await api.get('/api/catalog/budgets/', authF, {
      org: curOrg ? curOrg.id : currentOrg.id,
    });
    if (r.ok) {
      const d = await r.json();
      this.setState({
        budgets: d.map((b) => ({
          checked: true,
          id: b.id,
          repr: b.repr,
        })),
        isProcessing: false,
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isProcessing: false,
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  render() {
    const { currentUser } = this.props;
    const {
      isProcessing, isErrored, errorText,
      currentOrg, budgets, currentDate,
    } = this.state;

    return (
      <div style={{ overflow: 'unset' }}>
        <DimmableLoader loading={isProcessing} fullscreen={false}>
          {isErrored && (
            <ErrorMessage text={errorText} />
          )}
          <EditorContainer style={{
            overflow: 'inherit',
            height: 'auto',
          }}
          >
            <ThreeColGrid>
              <EditorControls.DateInput
                value={currentDate}
                onChange={(e, v) => this.setState({ currentDate: v })}
                label="Дата"
              />
              {currentUser.is_superuser
                ? (
                  <EditorControls.ItemPicker
                    label="Установа"
                    backend="/api/catalog/orgs/"
                    canErase
                    value={currentOrg}
                    onChange={(e, v) => {
                      this.setState({ currentOrg: v });
                      this.getBudget(v);
                    }}
                  />
                )
                : (
                  <EditorControls.StringInput
                    label="Установа"
                    value={currentUser.org.repr}
                  />
                )}
              {budgets
                && (
                  <Budgets
                    budgets={budgets}
                    onChange={(e, v) => this.setState({ budgets: v })}
                  />
                )}

            </ThreeColGrid>
            <Warnings
              currentOrg={currentOrg}
              budgets={budgets}
              currentDate={currentDate}
            />
          </EditorContainer>

        </DimmableLoader>
      </div>
    );
  }
}

export default withAuthConsumer(mapStateAuth)(Editor);
