import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  StyledContainer, StyledHeader, StyledSubContainer, VerticalCP,
} from '../styles';
import AllowedColumns, { allowedColumnPropType } from '../alowed';
import OrderColumns from './orderColumns';
import { orderPropType } from './orderColumn';
import { NextButton, PreviousButton } from '../../../components/Styled/Buttons';


class GroupsView extends PureComponent {
  static propTypes = {
    allowedColumns: PropTypes.arrayOf(allowedColumnPropType),
    orderColumns: PropTypes.arrayOf(orderPropType),
    onAddColumn: PropTypes.func,
    onDeleteColumn: PropTypes.func,
    onChangeColumn: PropTypes.func,
    onActivateOrder: PropTypes.func.isRequired,
    onActivateAvailable: PropTypes.func.isRequired,
    activeOrder: PropTypes.number,
    activeAvailable: PropTypes.number,
  };

  static defaultProps = {
    allowedColumns: [],
    orderColumns: [],
    onAddColumn: () => null,
    onDeleteColumn: () => null,
    onChangeColumn: () => null,
    activeOrder: null,
    activeAvailable: null,
  };

  render() {
    const {
      allowedColumns, orderColumns,
      onAddColumn, onDeleteColumn, onChangeColumn,
      activeAvailable, activeOrder, onActivateOrder, onActivateAvailable,
    } = this.props;
    return (
      <StyledContainer>
        <StyledHeader>Сортування</StyledHeader>
        <StyledSubContainer>
          <AllowedColumns
            columns={allowedColumns}
            onDoubleClick={onAddColumn}
            activeRow={activeAvailable}
            onActivateRow={onActivateAvailable}
          />
          <VerticalCP>
            <NextButton
              onClick={(e) => onAddColumn(e, activeAvailable)}
              disabled={activeAvailable === null}
            />
            <PreviousButton
              onClick={(e) => onDeleteColumn(e, activeOrder)}
              disabled={!activeOrder === null}
            />
          </VerticalCP>
          <OrderColumns
            columns={orderColumns}
            onDoubleClick={onDeleteColumn}
            activeRow={activeOrder}
            onActivateRow={onActivateOrder}
            onChangeColumn={onChangeColumn}
          />
        </StyledSubContainer>
      </StyledContainer>
    );
  }
}

export default GroupsView;
