import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

const generateSName = (selection, k) => `selection-${k}`;

/**
 * Генерирует поля выбора, пригодные для отображения в редакторе
 * @param selections {[string, string]}
 * @param fields {{ label: string }}
 * @returns {[]}
 */
const useDisplaySelections = (selections, fields) => useMemo(
  () => selections.map((selection, k) => ({
    name: generateSName(selection, k),
    label: selection[0] in fields ? fields[selection[0]].label : selection[0],
    errored: !(selection[0] in fields),
  })),
  [fields, selections],
);

/**
 * Генерирует доступные поля выбора
 * @param fields {Object}
 * @param rawFields {Object}
 * @param rawCFields {Object}
 * @returns {Array}
 */
const useAvailableSelection = (fields, rawFields, rawCFields) => useMemo(
  () => Object.keys(fields)
    .filter(
      (f) => (fields[f].key in rawFields && rawFields[fields[f].key].having)
        || (fields[f].key in rawCFields),
    )
    .map((f) => ({
      ...fields[f],
      name: f,
    })),
  [fields, rawCFields, rawFields],
);

/**
 * HOOK для работы с полями выбора отчетов
 * @param reportData {{options: {group: string[]}, fields: (string, string)[]}}
 * @param schema {{src: Object.<string, Object>}}
 * @returns {{
 *  selections: Array,
 *  displaySelections: Array,
 *  availableSelections: Array,
 *  selectionsHandlers: {
 *      clearAllSelections: function,
 *      removeSelectionHandler: function,
 *      addSelectionHandler: function,
 *      swapSelectionsHandler: function,
 *      changeFunctionHandler: function,
 *  },
 *  }}
 */

export const useSelections = (reportData, schema) => {
  const [selections, setSelections] = useState([]);
  useEffect(() => setSelections(
    reportData.options.fields
      .filter((f) => !!f[1]),
  ),
  [reportData.options.fields]);

  const displaySelections = useDisplaySelections(
    selections, schema.src.meta_fields, { ...schema.src.fields, ...schema.src.calculations },
  );
  const availableSelections = useAvailableSelection(
    schema.src.meta_fields,
    schema.src.fields,
    schema.src.calculations || {},
  );

  const handlers = useMemo(() => {
    const clearAllSelections = () => setSelections([]);

    const addSelectionHandler = (fname, sname) => {
      const index = selections.reduce(
        (R, g, k) => (generateSName(g, k) === sname ? k : R),
        selections.length,
      );
      setSelections([
        ...selections.slice(0, index),
        [fname, 'sum'],
        ...selections.slice(index),
      ]);
    };

    const removeSelectionHandler = (sname) => setSelections(
      selections.filter((s, k) => generateSName(s, k) !== sname),
    );

    const swapSelectionsHandler = (fromName, toName) => {
      const from = selections.reduce((R, s, k) => (generateSName(s, k) === fromName ? k : R), null);
      const to = selections.reduce(
        (R, s, k) => (generateSName(s, k) === toName ? k : R),
        selections.length,
      );

      if (from > to) {
        setSelections([
          ...selections.slice(0, to),
          selections[from],
          ...selections.slice(to, from),
          ...selections.slice(from + 1),
        ]);
      }
      if (from < to) {
        setSelections([
          ...selections.slice(0, from),
          ...selections.slice(from + 1, to),
          selections[from],
          ...selections.slice(to),
        ]);
      }
    };

    return ({
      clearAllSelections,
      removeSelectionHandler,
      addSelectionHandler,
      swapSelectionsHandler,
    });
  },
  [selections]);

  return {
    selections,
    displaySelections,
    availableSelections,
    selectionsHandlers: handlers,
  };
};

export const availableSelectionPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const availableSelectionsPropType = PropTypes.arrayOf(availableSelectionPropType);

export const selectionFuncPropType = PropTypes.oneOf(['sum', 'avg']);

export const selectionPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  func: selectionFuncPropType,
  alowedFuncs: PropTypes.arrayOf(selectionFuncPropType),
});

export const selectionsPropType = PropTypes.arrayOf(selectionPropType);

export const getPreviousSelection = (displaySelections, name) => {
  const index = displaySelections.reduce((R, g, k) => (g.name === name ? k : R), 0);
  if (!index) return null;
  return displaySelections[index - 1].name;
};
