import Styled from 'styled-components';
import { DivDropStyled, DivInnerDropStyled } from '../../../components/Styled/Misc';

export const GridContainer = Styled.div`
  // overflow: auto;
  display: grid;
  background: white;
  grid-template-columns: minmax(20%, 40%);
  grid-auto-columns: minmax(20%, 1fr);
  // border-bottom: 1px solid #a2b4e8;
  overflow-wrap: break-word;
  
  @media print {
    grid-template-columns: minmax(5%, 1fr);
    grid-auto-columns: minmax(1%, 1fr);
  };
    
  & > .g0{
    font-size: 12px;
  }
  & > .g1{
    font-size: 11px;
    font-weight: 600;
  }
  & > .g2{
    font-size: 10px;
    font-weight: 500;
  }
  & > .g3{
    font-size: 9px;
    font-family: 'Times New Roman', Times, serif;
  }
  & > .g4{
    font-size: 9px;
  }
  & > .g5{
    font-size: 9px;
  }
  & > .g6{
    font-size: 9px;
  }
  & > .g7{
    font-size: 9px;
  }
`;

const getGridArea = (props) => ({
  gridArea: `
    ${props.row} / 
    ${props.col} / 
    ${props.rowSpan ? props.row + props.rowSpan : 'auto'} / 
    ${props.colSpan ? props.col + props.colSpan : 'auto'} 
    `,
});

const getPaddingByLevel = (props) => ({
  paddingLeft: `${props.level}em`,
});

export const GridCell = Styled.div.attrs((props) => ({
  style: { ...getGridArea(props), ...getPaddingByLevel(props) },
}))`
  // border: 1px solid #DDE2E9;
  // border: 1px solid rgb(177,216,255);
  border: 1px solid #a2b4e8;
  padding: 1px 5px;
  background: white;
`;

export const ReportHeader = Styled(GridCell)`
  font-size: 20px;
  font-weight: 700;
  background: white;
  padding: 5px;
  text-align: center;
`;

export const TableHeader = Styled(GridCell)`
  font-size: 14px;
  font-weight: 700;
  // background: lightgrey;
  // background: #b5ddf5;
  // background: #bbdaf2;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  padding: 5px;
  text-align: center;
  // position: sticky;
  // top: 0;
  // bottom: 0;
 `;

export const TableFooter = Styled(GridCell)`
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  // background: #bbdaf2;
  // background: lightgrey;
`;

export const ResTableFooter = Styled(TableFooter)`
  text-align: right;
  // position: sticky;
  // bottom: 0;
`;

export const DataCell = Styled(GridCell)`
  position: relative;
`;

export const ResDataCell = Styled(GridCell)`
  text-align: right;
`;

export const ReportContainer = Styled.div`
`;

export const ContextMenuContainer = Styled(DivDropStyled)`
  z-index: 10000;
  display: flex;
  flex-direction: column;
  left: unset;
  right: unset;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
  ${(props) => `top: ${props.top}px; left: ${props.left}px;`} 
`;

export const ContextMenuItem = Styled(DivInnerDropStyled)`
`;

export const ContextMenuSubItem = Styled(ContextMenuItem)`
  margin-left: 10px;
  font-size: 14px;
`;
