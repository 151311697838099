import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { DefaultButton } from './defaultButton';
import {
  InfoIcon,
  PhoneIcon,
  MobolePhoneIcon,
  FaxIcon,
  EmailIcon,
  LogoCiatIcon,
} from './icons';

const StyledInfoButton = styled(DefaultButton)`
  position: relative;
  margin-right: 7px;
  padding: 0;
  border: none;
  background: none
    &:hover{
      filter: grayscale(0.4);
    };
`;
const BoxInfo = styled.div`
    position: absolute;
    z-index: 10;
    right: 5%;
    top: 8%;
    background: white;
    border: 1px solid #DDE2E9;
    border-radius: 0.3rem;
    padding: 10px;
    width: 400px;
    border: 1px solid #37a0a7;
    box-shadow: 0 0 20px 8px rgba(0,0,0,0.16);
    ::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        right: 55%;
        top: -30px;
        border: 15px solid;
        border-color: transparent #ffffff #ffffff transparent;
    }
`;
const InfoText = styled.span`
    color: #5f5b5b;
    font-size: 0.9em;
`;
const ContainerInfo = styled.div`
    display: grid;
    grid-template-columns: 10% 88%;
    grid-row-gap: 10px;
    padding: 10px;
    margin-top: 15px;
`;
const ContainerLogoLink = styled.div`
  display: flex;
  align-items: center;
`;
const LogoImg = styled.img`
  width: 50px;
`;
const FooterTextCompany = styled.span`
  font-size: 0.9em;
  font-weight: 700;
  color: #417eb1;
  max-width: 100%;
  margin-left: 10px;
`;
const ContainerShine = styled.a`
  position: relative;
  overflow: hidden;
`;
const rotate = keyframes`
  0% {
    transform: skewX(0deg) translateX(0px);
  }
  90% {
    transform: skewX(0deg) translateX(0px);
  }
  100% {
    transform: skewX(20deg) translateX(200px);
  }
`;
const Shine = styled.div`
  position: absolute;
  top: 0;
  left: -80px;
  height: 100px;
  width: 30px;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(20deg) translateX(0);
  animation: ${rotate} 10s linear infinite;
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  color: #5f5f5f;
  align-self: flex-end;
  outline: none;
  padding-bottom: 0;
  line-height: 0.5;
  cursor: pointer;
  &:hover{
    color: black;
  };
`;

class InfoButton extends PureComponent {
    static propTypes = {
      content: PropTypes.string,
      disabled: PropTypes.bool,
    };

    static defaultProps = {
      content: 'Інформація',
      disabled: false,
    };

    constructor() {
      super();
      this.state = {
        isVisible: false,
      };
    }

    render() {
      const {
        content,
        disabled,
      } = this.props;
      const {
        isVisible,
      } = this.state;
      return (
        <div>
          <StyledInfoButton
            onClick={() => this.setState({ isVisible: !isVisible })}
            disabled={disabled}
            title={content}
          >
            <img width="23px" src={InfoIcon} alt="info" />
          </StyledInfoButton>
          {isVisible
            && (
              <BoxInfo>
                <div style={{ display: 'grid', justifyContent: 'end' }}>
                  <CloseButton
                    onClick={() => this.setState({ isVisible: !isVisible })}
                  >
                    x
                  </CloseButton>
                </div>
                <ContainerLogoLink>
                  <ContainerShine href="https://ciat.net.ua/uk/" target="blank">
                    <LogoImg src={LogoCiatIcon} alt="ЦІАТ" title="ЦІАТ" />
                    <Shine />
                  </ContainerShine>
                  <FooterTextCompany>
                    Центр інформаційних та аналітичних технологій
                  </FooterTextCompany>
                </ContainerLogoLink>
                <ContainerInfo>
                  <img src={PhoneIcon} width="20px" alt="Телефон" title="Телефон" />
                  <InfoText>
                    (044) 364-07-87,
                    <br />
                    (044) 364-07-84;
                  </InfoText>
                  <img src={FaxIcon} width="20px" alt="Факс" title="Факс" />
                  <InfoText>(044) 424-04-34;</InfoText>
                  <img src={MobolePhoneIcon} width="20px" alt="Мобільний" title="Мобільний" />
                  <InfoText>
                    (067) 825-43-71,
                    <br />
                    (067) 825-43-72,
                    <br />
                    (066) 382-19-98,
                    <br />
                    (050) 858-07-59,
                    <br />
                    (093) 170-84-44;
                  </InfoText>
                  <img src={EmailIcon} width="20px" alt="e-mail" title="e-mail" />
                  <InfoText>
                    buh@ciat.net.ua (бухгалтерія),
                    <br />
                    support@ciat.net.ua (підтримка)
                  </InfoText>
                </ContainerInfo>
              </BoxInfo>
            )}
        </div>
      );
    }
}


export default InfoButton;
