import React, {
  useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';

const HeaderRow = ({ cells, sticked }) => {
  const [top, setTop] = useState(0);

  const rowRef = useCallback((node) => {
    if (node !== null) {
      setTop(node.getBoundingClientRect().top - node.parentNode.getBoundingClientRect().top);
    }
  }, []);
  return (
    <tr ref={rowRef}>
      <th>&nbsp;</th>
      {cells.filter((cell) => !!cell.repr).map((cell) => (
        <th
          className="HeaderOfTable"
          key={`row-${cell.row_num} col-${cell.col_num}`}
          colSpan={cell.colspan}
          rowSpan={cell.rowspan}
          style={sticked ? { top: `${top}px` } : { position: 'unset' }}
        >
          {cell.repr}
        </th>
      ))}
    </tr>

  );
};

HeaderRow.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({
    // key: PropTypes.string.isRequired,
    colspan: PropTypes.number,
    rowSpan: PropTypes.number,
    repr: PropTypes.string.isRequired,
    col_num: PropTypes.number,
    row_num: PropTypes.number,
  })).isRequired,
  sticked: PropTypes.bool,
};

HeaderRow.defaultProps = {
  sticked: true,
};

export default HeaderRow;
