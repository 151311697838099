import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  faLevelDownAlt, faAngleRight, faAngleLeft, faAngleDoubleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvailableFieldList from '../available';
import { availableGroupsPropType, groupsPropType, getPreviousGroup } from '../../hooks';
import {
  SColButtons, SColLeft, SColRight, StyledPanel,
} from '../styles';
import SKDGroupsList from './groupsList';
import { Button } from '../../../../components/Styled/Buttons';
import itemTypes from '../itemTypes';

const GroupEditor = ({ availableGroups, groups, groupsHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);

  const [openedColumns, setOpenedColumns] = useState([]);

  const visibleColumns = useMemo(() => availableGroups.reduce((R, col) => [
    ...R,
    col.parent === null && { ...col, lvl: 0 },
    ...availableGroups.filter((col2) => col2.parent === col.name && openedColumns
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1 })),
  ], []).filter((col) => col), [availableGroups, openedColumns]);

  const onActiveGroupHandler = useCallback(
    (gname) => setActiveGroup(gname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fName) => setActiveAvaField(fName),
    [],
  );

  const onSetOpenedColumns = useCallback((val) => {
    if (openedColumns.includes(val)) {
      return setOpenedColumns((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedColumns((prev) => ([...prev, val]));
  }, [openedColumns]);

  const groupDragEndHanlder = useCallback(
    ({ name, type }, gname) => {
      if (type === itemTypes.availableField) {
        groupsHandlers.addGroupHandler(name, gname);
      } else if (type === itemTypes.group) {
        groupsHandlers.swapGroupRowHandler(name, gname);
      }
    },
    [groupsHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.group) {
        groupsHandlers.removeGroupHandler(name);
        if (activeGroup === name) setActiveGroup(null);
      }
    },
    [activeGroup, groupsHandlers],
  );
  return (
    <StyledPanel>
      <SColLeft>
        <AvailableFieldList
          fields={availableGroups}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={groupsHandlers.addGroupHandler}
          label="Доступні групування"
          onDragEnd={avaDragEndHanlder}
          setOpenedColumns={onSetOpenedColumns}
          visibleColumns={visibleColumns}
          openedColumns={openedColumns}
        />
      </SColLeft>
      <SColButtons>
        <Button
          onClick={() => {
            groupsHandlers.clearAllGroupsHandler();
            setActiveGroup(null);
          }}
          disabled={!groups.length}
          title="Очистити"
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button
          onClick={() => {
            groupsHandlers.removeGroupHandler(activeGroup);
            setActiveGroup(getPreviousGroup(groups, activeGroup));
          }}
          disabled={activeGroup === null}
          title="Видалити"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          onClick={() => groupsHandlers.addGroupHandler(activeAvaField, activeGroup)}
          disabled={!activeAvaField}
          title="Додати"
        >
          <FontAwesomeIcon icon={faAngleRight} />

        </Button>
        <Button
          onClick={() => groupsHandlers.insertSubgroupHandler(activeAvaField, activeGroup)}
          disabled={!activeAvaField}
          title="Згрупувати"
        >
          <FontAwesomeIcon icon={faLevelDownAlt} />
        </Button>
      </SColButtons>
      <SColRight>
        <SKDGroupsList
          groups={groups}
          activeGroup={activeGroup}
          onClick={onActiveGroupHandler}
          onDblClick={(name) => {
            groupsHandlers.removeGroupHandler(name);
            setActiveGroup(getPreviousGroup(groups, name));
          }}
          onDragEnd={groupDragEndHanlder}
          onSubGroupClick={groupsHandlers.removeSubgroupHandler}
          onChangeHierarchy={groupsHandlers.onChangeHierarchyHandler}
        />
      </SColRight>
    </StyledPanel>
  );
};

GroupEditor.propTypes = {
  availableGroups: availableGroupsPropType.isRequired,
  groups: groupsPropType.isRequired,
  groupsHandlers: PropTypes.shape({
    addGroupHandler: PropTypes.func,
    removeGroupHandler: PropTypes.func,
    swapGroupRowHandler: PropTypes.func,
    insertSubgroupHandler: PropTypes.func,
    removeSubgroupHandler: PropTypes.func,
    clearAllGroupsHandler: PropTypes.func,
    onChangeHierarchyHandler: PropTypes.func,
  }).isRequired,
};

export default GroupEditor;
