import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import AllowedColumn, { allowedColumnPropType } from './alowedColumn';
import { StyledList } from '../styles';

class AllowedColumns extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(allowedColumnPropType).isRequired,
    onDoubleClick: PropTypes.func,
    activeRow: PropTypes.number,
    onActivateRow: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onDoubleClick: () => null,
    activeRow: null,
  };

  render() {
    const {
      columns, onDoubleClick, activeRow, onActivateRow,
    } = this.props;
    return (
      <Droppable droppableId="AVAILABLE">
        {(providedDrop) => (
          <StyledList ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            {columns.map((column, index) => (
              <AllowedColumn
                key={column.key}
                column={column}
                active={index === activeRow}
                onClick={onActivateRow}
                onDoubleClick={onDoubleClick}
                index={index}
              />
            ))}
            {providedDrop.placeholder}
          </StyledList>
        )}
      </Droppable>
    );
  }
}

export default AllowedColumns;
