import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import HeaderRow from './headerRow';

const Header = ({
  lines,
}) => {
  const [coord, setCoord] = useState({ height: 0, top: 0 });

  const mRef = useCallback((node) => {
    if (node !== null) {
      setCoord({
        height: node.getBoundingClientRect().height,
        top: node.getBoundingClientRect().top,
      });
    }
  }, []);
  return (
    <thead ref={mRef}>

      {lines.map((cells) => (
      // eslint-disable-next-line react/no-array-index-key
        <HeaderRow
          cells={cells}
          key={cells[0].row_num}
          sticked={coord.height / (window.innerHeight - coord.top) <= 0.2}
        />
      ))}
    </thead>
  );
};

Header.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    // key: PropTypes.string.isRequired,
    col_num: PropTypes.number,
    row_num: PropTypes.number,
    colspan: PropTypes.number,
    rowSpan: PropTypes.number,
    repr: PropTypes.string.isRequired,
  }))).isRequired,
};


export default memo(Header);
