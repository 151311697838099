import React, {
  useCallback, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import itemTypes from '../itemTypes';
import { StyledFieldItem, StyledSubItem } from '../styles';
import TableControls from '../../../BaseEditor/tableControls';
import PluralValues from './pluralValues';

const ContainerFilter = styled.div`
  display: grid;
  grid-template-columns: 25% 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  >div {
    margin-bottom: 0;
      &:last-child {
      >div div {margin-bottom: 0;}
      }
  }
`;

const FiltersItem = ({
  name, label, onClick, active, onDblClick, onHover, padded, errored,
  use, operation, value, allowedOperations, ctype, resource, isPlural,
  changeFilterUse, changeFilterOperation, changeFilterValue,
}) => {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.availableField, itemTypes.filter],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
    hover: (item, monitor) => {
      if (ref.current) {
        const gbcr = ref.current.getBoundingClientRect();
        const cursor = monitor.getClientOffset();
        if ((gbcr.top + gbcr.height / 2) > cursor.y) {
          onHover(name);
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { name, type: itemTypes.filter },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = useMemo(() => ({
    opacity: isDragging ? 0.4 : 1,
  }), [isDragging]);

  drag(drop(ref));
  let variant = '';
  if (errored) variant = 'danger';
  else if (isOver) variant = 'success';

  const onChanfeUseHandler = useCallback((e, v) => changeFilterUse(
    name, v,
  ), [changeFilterUse, name]);

  const onChangeOperationHandler = useCallback((e, v) => changeFilterOperation(
    name, v,
  ), [changeFilterOperation, name]);

  const onChangeValueHandler = useCallback((e, v) => changeFilterValue(
    name, v,
  ), [changeFilterValue, name]);

  return (
    <StyledFieldItem
      ref={ref}
      onClick={() => onClick(name)}
      onDoubleClick={() => onDblClick(name)}
      active={active}
      variant={variant}
      style={style}
    >

      <StyledSubItem padded={padded} />
      <ContainerFilter>
        <TableControls.CheckboxInput
          id={`filter_check_${name}`}
          label={label}
          value={use}
          onChange={onChanfeUseHandler}
        />
        <TableControls.Selector
          id={`filter_operation_${name}`}
          value={operation}
          values={allowedOperations}
          onChange={onChangeOperationHandler}
        />
        {isPlural
          ? (
            <PluralValues
              value={value}
              onChange={onChangeValueHandler}
              ctype={ctype}
              resource={resource}
              short={!active}
            />
          )
          : (
            <TableControls.MultiVaueInput
              id={`filter_value_${name}`}
              onChange={onChangeValueHandler}
              value={value}
              ctype={ctype}
              resource={resource}
            />
          )}
      </ContainerFilter>
    </StyledFieldItem>
  );
};


FiltersItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  use: PropTypes.bool.isRequired,
  operation: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape()),
  allowedOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
  onDblClick: PropTypes.func,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  padded: PropTypes.bool,
  changeFilterUse: PropTypes.func.isRequired,
  changeFilterOperation: PropTypes.func.isRequired,
  changeFilterValue: PropTypes.func.isRequired,
  errored: PropTypes.bool,
  ctype: PropTypes.string.isRequired,
  resource: PropTypes.string,
  isPlural: PropTypes.bool,
};


FiltersItem.defaultProps = {
  label: '???',
  value: null,
  active: false,
  onDblClick: () => null,
  padded: false,
  errored: false,
  resource: null,
  isPlural: false,
};

export default FiltersItem;
