import getLister from './getLister';
import {
  catListerCP, catSelectorCP, docListerCP, docSelectorCP,
} from './commandPanels';

export default getLister;

const CommandPanels = {
  catCommandPanel: catListerCP,
  docCommandPanel: docListerCP,
  catSelectCommandPanel: catSelectorCP,
  docSelectCommandPanel: docSelectorCP,
};

export {
  CommandPanels,
};
