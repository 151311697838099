import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_META_FIELD_STATE = {
  label: '',
  include: [],
  key: '',
  ordered: '',
  display: '',
  resource: null,
  ctype: '',
  format: null,
};

export const useMetaFields = () => {
  const [metaFields, setMetaFields] = useState([]);

  const [activeMetaField, setActiveMetaField] = useState(null);

  const onAddField = useCallback(
    (name) => setMetaFields((oldFields) => {
      if (oldFields.filter((of) => of.name === name).length) return oldFields;
      return [...oldFields, { name, ...DEFAULT_META_FIELD_STATE }];
    }),
    [],
  );

  const onRemoveField = useCallback(
    (fName) => {
      setMetaFields((oldFields) => oldFields.filter((of) => of.name !== fName));
      if (activeMetaField === fName) setActiveMetaField(null);
    },
    [activeMetaField],
  );

  const onRenameField = useCallback(
    (oldName, newName) => {
      if (!newName) return false; // Имя таблицы must have
      setMetaFields((oldFields) => {
        if (oldFields
          .map((t) => t.name)
          .filter((t) => t !== oldName).includes(newName)) return oldFields;
        return oldFields.map((t) => (t.name === oldName ? { ...t, name: newName } : t));
      });
      if (activeMetaField === oldName) setActiveMetaField(newName);
      return true;
    },
    [activeMetaField],
  );

  const onChangeField = useCallback(
    (tableName, value) => {
      setMetaFields((oldFields) => oldFields
        .map((of) => (of.name !== tableName ? of : { ...of, ...value })));
    },
    [],
  );


  const metaFieldHandlers = useMemo(
    () => ({
      onAddField,
      onRenameField,
      onRemoveField,
      onChangeField,
      setActiveMetaField,
    }),
    [onAddField, onChangeField, onRemoveField, onRenameField],
  );

  return {
    metaFields, setMetaFields, activeMetaField, metaFieldHandlers,
  };
};

export const metaFieldPropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  include: PropTypes.arrayOf(PropTypes.string),
  key: PropTypes.string,
  ordered: PropTypes.string,
  display: PropTypes.string,
  resource: PropTypes.string,
  ctype: PropTypes.string,
  format: PropTypes.shape({}),
});
