import styled from 'styled-components';

export const DocGrid = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;                
`;

export const GridItem = styled.div`
  grid-area: ${({ area }) => area};
`;
