import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCP = Styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

export const CommandPanel = ({ children, ...props }) => (
  <StyledCP {...props}>
    {children}
  </StyledCP>
);

CommandPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

CommandPanel.defaultProps = {
  children: null,
};

export default CommandPanel;
