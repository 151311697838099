import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DateInput from '../../BaseEditor/dates';
import { withLabel } from '../../BaseEditor/withLabel';
import { EditorControls } from '../../BaseEditor';
import { DimmableLoader } from '../../../components/Styled/Misc';
import { Button } from '../../Message/styles';

const Container = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 2%;
  
  @media (max-width: 600px) {
  grid-template-rows: auto auto auto;
  grid-template-columns: none;
  }
`;

const StyledButton = styled(Button)`
margin: 0;
margin-top: 20px;
cursor: pointer;
width: 200px;
justify-content: center`;

const P = styled.p`
margin-top: 0px;
text-align: center;
font-weight: bold
`;

const H2 = styled.h2`
color: ${(props) => props.color};
`;

const BackgroundContainer = styled.div`
background-color: #eee;
border-radius: 5px;
padding: 10px
`;

const LabeledDateInput = withLabel(DateInput);

const Notes = ({
  bdate, setBDate, edate, setEDate, kdb, setKdb, notesList, getNotes, err, isLoading,
}) => (
  <DimmableLoader loading={isLoading}>
    <div style={{ minHeight: '300px' }}>
      { err && <H2 color="red">При завантаженні даних з серверу виникла помилка</H2>}
      <BackgroundContainer>
        <P>Опціональні параметри для відбору</P>
        <Container>
          <LabeledDateInput
            label="Дата початку"
            value={bdate}
            onChange={(e, v) => setBDate(v)}
          />
          <LabeledDateInput
            label="Дата закінчення"
            value={edate}
            onChange={(e, v) => setEDate(v)}
          />
          <EditorControls.ItemPicker
            label="КДБ"
            backend="/api/catalog/kdbs/"
            canErase
            value={kdb}
            onChange={(e, v) => setKdb(v)}
            mode="no-repr"
          />
        </Container>
      </BackgroundContainer>

      <StyledButton onClick={getNotes}>Отримати нотатки</StyledButton>

      {!err && notesList && (notesList.length ? (
        <>
          <H2 color="#6cc7c7">Список нотаток</H2>
          <ul>
            {notesList.map((n) => (
              <>
                <li>{n.payment_notes}</li>
                <hr />
              </>
            ))}
          </ul>
        </>
      ) : <H2 color="red">На вказаний Вами період, нотаток немає</H2>)}
    </div>
  </DimmableLoader>
);

Notes.propTypes = {
  bdate: PropTypes.string,
  setBDate: PropTypes.func.isRequired,
  edate: PropTypes.string,
  setEDate: PropTypes.func.isRequired,
  kdb: PropTypes.shape(),
  setKdb: PropTypes.func.isRequired,
  notesList: PropTypes.arrayOf(PropTypes.shape()),
  getNotes: PropTypes.func.isRequired,
  err: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
};

Notes.defaultProps = {
  bdate: null,
  edate: null,
  kdb: null,
  notesList: null,
  err: null,
};

export default Notes;
