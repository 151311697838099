import React, {
  forwardRef, lazy, useMemo, useState, Suspense,
} from 'react';
import PropTypes from 'prop-types';
import { DivStyled, InputStyled } from '../../../components/Styled/Input';
import { DropdownButton, CloseButton } from '../../../components/Styled/Buttons';
import { Modal } from '../../../components/Styled/Misc';

const FormatInput = forwardRef(({
  value, onChange, disabled, readOnly,
  className, allowedFonts,
  noBorder,
}, ref) => {
  const [show, setShow] = useState(false);
  const displayValue = useMemo(
    () => (value ? 'Задано' : 'Не задано'),
    [value],
  );
  const FormatEditor = useMemo(() => lazy(() => import('./editor')), []);
  return (
    <div style={{ position: 'relative' }} className={className} ref={ref}>
      <DivStyled noBorder={noBorder}>
        <InputStyled
          disabled={disabled}
          readOnly
          value={displayValue}
        />
        {!readOnly ? (
          <>
            <DropdownButton onClick={() => setShow(!show)} />
          </>
        ) : null}
      </DivStyled>
      <Modal open={show}>
        <>
          <h3>Формат</h3>
          <Suspense fallback={<div>Загрузка...</div>}>
            <FormatEditor
              onChange={onChange}
              value={value}
              allowedFonts={allowedFonts}
            />
          </Suspense>
          <CloseButton onClick={() => setShow(false)} />
        </>
      </Modal>
    </div>
  );
});

FormatInput.propTypes = {
  value: PropTypes.shape({
    font: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
      bold: PropTypes.bool,
      italic: PropTypes.bool,
      strike: PropTypes.bool,
      underline: PropTypes.bool,
      color: PropTypes.string,
    }),
    background: PropTypes.shape({
      fgColor: PropTypes.string,
    }),
    alignment: PropTypes.shape({
      horizontal: PropTypes.oneOf(['left', 'right', 'center']),
      vertical: PropTypes.oneOf(['top', 'bottom', 'middle']),
    }),
  }),
  onChange: PropTypes.func.isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  noBorder: PropTypes.bool,
};

FormatInput.defaultProps = {
  value: {},
  allowedFonts: [],
  disabled: false,
  readOnly: false,
  noBorder: false,
  className: null,
};

export default FormatInput;
