export const cpActions = {
  expand: 'EXPAND',
  add: 'ADD',
  delete: 'DELETE',
  filter: 'FILTER',
  moveUp: 'MOVE_UP',
  moveDowm: 'MOVE_DOWN',
  sort_Ascending: 'SORT_ASC',
  sort_Descending: 'SORT_DEC',
};

export default cpActions;
