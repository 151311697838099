import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CancelExecuteIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledCancelExecuteButton = styled(StyledButton)`
  height: 2.5em;
`;

export const CancelExecuteButton = ({ content, onClick, disabled }) => (
  <StyledCancelExecuteButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={CancelExecuteIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledCancelExecuteButton>
);

CancelExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CancelExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Відмінити проведення',
  disabled: false,
};

export default CancelExecuteButton;
