import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HeaderRow from './headerRow';
import TableRow from './tableRow';
import TableRowNoRecords from './tableRowNoRecords';
import TableRowPrevPage from './tableRowPrevPage';
import TableRowNextPage from './tableRowNextPage';

const DivTable = styled.div`
  margin: 20px 0;
  display: grid;
  grid-template-columns: ${(props) => props.template};
  font-family: Roboto,sans-serif;
  font-size: 12px;
  color: black;
  border: 1px solid #DDE2E9;
  overflow: auto;
  overflow-wrap: break-word;
  background: white;
  // box-shadow: 1px 8px 32px 0px rgb(177, 177, 177);
  // text-align: center;
  
  @media (max-width: 900px) {
  max-height: 95vh;
  }
`;

class TableComponent extends Component {
  static propTypes = {
    // eslint-disable-next-line
    columns: PropTypes.shape().isRequired,
    // eslint-disable-next-line
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.number),
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    isHierarhical: PropTypes.bool,
    openedRows: PropTypes.arrayOf(PropTypes.number),
    onToggle: PropTypes.func,
    onHeaderClick: PropTypes.func,
    ordering: PropTypes.shape({
      column: PropTypes.string,
      isAscending: PropTypes.bool,
    }),
    mode: PropTypes.oneOf(['cat', 'doc']).isRequired,
    onScrollTop: PropTypes.func,
    onScrollBottom: PropTypes.func,
    // eslint-disable-next-line
    isSearch: PropTypes.bool,
    readOnly: PropTypes.bool,
    doNext: PropTypes.bool,
    doPrev: PropTypes.bool,
    selectMode: PropTypes.bool,
  };

  static defaultProps = {
    selectedItems: [],
    onRowClick: () => null,
    onRowDoubleClick: () => null,
    isHierarhical: false,
    openedRows: [],
    onToggle: () => null,
    onHeaderClick: () => null,
    ordering: {
      column: '',
      isAscending: true,
    },
    onScrollTop: () => null,
    onScrollBottom: () => null,
    isSearch: false,
    readOnly: false,
    doNext: false,
    doPrev: false,
    selectMode: false,
  };

  // eslint-disable-next-line
  static getDerivedStateFromProps(newProps, state) {
    const {
      columns, isHierarhical, openedRows, data, mode, isSearch,
    } = newProps;
    const getRowsData = (parentId = null, level = 0) => isSearch || !isHierarhical
      ? data
      : data.filter((r) => r.parent_id === parentId)
        .reduce((R, row) => [
          ...R,
          {
            ...row,
            level,
          },
          ...(isHierarhical && openedRows.includes(row.id)
            ? getRowsData(row.id, level + 1)
            : []),
        ], []);

    if (state.columns !== columns) {
      const visibleColumns = Object.keys(columns).filter((key) => columns[key].visible)
        .map((key) => ({ ...columns[key], name: key }));
      return ({
        visibleColumns,
        columns,
        openedRows,
        visibleRows: getRowsData(),
        mData: data,
        template: visibleColumns.reduce((R, col) => `${R}[${col.name}] ${col.width || 'minmax(7%, 1fr)'} `, isHierarhical || mode === 'doc' ? '[toggler] auto ' : ''),
      });
    }
    if (state.openedRows !== openedRows || state.mData !== data || state.isSearch !== isSearch) {
      return {
        ...state,
        openedRows,
        mData: data,
        isSearch,
        visibleRows: getRowsData(),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      visibleColumns: [],
      // eslint-disable-next-line
      rows: [],
      template: '',
      // eslint-disable-next-line
      columns: [],
      visibleRows: [],
      // eslint-disable-next-line
      openedRows: [],
      // eslint-disable-next-line
      mData: null,
      // eslint-disable-next-line
      isSearch: null,
      scrollingBottom: null,
    };
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scrollingBottom } = this.state;
    const { doPrev } = this.props;
    if (snapshot !== null) {
      if (scrollingBottom === true) {
        this.ref.current.scrollTop = snapshot.t;
      } else if (scrollingBottom === false) {
        this.ref.current.scrollTop = this.ref.current.scrollHeight - (snapshot.h - snapshot.t);
      } else if (doPrev) {
        this.ref.current.scrollTop = 50;
      }
    }
  }

  getSnapshotBeforeUpdate() {
    return { t: this.ref.current.scrollTop, h: this.ref.current.scrollHeight };
  }

  getRows = () => {
    const { visibleColumns, visibleRows } = this.state;
    const {
      selectedItems,
      onRowClick,
      onRowDoubleClick,
      isHierarhical,
      openedRows,
      onToggle,
      mode,
      readOnly,
      doPrev,
      doNext,
      onScrollTop,
      onScrollBottom,
      selectMode,
    } = this.props;
    if (visibleRows.length) {
      return [
        doPrev && (
          <TableRowPrevPage
            key="prev-page"
            colCount={visibleColumns.length + (isHierarhical ? 1 : 0)}
            onClick={onScrollTop}
          />
        ),
        ...visibleRows.map((row, k) => (
          <TableRow
            cols={visibleColumns}
            row={row}
            rowNumber={k + 2}
            level={row.level}
            isHierarhical={isHierarhical}
            key={row.id}
            active={selectedItems.includes(row.id)}
            onClick={onRowClick}
            onDoubleClick={onRowDoubleClick}
            opened={openedRows.includes(row.id)}
            canOpened={isHierarhical}
            onOpen={onToggle}
            mode={mode}
          />
        )),
        doNext && (
          <TableRowNextPage
            key="next-page"
            colCount={visibleColumns.length + (isHierarhical ? 1 : 0)}
            rowCount={visibleRows.length}
            onClick={onScrollBottom}
          />
        ),
      ];
    }
    return (
      <TableRowNoRecords
        colCount={visibleColumns.length + (isHierarhical ? 1 : 0)}
        readOnly={readOnly}
        canAdd={!selectMode}
      />
    );
  };

  onScrollHanlder = (e) => {
    const { onScrollBottom, onScrollTop } = this.props;
    const { scrollHeight, scrollTop, clientHeight } = this.ref.current;
    if (scrollTop === 0) {
      this.setState({ scrollingBottom: false });
      onScrollTop(e);
    } else if (scrollHeight === (scrollTop + clientHeight)) {
      this.setState({ scrollingBottom: true });
      onScrollBottom(e);
    }
  };

  render() {
    const {
      visibleColumns, template,
    } = this.state;
    const {
      isHierarhical, onHeaderClick, ordering, mode,
    } = this.props;

    return (
      <DivTable
        className="fullscreenContainer"
        template={template}
        ref={this.ref}
        onScroll={this.onScrollHanlder}
      >
        <HeaderRow
          cols={visibleColumns}
          isHierarhical={isHierarhical}
          onClick={onHeaderClick}
          ordering={ordering}
          mode={mode}
        />
        {this.getRows()}
      </DivTable>
    );
  }
}

export default TableComponent;
