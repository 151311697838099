import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  faLevelDownAlt, faAngleRight, faAngleLeft, faAngleDoubleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvailableFieldList from '../available';
import { availableColumnsPropType, columnsPropType, getPreviousColumn } from '../../hooks';
import {
  SColButtons, SColLeft, SColRight, StyledPanel,
} from '../styles';
import SKDColumnsList from './columnsList';
import { Button } from '../../../../components/Styled/Buttons';
import itemTypes from '../itemTypes';

const ColumnEditor = ({ availableColumns, columns, columnsHandlers }) => {
  const [activeAvaField, setActiveAvaField] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);

  const [openedColumns, setOpenedColumns] = useState([]);

  const visibleColumns = useMemo(() => availableColumns.reduce((R, col) => [
    ...R,
    col.parent === null && { ...col, lvl: 0 },
    ...availableColumns.filter((col2) => col2.parent === col.name && openedColumns
      .includes(col.name)).map((coll) => ({ ...coll, lvl: 1 })),
  ], []).filter((col) => col), [availableColumns, openedColumns]);

  const onActiveColumnHandler = useCallback(
    (cname) => setActiveColumn(cname),
    [],
  );

  const onActiveFieldHandler = useCallback(
    (fName) => setActiveAvaField(fName),
    [],
  );

  const onSetOpenedColumns = useCallback((val) => {
    if (openedColumns.includes(val)) {
      return setOpenedColumns((prev) => prev.filter((el) => el !== val));
    }
    return setOpenedColumns((prev) => ([...prev, val]));
  }, [openedColumns]);

  const columnDragEndHanlder = useCallback(
    ({ name, type }, gname) => {
      if (type === itemTypes.availableField) {
        columnsHandlers.addColumnHandler(name, gname);
      } else if (type === itemTypes.column) {
        columnsHandlers.swapColumnRowHandler(name, gname);
      }
    },
    [columnsHandlers],
  );
  const avaDragEndHanlder = useCallback(
    ({ name, type }) => {
      if (type === itemTypes.column) {
        columnsHandlers.removeColumnHandler(name);
        if (activeColumn === name) setActiveColumn(null);
      }
    },
    [activeColumn, columnsHandlers],
  );
  return (
    <StyledPanel>
      <SColLeft>
        <AvailableFieldList
          fields={availableColumns}
          activeField={activeAvaField}
          onClick={onActiveFieldHandler}
          onDblClick={columnsHandlers.addColumnHandler}
          label="Доступні групування"
          onDragEnd={avaDragEndHanlder}
          setOpenedColumns={onSetOpenedColumns}
          visibleColumns={visibleColumns}
          openedColumns={openedColumns}
        />
      </SColLeft>
      <SColButtons>
        <Button
          onClick={() => {
            columnsHandlers.clearAllColumnsHandler();
            setActiveColumn(null);
          }}
          disabled={!columns.length}
          title="Очистити"
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button
          onClick={() => {
            columnsHandlers.removeColumnHandler(activeColumn);
            setActiveColumn(getPreviousColumn(columns, activeColumn));
          }}
          disabled={activeColumn === null}
          title="Видалити"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button
          onClick={() => columnsHandlers.addColumnHandler(activeAvaField, activeColumn)}
          disabled={!activeAvaField}
          title="Додати"
        >
          <FontAwesomeIcon icon={faAngleRight} />

        </Button>
        <Button
          onClick={() => columnsHandlers.insertSubcolumnHandler(activeAvaField, activeColumn)}
          disabled={!activeAvaField}
          title="Згрупувати"
        >
          <FontAwesomeIcon icon={faLevelDownAlt} />
        </Button>
      </SColButtons>
      <SColRight>
        <SKDColumnsList
          columns={columns}
          activeColumn={activeColumn}
          onClick={onActiveColumnHandler}
          onDblClick={(name) => {
            columnsHandlers.removeColumnHandler(name);
            setActiveColumn(getPreviousColumn(columns, name));
          }}
          onDragEnd={columnDragEndHanlder}
          onSubColumnClick={columnsHandlers.removeSubcolumnHandler}
          onChangeHierarchy={columnsHandlers.onChangeHierarchyHandler}
        />
      </SColRight>
    </StyledPanel>
  );
};

ColumnEditor.propTypes = {
  availableColumns: availableColumnsPropType.isRequired,
  columns: columnsPropType.isRequired,
  columnsHandlers: PropTypes.shape({
    addColumnHandler: PropTypes.func,
    removeColumnHandler: PropTypes.func,
    swapColumnRowHandler: PropTypes.func,
    insertSubcolumnHandler: PropTypes.func,
    removeSubcolumnHandler: PropTypes.func,
    clearAllColumnsHandler: PropTypes.func,
    onChangeHierarchyHandler: PropTypes.func,
  }).isRequired,
};

export default ColumnEditor;
