import { compose } from 'recompose';
import StringInput from './stringInput';
import { ItemField, ItemPicker } from '../itemField';
import { withErrors } from './withErrors';
import { noBorder } from './noBorder';
import getNumerInput from './numberInput';
import Selector from './selector';
import TextInput from './textInput';
import DateInput from './dates';
import CheckboxInput from './Checkbox';
import Multiselector from './multiselector';
import FormatInput from './format';
import Radio from './radio';
import MultiVaueInput from './multiValue';

const TableEnhancer = compose(withErrors, noBorder);

const TableControls = {
  StringInput: TableEnhancer(StringInput),
  ItemPicker: TableEnhancer(ItemPicker),
  ItemField: TableEnhancer(ItemField),
  getNumerInput: compose(withErrors, noBorder, getNumerInput),
  Selector: TableEnhancer(Selector),
  TextInput: TableEnhancer(TextInput),
  DateInput: TableEnhancer(DateInput),
  CheckboxInput: TableEnhancer(CheckboxInput),
  Radio: TableEnhancer(Radio),
  Multiselector: TableEnhancer(Multiselector),
  MultiVaueInput: TableEnhancer(MultiVaueInput),
  FormatInput: TableEnhancer(FormatInput),
};

export default TableControls;
