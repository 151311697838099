import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

const StyledContainer = Styled.div`
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .3rem;
  background: white;
  position: relative;
`;

const StyledChildContainer = Styled.div`
  padding: 10px;
`;

const ProgresDiv = Styled.div`
  height: 5px;
`;

const StyledProgresLine = Styled(ProgresDiv)`
  width: 100%;
  background: linear-gradient(to right, rgba(255,0,0,1) 0%, rgba(255,183,0,1) 26%, rgba(196,255,0,1) 72%, rgba(15,153,15,1) 100%);
`;

const StyledProgresLineT = Styled(StyledProgresLine)`
  border-radius: 3rem 3rem 0 0;
`;
const StyledProgresLineB = Styled(StyledProgresLine)`
  border-radius: 0 0 3rem 3rem;
`;

const Supressor = Styled(ProgresDiv)`
  background: white;
  float: right;
  position: absolute;
  right: 0px;
  width: ${({ maxValue, minValue, value }) => 100 - (((value - minValue) * 100) / (maxValue - minValue))}%;
  border-radius: 0 3rem 0 0;
`;

const SupressorT = Styled(Supressor)`
  top: 0;
`;

const SupressorD = Styled(Supressor)`
  bottom: 0;
  border-radius: 0 0 3rem 0;
`;

const ProgressBar = ({
  maxValue, minValue, value, children,
}) => (
  <StyledContainer>
    <StyledProgresLineT />
    <SupressorT maxValue={maxValue} minValue={minValue} value={value} />
    <StyledChildContainer>
      {children}
    </StyledChildContainer>
    <StyledProgresLineB />
    <SupressorD maxValue={maxValue} minValue={minValue} value={value} />
  </StyledContainer>
);

ProgressBar.propTypes = {
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  value: PropTypes.number,
  children: PropTypes.element,
};


ProgressBar.defaultProps = {
  maxValue: 100,
  minValue: 0,
  value: 10,
  children: null,
};

export default ProgressBar;
