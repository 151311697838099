import React from 'react';
import PropTypes from 'prop-types';
import { CommandPanel, Question, QuestionActions } from '../../../components/Styled/Misc';
import {
  SaveButton, CloseButton, ExecuteButton, CancelExecuteButton,
} from '../../../components/Styled/Buttons';
import { actions } from './actions';

export const docEditorCP = ({ onActionClick, isChanged, isExecuted }) => (
  <CommandPanel>
    <SaveButton onClick={(e) => onActionClick(e, actions.save)} disabled={!isChanged} />
    <ExecuteButton onClick={(e) => onActionClick(e, actions.execute)} disabled={isExecuted} />
    <CancelExecuteButton onClick={(e) => onActionClick(e, actions.cancel)} disabled={!isExecuted} />
    {isChanged ? (
      <Question
        questionText="Вийти без запису?"
        actionHandler={(e, v) => {
          if (v === QuestionActions.YesNoAction.yes.value) {
            onActionClick(e, actions.close);
          }
          e.stopPropagation();
        }}
        actions={Object.values(QuestionActions.YesNoAction)}
      >
        <CloseButton onClick={(e) => onActionClick(e, actions.close)} />
      </Question>
    ) : (
      <CloseButton onClick={(e) => onActionClick(e, actions.close)} />
    )}
  </CommandPanel>
);

docEditorCP.propTypes = {
  onActionClick: PropTypes.func.isRequired,
  isChanged: PropTypes.bool,
  isExecuted: PropTypes.bool,
};

docEditorCP.defaultProps = {
  isChanged: false,
  isExecuted: false,
};

export default docEditorCP;
