import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import EditorControls from '../../../BaseEditor/editorControls';
import { fieldPropType } from '../hooks';

const FieldProps = ({
  value, onChange, readOnly, alowedTables,
  allowedCtypes, tableFields, allowedFilters, allowedAggregates,
}) => {
  const allowedFields = useMemo(
    () => (tableFields[value.table] || []).map((v) => ({ value: v, display_name: v })),
    [tableFields, value.table],
  );
  const displayAllowedFilters = useMemo(
    () => (allowedFilters[value.ctype] || []).map((v) => ({ value: v, display_name: v })),
    [allowedFilters, value.ctype],
  );
  return (
    <div className="containerField p-3 border rounded">
      <EditorControls.StringInput
        controlId="field_expression_input"
        label="Expression"
        required
        description="Выражение, для получения поля. Как правило совпадает с полем таблицы"
        value={value.expression}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ expression: v })}
      />
      <EditorControls.Selector
        controlId="field_table_input"
        label="Table"
        required
        description="Таблица, в которую входит поле"
        value={value.table}
        values={alowedTables}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ table: v })}
      />
      <EditorControls.StringInput
        controlId="field_alias_input"
        label="Alias"
        required
        description="Псевдоним (as {alias})"
        value={value.alias}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ alias: v })}
      />
      <EditorControls.Selector
        controlId="field_ctype_input"
        label="Ctype"
        required
        description="Тип поля"
        value={value.ctype}
        readOnly={readOnly}
        values={allowedCtypes}
        onChange={(e, v) => onChange({
          ctype: v,
          hierarchical_key: null,
          filtered: allowedFilters[v] || [],
        })}
      />
      <EditorControls.CheckboxInput
        controlId="field_mandatory_input"
        label="Mandatory"
        description="Признак обязательности поля. В случае если поле обязательно - то поле будет включено в запрос вне зависимости от настроек пользователя."
        value={value.mandatory}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ mandatory: v })}
      />
      <EditorControls.CheckboxInput
        controlId="field_key_input"
        label="Key"
        description="Признак того, что данной поле является ключевым. Ключевое поле может быть использовано в where и group_by"
        value={value.key}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ key: v, calc: null })}
      />
      <EditorControls.CheckboxInput
        controlId="field_ordered_input"
        label="Orderd"
        description="Признак того, что по данному полю можно сортировать."
        value={value.ordered}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ ordered: v })}
      />
      <EditorControls.CheckboxInput
        controlId="field_having_input"
        label="Having"
        description="Признак того, что по данное поле может быть включено в предложение having."
        value={value.having}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ having: v })}
      />
      <EditorControls.Selector
        controlId="field_hierarhical_input"
        label="Hierarhical key"
        description="Имя поля в этой-же таблице, для построения иерархии. Например 'parent_id'. Может быть указано только для ctype='key'"
        value={value.hierarchical_key}
        values={allowedFields}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ hierarchical_key: v })}
      />
      <EditorControls.Multiselector
        controlId="field_filtered_input"
        label="Filtered"
        required
        description="Допустимые виды сравнения."
        value={value.filtered}
        values={displayAllowedFilters}
        readOnly={readOnly}
        onChange={(e, v) => onChange({ filtered: v })}
      />
      <EditorControls.Selector
        controlId="field_calc_input"
        label="Calc"
        description="Перечень возможных агрегатных функций для поля."
        value={value.calc}
        values={allowedAggregates}
        readOnly={readOnly || value.key}
        onChange={(e, v) => onChange({ calc: v })}
      />
    </div>
  );
};

FieldProps.propTypes = {
  value: fieldPropType,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  alowedTables: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    display_name: PropTypes.string,
  })).isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableFields: PropTypes.shape({}).isRequired,
  allowedFilters: PropTypes.shape({}),
  allowedAggregates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

FieldProps.defaultProps = {
  value: '',
  readOnly: false,
  allowedFilters: {},
};

export default FieldProps;
