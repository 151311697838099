import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { YesIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledOKButton = Styled(StyledButton)`
  height: 2.5em;
  &:hover{
    ${({ disabled }) => !disabled && 'transform: none;'}
    background: #d0f7d4fc;
  }
`;

export const OKButton = ({
  content, onClick, disabled, className,
}) => (
  <StyledOKButton onClick={onClick} disabled={disabled} title={content} className={className}>
    <StyledImg src={YesIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledOKButton>
);

OKButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

OKButton.defaultProps = {
  onClick: () => null,
  content: 'OK',
  disabled: false,
  className: null,
};

export default OKButton;
