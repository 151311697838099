import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SaveButton, OKButton, CloseButton } from '../../../components/Styled/Buttons';
import { Modal, CommandPanel } from '../../../components/Styled/Misc';
import { HeaderText } from '../../../components/Styled/Forms';
import { EditorControls } from '../../BaseEditor';

class SaveVariant extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    variantName: PropTypes.string,
    variantDescription: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    variantName: '',
    variantDescription: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      title: props.variantName,
      description: props.variantDescription,
    };
  }

  save = (e) => {
    const { title, description } = this.state;
    const { onSave } = this.props;
    onSave(e, {
      title, description,
    });
  };

  render() {
    const { disabled } = this.props;
    const { modalOpened, title, description } = this.state;
    return (
      <>
        <SaveButton
          content="Зберегти звіт"
          disabled={disabled}
          onClick={() => this.setState({ modalOpened: !modalOpened })}
        />
        <Modal open={modalOpened}>
          <>
            <HeaderText>Збереження налаштувань звіту</HeaderText>
            <EditorControls.StringInput
              label="Назва звіту"
              value={title}
              onChange={(e, v) => this.setState({ title: v })}
            />
            <EditorControls.TextInput
              label="Опис"
              value={description}
              onChange={(e, v) => this.setState({ description: v })}
            />
            <CommandPanel>
              <OKButton
                disabled={!title}
                content="Зберегти"
                onClick={this.save}
              />
              <CloseButton
                content="Закрити"
                onClick={() => {
                  this.setState({ modalOpened: false });
                }}
              />
            </CommandPanel>
          </>
        </Modal>

      </>
    );
  }
}
export default SaveVariant;
