import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableHeader } from './styles';

const ListHeader = ({
  groupColumns, resourceColumns, xOffset, yOffset, columnGroupColumns, columnColumns,
}) => (
  <>
    {groupColumns.map((gColumn, k) => (
      <TableHeader
        key={gColumn.key}
        row={k + 1 + yOffset}
        col={1 + xOffset}
        rowSpan={groupColumns.length === k + 1
          ? Math.max(1, columnGroupColumns.length + 2 - groupColumns.length)
          : 1}
      >
        {gColumn.label}
      </TableHeader>
    ))}
    {columnColumns.map((colcol) => (
      <Fragment key={colcol.endColumn}>
        <TableHeader
          key={`col${colcol.endColumn}`}
          row={colcol.level + 1 + yOffset}
          col={colcol.startColumn + 2 + xOffset}
          colSpan={colcol.endColumn - colcol.startColumn + 1}
        >
          {colcol[columnGroupColumns[colcol.level].query_label]
          && colcol[columnGroupColumns[colcol.level].query_label].repr}
        </TableHeader>
        {colcol.level + 1 !== columnGroupColumns.length && (
          <TableHeader
            key={`col${colcol.endColumn}-total`}
            row={colcol.level + 2 + yOffset}
            col={colcol.endColumn - resourceColumns.length + 3 + xOffset}
            colSpan={resourceColumns.length}
            rowSpan={columnColumns.length - colcol.level}
          >
            РАЗОМ
          </TableHeader>
        )}

        {resourceColumns.map((rColumn, kk) => (
          <TableHeader
            key={`col${colcol.endColumn}-${rColumn.key}`}
            row={1 + yOffset + columnGroupColumns.length}
            col={colcol.endColumn - resourceColumns.length + 2 + 1 + kk + xOffset}
          >
            {rColumn.label}
          </TableHeader>
        ))}
      </Fragment>
    ))}
    {resourceColumns.map((rColumn, kk) => (
      <TableHeader
        key={`col${rColumn.key}-TOTAL`}
        row={1 + yOffset}
        rowSpan={Math.max(columnGroupColumns.length + 1, groupColumns.length, 1)}
        col={columnColumns.length * resourceColumns.length + 2 + kk + xOffset}
      >
        {rColumn.label}
      </TableHeader>
    ))}
  </>
);

ListHeader.propTypes = {
  groupColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  columnGroupColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  resourceColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  columnColumns: PropTypes.arrayOf(PropTypes.shape()),
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
};

ListHeader.defaultProps = {
  groupColumns: [],
  columnGroupColumns: [],
  resourceColumns: [],
  columnColumns: [],
  xOffset: 0,
  yOffset: 0,
};

export default ListHeader;
