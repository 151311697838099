import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Styled from 'styled-components';
import { compose } from 'recompose';
import api from '../../api/req';
import { ErrorMessage, DimmableLoader, SearchInput } from '../../components/Styled/Misc';
import { HeaderText } from '../../components/Styled/Forms';
import { actions, catListerCP } from './commandPanels';
import { withAuthConsumer, mapStateAuth } from '../../providers/authProvider';

const CPContainer = Styled.div`
  display: flex;
  & > :first-child {
    flex: 1 1 auto; 
  }
  & > :last-child {
    flex: 0 0 auto; 
  }
  
  @media (max-width: 720px) {
  flex-direction: column-reverse;
  }
`;

const StyledContainer = Styled.div`
`;

const getLister = (WrappedComponent, backend, CommandPanel = catListerCP, mode = 'cat') => {
  class Lister extends PureComponent {
    static propTypes = {
      match: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
      history: PropTypes.shape({
        push: PropTypes.func,
      }).isRequired,
      authF: PropTypes.func.isRequired,
      onSetSettings: PropTypes.func.isRequired,
      settings: PropTypes.shape({
        period: PropTypes.shape({
          bDate: PropTypes.string,
          eDate: PropTypes.string,
        }),
        lastLoadedPage: PropTypes.number,
        currentId: PropTypes.number,
        searchValue: PropTypes.string,
      }),
      onChoice: PropTypes.func, // Нажатие на конпку выбрать или двойной щелчок
      onSelect: PropTypes.func, // Событие при выделении строки
      onClose: PropTypes.func, // Событие при отказе от вібора
      canSelect: PropTypes.bool, // Можно ли выбрать
      selectMode: PropTypes.bool, // Режим выбора
      filter: PropTypes.shape({}), // фильтр ключ-значение
      currentId: PropTypes.number, // текущее выбраное значение
    };

    static defaultProps = {
      onChoice: () => null,
      onSelect: () => null,
      onClose: () => null,
      canSelect: true,
      selectMode: false,
      filter: null,
      currentId: null,
      settings: {},
    };

    constructor(props) {
      super(props);
      const cd = new Date();
      const period = (props.settings && props.settings.period) || {
        bDate: `${cd.getFullYear()}-01-01`,
        eDate: `${cd.getFullYear()}-12-31`,
      };

      this.state = {
        isLoading: true,
        isErrored: false,
        errorText: '',
        errorHeader: '',
        data: { count: 0, results: [] },
        options: { name: '', actions: {} },
        selectedItems: props.settings && props.settings.currentId ? [props.settings.currentId] : [],
        currentItem: props.settings && props.settings.currentId,
        columns: {},
        isHierarhical: true,
        openedRows: [],
        ordering: { column: '', isAscending: true },
        pages: [1],
        doPrev: false,
        doNext: false,
        searchValue: null,
        period,
      };
    }

    componentDidMount() {
      this.setState({ isLoading: true });
      const { authF } = this.props;
      api.options$(backend, authF).then((r) => this.queryParser(r, (options) => {
        if (options.actions && options.actions.GET) {
          this.reload(options);
        } else {
          this.setState({ isErrored: true, errorText: 'No "GET" action in response from server', errorHeader: 'Вам все вдалося, але виник несподіваний результат' });
        }
      }));
    }

    componentWillUnmount() {
      const { onSetSettings } = this.props;
      const {
        period, currentItem, data, searchValue,
      } = this.state;
      const cItem = currentItem && data.results.filter((r) => r.id === currentItem)[0];
      const settings = {
        period,
        searchValue,
        ...(cItem ? { lastLoadedPage: cItem.page, currentId: cItem.id } : {}),
      };
      onSetSettings(backend, settings);
    }

    getOrderQuery = () => {
      const { ordering, options } = this.state;
      if (!ordering.column) return null;
      const column = options.ordering_fields[ordering.column] || ordering.column;
      return { ordering: `${ordering.isAscending ? '' : '-'}${column}` };
    };

    getFilterQuery = (preFilter = {}) => {
      const { filter } = this.props;
      const { period } = this.state;
      return {
        ...preFilter,
        ...filter,
        ...(mode === 'doc' && period.bDate ? { date__gte: period.bDate } : {}),
        ...(mode === 'doc' && period.eDate ? { date__lte: period.eDate } : {}),
      };
    };

    reload = (options) => {
      const { authF, currentId, settings } = this.props;
      const { searchValue } = this.state;
      const fields = options.actions ? options.actions.GET || {} : {};
      const columns = Object.keys(fields).reduce((R, f) => (
        { ...R, [f]: { ...fields[f], visible: !(options.hidden_fields || []).includes(f) } }),
      {});
      const isHierarhical = !!columns.parent_id;
      this.setState({ isLoading: true, columns, isHierarhical });

      const searchText = searchValue === null ? settings.searchValue : searchValue;

      const page = settings && settings.lastLoadedPage ? settings.lastLoadedPage : 1;
      const params = {
        ...this.getOrderQuery(),
        ...this.getFilterQuery(isHierarhical && !searchText ? { parent_id__isnull: true } : {}),
        ...(searchText && { search: searchText }),
        ...(isHierarhical ? {} : page),
      };
      api.get$(backend, authF, params)
        .then((rr) => this.queryParser(rr, (data) => {
          const newData = isHierarhical
            ? { ...data, results: data }
            : { ...data, results: data.results.map((d) => ({ ...d, page })) };
          this.setState({
            options,
            data: newData,
            isLoading: false,
            isHierarhical,
            currentItem: currentId || settings.currentId,
            selectedItems: currentId || settings.currentId ? [currentId || settings.currentId] : [],
            doNext: !!data.next,
            doPrev: !!data.previous,
            pages: [page],
            searchValue: searchText,
          });
        }));
    };

    loadPage = (pageNo) => {
      const { authF } = this.props;
      const {
        data, pages, searchValue,
      } = this.state;
      this.setState({ isLoading: true });
      const isPrevPage = pageNo < pages[0];
      const params = {
        ...this.getOrderQuery(),
        ...this.getFilterQuery(),
        ...(searchValue && { search: searchValue }),
        page: pageNo,
      };
      api.get$(backend, authF, params)
        .then((rr) => this.queryParser(rr, (loadedData) => {
          const newState = isPrevPage ? {
            isLoading: false,
            data: {
              ...data,
              previous: loadedData.previous,
              results: [
                ...loadedData.results.map((d) => ({ ...d, page: pageNo })),
                ...data.results],
            },
            pages: [pageNo, ...pages],
            doPrev: !!loadedData.previous,
          } : {
            isLoading: false,
            data: {
              ...data,
              next: loadedData.next,
              results: [
                ...data.results,
                ...loadedData.results.map((d) => ({ ...d, page: pageNo }))],
            },
            pages: [...pages, pageNo],
            doNext: !!loadedData.next,
          };
          this.setState(newState);
        }));
    };

    loadChildren = (parentId) => {
      const { authF } = this.props;
      const {
        data, searchValue, isHierarhical,
      } = this.state;
      if (searchValue || !isHierarhical) return false;
      this.setState({ isLoading: true });
      const params = {
        ...this.getOrderQuery(),
        ...this.getFilterQuery({ parent_id: parentId }),
      };
      api.get$(backend, authF, params)
        .then((rr) => this.queryParser(rr, (loadedData) => {
          const newState = {
            isLoading: false,
            data: {
              ...data,
              results: [
                ...loadedData,
                ...data.results.filter((d) => d.parent_id !== parentId)],
            },
            doPrev: false,
          };
          this.setState(newState);
        }));
      return true;
    };

    queryParser = (response, f) => {
      if (response.ok) {
        response.json().then((data) => f(data));
      } else {
        this.setState({ isErrored: true, errorText: response.status === 403 ? 'У Вас немає прав доступу до цього розділу.' : `${response.status} ${response.statusText}`, errorHeader: response.status === 403 ? 'Помилка прав доступу' : 'Вам все вдалося, але виник несподіваний результат' });
      }
    };

    newAction = () => {
      const { history, match } = this.props;
      history.push(`${match.url}create`);
    };

    editAction = (e, id) => {
      const { history, match } = this.props;
      history.push(`${match.url}${id}`);
    };

    deleteAction = () => {
      const { authF } = this.props;
      const { options, selectedItems } = this.state;
      const deleteItem = async (itemId) => {
        const r = await api.delete$(`${backend}${itemId}/`, authF);
        if (r.ok) {
          return { ok: true, err: '' };
        }
        return { ok: false, err: `${r.status} ${r.statusText}` };
      };

      Promise.all(selectedItems.map((id) => deleteItem(id)))
        .then((r) => {
          // eslint-disable-next-line
          const newErrors = r.reduce((R, res) => res.ok ? R : `${R}${res.err} `, '');
          if (newErrors.length) {
            this.setState({
              isErrored: true,
              errorText: newErrors,
              errorHeader: 'Вам все вдалося, але виник несподіваний результат',
            });
          }
          this.reload(options);
        });
    };

    choiceAction = (e, id) => {
      e.stopPropagation();
      const { onChoice, selectMode } = this.props;
      const { data } = this.state;
      if (!selectMode) {
        console.error('Trying select from not select mode');
        return false;
      }
      const res = data.results.filter((r) => r.id === id)[0];
      return onChoice(e, res);
    };

    selectAllAction = () => {
      const { data } = this.state;
      this.setState({
        selectedItems: data.results.map((d) => d.id),
      });
    };

    executeSelectedAction = (e, execute = true) => {
      const { authF } = this.props;
      const { data, selectedItems, options } = this.state;

      const executeDocuments = async (ids) => {
        if (!ids.length) return [];
        const id = ids[0];
        const r = await api.patch$(`${backend}${id}/`, authF, { executed: execute });
        return [
          { id, isErored: !r.ok, errText: `${r.status} ${r.statusText}` },
          ...await executeDocuments(ids.slice(1)),
        ];
      };

      this.setState({ isLoading: true });
      executeDocuments(selectedItems).then((results) => {
        const errs = results.filter((result) => result.isErored).reduce((R, er) => {
          const currentDoc = data.results.filter((d) => d.id === er.id)[0];
          return `${R}${currentDoc.repr}: ${er.errText} \n`;
        }, '');
        this.setState({
          isErrored: errs !== '',
          errorText: errs,
          errorHeader: 'Вам все вдалося, але виник несподіваний результат',
        });
        this.reload(options);
      });
    };

    executeSetPeriodAction = (e, period) => {
      const { options } = this.state;
      this.setState({
        period,
      }, () => this.reload(options));
    };

    closeAction = (e) => {
      const { onClose, selectMode } = this.props;
      if (!selectMode) {
        console.error('Trying close from not select mode');
        return false;
      }
      return onClose(e);
    };

    exportXLS = async () => {
      const { authF } = this.props;
      const r = await api.get(`${backend}exportXLS/`, authF);
      if (r.ok) {
        const data = await r.json();
        const fStr = ';base64,';
        const fileData = data.file.slice(data.file.indexOf(fStr) + fStr.length);
        const contentType = data.file.slice(0, data.file.indexOf(fStr));
        const byteCharacters = atob(fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        const URL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = URL;
        tempLink.setAttribute('download', `${data.filename}.xlsx`);
        const ch = document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(ch);
      }
    };

    processAction = (e, action, extra) => {
      const { currentItem } = this.state;
      switch (action) {
      case actions.new:
        this.newAction(e);
        break;
      case actions.edit:
        this.editAction(e, currentItem);
        break;
      case actions.delete:
        this.deleteAction(e, currentItem);
        break;
      case actions.select:
        this.choiceAction(e, currentItem);
        break;
      case actions.selectAll:
        this.selectAllAction(e);
        break;
      case actions.close:
        this.closeAction(e);
        break;
      case actions.executeSelected:
        this.executeSelectedAction(e, true);
        break;
      case actions.cancelExecuteSelected:
        this.executeSelectedAction(e, false);
        break;
      case actions.setPeriod:
        this.executeSetPeriodAction(e, extra);
        break;
      case actions.exportXLS:
        this.exportXLS(e);
        break;
      default:
        console.log('Unknow action', action);
      }
    };

    activateItem = (e, rowId) => {
      const { selectedItems, data } = this.state;
      const { onSelect } = this.props;
      const currentItem = data.results.filter((r) => r.id === rowId)[0];
      if (e.ctrlKey) {
        if (selectedItems.includes(rowId)) {
          this.setState({
            selectedItems: selectedItems.filter((id) => id !== rowId),
            currentItem: rowId,
          });
        } else {
          this.setState({
            selectedItems: [...selectedItems, rowId],
            currentItem: rowId,
          });
        }
      } else {
        this.setState({
          selectedItems: [rowId],
          currentItem: rowId,
        });
      }
      onSelect(e, currentItem);
    };

    toggleRow = (e, rowId) => {
      const { openedRows } = this.state;
      if (openedRows.includes(rowId)) {
        this.setState({ openedRows: openedRows.filter((r) => r !== rowId) });
      } else {
        this.setState({ openedRows: [...openedRows, rowId] });
        this.loadChildren(rowId);
      }
    };

    onHeaderClick = (e, column) => {
      const { ordering, options } = this.state;
      this.setState({
        ordering: {
          column,
          isAscending: ordering.column === column ? !ordering.isAscending : true,
        },
      }, () => this.reload(options));
    };

    onScrollBottom = () => {
      const { doNext, pages } = this.state;
      if (doNext) {
        this.loadPage(pages[pages.length - 1] + 1);
      }
    };

    onScrollTop = () => {
      const { doPrev, pages } = this.state;
      if (doPrev) {
        this.loadPage(pages[0] - 1);
      }
    };

    onSearchHandler = (e, v) => {
      const { options } = this.state;
      this.setState({
        searchValue: v,
      }, () => this.reload(options));
    };

    rowDoubleClickHandler = (e, id) => {
      const { selectMode } = this.props;
      const { options } = this.state;
      if (selectMode) {
        this.choiceAction(e, id);
      } else if (options.actions.POST) {
        this.editAction(e, id);
      }
    };

    render() {
      const {
        isLoading,
        isErrored,
        errorText,
        data,
        options,
        selectedItems,
        currentItem,
        columns,
        isHierarhical,
        openedRows,
        ordering,
        searchValue,
        period,
        doNext,
        doPrev,
        errorHeader,
      } = this.state;
      const { canSelect, selectMode } = this.props;
      return (
        <StyledContainer className="fullscreenContainer, fullscreenParent">
          {isErrored && (<ErrorMessage text={errorText} header={errorHeader} />)}
          <DimmableLoader loading={isLoading} fullscreen>
            <HeaderText>{options.name}</HeaderText>
            <div style={{ display: 'block' }}>
              <CPContainer>
                {CommandPanel ? (
                  <CommandPanel
                    onActionClick={this.processAction}
                    canNew={!!options.actions.POST}
                    canEdit={!!currentItem && (!!options.actions.POST || options.actions.CAN_EDIT)}
                    canDelete={!!selectedItems.length && !!options.actions.POST}
                    canSelect={selectMode && canSelect && !!currentItem}
                    canExecute={!!selectedItems.length}
                    canCancelExecute={!!selectedItems.length}
                    period={period}
                  />
                ) : null}
                <SearchInput onSearch={this.onSearchHandler} value={searchValue} />
              </CPContainer>
            </div>
            <WrappedComponent
              columns={columns}
              data={data.results || []}
              selectedItems={selectedItems}
              onRowClick={this.activateItem}
              onRowDoubleClick={this.rowDoubleClickHandler}
              isHierarhical={isHierarhical}
              isSearch={!!searchValue}
              openedRows={openedRows}
              onToggle={this.toggleRow}
              onHeaderClick={this.onHeaderClick}
              ordering={ordering}
              mode={mode}
              onScrollBottom={this.onScrollBottom}
              onScrollTop={this.onScrollTop}
              readOnly={!options.actions.POST}
              doPrev={doPrev}
              doNext={doNext}
              selectMode={selectMode}
            />
          </DimmableLoader>
        </StyledContainer>
      );
    }
  }
  const mapStateAuthWithSettings = (state) => ({
    ...mapStateAuth(state),
    settings: state.settings[backend],
  });


  const enchance = compose(withRouter, withAuthConsumer(mapStateAuthWithSettings));
  return enchance(Lister);
};

export default getLister;
