import Styled from 'styled-components';

export const EditorContainer = Styled.div`
  // height: 100%;
  // border: 1px solid #DDE2E9;
  // border-radius: 0.3rem;
  padding: 1em 1.5em;
  // display: flex;
  // flex-direction: column;
`;

export const EditorHeader = Styled.h2`
  color: #008F21;
  font-family: 'Roboto',sans-serif;
  margin: 15px 0 20px 0;
  font-size: 1.6em;
`;

export const InfoLabel = Styled.div`
  color: #4281c9;
  font-size: 1em;
  margin-bottom: 0.5em;
`;

export const ContainerButton = Styled.div`
  text-align: right;
  margin-top: 1.5em;
`;
export const FiltersGrid = Styled.div`
  display: grid;
  grid-template-columns: [use] 12% [field] 1fr [eq] 1fr [value] 1fr;
`;

const defaultFiltersItem = Styled.div`
  border: 1px solid #DDE2E9;
  padding: 6px 9px;
  position: relative;
`;

export const FiltersItemHeader = Styled(defaultFiltersItem)`
  font-weight: 700;
  text-align: center; 
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
`;

export const FiltersItem = Styled(defaultFiltersItem).attrs((props) => ({
  style: {
    gridArea: `${props.rowId}/${props.colName}/${props.rowId}/${props.colName}`,
    background: props.active ? 'linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21' : 'initial',
    alignItems: 'center',
    display: 'grid',
    fontSize: '12px',
  },
}))`
  &:hover{
    background: linear-gradient(0deg, #66BC7A, #66BC7A), #008F21
  };
`;
