import styled from 'styled-components';

export const StyleTable = styled.table`
  border-collapse: collapse;
  td, th { border: 1px solid #a9d4ff}
  
  th { position: sticky;
    top: 0;
    z-index: 3; 
  };
  @media print {
    position: inherit;
  }
 
    ${({ addStyles }) => addStyles}
        
`;

export const ContainerButton = styled.div`
  border-bottom: 1px solid #dee2e6;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
`;


export const NumberTD = styled.td`
    white-space: nowrap;
    text-align: right;
    vertical-align: middle ;
    padding: 5px !important;
`;
export const StyledTD = styled.td`
  padding-top: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 5px !important;
  line-height: 1.3;
`;
