import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DefaultInput } from '../../components/Styled/Input';

const StyledInput = styled(DefaultInput).attrs({
  type: (props) => props.password ? 'password' : 'text',
})`
  color: ${(props) => props.errored ? 'red' : 'unset'}
  // padding: 0.3em;
`;


class StringInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    errors: PropTypes.arrayOf(PropTypes.string),
    noBorder: PropTypes.bool,
    noBackground: PropTypes.bool,
    password: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    onChange: () => console.log('This control id ReadOnly because prop "onChange" is not defined'),
    disabled: false,
    readOnly: false,
    maxLength: 0,
    errors: null,
    noBorder: false,
    noBackground: false,
    password: false,
  };

  changeHandler = (e) => {
    const { readOnly, maxLength, onChange } = this.props;
    const newValue = e.target.value;
    if (!readOnly && (!maxLength || newValue.length <= maxLength)) {
      onChange(e, newValue);
    }
  };

  render() {
    const {
      value, disabled, errors, noBackground, password,
    } = this.props;
    return (
      <StyledInput
        value={value || ''}
        onChange={this.changeHandler}
        disabled={disabled}
        errored={!!errors}
        noBorder={false}
        noBackground={noBackground}
        password={password}
      />
    );
  }
}

export default StringInput;
