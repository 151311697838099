import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import JSZip from 'jszip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare, faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { EditorControls } from '../../../BaseEditor';
import { GridItem } from '../../../../components/Styled/grids/editorGrid';
import api from '../../../../api/req';
import { trausureFileTypes } from '../../../../const/meta/enums';
import { withAuthConsumer, mapStateAuth } from '../../../../providers/authProvider';
import {
  ThisDocGrid,
  DropZone,
  BoxTittle,
  InfoLabel,
  StepItem,
  PeriodDiv,
  Form,
  Buttons,
  FileDiv,
  BoxFile,
  GItem,
} from './styles';
import Budgets from './budgets';
import Kdbs from './kdbs';
import Files from './files';
import { NextButton, PreviousButton } from '../../../../components/Styled/Buttons';
import { ErrorMessage, Modal } from '../../../../components/Styled/Misc';
import { DivStyledUpload, InputStyled } from '../../../../components/Styled/Input';
import ProgressBar from '../../../../components/progressBar';
import SearchInput from '../../../../components/Styled/Misc/Search';

const FileInput = styled(InputStyled).attrs({
  type: 'file',
  multiple: true,
})`
`;

const StepGrid = styled.div`
  display: grid;
  grid-template-areas: "step1 step2" "step3 step3";
  grid-gap: 4px;
  width: 100%;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

class TestFileUploader extends Component {
  static propTypes = {
    authF: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      is_superuser: PropTypes.bool,
      org: PropTypes.shape({
        id: PropTypes.number,
        repr: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { currentUser } = this.props;
    const d = new Date();
    this.state = {
      data: {
        bdate: `${d.getFullYear()}-01-01`,
        edate: d.toISOString().slice(0, 10),
      },
      files: [],
      tasks: {},
      budgets: [],
      kdbs: [],
      searchText: '',
      searchedKdbsList: [],
      org: currentUser.org ? { id: currentUser.org.id, repr: currentUser.org.repr } : null,
      step: 1,
      isErrored: '',
      errorText: '',
      createRelated: true,
      uploadStarted: false,
      force: true,
      fileType: -1,
    };
  }

  componentDidMount() {
    const { org } = this.state;
    this.reloadBudgets(org);
    this.reloadKDBs();
  }

   setSearchedKdbsList = (kdbs, searchText) => searchText
     ? kdbs.filter((r) => `${r.repr}`.toUpperCase().indexOf(searchText.toUpperCase()) !== -1)
     : kdbs

  scanFiles = (item, callback) => {
    if (item.isDirectory) {
      const DR = item.createReader();
      DR.readEntries((items) => {
        items.map((i) => this.scanFiles(i, callback));
      });
    } else {
      callback(item);
    }
  };

  fileLoadHandler = (e) => {
    const { items } = e.dataTransfer;
    this.setState({ files: [] });
    e.preventDefault();
    let f = [];
    Object.values(items).forEach((i) => this.scanFiles(i.webkitGetAsEntry(), (item) => {
      item.file((file) => {
        this.fileGetter(file).then((files) => {
          if (files) {
            f = [...f, ...files.filter((ff) => !!ff).map((fl) => ({ file: fl }))];
            this.setState({ files: f });
          }
        });
      });
    }));
  };

  clean = async () => {
    const { authF } = this.props;
    const { org, budgets } = this.state;
    this.setState({
      isErrored: false,
    });

    const r = await api.post('/api/service/clean_master/', authF, {
      org: org.id,
      budget: budgets.map((b) => b.id),
    });
    if (r.ok) {
      this.setState({
        isErrored: false,
        errorText: '',
      });
    } else {
      this.setState({
        isErrored: true,
        errorText: `${r.status} ${r.statusText}`,
      });
    }
  };

  submitform = () => {
    const {
      files, data, budgets, org, tasks, force, createRelated, kdbs,
    } = this.state;
    const kdbIdList = kdbs.filter((b) => b.checked).map((b) => b.id);
    const { authF } = this.props;
    const filesUploader = async (file) => {
      const formData = new FormData();
      formData.append('date_after', data.bdate);
      formData.append('date_before', data.edate);
      budgets.filter((b) => b.checked).forEach((b) => formData.append('budgets', b.id));
      formData.append('kdb_id_list', kdbIdList);
      formData.append('file', file, file.name);
      formData.append('create_related', createRelated);
      formData.append('org', org && org.id);
      formData.append('force', force);
      const r = await api.post$('/api/initial/subscription/', authF, formData, true);
      if (r.ok) {
        const d = await r.json();
        return {
          fileName: file.name,
          taskId: d.id,
          err: '',
        };
      }
      return {
        fileName: file.name,
        taskId: null,
        err: `${r.status} ${r.statusText}`,
      };
    };
    const notDoneFiles = files.filter((f) => !f.done);
    if (notDoneFiles.length) {
      filesUploader(notDoneFiles[0].file).then((task) => {
        this.setState({
          tasks: {
            ...tasks,
            [task.fileName]: {
              id: task.taskId,
              err: task.err,
            },
          },
          uploadStarted: true,
        });
      });
    } else {
      this.setState({ uploadStarted: false });
    }
  };

  queryParser = (response, f) => {
    if (response.ok) {
      response.json().then((data) => f(data));
    } else {
      this.setState({ isErrored: true, errorText: `${response.status} ${response.statusText}` });
    }
  };

  reloadBudgets = (org) => {
    const { authF } = this.props;
    if (org && org.id) {
      api.get$('/api/catalog/budgets/', authF, { org: org.id })
        .then((r) => this.queryParser(r, (d) => this.setState({
          budgets: d.map((b) => ({
            checked: true, id: b.id, repr: b.repr, maskFt: b.mask_ft, maskBudget: b.mask_of_file,
          })),
        })));
    }
  };

  reloadKDBs = () => {
    const { authF } = this.props;
    const { searchText } = this.state;
    api.get$('/api/catalog/kdbs/', authF)
      .then((r) => this.queryParser(r, (d) => this.setState({
        kdbs: d,
        searchedKdbsList: this.setSearchedKdbsList(d, searchText),
      })));
  }

  onSearchHandler = (e, searchText) => {
    const { kdbs } = this.state;
    this.setState({
      searchText,
      searchedKdbsList: this.setSearchedKdbsList(kdbs, searchText),
    });
  };

  handleCheckAllKDBs = (type) => {
    const { searchedKdbsList, kdbs } = this.state;
    if (type === 'all') {
      this.setState({
        searchedKdbsList: searchedKdbsList.map((kdb) => ({ ...kdb, checked: true })),
        kdbs: kdbs.map((kdb) => ({ ...kdb, checked: true })),
      });
    } else {
      this.setState({
        searchedKdbsList: searchedKdbsList.map((kdb) => ({ ...kdb, checked: false })),
        kdbs: kdbs.map((kdb) => ({ ...kdb, checked: false })),
      });
    }
  }

  handleFileDone = (fileName) => {
    const { files } = this.state;
    this.setState({
      // eslint-disable-next-line
      files: files.map(f => f.file.name === fileName ? { ...f, done: true } : f),
    }, this.submitform);
  };

  fileGetter = async (file) => {
    const { fileType } = this.state;
    const zip = new JSZip();

    const zipFileGetter = async (zipFile, fileName) => {
      // console.log(zipFile);
      const blob = await zipFile.async('blob');
      return new File([blob], fileName);
    };

    try {
      const zipFiles = await zip.loadAsync(file);
      return await Promise.all(Object.keys(zipFiles.files)
        .filter((fileName) => this.fileCompile(fileName, fileType))
        .map((fileName) => zipFileGetter(zipFiles.files[fileName], fileName)));
    } catch {
      if (this.fileCompile(file.name, fileType)) return [file];
      return null;
    }
  };

  handleLoadFile = (e) => {
    const files = [...e.target.files];
    Promise.all(files.map((f) => this.fileGetter(f))).then((results) => {
      this.setState({
        files: results.filter((f) => !!f).reduce((R, ffs) => [
          ...R,
          ...ffs.map((file) => ({ file })),
        ], []),
      });
    });
  };

  fileCompile = (fileName, fileType) => {
    const { budgets } = this.state;

    switch (fileType) {
    case trausureFileTypes.plain:
      return fileName.substr(0, 2).toUpperCase() === 'VP';
    case trausureFileTypes.commercial_at:
      return fileName.substr(0, 1).toUpperCase() === '@';
    case trausureFileTypes.ft:
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < budgets.length; i++) {
        if (fileName.toUpperCase().includes(budgets[i].maskFt.toUpperCase().trim())
            && fileName.toUpperCase().includes(budgets[i].maskBudget.toUpperCase().trim())) {
          return true;
        }
      }
      return false;

    default:
      return true;
    }
  };

  renderStep1 =() => {
    const {
      data, budgets, files, searchedKdbsList, kdbs,
    } = this.state;
    return (
      <StepGrid>
        <div style={{ gridArea: 'step1', display: 'inherit', height: '350px' }}>
          <StepItem label="Крок 1: Оберіть період завантаження виписок">
            <PeriodDiv>
              <EditorControls.DateInput
                label="Дата початку"
                value={data.bdate}
                onChange={(e, v) => this.setState({ data: { ...data, bdate: v } })}
              />
              <EditorControls.DateInput
                label="Дата закінчення"
                value={data.edate}
                onChange={(e, v) => this.setState({ data: { ...data, edate: v } })}
              />
            </PeriodDiv>
          </StepItem>
          <StepItem label="Крок 2: Оберіть бюджети, по яким необхідно завантажити виписки">
            <Budgets budgets={budgets} gridArea="budgets" onChange={(e, v) => this.setState({ budgets: v })} />
          </StepItem>
        </div>
        <div style={{ gridArea: 'step2', display: 'inherit', minHeight: '300px' }}>
          <StepItem label="Крок 3: Оберіть КДБ, по яким необхідно завантажити виписки (якщо не обрано, буде завантажено по всім)">
            <div style={{ display: 'flex', height: '40px', alignItems: 'center' }}>
              <FontAwesomeIcon
                icon={faCheckSquare}
                title="Обрати всі"
                size="lg"
                color="white"
                style={{ backgroundColor: '#4281c9', margin: '0 5px', cursor: 'pointer' }}
                onClick={() => this.handleCheckAllKDBs('all')}
              />
              <FontAwesomeIcon
                icon={faSquare}
                title="Скинути всі відмітки"
                color="white"
                style={{ backgroundColor: '#4281c9', marginRight: '15px', cursor: 'pointer' }}
                size="lg"
                onClick={() => this.handleCheckAllKDBs('nothing')}
              />
              <SearchInput onSearch={this.onSearchHandler} />
            </div>

            <Kdbs
              kdbs={kdbs}
              searchedKdbsList={searchedKdbsList}
              gridArea="kdbs"
              onChangeKdbs={(e, v) => this.setState({ kdbs: v })}
              onChangeSearchedKdbsList={(e, v) => this.setState({ searchedKdbsList: v })}
            />
          </StepItem>
        </div>
        <div style={{ gridArea: 'step3', display: 'inherit' }}>
          <StepItem label="Крок 4: Оберіть файли для імпорту або перетяніть їх до червоної зони">
            <DropZone
              onDragOver={(e) => e.preventDefault()}
              onDrop={this.fileLoadHandler}
            >
              <DivStyledUpload noBorder>
                <div>
                  <FileInput
                    onChange={this.handleLoadFile}
                  />
                  <span>Оберіть файл</span>
                </div>
              </DivStyledUpload>
              <BoxTittle>
                {files.map((file, index) => (
                  <FileDiv
                  // eslint-disable-next-line
                        key={index}
                    onClick={() => this.setState({
                      files: files.filter((f) => f.file.name !== file.file.name),
                    })}
                  >
                    <BoxFile fileName={file.file.name} />
                  </FileDiv>
                ))}
              </BoxTittle>
            </DropZone>
          </StepItem>
        </div>
      </StepGrid>
    );
  };

  renderStep4 =() => {
    const { tasks, files } = this.state;
    const doneFiles = files.filter((f) => f.done);
    const allDone = doneFiles.length === files.length;
    const content = (
      <ResultContainer>
        <ProgressBar maxValue={files.length} value={doneFiles.length}>
          <InfoLabel>
            {!allDone ? `Оброблено ${doneFiles.length} з ${files.length} файлів.` : 'Оброблено всі файли.'}
          </InfoLabel>
        </ProgressBar>
        <Files
          tasks={Object.keys(tasks).map((f) => ({ ...tasks[f], name: f }))}
          onTaskDone={this.handleFileDone}
        />
      </ResultContainer>
    );
    return (
      <div style={{ width: '100%', display: 'grid' }}>
        <StepItem area="step4" label="Крок 4: Дочекайтесь виконання, та перегляньте результати">
          {allDone ? content : (
            <Modal open={!allDone}>
              {content}
            </Modal>
          )}
        </StepItem>
      </div>
    );
  };

  renderMaster = () => {
    const { step } = this.state;
    switch (step) {
    case 1:
      return this.renderStep1(); // период бюджеты файлы
    case 2:
      return this.renderStep4(); // результат
    default:
      return null;
    }
  };

  nextStep = () => {
    const { step, files } = this.state;
    if (step === 1) {
      this.clean();
      this.setState({
        tasks: [],
        files: files.map((f) => ({ ...f, done: false })),
      },
      this.submitform);
    }
    this.setState({ step: step + 1 });
  };

  render() {
    const {
      org, step, isErrored, errorText, uploadStarted,
    } = this.state;
    const { currentUser } = this.props;
    return (
      <Form onSubmit={this.submitform} method="POST">
        {isErrored && (
          <ErrorMessage text={errorText} />
        )}
        <ThisDocGrid>
          <GridItem area="info">
            <InfoLabel>
              Для імпорту казначейської виписки пройдіть будь-ласка наступні кроки:
            </InfoLabel>
          </GridItem>
          <GItem area="master">
            {this.renderMaster()}
          </GItem>
          <GridItem area="org" style={{ display: 'flex' }}>
            {currentUser.is_superuser && (
              <EditorControls.ItemPicker
                label="Установа"
                value={org}
                backend="/api/catalog/orgs/"
                onChange={(e, v) => {
                  this.setState({ org: v });
                  this.reloadBudgets(v);
                }}
              />
            )}
            <Buttons area="buttons" style={{ marginLeft: 'auto' }}>
              <PreviousButton content="Назад" disabled={step === 1 || uploadStarted} onClick={() => this.setState({ step: step - 1 })} />
              <NextButton content="Далі" disabled={step === 2 || uploadStarted} onClick={this.nextStep} />
            </Buttons>
          </GridItem>

        </ThisDocGrid>
      </Form>
    );
  }
}


export default withAuthConsumer(mapStateAuth)(TestFileUploader);
