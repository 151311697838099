import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ImageShower extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line
    value: PropTypes.oneOfType([
      PropTypes.shape({ file: PropTypes.string }),
      PropTypes.string,
    ]),
    width: PropTypes.string,
    height: PropTypes.string,
    alt: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    width: null,
    height: null,
    alt: '',
  };

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (value !== state.value) {
      const fStr = ';base64,';
      if (!value || !value.file) {
        return {
          value,
          URL: value,
        };
      }
      const fpos = value.file.indexOf(fStr);
      const fileData = value.file.slice(fpos + fStr.length);
      const contentType = value.file.slice(0, fpos);
      const byteCharacters = atob(fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });
      const URL = window.URL.createObjectURL(blob);
      return {
        value,
        URL,
      };
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line
      value: null,
      URL: null,
    };
  }

  render() {
    const {
      width, height, alt,
    } = this.props;
    const { URL } = this.state;
    const wh = {
      ...width && { width },
      ...height && { height },
    };
    return (
      <img src={URL} alt={alt} {...wh} />
    );
  }
}

export default ImageShower;
