/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Header from './headerResults';
import Row from './rowResults';
import { useReportResult } from './hooks';
import { ContainerButton, StyleTable } from './styles';

const Results = forwardRef(({ result, caption, onContextMenu }, ref) => {
  const { header, body, footer } = useReportResult(result);
  return (
    <div id="print-area-rep" ref={ref}>
      <ContainerButton>
        {/* <div size="sm" style={{ height: '32px' }}> */}
        {/*  {levels.map((l) => ( */}
        {/*    <Button */}
        {/*      className="buttonGroup" */}
        {/*      key={l} */}
        {/*      onClick={() => { generalToggleGroupHandler(l); }} */}
        {/*      variant="secondary" */}
        {/*      size="sm" */}
        {/*    > */}
        {/*      {l + 1} */}
        {/*    </Button> */}
        {/*  ))} */}
        {/* </div> */}
      </ContainerButton>
      {caption && (
        <h3>{caption}</h3>
      )}
      <StyleTable
        addStyles={Object.keys(result.styles)
          .map((s) => `.${s}  { ${result.styles[s]}}`)}
      >
        <Header
          lines={header}
        />
        <tfoot>
          {footer.map((row) => (
            <tr key={row[0].row_num}>
              <td>&nbsp;</td>
              {row.map((cell) => (
                <td
                  key={`row-${cell.row_num} col-${cell.col_num}`}
                  colSpan={cell.colspan}
                  rowSpan={cell.rowspan}
                  className={`FooterOfTable ${cell.className}`}
                >
                  {cell.repr}
                </td>

              ))}
            </tr>
          ))}
        </tfoot>
        <tbody>
          {body.map((cells) => (
            <Row
              key={cells[0].row_num}
              cells={cells}
              visible
              onContextMenu={onContextMenu}
            />
          ))}

        </tbody>

      </StyleTable>
    </div>
  );
});

Results.propTypes = {
  result: PropTypes.shape({
    meta: PropTypes.shape({}),
    data: PropTypes.shape({}),
    styles: PropTypes.shape({}),
  }),
  caption: PropTypes.string,
  onContextMenu: PropTypes.func.isRequired,
};

Results.defaultProps = {
  result: null,
  caption: null,
};

export default Results;
