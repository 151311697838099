import Styled from 'styled-components';

export const StyledControl = Styled.div`
  flex-grow: 1;
`;

export const ControlsConrainer = Styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;
  margin-bottom: 10px;
  // align-items: center;
  
  @media (max-width: 600px) {
  grid-template-rows: auto auto;
  grid-template-columns: none;
  }
`;

export const ReportContainer = Styled.div.attrs({
  className: 'fullscreenContainer fullscreenParent',
})`
  padding: 20px 0;
`;
