import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { StyledItem, getDraggableProps, OrderColumnIcon } from '../styles';
import { AscendingIcon, DescendingIcon } from '../../../components/Styled/Buttons/icons';


const OrderColumn = ({
  column, active, onClick, onDoubleClick, index, onChangeColumn,
}) => (
  <StyledItem
    active={active}
    onClick={(e) => onClick(e, index)}
    onDoubleClick={(e) => onDoubleClick(e, index)}
  >
    <Draggable draggableId={`ORDER-${column.key}-${index}`} index={index} key={column.key}>
      {(providedDrag, snapshot) => (
        <div
          ref={providedDrag.innerRef}
          {...getDraggableProps(providedDrag, snapshot)}
        >
          <OrderColumnIcon
            src={column.isDescending ? DescendingIcon : AscendingIcon}
            onClick={(e) => onChangeColumn(
              e,
              index,
              { ...column, isDescending: !column.isDescending },
            )}
          />
          {column.label}
        </div>
      )}
    </Draggable>
  </StyledItem>
);

export const orderPropType = PropTypes.shape({
  label: PropTypes.string,
  query_label: PropTypes.string,
  key: PropTypes.string.isRequired,
  isDescending: PropTypes.bool,
});

OrderColumn.propTypes = {
  column: orderPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onChangeColumn: PropTypes.func,
  index: PropTypes.number.isRequired,
};

OrderColumn.defaultProps = {
  active: false,
  onClick: () => null,
  onDoubleClick: () => null,
  onChangeColumn: () => null,
};

export default OrderColumn;
