import { compose } from 'recompose';
import StringInput from './stringInput';
import { ItemField, ItemPicker } from '../itemField';
import getNumerInput from './numberInput';
import Selector from './selector';
import TextInput from './textInput';
import DateInput from './dates';
import Multiselector from './multiselector';
import MultiVaueInput from './multiValue';
import FormatInput from './format';
import { withErrors } from './withErrors';
import CheckboxInput from './Checkbox';
import Radio from './radio';
import { withLabel } from './withLabel';

const EditorEnhancer = compose(withLabel, withErrors);

const EditorControls = {
  StringInput: EditorEnhancer(StringInput),
  ItemPicker: EditorEnhancer(ItemPicker),
  ItemField: EditorEnhancer(ItemField),
  getNumerInput: (...r) => EditorEnhancer(getNumerInput(...r)),
  Selector: EditorEnhancer(Selector),
  TextInput: EditorEnhancer(TextInput),
  DateInput: EditorEnhancer(DateInput),
  CheckboxInput: withErrors(CheckboxInput),
  Radio: EditorEnhancer(Radio),
  Multiselector: EditorEnhancer(Multiselector),
  MultiVaueInput: EditorEnhancer(MultiVaueInput),
  FormatInput: EditorEnhancer(FormatInput),
};

export default EditorControls;
