import React, {
  useMemo, memo, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddButton,
  RemoveSelectionButton,
} from '../../../../components/Styled/Buttons';
import { CommandPanel } from '../../../../components/Styled/Misc';
import { metaFieldPropType } from '../hooks';
import MetaFieldItem from './item';
import MetaFieldProps from './props';
import { TwoColGrid } from '../styles';
import { EditorContainer } from '../../../../components/Styled/Forms';

const MetaFieldsPage = ({
  metaFields, activeMetaField, onRenameField, onChangeField,
  onAddField, onRemoveField, onSetActiveField, allowedCtypes, fields, allowedFonts,
}) => {
  const [height, setHeight] = useState(0);

  const containerHeaderRef = useRef(null);

  useEffect(() => {
    if (containerHeaderRef.current) {
      setHeight(containerHeaderRef.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  const displayMetaFields = useMemo(
    () => metaFields.map((t, itemKey) => ({
      ...t,
      itemKey,
    })), [metaFields],
  );

  const displayAllowedMetaFields = useMemo(() => metaFields.map((value) => ({
    value: value.name,
    display_name: value.name,
  })), [metaFields]);

  const fValue = useMemo(
    () => metaFields.filter((t) => t.name === activeMetaField)
      .reduce((R, f) => f, {}),
    [activeMetaField, metaFields],
  );
  // const alowedTables = useMemo(
  //   () => Object.keys(tableFields).map((value) => ({ value, display_name: value })),
  //   [tableFields],
  // );

  const displayAlowedCtypes = useMemo(
    () => allowedCtypes.map((value) => ({
      value,
      display_name: value,
    })),
    [allowedCtypes],
  );

  const allowedFields = useMemo(
    () => fields.map((f) => ({
      value: f,
      display_name: f,
    })),
    [fields],
  );
  return (
    <div>
      <EditorContainer>
        <CommandPanel>
          <AddButton
            title="Додати поле"
            content=""
            onClick={() => onAddField(Math.random()
              .toString(36))}
          />
          <RemoveSelectionButton
            title="Видалити поле"
            content=""
            onClick={() => onRemoveField(activeMetaField)}
            disabled={!activeMetaField}
          />
        </CommandPanel>
        <TwoColGrid ref={containerHeaderRef} height={height}>
          <div>
            {displayMetaFields.map((metaField) => (
              <MetaFieldItem
                key={metaField.itemKey}
                active={metaField.name === activeMetaField}
                onClick={() => onSetActiveField(metaField.name)}
                metaField={metaField}
                onRenameField={onRenameField}
              />
            ))}
          </div>
          <div>
            <MetaFieldProps
              onChange={(v) => onChangeField(activeMetaField, v)}
              value={fValue}
              readOnly={!('name' in fValue)}
              allowedCtypes={displayAlowedCtypes}
              allowedFields={allowedFields}
              allowedFonts={allowedFonts}
              displayAllowedMetaFields={displayAllowedMetaFields}
            />
          </div>
        </TwoColGrid>
      </EditorContainer>
    </div>
  );
};

MetaFieldsPage.propTypes = {
  metaFields: PropTypes.arrayOf(metaFieldPropType).isRequired,
  activeMetaField: PropTypes.string,
  onRenameField: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onAddField: PropTypes.func.isRequired,
  onRemoveField: PropTypes.func.isRequired,
  onSetActiveField: PropTypes.func.isRequired,
  allowedCtypes: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowedFonts: PropTypes.arrayOf(PropTypes.string),
};

MetaFieldsPage.defaultProps = {
  activeMetaField: null,
  allowedCtypes: [],
  allowedFonts: [],
};

export default memo(MetaFieldsPage);
