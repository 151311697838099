/* eslint-disable react/no-array-index-key */
import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTabContainer = styled.div`
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  flex: 0 0 auto; 
`;

const StyledTab = styled.div`
  background: ${({ active }) => active ? 'linear-gradient(0deg,rgba(204,230,255,0.88),rgba(255,255,255,0.88)),#4281C9' : 'inherit'};
  border-radius: 0.3rem 0.3rem 0 0;
  float: left;
  border: ${({ active }) => active ? '1px solid #ccc' : '1px solid transparent'};
  border-bottom: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), rgb(0, 143, 33);
  };
  
   @media (max-width: 600px) {
   padding: 5px;
   font-size: 0.9em;
   }
`;

const StyledTabContent = styled.div`
  max-height : ${({ maxHeight }) => maxHeight};
  overflow: auto;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
  background: white;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const Tab = ({ panes, onTabSelect }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [height, setHeight] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().top);
    } else setHeight(0);
  }, []);

  return (
    <TabContainer>
      <StyledTabContainer>
        {panes.map((pane, i) => (
          <StyledTab
            active={i === activeIndex}
            key={i}
            onClick={() => {
              setActiveIndex(i);
              if (onTabSelect) onTabSelect(i);
            }}
          >
            {pane.menuItem}
          </StyledTab>
        ))}
      </StyledTabContainer>
      <StyledTabContent maxHeight={`calc(100vh - ${height}px - 60px)`} ref={ref}>
        <div>
          {panes[activeIndex].render()}
        </div>
      </StyledTabContent>
    </TabContainer>
  );
};

Tab.propTypes = {
  panes: PropTypes.arrayOf(PropTypes.shape({
    menuItem: PropTypes.string,
    render: PropTypes.func,
  })),
  onTabSelect: PropTypes.func,
};

Tab.defaultProps = {
  panes: [],
  onTabSelect: null,
};

export default Tab;
