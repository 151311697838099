import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { getDraggableProps, StyledItem } from '../styles';
import { lookups } from './lookups';
import Selector from '../../BaseEditor/selector';
import CheckBox from '../../BaseEditor/Checkbox';
import MultiValueControl from './multiValueControl';
import ValuesColntol from './valueArray';

const FilterStyledItem = Styled(StyledItem)`
  display: grid;
  // grid-template-columns: 19% 21% 27% 27%;
  grid-template-columns: 17% 13% 32% 32%;
  grid-gap: 2%;
  background: #f7faff;
  border: 1px solid #DDE2E9;
  border-radius: 0.3rem;
  align-items: center;
  position: relative;
  padding: 0 10px;
  overflow-wrap: break-word;
  
  @media (max-width: 600px) {
  display: block;
  }
`;

const ValueItem = Styled.div`
  position: relative;
`;
const FilterColumn = ({
  column, active, onClick, onDoubleClick, onChange, index,
}) => (
  <Draggable draggableId={`FILTER-${column.key}-${index}`} index={index} key={column.key}>
    {(providedDrag, snapshot) => (
      <FilterStyledItem
        ref={providedDrag.innerRef}
        {...getDraggableProps(providedDrag, snapshot)}
        active={active}
        onClick={(e) => onClick(e, index)}
        onDoubleClick={(e) => onDoubleClick(e, index)}
      >
        {column.label}
        <CheckBox label="Не" value={column.not} onChange={(e, v) => onChange(e, index, { ...column, not: v })} />
        <Selector
          // noBorder
          values={column.allowedLookups.map((a) => ({ value: a.value, display_name: a.label }))}
          value={column.ct}
          onChange={(e, v) => onChange(e, index, { ...column, ct: v, value: null })}
        />
        <ValueItem>
          {column.ct !== lookups.in ? (
            <MultiValueControl
              type={column.type}
              value={column.value}
              choices={column.choices}
              onChange={(e, value) => onChange(e, index, { ...column, value })}
            />
          ) : (
            <ValuesColntol
              type={column.type}
              value={column.value}
              choices={column.choices}
              onChange={(e, value) => onChange(e, index, { ...column, value })}
              active={active}
            />

          )}
        </ValueItem>
      </FilterStyledItem>
    )}
  </Draggable>
);

const filterValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.shape({
    id: PropTypes.number,
    repr: PropTypes.string,
  }),
]);

export const filterColumnPropType = PropTypes.shape({
  label: PropTypes.string,
  query_label: PropTypes.string,
  allowedLookups: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  value: PropTypes.oneOfType([
    filterValuePropType,
    PropTypes.arrayOf(filterValuePropType),
  ]),
  key: PropTypes.string.isRequired,
  not: PropTypes.bool,
  ct: PropTypes.oneOf(Object.values(lookups)),
});

FilterColumn.propTypes = {
  column: filterColumnPropType.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onChange: PropTypes.func,
  index: PropTypes.number.isRequired,
};

FilterColumn.defaultProps = {
  active: false,
  onClick: () => null,
  onDoubleClick: () => null,
  onChange: () => null,
};

export default FilterColumn;
