import React from 'react';
import { DivHeader } from './styles';
import { fieldsPropType } from './PropTypes';


const HeaderComponent = ({ fields }) => (
  <>
    <DivHeader area="1 / date">
      {fields.date.label}
    </DivHeader>
    <DivHeader area="1 / order_amount">
      {fields.order_amount.label}
    </DivHeader>
    <DivHeader area="1 / payment_notes">
      {fields.payment_notes.label}
    </DivHeader>
  </>
);

HeaderComponent.propTypes = {
  fields: fieldsPropType,
};

HeaderComponent.defaultProps = {
  fields: {},
};
export default HeaderComponent;
