import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  
  "org"
  "user";
`;

const editorRenderer = (getControl) => (
  <ThisDocGrid>
    <GridItem area="org">
      {getControl('org', { backend: '/api/catalog/orgs/' })}
    </GridItem>
    <GridItem area="user">
      {getControl('user', { backend: '/api/core/user/' })}
    </GridItem>
  </ThisDocGrid>
);

export default getBaseEditor(editorRenderer, '/api/core/profiles/', CommandPanels.catCommandPanel);
