import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ExecuteIcon } from './icons';
import { StyledButton, StyledImg, StyledText } from './styledCommandPanelButton';

const StyledExecuteButton = styled(StyledButton)`
  height: 2.5em;
`;

export const ExecuteButton = ({ content, onClick, disabled }) => (
  <StyledExecuteButton onClick={onClick} disabled={disabled} title={content}>
    <StyledImg src={ExecuteIcon} alt={content} />
    <StyledText>{content}</StyledText>
  </StyledExecuteButton>
);

ExecuteButton.propTypes = {
  content: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

ExecuteButton.defaultProps = {
  onClick: () => null,
  content: 'Провести',
  disabled: false,
};

export default ExecuteButton;
