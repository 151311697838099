import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditorControls } from '../../../BaseEditor';
import { CloseButton } from '../../../../components/Styled/Buttons';
import { Modal } from '../../../../components/Styled/Misc/Modal';

const Button = styled.button`
    width: 200px;
    margin-bottom: 10px;
    height: 30px;
    cursor: pointer;`;

const TableProps = ({
  onChange, entries, showModal, setShowModal, keyValue, setKeyValue,
}) => (
  <>
    {entries.length > 0 && entries.map((el) => (
      <EditorControls.TextInput
        key={el[0]}
        controlId={el[0]}
        value={el[1]}
        label={el[0]}
        required
        description="Обращение к параметрам - :, например :bdate или :year"
        onChange={(e, v) => onChange(v, el[0])}
      />
    ))}
    <button type="button" onClick={() => setShowModal(true)}>Додати</button>
    <Modal open={showModal}>
      <>
        <h3>Додати ключ</h3>
        <Suspense fallback={<div>Загрузка...</div>}>
          <EditorControls.StringInput label="Введіть ім'я ключа" value={keyValue} onChange={(e, v) => setKeyValue(v)} />
          <Button onClick={() => { entries.push([keyValue, '']); setShowModal(false); setKeyValue(null); }}>Додати</Button>
        </Suspense>
        <CloseButton onClick={() => setShowModal(false)} />
      </>
    </Modal>
  </>
);
TableProps.propTypes = {
  onChange: PropTypes.func.isRequired,
  entries: PropTypes.node,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  keyValue: PropTypes.string,
  setKeyValue: PropTypes.func.isRequired,
};

TableProps.defaultProps = {
  entries: [],
  keyValue: null,
};

export default TableProps;
