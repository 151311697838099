import PropTypes from 'prop-types';

export const fieldsPropType = PropTypes.shape({
  payment_number: PropTypes.shape({
    label: PropTypes.string,
  }),
  payment_date: PropTypes.shape({
    label: PropTypes.string,
  }),
  payment_notes: PropTypes.shape({
    label: PropTypes.string,
  }),
  day_sum: PropTypes.shape({
    label: PropTypes.string,
  }),
  accum_sum: PropTypes.shape({
    label: PropTypes.string,
  }),
});

export default fieldsPropType;
