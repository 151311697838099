import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DefaultInput } from '../../components/Styled/Input';

const Input = styled(DefaultInput)`
  text-align: right;
`;

const getNumerInput = (width = 0, precision = 0, onlyPozitive = false) => {
  class NumberInput extends PureComponent {
    static propTypes = {
      value: PropTypes.number,
      onChange: PropTypes.func,
      readOnly: PropTypes.bool,
      disabled: PropTypes.bool,
      noBorder: PropTypes.bool,
      noBackground: PropTypes.bool,
    };

    static defaultProps = {
      value: null,
      onChange: () => null,
      readOnly: false,
      disabled: false,
      noBorder: false,
      noBackground: false,
    }


    static getDerivedStateFromProps(props, state) {
      if (props.value !== state.initialValue && props.value !== null) {
        return {
          initialValue: props.value,
          currentvalue: props.value && Number(props.value).toFixed(precision),
          dotPressed: false,
        };
      }
      return state;
    }

    constructor(props) {
      super(props);
      this.state = {
        // eslint-disable-next-line
        initialValue: props.value,
        currentvalue: props.value && (parseFloat(props.value) || 0).toFixed(precision),
        dotPressed: false,
      };
      this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (snapshot !== null) {
        this.inputRef.current.setSelectionRange(snapshot, snapshot);
      }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
      const { currentvalue } = this.state;
      const oldValue = prevState.currentvalue;
      const { dotPressed } = this.state;
      if (this.inputRef.current) {
        const el = this.inputRef.current;
        if (dotPressed) return currentvalue.indexOf('.') + 1;
        if (Number(currentvalue) === -Number(oldValue) && Number(oldValue)) {
          return Number(currentvalue) < 0 ? el.selectionStart + 1 : el.selectionStart - 1;
        }
        if (currentvalue && oldValue && currentvalue.length === oldValue.length) return el.selectionStart;
        if (currentvalue && oldValue && currentvalue.length > oldValue.length) return el.selectionStart;
        if (!oldValue) return el.selectionStart;
      }
      return null;
    }

    change = (e) => {
      // const { onChange } = this.props;
      const newValue = Number(e.target.value);
      if (!width || newValue.toFixed(precision).length <= width) {
        this.setState({ currentvalue: newValue.toFixed(precision), dotPressed: false });
      }
      // onChange(e, newValue);
    };

    keyPress = (e) => {
      const { currentvalue } = this.state;
      const valid = (e.charCode >= 48 && e.charCode <= 59)
        || (precision && (e.charCode === 44 || e.charCode === 46))
        || (!onlyPozitive && e.charCode === 45);
      if (!valid) e.preventDefault();
      if (e.charCode === 45) {
        e.preventDefault();
        this.setState({
          currentvalue: (-Number(currentvalue)).toFixed(precision),
          dotPressed: false,
        });
      }
      if (precision && (e.charCode === 44 || e.charCode === 46)) {
        e.preventDefault();
        this.setState({ dotPressed: true });
      }
    };

    render() {
      const {
        readOnly, disabled, onChange, noBackground, noBorder,
      } = this.props;

      const { currentvalue } = this.state;
      return (
        <Input
          ref={this.inputRef}
          value={currentvalue}
          readOnly={readOnly}
          diabled={disabled}
          onKeyPress={this.keyPress}
          onChange={this.change}
          onBlur={(e) => currentvalue && onChange(e, Number(currentvalue))}
          noBackground={noBackground}
          noBorder={noBorder}
        />

      );
    }
  }
  return NumberInput;
};


export default getNumerInput;
