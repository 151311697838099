import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { InputStyled } from '../../Input';
import { SeacrhIcon } from '../../Buttons/icons';
import { debounce } from '../../../../api/utils';

const StyledSearchContainer = Styled.div`
  background: white;
  border-radius:.28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  padding: 3px 10px;
  width: 30%;
  display: flex;  
  align-self: center;
  box-sizing: border-box;
  
  
  @media (max-width: 720px) {
  width: 100%;
  margin-bottom: 5px;
  }
`;

const StyledSeacrh = Styled(InputStyled).attrs({
  placeholder: 'Пошук',
})`
`;

class SearchInput extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: '',
  }

  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  render() {
    const { onSearch, value } = this.props;
    return (
      <StyledSearchContainer>
        <img src={SeacrhIcon} alt="Пошук" />
        <StyledSeacrh
          ref={this.ref}
          onChange={debounce((e) => onSearch(e, this.ref.current.value), 1000)}
          defaultValue={value}
        />
      </StyledSearchContainer>
    );
  }
}
// onSearch(e, e.target.value)
export default SearchInput;
