import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Label } from '../../components/Styled/elements';

export const StyledLabel = styled(Label)`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  :first-letter{
    text-transform: uppercase;
  };
`;

export const withLabel = (WrappedComponent) => {
  const Labeled = ({ label, ...props }) => (
    <StyledLabel>
      {label}
      <WrappedComponent {...props} />
    </StyledLabel>
  );

  Labeled.propTypes = {
    label: PropTypes.string,
  };

  Labeled.defaultProps = {
    label: 'Поле без назви',
  };

  return Labeled;
};
