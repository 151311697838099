import React from 'react';
import styled from 'styled-components';
import getBaseEditor, { CommandPanels } from '../../BaseEditor/index';
import { DocGrid, GridItem } from '../../../components/Styled/grids/editorGrid';
import TPs from './tp';

const ThisDocGrid = styled(DocGrid)`
  grid-template-areas:  "org date" "comment comment";
  grid-template-columns:  1fr 150px;
  
  @media (max-width: 600px) {
  display: block;
  }
`;

const GeneralTPColumnTypes = {
  contract: { type: '/api/catalog/contracts/' },
};


const editorRenderer = (getControl, {
  data, fields, dataSetter, currentUser,
}) => (
  <>
    <ThisDocGrid>
      <GridItem area="date">
        {getControl('date')}
      </GridItem>
      {currentUser.is_superuser && (
        <GridItem area="org">
          {getControl('org', {
            backend: '/api/catalog/orgs/',
          })}
        </GridItem>
      )}
      <GridItem area="comment">
        {getControl('comment')}
      </GridItem>
    </ThisDocGrid>
    <TPs.GeneralTP
      data={data.enteringtheremainderrows_set}
      options={fields.enteringtheremainderrows_set}
      onChange={(e, newRecs) => dataSetter({ enteringtheremainderrows_set: newRecs })}
      overrideColumns={GeneralTPColumnTypes}
    />
  </>
);

export default getBaseEditor(editorRenderer, '/api/document/enteringtheremainder/', CommandPanels.docCommandPanel);
