import Styled from 'styled-components';
import { AddButton, DeleteButton } from '../../../../components/Styled/Buttons';

export const StyledDiv = Styled.div`

`;

export const StyledItem = Styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRemoveIcon = Styled(DeleteButton)`
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(34,36,38,.15);
  &:hover {
    background: #AAC6E6;  
  }
`;

export const StyledAddButton = Styled(AddButton)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`;
