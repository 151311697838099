import React, { PureComponent } from 'react';
import ReportEditor from '../../BaseReport';
import { EditorControls } from '../../BaseEditor';
import { dataTypes, reports } from '../../../const/meta';
import { ControlsConrainer, StyledControl, ReportContainer } from '../styles';

const md = reports.compare412;

const redefineColumns = {
  budget: { type: '/api/catalog/budgets/', columns: ['name'] },
  date: { type: dataTypes.date },
  kdb: { type: '/api/catalog/kdbs/', columns: ['code', 'name'] },
  org: { type: '/api/catalog/orgs/', columns: ['name'] },
};

const resColumns = [
  { label: 'Сума виписки, грн', key: 'sum_revenue_amount_412' },
  { label: 'Сума файлів ft*.*(форма 412), грн', key: 'sum_day_sum' },
  { label: 'Відхилення, грн', key: 'sum_difference' },
  { label: 'Інформаційне відхилення з урахуванням коефійіенту розмежування, грн', key: 'sum_difference_k' },
];

const resColumnsItems = resColumns.map((r) => r.key);

const valuesRepresentator = (field, value) => {
  if (value && resColumnsItems.includes(field)) {
    return value.toLocaleString('uk', { minimumFractionDigits: 2 });
  } if (field === 'date') {
    const v = new Date(value.repr);
    return v.toLocaleDateString();
  }
  return value && value.repr;
};

class Compare412 extends PureComponent {
  constructor(props) {
    super(props);
    const cDate = new Date();
    this.state = {
      bdate: `${cDate.getFullYear()}-01-01`,
      edate: `${cDate.getFullYear()}-12-31`,
    };
  }

  bdateChangeHandler = (e, v) => {
    this.setState({
      bdate: v,
    });
  };

  edateChangeHandler = (e, v) => {
    this.setState({
      edate: v,
    });
  };

  render() {
    const {
      bdate, edate,
    } = this.state;
    return (
      <ReportContainer>
        <ControlsConrainer>
          <StyledControl>
            <EditorControls.DateInput
              value={bdate}
              onChange={this.bdateChangeHandler}
              label="Початок періоду"
            />
          </StyledControl>
          <StyledControl>
            <EditorControls.DateInput
              value={edate}
              onChange={this.edateChangeHandler}
              label="Кінець періоду"
            />
          </StyledControl>
        </ControlsConrainer>
        <ReportEditor
          valuesRepresentator={valuesRepresentator}
          params={{
            begin_date: bdate,
            end_date: edate,
          }}
          overrideColumns={redefineColumns}
          resourceColumns={resColumns}
          meta={md}
        />
      </ReportContainer>
    );
  }
}

export default Compare412;
