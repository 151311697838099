import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cpActions } from './commandPanels/actions';
import FiltersEditor, { eqTypesByType, eqTypes } from './filtersEditor';

const RECORDS_PER_PAGE = 50;

const TPConainer = styled.div`
  display: grid;
  overflow: auto;
  grid-template-rows: [CP] auto [Filter] auto [Table] 1fr; 
  background: white;
`;

const NoPortalConatiner = styled(TPConainer)`
`;


const PortalContainer = styled(TPConainer)`
  position: absolute;
  z-index: 1000;
  padding: 0.5em 1em;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  @media (max-width: 900px) {
  position: fixed;
  }
`;


const CPContainer = styled.div`
  grid-row: CP;
`;

const TableContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #DDE2E9;
  grid-row: Table;
`;
const FilterPortalContainer = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: rgba(0,0,0,0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FilterWrapper = styled.div`
  border-radius: 0.3rem;
  margin: 30px;
  height: 100%;
  background: white;
`;

const StyledPagesContainer = styled.div`
  display: inline-block;
  flex-grow: 0;
  padding: 5px 0 0;
  grid-row: Filter 
`;

const StyledPage = styled.div`
  // background: linear-gradient(0deg,#66BC7A,#ffffff);
  // border: 1px solid #a2b4e8;
  display: inline-block;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  box-shadow: 0 0 20px rgba(0,0,0,0.16);
  // border-radius: 17px 1px;
  border-radius: 0.3rem;
  margin-right: 4px;
  border: 1px solid lightblue;
  cursor: pointer;
  padding: 5px 10px;
  background: ${({ active }) => active ? 'linear-gradient(0deg,rgba(93,167,239,0.88),rgba(255,255,255,0.88)),#4281C9' : 'none'};
  ${({ active }) => active ? 'font-weight: 700' : ''}
    &:hover{
      background: linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),rgb(0,143,33);
    };
`;

const IconContainer = styled.div`
display: inline-flex;
vertical-align: middle;
cursor: pointer`;

const getTablePartController = (
  GridTemplate,
  BodyComponent,
  newRecordData,
  CommandPanel = null,
  HeaderComponent = null,
  FooterComponent = null,
  privateActionHandler = null,
) => {
  class TPController extends Component {
    static propTypes = {
      data: PropTypes.arrayOf(PropTypes.object),
      options: PropTypes.shape({
        child: PropTypes.shape({
          children: PropTypes.shape(),
        }),
      }),
      onChange: PropTypes.func,
      readOnly: PropTypes.bool,
      overrideColumns: PropTypes.shape(),
    };

    static defaultProps = {
      data: [],
      options: null,
      onChange: () => null,
      readOnly: false,
      overrideColumns: {},
    };

    static getDerivedStateFromProps(props, state) {
      const { data, overrideColumns } = props;
      const columns = props.options.child.children;
      const { currentPage, filterFields } = state;
      if (
        data !== state.data
        || currentPage !== state.oldCurrentPage
        || filterFields !== state.oldFilterFields
      ) {
        const filteredData = data.map((d, i) => ({ ...d, index: i })).filter((row) => (
          Object.keys(filterFields).reduce((R, f) => {
            if (!filterFields[f].use) {
              return R;
            }
            const currentColumn = { ...columns[f], ...overrideColumns[f] };
            let lV = null;
            let rV = null;
            if (currentColumn.type.indexOf('/api/') !== -1) {
              lV = row[f] ? row[f].id : null;
              rV = filterFields[f].value ? filterFields[f].value.id : null;
            } else if (currentColumn.type === 'decimal') {
              lV = Number(row[f]);
              rV = filterFields[f].value;
            } else {
              lV = row[f];
              rV = filterFields[f].value;
            }
            switch (filterFields[f].eq) {
            case eqTypes.eq.value:
              return R && lV === rV;
            case eqTypes.neq.value:
              return R && lV !== rV;
            case eqTypes.lt.value:
              return R && lV < rV;
            case eqTypes.lte.value:
              return R && lV <= rV;
            case eqTypes.gt.value:
              return R && lV > rV;
            case eqTypes.gte.value:
              return R && lV >= rV;
            case eqTypes.like.value:
              return R && rV && lV.toUpperCase().indexOf(rV.toUpperCase()) !== -1;
            default:
              console.log('Unknow equal type ', filterFields[f].eq);
              return R && true;
            }
          }, true)
        ));
        return ({
          data,
          currentPage,
          oldCurrentPage: currentPage,
          oldFilterFields: filterFields,
          recordsCount: filteredData.length,
          visivleRows: filteredData
            .slice(currentPage * RECORDS_PER_PAGE, (currentPage + 1) * RECORDS_PER_PAGE),
        });
      }
      return state;
    }

    constructor(props) {
      super(props);
      const hiddenFields = Object.keys(props.options.child.children)
        .filter((fName) => fName === 'id' || fName.indexOf('_id') !== -1);
      this.state = {
        activeRows: [],
        fullScreen: false,
        currentPage: 0,
        // eslint-disable-next-line
        oldCurrentPage: 0,
        // eslint-disable-next-line
        data: null,
        // eslint-disable-next-line
        oldFilterFields: null,
        visivleRows: [],
        filterEditorOpened: false,
        recordsCount: 0,
        filterFields: Object.keys(props.options.child.children)
          .filter((fname) => !hiddenFields.includes(fname))
          .reduce((R, fname) => {
            const cType = (props.overrideColumns[fname] && props.overrideColumns[fname].type)
              || props.options.child.children[fname].type;
            return ({
              ...R,
              [fname]: {
                use: false,
                label: props.options.child.children[fname].label,
                eq: 'eq',
                eqs: eqTypesByType(cType),
                value: null,
                type: cType,
              },
            });
          }, {}),
      };
    }

    // eslint-disable-next-line
    getFields = () => this.props.options.child.children;

    setActiveRow = (e, index) => {
      const { activeRows } = this.state;
      if (e.ctrlKey) {
        this.setState({
          activeRows: activeRows.includes(index)
            ? activeRows.filter((a) => a !== index)
            : [...activeRows, index],
        });
      } else {
        this.setState({
          activeRows: [index],
        });
      }
    };

    actionClickHandler = (e, action, values) => {
      const { onChange, data } = this.props;
      const {
        activeRows, fullScreen, filterEditorOpened,
      } = this.state;
      switch (action) {
      case cpActions.add:
        onChange(e, [...data, newRecordData()]);
        break;
      case cpActions.delete:
        onChange(e, data.filter((d, k) => !activeRows.includes(k)));
        break;
      case cpActions.expand:
        this.setState({ fullScreen: !fullScreen });
        // onChange(e, data.filter((d, k) => !activeRows.includes(k)));
        break;
      case cpActions.filter:
        this.setState({ filterEditorOpened: !filterEditorOpened });
        break;
      default:
        if (privateActionHandler) {
          privateActionHandler(e, action, {
            values,
            data,
            onDataChange: onChange,
          });
        } else {
          console.log(`Unknow action ${action}`);
        }
      }
    };

    ChangeHandler = (e, rowNumber, partRow) => {
      const { onChange, data, options } = this.props;
      // eslint-disable-next-line
      const modifiedPartRow = Object.keys(partRow).reduce((R, col) => (
        options.child.children[col].type === 'nested object' ? {
          ...R,
          [`${col}_id`]: partRow[col].id,
        } : R
      ), partRow);
      onChange(
        e,
        // eslint-disable-next-line
        data.map((d, k) => (k === rowNumber) ? { ...d, ...modifiedPartRow } : d),
      );
    };

    handleClick = (type) => {
      const { currentPage, recordsCount } = this.state;
      const pages = [...Array(Math.ceil(recordsCount / RECORDS_PER_PAGE)).keys()];
      if (type === 'next' && currentPage < pages.length - 1) {
        this.setState({ currentPage: currentPage + 1, activeRows: [] });
      }
      if (type === 'prev' && currentPage > 0) {
        this.setState({ currentPage: currentPage - 1, activeRows: [] });
      }
    }

    renderBody = () => {
      const { data, readOnly } = this.props;
      const {
        activeRows, fullScreen, visivleRows, currentPage, recordsCount,
      } = this.state;
      const pages = [...Array(Math.ceil(recordsCount / RECORDS_PER_PAGE)).keys()];
      const userPages = pages.map((page) => `${page + 1}`);
      // const userPages = pages.map((page) => `Частина ${page + 1} / ${pages.length}`);
      return (
        <>
          {CommandPanel ? (
            <CPContainer>
              <CommandPanel
                onActionClick={this.actionClickHandler}
                readOnly={readOnly}
                canDelete={!readOnly && !!activeRows.length}
                fullScreen={fullScreen}
              />
            </CPContainer>
          ) : null}

          <StyledPagesContainer>
            <IconContainer style={{ marginRight: '4px' }} onClick={() => this.handleClick('prev')}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" color="#4281C9" />
            </IconContainer>

            {userPages.map((page, k) => (
              <StyledPage
                active={k === currentPage}
                // eslint-disable-next-line
                key={k}
                onClick={() => this.setState({ currentPage: k, activeRows: [] })}
              >
                {page}
              </StyledPage>
            ))}

            <IconContainer onClick={() => this.handleClick('next')}>
              <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" color="#4281C9" />
            </IconContainer>
          </StyledPagesContainer>

          <TableContainer>
            <GridTemplate>
              {HeaderComponent && (
                <HeaderComponent data={data} fields={this.getFields()} />
              )}
              {visivleRows.map((row, k) => (
                <BodyComponent
                  // eslint-disable-next-line
                  key={k}
                  row={row}
                  rowNumber={row.index}
                  onChange={this.ChangeHandler}
                  fields={this.getFields()}
                  onClick={this.setActiveRow}
                  active={activeRows.includes(k + currentPage * RECORDS_PER_PAGE)}
                  readOnly={readOnly}
                  index={k}
                  shift={currentPage * RECORDS_PER_PAGE}
                />
              ))}
              {FooterComponent && (
                <FooterComponent
                  data={data}
                  fields={this.getFields()}
                  rowNumber={visivleRows.length}
                />
              )}
            </GridTemplate>
          </TableContainer>
        </>
      );
    };

    changeFilterHandler = (e, v) => {
      this.setState({
        filterFields: v,
      });
    };

    render() {
      const {
        fullScreen, filterEditorOpened, filterFields, recordsCount,
      } = this.state;
      if (filterEditorOpened) {
        return createPortal((
          <FilterPortalContainer>
            <FilterWrapper>
              <FiltersEditor
                filterFields={filterFields}
                onChange={this.changeFilterHandler}
                recordsCount={recordsCount}
                onClose={() => this.setState({ filterEditorOpened: false })}
              />
            </FilterWrapper>
          </FilterPortalContainer>
        ), document.body);
      }
      return !fullScreen ? (
        <NoPortalConatiner>
          {this.renderBody()}
        </NoPortalConatiner>
      ) : createPortal((
        <PortalContainer>
          {this.renderBody()}
        </PortalContainer>
      ), document.body);
    }
  }

  return TPController;
};

export default getTablePartController;
